
import { Transition, TransitionGroup } from 'vue'

export default {
  name: 'FadeTransition',
  components: {
    Transition,
    TransitionGroup
  },
  props: {
    isGroup: {
      type: Boolean,
      default: false
    }
  }
}
