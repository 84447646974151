
import { defineComponent, computed } from 'vue'
import { store } from '@/store'

import QuizService from '@/services/QuizService'

import QuizCard from '@feature/quiz/QuizCard.vue'

import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'QuizFinishStage',
  emits: ['quiz-navigation:next'],
  components: {
    QuizCard
  },
  setup() {
    // TODO: Dismiss chip
    const router = useRouter()

    const activeTeam = computed(() => store.getters['teams/getActiveTeam'])

    async function updateTeamInsights() {
      await store.dispatch('teams/getTeamInsights', { teamId: activeTeam.value?.teamId })
    }

    const finishQuizAction = () => {
      QuizService.stop()

      if (activeTeam.value) {
        updateTeamInsights()
      }

      router.push({ name: 'Profile' })
    }

    return {
      QuizService,
      finishQuizAction
    }
  }
})
