
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { useRouter } from 'vue-router'

/* Services */
import FormValidationService, { shallowValidate } from '@/services/FormValidationService'
import ModalHandlerService from '@/services/ModalHandlerService'
import ToastNotificationService from '@/services/ToastNotificationService'

/* Components */
import AppDropdown from '@common/AppDropdown.vue'
import Modal from '@common/Modal.vue'
import AccountPictureUpload from '@/components/feature/user-settings/personal/AccountPictureUpload.vue'
import SharedProfilePictureCrop from '@shared/SharedProfilePictureCrop.vue'
import SharedProfilePictureUpload from '@shared/SharedProfilePictureUpload.vue'

import isEmpty from '@/helpers/empty'

import { useStore } from '@/store'
import { TeamProfile } from '@/types/team'
import { AVAILABLE_ORG_INDUSTRIES, AVAILABLE_TEAM_SIZES, AVAILABLE_USER_ROLES } from '@/data/static'
import ImageUploadService from '@/services/ImageUploadService'

interface FormErrors {
  fieldEmptyErrors: Record<string, unknown>,
  specialErrors: Record<string, unknown>
}

export default defineComponent({
  name: 'SharedCreateTeamModal',
  components: {
    Modal,
    AppDropdown,
    AccountPictureUpload,
    SharedProfilePictureCrop,
    SharedProfilePictureUpload
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const loading = ref(false)
    const newPictureURL = ImageUploadService.getUrlData()

    const fields = reactive({
      teamName: '',
      teamType: '',
      teamSize: '',
      role: ''
    })

    const teamDisplayName = ref(fields.teamName)

    const errors = reactive<FormErrors>({
      fieldEmptyErrors: {},
      specialErrors: {}
    })

    async function createTeam() {
      errors.fieldEmptyErrors = shallowValidate({ teamName: fields.teamName })
      errors.specialErrors = FormValidationService({ teamName: fields.teamName })
      if (isEmpty(errors.fieldEmptyErrors) && isEmpty(errors.specialErrors)) {
        loading.value = true
        teamDisplayName.value = fields.teamName
        try {
          await store.dispatch('teams/createTeam', new TeamProfile(
            fields.teamName,
            newPictureURL.value?.pictureId,
            fields.teamType,
            fields.teamSize,
            fields.role
          ))
          ModalHandlerService.open('create-team-successful')
          clearFields()
        } catch (error) {
          if (error instanceof Error) {
            ToastNotificationService.push({ type: 'error', message: error.message })
          }
        } finally {
          loading.value = false
        }
      }
    }

    function clearFields() {
      fields.teamName = ''
      fields.teamType = ''
      fields.teamSize = ''
      fields.role = ''

      if (newPictureURL.value) {
        newPictureURL.value.pictureId = ''
      }
    }

    function handleModalClose() {
      ModalHandlerService.close()
      clearFields()
    }

    function handleCloseAfterTeamCreated() {
      ModalHandlerService.close()
      router.push({ path: '/settings', hash: '#organisation-members' })
      /* ModalHandlerService.open('add-members-modal') */
    }

    return {
      ...toRefs(errors),
      ...toRefs(fields),
      loading,
      ModalHandlerService,
      teamDisplayName,
      AVAILABLE_ORG_INDUSTRIES,
      AVAILABLE_TEAM_SIZES,
      AVAILABLE_USER_ROLES,
      handleCloseAfterTeamCreated,
      createTeam,
      handleModalClose
    }
  }
})
