/* eslint-disable @typescript-eslint/no-explicit-any */
import JiraMentionList from '@/components/common/JiraMentionList.vue'
import ApiService from '@/services/ApiService'
import { store } from '@/store'
import TeamJiraSettings, { JiraIssue } from '@/types/jiraSettings'
import Mention from '@tiptap/extension-mention'
import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion'
import { mergeAttributes, VueRenderer } from '@tiptap/vue-3'
import { PluginKey } from 'prosemirror-state'
import tippy, { GetReferenceClientRect } from 'tippy.js'
import { computed, ref } from 'vue'

export interface JiraMentionData {
    key: string,
    summary: string,
    summaryHtml: string,
    issueUrl: string
}

const jiraSettings = computed<TeamJiraSettings>(() => store.getters['integrations/getJiraSettings'])
const activeTeamId = computed(() => store.getters['user/getActiveMembership']?.teamId)
let currentSuggestions: JiraMentionData[] = []
let abortController = new AbortController()
const isSearching = ref(false)

export const JiraMention = Mention
  .extend({
    name: 'jiraMention',
    inline: true,
    group: 'inline',
    draggable: true,
    parseHTML() {
      return [
        {
          tag: 'span[data-type="jira-mention"]'
        }
      ]
    },
    renderHTML(props) {
      const { node } = props
      const issue = JSON.parse(node.attrs.id)
      return [
        'span', mergeAttributes(props.HTMLAttributes, { 'data-type': 'jira-mention', class: 'jira-mention' }),
        ['img', { class: 'mention-list__jira-item__icon', src: 'https://cadrelo-public.s3.ap-southeast-2.amazonaws.com/jira-blue.svg' }],
        ['a', { class: 'jira-mention__key', href: `${issue.issueUrl}`, target: '_blank' }, `${issue.key}`],
        ['span', { class: 'mention-list__jira-item__summary' }, `${issue.summary}`]
      ]
    }
  })
  .configure({
    HTMLAttributes: {
      class: 'mention'
    },
    suggestion: {
      items: (params: { query: string }): Promise<JiraMentionData[]> => getJiraIssues(params.query),
      render: renderJiraIssueSuggestions,
      char: '/jira',
      pluginKey: new PluginKey('jiraMention'),
      allowSpaces: true
    }
  })

// Gets a list of up to 5 jira issue suggestsions
async function getJiraIssues(query: string): Promise<JiraMentionData[]> {
  try {
    abortController.abort() // abort any pending search requests
    abortController = new AbortController()
    isSearching.value = true
    const issues = query.trim().length === 0 ? getDefaultIssues() : await searchForIssues(query)
    currentSuggestions = issues.map(e => ({
      key: e.key,
      summary: e.summary,
      summaryHtml: e.summaryHtml,
      issueUrl: `${jiraSettings.value?.jiraSiteUrl}/browse/${e.key}`
    })).slice(0, 5)
    isSearching.value = false
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return currentSuggestions
  }
}

function getDefaultIssues(): JiraIssue[] {
  return jiraSettings.value?.defaultIssueSuggestions ?? []
}

async function searchForIssues(query: string): Promise<JiraIssue[]> {
  const response = await ApiService.sendRequest(`jira-issues/${activeTeamId.value}?query=${query}`, {
    method: 'GET',
    requiresAuth: true,
    abortController
  })
  return await response.json()
}

function renderJiraIssueSuggestions(): any {
  let component: VueRenderer
  let popup: any

  return {
    onStart: (props: SuggestionProps) => {
      component = new VueRenderer(JiraMentionList, {
        props: {
          ...props,
          isSearching
        },
        editor: props.editor
      })

      popup = tippy('body', {
        getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: 'manual',
        placement: 'bottom-start',
        maxWidth: '500px'
      })
    },

    onUpdate(props: SuggestionProps) {
      component.updateProps(props)

      popup[0].setProps({
        getReferenceClientRect: props?.clientRect
      })
    },

    onKeyDown(props: SuggestionKeyDownProps) {
      if (props.event.key === 'Escape') {
        popup[0].hide()

        return true
      }

      return component.ref?.onKeyDown(props)
    },

    onExit() {
      popup[0].destroy()
      component.destroy()
    }
  }
}
