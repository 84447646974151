import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-spacer__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.type && `app-spacer--${_ctx.type}`, "app-spacer"]),
    role: "separator",
    "aria-orientation": "horizontal"
  }, [
    (!!_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", null, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}