import { Component } from 'vue'
import { QuizInterestItem } from './quiz'

export type Callback = () => void

export interface IFormErrors {
  fieldEmptyErrors?: Record<string, unknown>,
  specialErrors?: Record<string, unknown>,
  fileUploadErrors?: Record<string, unknown>
}

export interface IPictureObject {
  src: string,
  alt: string
}

export interface IToggleOption {
  text: string,
  iconName: string
}

export interface IPanelItem {
  name: string,
  id: string,
  component: Component
}

export interface ISettingsPanels {
  personal: {
    label: string,
    panels: IPanelItem[]
  },
  organisation: {
    label: string,
    panels: IPanelItem[]
  }
}

export interface ICommonInterestsAndPassions {
  interests: QuizInterestItem[]
  passions: string[]
}
export interface CheckInStats {
  value: number,
  title: string
}

export enum TEAM_DIRECTORY_SECTIONS {
  ABOUT = 'About',
  TEAM = 'Team',
  AT_WORK = 'At work',
  INTERESTS = 'Interests',
  TOP_INTERESTS = 'Top interests',
  CONTRIBUTION_STYLE = 'Contribution style',
  TEAM_VALUES = 'Team values',
  TEAM_SPLITS = 'Team splits'
}

export type DetectedMouseEvent = MouseEvent | TouchEvent | Event

export enum CHECK_IN_SECTIONS {
  CHECK_IN = 'Check-in',
  CYCLE_VIEW = 'Cycle view',
  ACTION_LIST = 'Action list'
}

export enum GOALS_SECTIONS {
  ACTIVE = 'Active',
  CLOSED = 'Closed'
}

export enum DAILY_SYNC_SECTIONS {
  SYNC_DETAILS = 'Sync details',
  COMMENTS = 'Comments'
}

export enum EDIT_NOTE_SECTIONS {
  NOTE_DETAILS = 'Note details',
  COMMENTS = 'Comments'
}

export enum EDIT_GOAL_SECTIONS {
  GOAL_DETAILS = 'Goal details',
  COMMENTS = 'Comments'
}

export enum TEAMS_SETTINGS_TABS {
  MEMBERS = 'Members',
  SETTINGS = 'Settings'
}

export enum PULSE_NAVIGATION {
  RETRO = 'Board',
  ANALYTICS = 'Analytics',
}

export enum DAYS_IN_WEEK {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum FILTER_OPTIONS {
  USER = 'user',
  GOAL = 'goal',
  TEAM = 'team'
}

export interface SLICER_FILTER_OPTION {
  id: string,
  title: string,
  iconName: string
}

export interface IMarketingData {
  src: string,
  title: string,
  description: string
}

export interface IOnboardingData {
  src: string,
  firstline: string,
  secondline: string,
  description: string
}

export const MIN_CHARS_INCLUDING_HTML_START_END_BRACKETS = 8
