import { CheckInItemCategory } from './checkinItemCategory'

export default class CheckInConfig {
  constructor(
        public teamId: string,
        public enabled: boolean,
        public daysInCycle: number,
        public categories: CheckInItemCategory[]
  ) {}
}

export class UpsertCheckInConfigRequest {
  constructor(
        public enabled: boolean,
        public daysInCycle: number,
        public categories: CheckInItemCategory[]
  ) {}
}
