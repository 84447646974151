
import { useStore } from '@/store'
import { Membership } from '@/types/membership'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { APP_CHECKBOX_VARIANTS } from '@/components/global/AppCheckbox.vue'

import AppAvatar from '@common/AppAvatar.vue'

interface selectedUser extends Membership {
  selected: boolean
}
export default defineComponent({
  name: 'FilterUserDropdown',
  emits: ['update:modelValue', 'user-action:selected'],
  components: {
    AppAvatar
  },
  props: {
    modelValue: {
      type: Array as PropType<Membership[]>,
      default: () => []
    },
    teamMembers: {
      type: Array as PropType<Membership[]>,
      required: false
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const userSelected = ref<boolean>()
    const searchTerm = ref('')
    const getUsers = computed(() => store.getters['teams/getActiveTeamMembers'].map((membership: Membership) => ({ ...membership, selected: selectedUsers.value.some((user: Membership) => user.userId === membership.userId) })))
    const users = computed(() => props.teamMembers ? props.teamMembers : getUsers.value)
    const selectedUsers = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    const filteredUsers = ref(users.value)

    function addToFilter(item: selectedUser) {
      const alreadyAdded = selectedUsers.value?.filter((t) => t.userId === item.userId)
      const user = filteredUsers.value.find((el: Membership) => el.userId === item.userId)

      if (!alreadyAdded.length && !user.selected) {
        user.selected = true
        item.selected = true
        selectedUsers.value.push(item)
      } else {
        user.selected = false
        const objWithIdIndex = selectedUsers.value.findIndex((obj) => obj.userId === item.userId)

        if (objWithIdIndex > -1) {
          selectedUsers.value.splice(objWithIdIndex, 1)
        }
      }

      emit('update:modelValue', selectedUsers.value)
    }

    watch(searchTerm, () => {
      if (searchTerm.value) {
        filteredUsers.value = users.value
          .filter((user: Membership) => user.userProfile.firstName?.concat(' ').concat(user.userProfile.lastName as string).toLowerCase().includes(searchTerm.value.toLowerCase()))
      } else {
        filteredUsers.value = users.value
      }
    })

    return {
      APP_CHECKBOX_VARIANTS,
      filteredUsers,
      userSelected,
      selectedUsers,
      searchTerm,
      addToFilter
    }
  }
})
