import { Component } from 'vue'
import { RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw, RouterView } from 'vue-router'

/* Layouts */
import AuthLayout from '@/layouts/AuthLayout.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'

// Todo; Extract & use in vue.config.js
const FEATURE_DOMAIN = 'feature'
const STAGING_DOMAIN = 'staging'

const isFeatureOrStaging = process.env.VUE_APP_DOMAIN === FEATURE_DOMAIN || process.env.VUE_APP_DOMAIN === STAGING_DOMAIN

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Sync',
    component: (): Component => {
      return import(/* webpackChunkName: "sync" */ '@/views/Sync.vue')
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      layout: MainLayout
    },
    props: true
  },
  {
    path: '/pulse/',
    name: 'Pulse',
    component: (): Component => {
      return import(/* webpackChunkName: "pulse" */ '@/views/Pulse.vue')
    },
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      layout: MainLayout
    }
  },
  {
    path: '/goals/',
    name: 'Goals',
    component: (): Component => {
      return import(/* webpackChunkName: "pulse" */ '@/views/Goals.vue')
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      layout: MainLayout
    }
  },
  {
    path: '/plays',
    name: 'Plays',
    component: () => import(/* webpackChunkName: "plays" */ '@/views/Plays.vue'),
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      layout: MainLayout
    }
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/Subscriptions.vue'),
    meta: {
      requiresAuth: true,
      layout: MainLayout
    }
  },
  {
    path: '/plays/:code',
    name: 'Play Session',
    component: () => import(/* webpackChunkName: "plays" */ '@/views/PlayLobby.vue'),
    meta: {
      requiresAuth: false,
      layout: DefaultLayout
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
    meta: {
      requiresAuth: true,
      layout: MainLayout
    }
  },
  {
    path: '/sign-up',
    name: 'Sign up',
    component: () => import(/* webpackChunkName: "sign-up" */ '@/views/SignUp.vue'),
    meta: {
      disallowIfAuth: true,
      layout: AuthLayout
    },
    props: true
  },
  {
    path: '/sign-in',
    name: 'Sign in',
    component: () => import(/* webpackChunkName: "sign-in" */ '@/views/SignIn.vue'),
    meta: {
      disallowIfAuth: true,
      layout: AuthLayout
    },
    props: true
  },
  {
    path: '/reset-password/',
    name: 'Reset password',
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'),
    meta: {
      disallowIfAuth: true,
      layout: AuthLayout
    },
    props: true
  },
  {
    name: 'Profile',
    path: '/profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      layout: MainLayout
    }
  },
  {
    path: '/team',
    name: 'Team',
    component: RouterView,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      layout: MainLayout
    },
    children: [
      {
        path: '',
        name: 'TeamUsersList',
        component: () => import(/* webpackChunkName: "team" */ '@/views/Team.vue')
      },
      {
        name: 'TeamUserProfile',
        path: '/team/:teamId/users/:userId',
        component: () => import(/* webpackChunkName: "team-profile" */ '@/views/Profile.vue')
      }
    ]
  },
  {
    // because the oauth redirect from slack cannot contain a # we redirect to the #team-apps page from the /slack-auth path
    path: '/slack-auth',
    redirect: (to: RouteLocationNormalized): RouteLocationRaw => {
      return { path: '/settings', query: to.query, hash: '#organisation-apps' }
    }
  },
  {
    // because the oauth redirect from JIRA cannot contain a # we redirect to the #team-apps page from the /jira-auth path
    path: '/jira-auth',
    redirect: (to: RouteLocationNormalized): RouteLocationRaw => {
      return { path: '/settings', query: to.query, hash: '#organisation-apps' }
    }
  },
  {
    path: '/sync',
    redirect: (to: RouteLocationNormalized): RouteLocationRaw => {
      return { path: '/', query: to.query }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: ":pathMatch(.*)*" */ '@/views/PageNotFound.vue')
  }
]

if (isFeatureOrStaging) {
  routes.push({
    path: '/design-system',
    name: 'DesignSystem',
    component: () => import(/* webpackChunkName: "design-system" */ '@/views/DesignSystem.vue')
  })
  routes.push({
    path: '/social-connect',
    name: 'SocialConnect',
    component: () => import(/* webpackChunkName: "design-system" */ '@/views/SocialConnect.vue')
  })
}

export default routes
