
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppPanel',
  props: {
    tag: {
      type: String,
      required: false
    },
    elevated: {
      type: Boolean,
      required: false
    },
    swirl: {
      type: Boolean,
      required: false
    },
    swirlCentered: {
      type: Boolean,
      required: false
    },
    subPanel: {
      type: Boolean,
      required: false
    },
    positionRight: {
      type: Boolean,
      required: false
    },
    secondary: {
      type: Boolean,
      required: false
    }
  }
})
