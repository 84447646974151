
import { computed, defineComponent, PropType, ref } from 'vue'
import { ReactionId } from '@/types/reaction'
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import AnimationLike from '@/assets/images/reactions/like.json'
import AnimationLove from '@/assets/images/reactions/love.json'
import AnimationHaha from '@/assets/images/reactions/haha.json'
import AnimationCelebrate from '@/assets/images/reactions/celebrate.json'
import AnimationAmazing from '@/assets/images/reactions/amazing.json'
import AnimationWow from '@/assets/images/reactions/wow.json'
import AnimationSad from '@/assets/images/reactions/sad.json'
import AnimationGratitude from '@/assets/images/reactions/gratitude.json'

enum ReactionInputTheme {
  DAILY_SYNC = 'daily_sync',
  COMMENT = 'comment'
}

export default defineComponent({
  name: 'ReactionInput',
  emits: ['update:modelValue'],
  components: {
    Vue3Lottie
  },
  props: {
    modelValue: {
      type: String,
      required: false
    },
    theme: {
      type: String as PropType<ReactionInputTheme>,
      default: 'daily_sync',
      validator: (value: ReactionInputTheme): boolean => Object.values(ReactionInputTheme).includes(value)
    }
  },
  setup(props, { emit }) {
    const selectedReaction = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const showReactionOverlay = ref(false)
    let lockReactionOverlay = false
    let revealReactionOverlayTimer: ReturnType<typeof setTimeout> | null = null

    function reactionHover() {
      revealReactionOverlayTimer = setTimeout(() => {
        if (!lockReactionOverlay) {
          showReactionOverlay.value = true
        }
      }, 300)
    }

    function reactionUnhover() {
      if (revealReactionOverlayTimer) {
        clearTimeout(revealReactionOverlayTimer)
      }
      showReactionOverlay.value = false
    }

    function setReaction(id?: ReactionId) {
      selectedReaction.value = id
      reactionUnhover()
      lockReactionOverlay = true
      setTimeout(() => { lockReactionOverlay = false }, 500)
    }

    const iconSize = props.theme === 'daily_sync' ? 22 : 16

    return {
      ReactionId,
      AnimationLike,
      AnimationLove,
      AnimationHaha,
      AnimationCelebrate,
      AnimationAmazing,
      AnimationWow,
      AnimationSad,
      AnimationGratitude,
      selectedReaction,
      showReactionOverlay,
      setReaction,
      reactionHover,
      reactionUnhover,
      iconSize
    }
  }
})
