import ApiService from '@/services/ApiService'
import { DreTrayMessage } from '@/types/dreTray'
import { DreTrayState, RootState } from '@/types/store'
import { DateTime } from 'luxon'
import { Module } from 'vuex'

const dreTray: Module<DreTrayState, RootState> = {
  namespaced: true,
  state: {
    messages: [],
    dreTrayLoaded: false
  },
  getters: {
    getMessages: (state) => state.messages,
    isDreTrayLoaded: (state) => state.dreTrayLoaded
  },
  mutations: {
    setMessages(state, messages: DreTrayMessage[]) {
      messages.sort((a, b) => DateTime.fromISO(b.time).diff(DateTime.fromISO(a.time)).milliseconds)
      state.messages = messages
    },
    setDreTrayLoaded(state, value: boolean) {
      state.dreTrayLoaded = value
    },
    upsertMessage(state, message: DreTrayMessage) {
      const messages = state.messages.filter(e => e.id !== message.id)
      messages.push(message)
      messages.sort((a, b) => DateTime.fromISO(b.time).diff(DateTime.fromISO(a.time)).milliseconds)
      state.messages = messages
    }
  },
  actions: {
    clearActiveTeamData({ commit }) {
      commit('setMessages', [])
      commit('setDreTrayLoaded', false)
    },
    async loadActiveTeamData({ dispatch }, params: { teamId: string }): Promise<void> {
      await dispatch('getMessages', params)
    },
    async getMessages({ commit }, params: { teamId: string }): Promise<void> {
      try {
        const response = await ApiService.sendTrackedRequest(`dre-tray/${params.teamId}`, {
          method: 'GET',
          requiresAuth: true
        })
        commit('setMessages', response)
        commit('setDreTrayLoaded', true)
      } catch (error) {
        throw new Error('Could not retrieve current dre insights cycle')
      }
    },
    async getDailySyncSummary({ commit }, params: { teamId: string, date: string }): Promise<void> {
      try {
        const response = await ApiService.sendTrackedRequest(`dre-tray/${params.teamId}/daily-sync/${params.date}/summary`, {
          method: 'GET',
          requiresAuth: true
        })
        if (response) {
          commit('upsertMessage', response)
        }
      } catch (error) {
        console.log(error)
        throw new Error('Could not retrieve daily sync summary')
      }
    },
    async getIndividualStrategy({ commit }): Promise<void> {
      try {
        const response = await ApiService.sendTrackedRequest('dre-individual-strategy', {
          method: 'GET',
          requiresAuth: true
        })
        if (response) {
          commit('upsertMessage', response)
        }
      } catch (error) {
        throw new Error('Could not retrieve daily message from Dre')
      }
    },
    async upsertMessage({ commit }, params: { message: DreTrayMessage, expectResponse: boolean }): Promise<void> {
      commit('upsertMessage', params.message)
      const response = await ApiService.sendRequest(`dre-tray/${params.message.teamId}/${params.message.id}?expectResponse=${params.expectResponse}`, {
        method: 'PUT',
        requiresAuth: true,
        body: JSON.stringify(params.message)
      })
      if (response.status === 200) {
        const dreResponse = await response.json()
        commit('upsertMessage', dreResponse)
      }
    }
  }
}

export default dreTray
