<template>
  <div class="main-notification-item">
    <slot>
      <div class="main-notification-item__image">
        <!-- <AppIcon name="dre" /> -->
        <img src="@/assets/images/dreAvatar.png" loading="lazy"/>
      </div>
    </slot>

    <div class="main-notification-item__content">
      <slot name="title" />
      <slot class="main-notification-item__timestamp" name="timestamp" />
      <div class="main-notification-item__actions">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainNotificationItem'
}
</script>

<style lang="scss">
.main-notification-item {
  $self: &;

  padding: 12px 16px 12px 0;
  border-radius: 5px;
  display: flex;
  position: relative;

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: var(--color-primary);
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  p {
    margin: 0;
  }

  & + & {
    margin-top: 8px;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-grey-100);
    border-radius: 50%;
    flex-basis: 44px;
    width: 44px;
    height: 44px;

    img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }
  }

  &__content {
    margin-left: 12px;
    flex: 1;

    & > p {
      font-weight: 600;
      line-height: 1.35em;
      font-size: 14px;
      text-align: left;
      color: var(--color-augite);

      & + p {
        margin-top: 4px;
        font-size: 12px;
        color: var(--color-primary);
        line-height: 16px;
      }
    }
  }

  &__actions {
    flex-basis: 100%;
    display: flex;
    margin-top: 4px;

    & > .app-button {
      width: auto;
    }

    .app-button + .app-button {
      margin-left: 8px;
    }
  }

  strong {
    color: var(--color-primary)
  }

  &__timestamp {
    color: var(--color-primary);
    font-family: $nunito;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &.seen {
    color: var(--color-slate);
    padding: 12px 0;

    &::after {
      content: none;
    }

    #{$self}__timestamp {
      color: var(--color-slate)
    }
  }
}
</style>
