
import AppTextEditor from '@/components/common/AppTextEditor.vue'
import { extractMentionedUsers } from '@/helpers/mentionUtils'
import ToastNotificationService from '@/services/ToastNotificationService'
import { useStore } from '@/store'
import Conversation, { Comment, CommentType, ItemId } from '@/types/comments'
import { User } from '@/types/user'
import { isNonEmptyRichText } from '@/utils/conversationUtils'
import AppAvatar from '@common/AppAvatar.vue'
import CommentComponent from '@common/Comment.vue'
import { computed, defineComponent, PropType, ref, watch } from 'vue'

export default defineComponent({
  name: 'AppComment',
  emits: ['user-action:reply', 'user-action:click', 'update:selected-comment-id', 'update:selected-reply-id'],
  components: {
    AppAvatar,
    CommentComponent,
    AppTextEditor
  },
  props: {
    itemId: {
      type: Object as PropType<ItemId>,
      required: true
    },
    itemType: {
      type: String,
      required: true
    },
    commentFieldToggled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()
    const currentUser = computed<User>(() => store.getters['user/getUser'])
    const conversation = computed<Conversation>(() => {
      if (props.itemType === CommentType.DAILY_SYNC) {
        return store.getters['conversation/getDailySyncConversation'](props.itemId)
      } else if (props.itemType === CommentType.SOCIAL_CONNECT) {
        return store.getters['conversation/getSocialConnectConversation'](props.itemId)
      } else {
        return store.getters['conversation/getDreInsightsConversation'](props.itemId)
      }
    })
    const defaultVisibleComments = computed<Comment[]>(() => {
      const comments = conversation.value?.comments.filter(e => !e.deleted)
      const commentCount = comments?.length
      if (comments && commentCount) {
        const latestComment = comments[commentCount - 1]
        if (showHiddenComments.value) { // show all comments
          return comments
        } else if (latestComment.replies?.length || commentCount === 1) { // if latest comment has replies then that is the only comment that will be made visible
          return [latestComment]
        } else {
          return [comments[commentCount - 2], latestComment] // if latest comment has no replies then the latest 2 comments are visible
        }
      } else {
        return []
      }
    })
    const hiddenCommentCount = computed(() => {
      const hiddenComments = conversation.value?.comments.filter(e => !defaultVisibleComments.value.includes(e)) ?? []
      const countOfCommentsPlusReplies = hiddenComments.reduce((acc, comment) => acc + (comment.deleted ? 0 : 1) + comment.replies.filter(e => !e.deleted).length, 0)
      return countOfCommentsPlusReplies
    })
    const showHiddenComments = ref(false)
    const commentInput = ref<string>()
    const expandCommentInput = ref(props.commentFieldToggled)
    const isSavingComment = ref(false)
    const commentWithOpenReply = ref<string>() // the comment id that we are displaying the reply editor for
    const newCommentEditor = ref()

    async function postComment() {
      isSavingComment.value = true
      await addCommentToConversation(commentInput.value)
      isSavingComment.value = false
    }

    async function addCommentToConversation(content?: string, replyToCommentId?: string) {
      if (isNonEmptyRichText(content)) {
        try {
          const request = {
            itemId: props.itemId,
            content,
            mentions: extractMentionedUsers(content),
            replyToCommentId
          }
          await store.dispatch('conversation/addComment', {
            itemType: props.itemType,
            request
          })
        } catch (error) {
          if (error instanceof Error) {
            ToastNotificationService.push({ type: 'error', message: error.message })
          }
        }
      }
    }

    function onReply(commentId: string) {
      commentWithOpenReply.value = commentId
    }

    function closeReply(commentId: string) {
      if (commentWithOpenReply.value === commentId) {
        commentWithOpenReply.value = undefined
      }
    }

    watch(() => props.commentFieldToggled, (value) => {
      if (value) {
        displayCommentInput()
      }
    })

    function displayCommentInput() {
      expandCommentInput.value = true
      setTimeout(() => {
        newCommentEditor.value.editor.chain().focus()
      }, 50)
    }

    async function onPostReply(commentId: string, content: string) {
      await addCommentToConversation(content, commentId)
      closeReply(commentId)
    }

    return {
      currentUser,
      defaultVisibleComments,
      hiddenCommentCount,
      showHiddenComments,
      commentInput,
      expandCommentInput,
      isSavingComment,
      commentWithOpenReply,
      postComment,
      onReply,
      closeReply,
      onPostReply,
      newCommentEditor,
      displayCommentInput
    }
  }
})
