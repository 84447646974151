
import { defineComponent, computed, PropType } from 'vue'
const SUPPORTED_INPUT_TYPES = ['text', 'password', 'email', 'date']

interface DateType {
  [key: string]: string
}

export default defineComponent({
  name: 'AppInput',
  emits: ['update:modelValue', 'focus', 'blur', 'update:date'],
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: true,
      validator: (value: string): boolean => SUPPORTED_INPUT_TYPES.includes(value)
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: [Boolean, String],
      required: false,
      default: false
    },
    modelValue: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    date: {
      type: Object as PropType<DateType>,
      required: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const placeholderText = props.placeholder ? props.placeholder : `Enter ${props.type}`
    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    function updateDateFields(key: string, value: string) {
      emit('update:date', { ...props.date, [key]: value })
    }

    return {
      inputValue,
      placeholderText,
      updateDateFields
    }
  }
})
