import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSelect = _resolveComponent("AppSelect")!
  const _component_AppDropdown = _resolveComponent("AppDropdown")!

  return (_openBlock(), _createBlock(_component_AppDropdown, {
    class: _normalizeClass(["daily-sync-mood-selector", { 'daily-sync-mood-selector--selected': _ctx.selectedMood }]),
    type: "simple"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AppSelect, {
        modelValue: _ctx.selectedMood,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedMood) = $event)),
        options: _ctx.MOOD_OPTIONS,
        min: 1,
        "close-on-select": true,
        maxHeight: 186
      }, {
        toggle: _withCtx(({ isFocusing, toggle }) => [
          _createElementVNode("div", {
            onClick: toggle,
            onMousedown: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent","stop"])),
            class: _normalizeClass(["app-dropdown__vue-select-trigger", { active: isFocusing }])
          }, null, 42, _hoisted_1)
        ]),
        label: _withCtx(({ selected }) => [
          selected
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(selected.emoji) + " " + _toDisplayString(selected.title), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createTextVNode(_toDisplayString(_ctx.selectedMood.emoji) + " ", 1),
                _createElementVNode("em", null, _toDisplayString(_ctx.selectedMood.title), 1)
              ]))
        ]),
        "dropdown-item": _withCtx(({ option }) => [
          _createElementVNode("span", null, _toDisplayString(option.emoji) + " " + _toDisplayString(option.title), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "options"])
    ]),
    _: 1
  }, 8, ["class"]))
}