import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "filter-users-select__title" }
const _hoisted_2 = { class: "filter-users-select__items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckInAssigneesAvatar = _resolveComponent("CheckInAssigneesAvatar")!
  const _component_AppButtonMini = _resolveComponent("AppButtonMini")!
  const _component_FilterUserDropdown = _resolveComponent("FilterUserDropdown")!
  const _component_AppBasicDropdown = _resolveComponent("AppBasicDropdown")!
  const _component_AppDropdown = _resolveComponent("AppDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["filter-users-select", { 'filter-users-select--stacked' : _ctx.openDirection === _ctx.OPEN_DIRECTION.TOP }])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users.slice(0, 3), (selected) => {
        return (_openBlock(), _createBlock(_component_CheckInAssigneesAvatar, {
          key: `contributor-${selected.userId}`,
          onClick: ($event: any) => (_ctx.removeItem(selected)),
          user: selected.userProfile
        }, null, 8, ["onClick", "user"]))
      }), 128)),
      (!_ctx.$helpers.isEmpty(_ctx.users.slice(3)))
        ? (_openBlock(), _createBlock(_component_CheckInAssigneesAvatar, {
            key: 0,
            user: { firstName: '+', lastName: _ctx.users.slice(3).length.toString() },
            preventRemove: true
          }, null, 8, ["user"]))
        : _createCommentVNode("", true),
      _createVNode(_component_AppDropdown, {
        key: "add-contributor",
        type: "simple",
        class: "app-filter__dropdown"
      }, {
        handler: _withCtx((props) => [
          _createVNode(_component_AppButtonMini, {
            icon: "plus-charoite",
            onClick: props.visibility.toggle
          }, null, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_AppBasicDropdown, null, {
              items: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_FilterUserDropdown, {
                    modelValue: _ctx.users,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.users) = $event)),
                    teamMembers: _ctx.teamMembers,
                    class: "app-filter-chip__dropdown"
                  }, null, 8, ["modelValue", "teamMembers"])
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ])
  ], 2))
}