import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = {
  key: 1,
  class: "aspect-ratio-16-9"
}
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["app-interaction-button", {
      'app-interaction-button--has-image': _ctx.image,
      'app-interaction-button--has-icon': _ctx.icon && !_ctx.image,
      'app-interaction-button--has-description': _ctx.description && !_ctx.image
    }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.interactionButtonClickHandler && _ctx.interactionButtonClickHandler(...args)))
  }, [
    (!_ctx.image)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_AppIcon, {
                key: 0,
                name: _ctx.icon
              }, null, 8, ["name"]))
            : _createCommentVNode("", true),
          (_ctx.description)
            ? (_openBlock(), _createElementBlock("p", {
                key: 1,
                textContent: _toDisplayString(_ctx.description),
                class: "app-interaction-button__description no-margin"
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.image.src,
            alt: _ctx.image.alt
          }, null, 8, _hoisted_3)
        ]))
  ], 2))
}