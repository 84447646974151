
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'

import { UserProfile } from '@/types/user'
import ModalHandlerService from '@/services/ModalHandlerService'
import WindowEventsService, { CSS_BREAKPOINTS } from '@/services/WindowEventsService'

import AppAvatar from '@common/AppAvatar.vue'
import AppSpacer from '@common/AppSpacer.vue'
import OrgDropdown from '@/components/UserInterface/OrgDropdown.vue'

export default defineComponent({
  name: 'MobileNavigationPanel',
  emits: ['close-panel'],
  components: {
    AppAvatar,
    AppSpacer,
    OrgDropdown
  },
  setup() {
    const store = useStore()
    const user = computed(() => store.getters['user/getUser'])
    const trackingState = computed(() => store.getters['timetracker/getActiveTrackingStatus'])

    const activeMembershipTeamName = computed(() => store.getters['user/getActiveMembership']?.teamProfile.name)

    const userProfile = computed(() => new UserProfile(
      user.value.profile?.displayName || user.value.email,
      user.value.profile?.firstName,
      user.value.profile?.lastName,
      user.value.profile?.pictureId
    ))

    const userDisplayName = computed(() => {
      return user.value.profile.firstName && user.value.profile.lastName
        ? `${user.value.profile.firstName} ${user.value.profile.lastName}`
        : user.value.profile.displayName || user.value.email
    })

    async function signOut() {
      if (trackingState.value) await store.dispatch('timetracker/stopTracking')
      return store.dispatch('auth/signOut')
    }

    return {
      activeMembershipTeamName,
      userDisplayName,
      userProfile,
      user,
      ModalHandlerService,
      WindowEventsService,
      CSS_BREAKPOINTS,
      signOut
    }
  }
})
