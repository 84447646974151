import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$attrs.href ? 'a' : 'button'), {
    class: _normalizeClass(["app-button", [
      $props.size && `app-button--${$props.size}`,
      $props.theme && `app-button--${$props.theme}`,
      $props.loading && `app-button--loading`
    ]]),
    type: !_ctx.$attrs.href ? 'button' : null,
    href: _ctx.$attrs.href,
    disabled: $props.loading || $props.disabled
  }, {
    default: _withCtx(() => [
      ($props.loading || $props.icon)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.loading ? 'AppLoadingIcon' : 'AppIcon'), {
            key: 0,
            name: !$props.loading ? $props.icon : false
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["type", "href", "disabled", "class"]))
}