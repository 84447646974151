import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/swirl.png'
import _imports_1 from '@/assets/images/swirl-2.png'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  class: "app-panel__swirl-image"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  class: "app-panel__swirl-image"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag ? _ctx.tag : 'div'), {
    class: _normalizeClass(["app-panel", [
      _ctx.swirl && 'app-panel--swirl',
      _ctx.swirlCentered && 'app-panel--swirl-centered',
      _ctx.elevated && 'app-panel--elevated',
      _ctx.subPanel && 'app-panel--sub-panel',
      _ctx.secondary && 'app-panel--secondary',
      _ctx.positionRight && 'app-panel--position-right'
    ]])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.swirl)
        ? (_openBlock(), _createElementBlock("img", _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.swirlCentered)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class"]))
}