
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'AppRangeSlider',
  emits: ['update:modelValue'],
  inheritAttrs: false,
  props: {
    min: {
      type: Number,
      required: false,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 100
    },
    step: {
      type: Number,
      required: false,
      default: 1
    },
    modelValue: {
      type: Number,
      required: false,
      default: 0
    }
  },
  setup(props, { emit }) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    return {
      inputValue
    }
  }
})
