
import { defineComponent } from 'vue'
import { IMarketingData } from '@/types/global'

import AppSlider from '@common/AppSlider.vue'

export default defineComponent({
  name: 'AppMarketing',
  components: {
    AppSlider
  },
  setup() {
    const MARKETING_DATA: IMarketingData[] = [
      {
        src: require('@/assets/images/marketing-daily-sync.png'),
        title: 'Daily sync',
        description: 'Daily sync experience keeps your team on the same page at all times, allowing you to update, share, and request feedback while tracking progress in real-time.'
      },
      {
        src: require('@/assets/images/marketing-timetracking.png'),
        title: 'Time tracking',
        description: 'Simplify task based time tracking by eliminating manual data entry through effortless and hassle-free  tracking experience.'
      },
      {
        src: require('@/assets/images/marketing-goals.png'),
        title: 'Tasks and goals',
        description: 'Goals offer a intuitive way to drive towards an outcome in either a planned or emergent workflowsm - so you can just focus on getting stuff done.'
      },
      {
        src: require('@/assets/images/marketing-team-directory.png'),
        title: 'Team directory',
        description: 'Breezy way for new and existing team members to share their human side and build deeper connections over common interests and values.'
      },
      {
        src: require('@/assets/images/marketing-pulse.png'),
        title: 'Board',
        description: 'A powerful engagement tool that helps the team continually share, discuss and act on feedback that helps them kick goals.'
      }
    ]

    return { MARKETING_DATA }
  }
})
