import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-time-tracker__task-info" }
const _hoisted_2 = ["value", "disabled"]
const _hoisted_3 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButtonMini = _resolveComponent("AppButtonMini")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-time-tracker", {
    'app-time-tracker--running': _ctx.isTrackingCurrentTask
  }])
  }, [
    _createVNode(_component_AppButtonMini, {
      icon: _ctx.isTrackingCurrentTask ? 'stop' : 'play',
      "icon-size": 8,
      onClick: _ctx.toggleTimer
    }, null, 8, ["icon", "onClick"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["app-time-tracker__inputs-wrapper", { 'app-time-tracker__inputs-wrapper--disabled': _ctx.isTrackingCurrentTask }])
      }, [
        _createElementVNode("input", {
          id: "time-tracker-hours",
          value: _ctx.leadingZero(_ctx.hours),
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hours = +$event.target.value)),
          disabled: _ctx.isTrackingCurrentTask,
          maxlength: "2",
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.overrideTimeblocks && _ctx.overrideTimeblocks(...args)))
        }, null, 40, _hoisted_2),
        _createElementVNode("span", {
          class: _normalizeClass(["colon", { 'colon__tracking': _ctx.isTrackingCurrentTask }])
        }, ":", 2),
        _createElementVNode("input", {
          id: "time-tracker-minutes",
          value: _ctx.leadingZero(_ctx.minutes),
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cc($event.target.value))),
          disabled: _ctx.isTrackingCurrentTask,
          maxlength: "2",
          onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.overrideTimeblocks && _ctx.overrideTimeblocks(...args)))
        }, null, 40, _hoisted_3)
      ], 2)
    ])
  ], 2))
}