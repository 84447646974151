
import { defineComponent, ref } from 'vue'

import QuizService, { QUIZ_STAGE_DEFINITION, QUIZ_BASIC_STAGE_DEFINITION } from '@/services/QuizService'

import QuizBasicStageGifs from '@feature/quiz/quiz-basic-stage/QuizBasicStageGifs.vue'
import QuizBasicStageIntro from '@feature/quiz/quiz-basic-stage/QuizBasicStageIntro.vue'
import QuizBasicStageSkills from '@feature/quiz/quiz-basic-stage/QuizBasicStageSkills.vue'
import QuizBasicStageDescription from '@feature/quiz/quiz-basic-stage/QuizBasicStageDescription.vue'

import QuizGroupNavigationTransition from '@transition/QuizGroupNavigationTransition.vue'

export default defineComponent({
  name: 'QuizBasicStage',
  components: {
    QuizBasicStageGifs,
    QuizBasicStageIntro,
    QuizBasicStageSkills,
    QuizBasicStageDescription,
    QuizGroupNavigationTransition
  },
  setup() {
    const isNavigationDirectionForward = ref(false)

    return {
      QuizService,
      QUIZ_STAGE_DEFINITION,
      QUIZ_BASIC_STAGE_DEFINITION,
      isNavigationDirectionForward
    }
  }
})
