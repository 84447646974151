export enum Plan {
  FREE = 'Free',
  TEAM = 'Team',
  ORG = 'Org'
}

export class Subscription {
  constructor(
      public plan = Plan.FREE,
      public paid = true,
      public stripeCustomerId?: string,
      public cancelAt: string | null = null,
      public freeTrialStart?: string
  ) {}
}

export class TeamValue {
  constructor(
    public id: string,
    public title: string,
    public description: string,
    public emoji?: string
  ) {}
}
export class TeamProfile {
  constructor(
      public name?: string,
      public pictureId?: string,
      public industry?: string,
      public size?: string,
      public role?: string
  ) {}
}

export class Team {
  constructor(
      public teamId: string,
      public createdAt: string,
      public profile: TeamProfile,
      public teamDirectoryEnabled: boolean,
      public dailySyncEnabled: boolean,
      public goalsEnabled: boolean,
      public values: TeamValue[],
      public subscription: Subscription
  ) { }
}
export class UpdateActiveTeam {
  constructor(
    public teamId: string,
    public profile?: TeamProfile,
    public values?: TeamValue[],
    public teamDirectoryEnabled?: boolean,
    public dailySyncEnabled?: boolean,
    public goalsEnabled?: boolean
  ) {}
}

export class Interest {
  constructor(
      public category: number,
      public subcategory: number,
      public id: number
  ) {}
}

export class TeamTopInterest {
  constructor(
      public interest: Interest,
      public matches: string[],
      public commonInterest?: string,
      public interestName?: string
  ) {}
}

export class TeamContributionStyles {
  constructor(
      public explorer: IndividualContributionStyle[],
      public structured: IndividualContributionStyle[],
      public outgoing: IndividualContributionStyle[],
      public supportive: IndividualContributionStyle[],
      public reactive: IndividualContributionStyle[]
  ) {}
}

export class IndividualContributionStyle {
  constructor(
      public userId: string,
      public value?: number
  ) {}
}

export enum GreatDebateCategory {
  CoffeeOrTea = 'CoffeeOrTea',
  EarlyBirdOrNightOwl = 'EarlyBirdOrNightOwl',
  CatsOrDogs = 'CatsOrDogs',
  NightInOrNightOut = 'NightInOrNightOut',
  BeachOrMountains = 'BeachOrMountains'
}

export class TeamGreatDebateSplit {
  constructor(
      public category: GreatDebateCategory,
      public firstOption: number,
      public secondOption: number
  ) {}
}
