export enum GroupRole {
  Owner = 'Owner',
  Admin = 'Admin',
  Member = 'Member'
}

export class GroupMembership {
  constructor(
    public userId: string,
    public role: GroupRole
  ) {}
}

export class Group {
  constructor(
    public id: string,
    public teamId: string,
    public name: string,
    public memberships: GroupMembership[],
    public type?: string,
    public description?: string
  ) {}
}

export class CreateGroupRequest {
  constructor(
    public teamId: string,
    public name: string,
    public type?: string,
    public description?: string,
    public memberships?: GroupMembership[]
  ) {}
}

export class UpdateGroupRequest {
  constructor(
    public groupId: string,
    public teamId: string,
    public name?: string,
    public type?: string,
    public description?: string,
    public memberships?: GroupMembership[]
  ) {}
}

export class DeleteGroupRequest {
  constructor(
    public teamId: string,
    public groupId: string
  ) {}
}
