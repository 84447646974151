import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "swiper-button-next"
}
const _hoisted_2 = {
  key: 1,
  class: "swiper-button-prev"
}
const _hoisted_3 = {
  key: 2,
  class: "swiper-scrollbar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliderData, (slide, index) => {
        return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {
              slide: slide,
              index: index
            })
          ]),
          _: 2
        }, 1024))
      }), 128)),
      (_ctx.displaySliderButtons)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_AppIcon, { name: "arrow" })
          ]))
        : _createCommentVNode("", true),
      (_ctx.displaySliderButtons)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_AppIcon, { name: "arrow" })
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "actions"),
      (_ctx.$attrs['scrollbar'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16))
}