/* eslint-disable @typescript-eslint/no-empty-function */
import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import AppTextEditorImage from '@/components/common/AppTextEditorImage.vue'

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
      AppTextEditorImage: {
        setCustomImage: (options: { src: string }, coords?: { left: number, top: number}) => ReturnType,
      }
    }
}

interface AppTextEditorImageOptions {
  onProcessingImageStart: () => void,
  onProcessingImageEnd: () => void
}

export default Node.create<AppTextEditorImageOptions>({
  name: 'AppTextEditorImage',
  inline: false,
  group: 'block',
  draggable: true,
  atom: true,

  addOptions() {
    return {
      onProcessingImageStart: () => {},
      onProcessingImageEnd: () => {}
    }
  },

  addAttributes() {
    return {
      src: {
        default: null
      },
      size: {
        default: 'medium'
      }
    }
  },

  addCommands() {
    return {
      setCustomImage: (options, coords) => (e) => {
        if (coords) {
          const pos = e.view.posAtCoords(coords)?.pos ?? 0
          return e.commands.insertContentAt(pos, {
            type: this.name,
            attrs: options
          })
        } else {
          return e.commands.insertContent({
            type: this.name,
            attrs: options
          })
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]'
      }
    ]
  },

  renderHTML({ HTMLAttributes, node }) {
    return ['div', { class: 'app-text-editor-image' },
      ['img', mergeAttributes(HTMLAttributes, { class: `custom-image custom-image__size-${node.attrs.size}` })]
    ]
  },

  addNodeView() {
    return VueNodeViewRenderer(AppTextEditorImage)
  }
})
