/* eslint-disable @typescript-eslint/no-explicit-any */
/*
    A utility used by the ApiService to track pending GET requests. If the ApiService receives a new request with a request key
    that matches a currently pending request then it will await the response of the pending request instead of issuing a second
    duplicate request to the backend API. The request key needs to be generated in a way that ensures requests can be compared
    accurately (i.e. we only want to wait on the response of a pending request if the subsequent request is a true duplicate of it).
    The current implementation simply uses the endpoint URL as the request key (so it will match path parameters and query parameters)
    but will not match anything in the request body or headers. Therefore the pending request tracker is currently only suitable for GET requests.
*/

interface PendingRequest {
    requestKey: string,
    requestTracker: Promise<any>
}
export default class ApiServicePendingRequestTracker {
    pendingRequests: PendingRequest[] = []

    public findPendingRequest(requestKey: string): Promise<any> | undefined {
      return this.pendingRequests.find(e => e.requestKey === requestKey)?.requestTracker
    }

    public async trackRequest(requestKey: string, request: Promise<Response>): Promise<any> {
      const requestTracker = request.then(async response => {
        try {
          if (!response.ok) {
            throw new Error('request failed')
          } else {
            return await response.json()
          }
        } finally {
          this.pendingRequests = this.pendingRequests.filter(e => e.requestKey !== requestKey)
        }
      })
      this.pendingRequests.push({ requestKey, requestTracker })
      return requestTracker
    }
}
