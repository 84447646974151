
import { computed, defineComponent } from 'vue'
const SUPPORTED_OVERLAY_THEMES = ['gray', 'white', 'transparent']
const SUPPORTED_CONTENT_VARIANTS = ['onboarding-cards', 'delete-note', 'quiz-card', 'sync-overlay']

export default defineComponent({
  name: 'AppOverlay',
  props: {
    theme: {
      type: String,
      required: false,
      validator: (value: string): boolean => SUPPORTED_OVERLAY_THEMES.includes(value)
    },
    variant: {
      type: String,
      required: false,
      validator: (value: string): boolean => SUPPORTED_CONTENT_VARIANTS.includes(value)
    },
    minimized: {
      type: Boolean,
      required: false
    }
  },
  setup() {
    const maximized = computed(() => localStorage.getItem('isSideNavMaximized') !== 'false')

    return {
      maximized
    }
  }
})
