import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-main-navigation grid align-items-center" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "col-6 justify-self-center" }
const _hoisted_4 = { class: "app-main-navigation__notifications col-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavigationMobileMenu = _resolveComponent("MainNavigationMobileMenu")!
  const _component_AppLogo = _resolveComponent("AppLogo")!
  const _component_MainNavigationNotifications = _resolveComponent("MainNavigationNotifications")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MainNavigationMobileMenu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AppLogo, {
        class: "app-main-navigation__logo",
        type: "text"
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_MainNavigationNotifications, {
        "navigation-panel": _ctx.NavigationPanel.MobileNotificationPanel
      }, null, 8, ["navigation-panel"])
    ])
  ]))
}