
import { useStore } from '@/store'
import { DailySyncTask, DailySyncTaskCategory } from '@/types/dailySync'
import AppBasicDropdown from '@common/AppBasicDropdown.vue'
import AppDropdown from '@common/AppDropdown.vue'
import AppTimeTracker from '@common/AppTimeTracker.vue'
import DailySyncTaskEditor from '@feature/daily-sync/DailySyncTaskEditor.vue'
import AppAvatar from '@common/AppAvatar.vue'
import { computed, defineComponent, PropType, ref } from 'vue'
import { BacklogService } from '@/services/BacklogService'
import { Membership } from '@/types/membership'
import { UserProfile } from '@/types/user'
import { Goal } from '@/types/objective'
import { isPaidOrTrialSubscription } from '@/services/SubscriptionService'
import { SUPPORTED_GOAL_STATUSES } from '@/data/objectives'

export default defineComponent({
  emits: ['submit', 'delete', 'focus', 'change', 'send', 'assigned', 'unassigned', 'create', 'delay'],
  components: {
    DailySyncTaskEditor,
    AppBasicDropdown,
    AppDropdown,
    AppTimeTracker,
    AppAvatar
  },
  props: {
    task: {
      type: Object as PropType<DailySyncTask>,
      required: true
    },
    hasFocus: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    isBacklog: {
      type: Boolean,
      default: false
    },
    showAssignee: {
      type: Boolean,
      default: false
    },
    showGoal: {
      type: Boolean,
      default: false
    },
    isGoal: {
      type: Boolean,
      default: false
    },
    isGoalBacklog: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, { emit }) => {
    const store = useStore()
    const editorRef = ref()
    const dailySyncTask = computed<DailySyncTask>(() => props.task)
    const teamMembers = computed<Membership[]>(() => [{} as Membership, ...store.getters['teams/getTeamMembersWithFullName']])
    const assignedUser = computed<UserProfile | undefined>(() => teamMembers.value.find(e => e.userId === dailySyncTask.value.assignee)?.userProfile)
    const goals = computed<Goal[]>(() => store.getters['goals/getGoals'])
    const goal = computed<Goal | undefined>(() => dailySyncTask.value.goalId ? goals.value.find((e: Goal) => e.id === dailySyncTask.value.goalId) : undefined)
    const activeTrackedTask = computed(() => store.getters['timetracker/getActiveTrackedTask'])
    const filteredGoals = computed<Goal[]>(() => goals.value.filter(e => e.status !== SUPPORTED_GOAL_STATUSES.COMPLETED))

    const isInFocus = computed(() => !props.isBacklog && !props.isGoalBacklog && !props.isGoal)
    const activeTask = computed(() => store.getters['timetracker/getActiveTask'])
    const isTrackingCurrentTask = computed(() => store.getters['timetracker/getActiveTrackingStatus'] && activeTask.value?.id === props.task.id)

    const hasOpenTimeblock = computed<boolean>(() => {
      if (dailySyncTask.value?.timeblocks) {
        if (dailySyncTask.value?.timeblocks.at(-1)?.end === null) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    })

    let savedTaskText = dailySyncTask.value.text
    let saveTaskTextTimer: ReturnType<typeof setTimeout> | null = null

    const paidOrTrialSubscription = computed<boolean>(() => isPaidOrTrialSubscription())

    function submitTask() {
      if (!isTaskEmpty()) {
        emit('submit')
      }
    }

    function deleteTask(force = false) {
      if (force || isTaskEmpty()) {
        emit('delete')
      }
    }

    function updateTaskCategory(category: DailySyncTaskCategory) {
      if (dailySyncTask.value.category !== category) {
        dailySyncTask.value.category = category
        emit('change')
      }
    }

    function clearGoal() {
      dailySyncTask.value.goalId = undefined
      emit('change')
    }

    function setGoal(goal: Goal) {
      if (dailySyncTask.value.goalId !== goal.id) {
        dailySyncTask.value.goalId = goal.id
        emit('change')
      }
    }

    function onTextUpdated() {
      if (saveTaskTextTimer) {
        clearTimeout(saveTaskTextTimer)
      }
      if (savedTaskText === '') {
        saveChangesToText() // save changes immediately if this is a new task
      } else {
        saveTaskTextTimer = setTimeout(() => {
          saveChangesToText()
        }, 2000)
      }
    }

    function saveChangesToText() {
      if (saveTaskTextTimer) {
        clearTimeout(saveTaskTextTimer)
      }
      if (savedTaskText !== dailySyncTask.value.text) {
        if (savedTaskText === '') {
          emit('create')
        } else {
          emit('change')
        }
        savedTaskText = dailySyncTask.value.text
      }
    }

    function updateAssignee(userId?: string) {
      const previousAssignee = dailySyncTask.value.assignee
      if (previousAssignee && previousAssignee !== userId) {
        emit('unassigned', { previousAssignee, task: props.task })
      }
      dailySyncTask.value.assignee = userId
      emit('change')
      if (userId) {
        emit('assigned', { userId, task: props.task })
      }
    }

    function isTaskEmpty() {
      return editorRef.value?.editor.isEmpty
    }

    function sendToTaskList() {
      BacklogService.instance.sendToTaskList(dailySyncTask.value)
      if (props.isGoalBacklog) {
        emit('delay')
      }
      if (props.isBacklog) {
        emit('send')
      }
    }

    function sendToBacklog() {
      BacklogService.instance.sendToBacklog(dailySyncTask.value)
      emit('send')
    }

    function updateTaskTime({ start, end }: { start: string, end: string }) {
      if (dailySyncTask.value.timeblocks?.length) {
        const lastBlock = dailySyncTask.value.timeblocks[dailySyncTask.value.timeblocks.length - 1]
        if (!lastBlock.end) {
          lastBlock.end = end
        } else {
          dailySyncTask.value.timeblocks.push({ start, end })
        }
      } else {
        dailySyncTask.value.timeblocks = [{ start, end }]
      }

      emit('change')
    }

    function overrideTaskTime({ start, end }: { start: string, end: string }) {
      dailySyncTask.value.timeblocks = [{ start, end }]
      emit('change')
    }

    return {
      dailySyncTask,
      editorRef,
      teamMembers,
      activeTrackedTask,
      overrideTaskTime,
      submitTask,
      deleteTask,
      updateTaskCategory,
      onTextUpdated,
      saveChangesToText,
      isTaskEmpty,
      sendToTaskList,
      sendToBacklog,
      assignedUser,
      updateAssignee,
      goal,
      goals,
      setGoal,
      updateTaskTime,
      isTrackingCurrentTask,
      paidOrTrialSubscription,
      filteredGoals,
      isInFocus,
      hasOpenTimeblock,
      clearGoal
    }
  }
})
