
import AppTextEditor from '@/components/common/AppTextEditor.vue'
import { useStore } from '@/store'
import { Membership } from '@/types/membership'
import { timeDiffCalc } from '@/utils/calculateTimeDiff'
import AppAvatar from '@common/AppAvatar.vue'
import AppBasicDropdown from '@common/AppBasicDropdown.vue'
import AppDropdown from '@common/AppDropdown.vue'
import { Comment, ItemId } from '@/types/comments'
import ReactionInput from '@common/ReactionInput.vue'
import ReactionDisplay from '@common/ReactionDisplay.vue'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import ToastNotificationService from '@/services/ToastNotificationService'
import { extractMentionedUsers } from '@/helpers/mentionUtils'
import { User } from '@/types/user'
import Reaction from '@/types/reaction'
import { DateTime } from 'luxon'

export default defineComponent({
  name: 'AppComment',
  emits: ['user-action:reply', 'user-action:cancel-reply', 'user-action:post-reply'],
  components: {
    AppAvatar,
    AppDropdown,
    AppBasicDropdown,
    AppTextEditor,
    ReactionInput,
    ReactionDisplay
  },
  props: {
    comment: {
      type: Object as PropType<Comment>,
      required: true
    },
    itemId: {
      type: Object as PropType<ItemId>,
      required: true
    },
    itemType: {
      type: String,
      required: true
    },
    parentCommentId: {
      type: String,
      required: false
    },
    replyEditorOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const currentUser = computed<User>(() => store.getters['user/getUser'])
    const author = computed(() => store.getters['teams/getTeamMembersWithFullName'].find((member: Membership) => member.userId === props.comment.author))
    const isCurrentUserAuthor = computed(() => author.value?.userId === currentUser.value.userId)
    const commentText = ref(props.comment.content)
    const isEditing = ref(false)
    const replyEditor = ref()
    const replyText = ref()
    const isSaving = ref(false)
    const replyTriggeredFromThisComment = ref(false) // we use this to track which comment to show the 'cancel reply' text on
    const currentUserReaction = ref(props.comment.reactions?.find(e => e.userId === currentUser.value.userId)?.id)

    function startReply() {
      replyTriggeredFromThisComment.value = true
      emit('user-action:reply', props.comment.id)
    }

    function cancelReply() {
      console.log('cancelling reply')
      replyText.value = undefined
      emit('user-action:cancel-reply', props.comment.id)
    }

    function postReply() {
      emit('user-action:post-reply', props.comment.id, replyText.value)
    }

    async function editComment(markAsDeleted = false, reactions = props.comment.reactions) {
      const request = {
        itemId: props.itemId,
        content: commentText.value,
        mentions: extractMentionedUsers(commentText.value),
        markAsDeleted,
        reactions
      }

      try {
        await store.dispatch('conversation/updateComment', {
          itemType: props.itemType,
          request,
          commentId: props.comment.id
        })
      } catch (error) {
        if (error instanceof Error) {
          ToastNotificationService.push({ type: 'error', message: error.message })
        }
      } finally {
        isEditing.value = false
      }
    }

    async function deleteComment() {
      await editComment(true)
    }

    async function submitReaction() {
      const reactions = (props.comment.reactions ?? []).filter(e => e.userId !== currentUser.value.userId)
      if (currentUserReaction.value) {
        reactions.push(new Reaction(currentUserReaction.value, currentUser.value.userId, DateTime.utc().toISO()))
      }
      await editComment(false, reactions)
    }

    watch(() => props.comment, () => {
      commentText.value = props.comment.content
    })

    watch(() => props.replyEditorOpen, () => {
      if (!props.replyEditorOpen) {
        replyTriggeredFromThisComment.value = false
      }
    })

    watch(replyEditor, () => {
      if (replyEditor.value) {
        replyEditor.value.$el.scrollIntoView({ behavior: 'smooth', block: 'center' }) // scroll to the reply editor when it is opened
      }
    })

    return {
      author,
      commentText,
      isCurrentUserAuthor,
      currentUserReaction,
      replyEditor,
      replyText,
      isSaving,
      timeDiffCalc,
      startReply,
      cancelReply,
      postReply,
      editComment,
      deleteComment,
      submitReaction,
      replyTriggeredFromThisComment,
      isEditing
    }
  }
})
