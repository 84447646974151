import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "check-in-assignee-dropdown__dropdown" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "check-in-assignee-dropdown__message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButtonMini = _resolveComponent("AppButtonMini")!
  const _component_AppAvatar = _resolveComponent("AppAvatar")!
  const _component_AppBasicDropdown = _resolveComponent("AppBasicDropdown")!
  const _component_AppDropdown = _resolveComponent("AppDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["check-in-assignee-dropdown", [
      _ctx.openDirection === _ctx.OPEN_DIRECTION.TOP && 'check-in-assignee-dropdown--top',
      !_ctx.userList.length && 'check-in-assignee-dropdown--no-users'
    ]])
  }, [
    _createVNode(_component_AppDropdown, { type: "simple" }, {
      handler: _withCtx((props) => [
        _createVNode(_component_AppButtonMini, {
          icon: "plus-charoite",
          onClick: props.visibility.toggle
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx((props) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_AppBasicDropdown, {
            onClick: props.visibility.toggle
          }, {
            items: _withCtx(() => [
              (_ctx.userList.length)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.userList, (user, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["check-in-assignee-dropdown__user", [ _ctx.assignedUsers.some((assigned) => assigned.userId === user.userId) && 'check-in-assignee-dropdown__user--selected' ]]),
                      key: index,
                      onClick: ($event: any) => (_ctx.$emit('user-action:assign-user', user))
                    }, [
                      _createVNode(_component_AppAvatar, {
                        user: user.userProfile,
                        size: "smaller"
                      }, null, 8, ["user"]),
                      _createElementVNode("p", null, _toDisplayString(user.userProfile.firstName) + " " + _toDisplayString(user.userProfile.lastName), 1)
                    ], 10, _hoisted_2))
                  }), 128))
                : (_openBlock(), _createElementBlock("p", _hoisted_3, "Currently no members available!"))
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ]),
      _: 1
    })
  ], 2))
}