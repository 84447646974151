
import { computed, defineComponent, ref } from 'vue'
import { displayFreeTrialWarning, daysLeftInFreeTrial } from '@/services/SubscriptionService'

export default defineComponent({
  name: 'FreeTrial',
  setup() {
    const onFreeTrial = computed<boolean>(() => displayFreeTrialWarning())
    const isVisible = ref(true)

    return {
      onFreeTrial,
      daysLeftInFreeTrial,
      isVisible
    }
  }
})
