import { Callback } from '@/types/global'

export const throttle = (callback: Callback, delay: number): Callback => {
  let lastTime = 0

  return () => {
    const now = Date.now()

    if (now - lastTime > delay) {
      callback()
      lastTime = now
    }
  }
}
