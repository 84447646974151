import ApiService from '@/services/ApiService'
import { CreateGroupRequest, Group, UpdateGroupRequest, DeleteGroupRequest, GroupRole } from '@/types/group'
import { GroupState, RootState } from '@/types/store'
import { Module } from 'vuex'

const groups: Module<GroupState, RootState> = {
  namespaced: true,
  state: {
    groups: [],
    groupsDataLoaded: false
  },
  getters: {
    getGroups: (state) => state.groups,
    isGroupsDataLoaded: (state) => state.groupsDataLoaded
  },
  mutations: {
    setGroups(state, groups: Group[]) {
      state.groups = groups
    },
    setGroupsDataLoaded(state, isLoaded: boolean) {
      state.groupsDataLoaded = isLoaded
    },
    addGroup(state, group: Group) {
      state.groups.push(group)
    },
    syncChangesForGroup(state, group: UpdateGroupRequest) {
      const groupForUpdateIndex = state.groups.findIndex(current => current.id === group.groupId)
      const groupForUpdate = state.groups.find(current => current.id === group.groupId)

      if (groupForUpdate) {
        const updatedGroup = Object.keys(groupForUpdate).reduce((currentGroup, key) => {
          currentGroup = { ...currentGroup, [key]: group[key as keyof UpdateGroupRequest] ?? groupForUpdate[key as keyof Group] }
          return currentGroup
        }, groupForUpdate)

        state.groups.splice(groupForUpdateIndex, 1, updatedGroup)
      }
    },
    deleteGroup(state, groupId: string) {
      state.groups = state.groups.filter(e => e.id !== groupId)
    }
  },
  actions: {
    clearActiveTeamData({ commit }) {
      commit('setGroups', [])
      commit('setGroupsDataLoaded', false)
    },
    async loadActiveTeamData({ dispatch }, params: { teamId: string }): Promise<void> {
      await dispatch('getGroups', params)
    },
    async getGroups({ commit }, params: { teamId: string }): Promise<void> {
      try {
        const response = await ApiService.sendTrackedRequest(`groups/${params.teamId}`, {
          method: 'GET',
          requiresAuth: true
        })

        commit('setGroups', response)
        commit('setGroupsDataLoaded', true)
      } catch (error) {
        throw new Error('Groups could not be loaded')
      }
    },
    async createGroup({ commit }, request: CreateGroupRequest): Promise<void> {
      const response = await ApiService.sendRequest(`groups/${request.teamId}`, {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify(request)
      })

      if (response.ok) {
        const newGroup = await response.json()
        commit('addGroup', newGroup)
      } else {
        throw new Error('Group could not be created.')
      }
    },
    async updateGroup({ commit }, request: UpdateGroupRequest): Promise<void> {
      const response = await ApiService.sendRequest(`groups/${request.teamId}/group/${request.groupId}`, {
        method: 'PUT',
        requiresAuth: true,
        body: JSON.stringify(request)
      })

      if (response.ok) {
        commit('syncChangesForGroup', request)
      } else {
        throw new Error('Group could not be updated.')
      }
    },
    async deleteGroup({ commit }, request: DeleteGroupRequest): Promise<void> {
      const response = await ApiService.sendRequest(`groups/${request.teamId}/group/${request.groupId}`, {
        method: 'DELETE',
        requiresAuth: true
      })

      if (response.ok) {
        commit('deleteGroup', request.groupId)
      } else {
        throw new Error('Could not delete group')
      }
    },

    async updateRole({ dispatch }, params: { group: Group, userId: string, role: GroupRole}) {
      const updateMembership = params.group.memberships.find(member => member.userId === params.userId)

      if (updateMembership) {
        updateMembership.role = params.role

        await dispatch('updateGroup', new UpdateGroupRequest(
          params.group.id,
          params.group.teamId,
          undefined,
          undefined,
          undefined,
          params.group.memberships
        ))
      }

      return {}
    },

    async removeGroupMember({ dispatch }, params: { group: Group, userId: string }) {
      const updatedMemberships = params.group.memberships.filter(el => el.userId !== params.userId)

      await dispatch('updateGroup', new UpdateGroupRequest(
        params.group.id,
        params.group.teamId,
        undefined,
        undefined,
        undefined,
        updatedMemberships
      ))

      return {}
    }
  }
}

export default groups
