
const THEME_VARIATIONS = ['primary', 'secondary']

export default {
  name: 'Button',
  props: {
    icon: {
      type: String,
      required: false
    },
    theme: {
      type: String,
      required: false,
      validator: (value: string): boolean => THEME_VARIATIONS.includes(value)
    },
    iconSize: {
      type: Number,
      required: false
    }
  }
}
