
import { defineComponent, computed, PropType } from 'vue'
import { Membership } from '@/types/membership'

import CheckInAssigneesAvatar from '@feature/check-in/CheckInAssigneesAvatar.vue'
import AppDropdown from '@common/AppDropdown.vue'
import AppButtonMini from '@common/AppButtonMini.vue'
import AppBasicDropdown from '@common/AppBasicDropdown.vue'
import { OPEN_DIRECTION } from '@common/AppAssigneesDropdown.vue'

import FilterUserDropdown from '@feature/filter-slicer/FilterUserDropdown.vue'

export default defineComponent({
  name: 'FilterUsersSelect',
  emits: ['update:modelValue'],
  components: {
    CheckInAssigneesAvatar,
    AppButtonMini,
    AppBasicDropdown,
    AppDropdown,
    FilterUserDropdown
  },
  props: {
    modelValue: {
      type: Array as PropType<Membership[]>,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    openDirection: {
      type: String,
      required: false,
      default: OPEN_DIRECTION.BOTTOM,
      validator: (value: OPEN_DIRECTION): boolean => Object.values(OPEN_DIRECTION).includes(value)
    },
    teamMembers: {
      type: Array as PropType<Membership[]>,
      required: false
    }
  },
  setup(props, { emit }) {
    const users = computed({
      get: () => props.modelValue,
      set: (val) => emit('update:modelValue', val)
    })

    function removeItem(member: Membership) {
      users.value = users.value.filter(e => e.userId !== member.userId)
    }

    return {
      users,
      OPEN_DIRECTION,
      removeItem
    }
  }

})
