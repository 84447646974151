
import { computed, defineComponent, PropType, reactive, ref, toRefs, watch } from 'vue'
import { useStore } from '@/store'
import { useCitiesForCountry, useCountrySearch, useTimezoneForCity } from '@/utils/location'
import { UserOnBoardingCard } from '@/types/quiz'
import { IFormErrors } from '@/types/global'
import { UserLocation, UserProfile } from '@/types/user'
import ModalHandlerService from '@/services/ModalHandlerService'

import { USER_ONBOARDING_CARDS } from '@/data/static'
import { shallowValidate } from '@/services/FormValidationService'
import { SuccessMessages } from '@/data/messages'
import ToastNotificationService from '@/services/ToastNotificationService'

import AppDropdown from '@common/AppDropdown.vue'
import AccountPictureUpload from '@/components/feature/user-settings/personal/AccountPictureUpload.vue'

export default defineComponent({
  name: 'UserOnboardingCreateProfile',
  components: {
    AccountPictureUpload,
    AppDropdown
  },
  props: {
    data: {
      type: Object as PropType<UserOnBoardingCard>,
      required: true
    }
  },
  emits: ['navigate:next'],
  setup(_, { emit }) {
    const store = useStore()
    const userProfile = computed(() => store.getters['user/getUserProfile'])

    /* User's country variables */
    const country = ref('')
    const city = ref()
    const countries = ref<string[]>(useCountrySearch())
    const cities = ref<string[]>(useCitiesForCountry())
    const visibleCountries = ref(countries.value)

    const buttonIsDisabled = ref(true)
    const loading = ref(false)

    const userFields = reactive<Record<string, string>>({
      firstName: userProfile.value?.firstName ?? '',
      lastName: userProfile.value?.lastName ?? '',
      jobTitle: ''
    })

    const errors = reactive<IFormErrors>({
      fieldEmptyErrors: {},
      specialErrors: {}
    })

    function setCountriesOnSearch(country?: string) {
      if (country) {
        visibleCountries.value = useCountrySearch(country)
        cities.value = useCitiesForCountry(country)
      } else {
        visibleCountries.value = countries.value
      }
    }

    function checkForEmptyErrorsOnCurrentStep() {
      const { validationNeeded, result } = getAllErrorsGrouped()
      let errors = {}
      if (result && Object.keys(result).length) {
        errors = shallowValidate({ ...result })
      }
      if (errors && Object.keys(errors).length) {
        buttonIsDisabled.value = true
        return { result: errors, validationNeeded }
      } else {
        buttonIsDisabled.value = false
        return { }
      }
    }

    function getAllErrorsGrouped() {
      let result = {}
      const validationNeeded = USER_ONBOARDING_CARDS[0].inputs?.filter(input => input.required === true)

      if (validationNeeded && validationNeeded.length) {
        result = validationNeeded.reduce((acc, userField) => {
          const { name } = userField
          if (userField.name === 'country') {
            return { ...acc, [name]: country.value ?? '' }
          } else if (userField.name === 'city') {
            return { ...acc, [name]: city.value ?? '' }
          } else {
            return { ...acc, [name]: userFields[userField.name] ?? '' }
          }
        }, {})
        return { validationNeeded, result }
      }
      return { validationNeeded, result }
    }

    async function updateUserProfile() {
      const { result: emptyErrors } = checkForEmptyErrorsOnCurrentStep()

      if (!emptyErrors) {
        try {
          loading.value = true
          await store.dispatch('user/updateUser', {
            profile: new UserProfile(
              '',
              userFields.firstName,
              userFields.lastName,
              undefined,
              new UserLocation(
                country.value,
                city.value,
                useTimezoneForCity(country.value, city.value)
              ),
              userFields.jobTitle ?? '',
              undefined,
              undefined
            )
          })

          ToastNotificationService.push({
            type: 'info',
            message: SuccessMessages.ACCOUNT_DETAILS_UPDATED
          })
        } catch (error) {
          errors.specialErrors = Object.assign(errors.specialErrors, {
            aws: {
              err: error instanceof Error && error.message
            }
          })
        } finally {
          loading.value = false
          emit('navigate:next')
        }
      }
    }

    watch(country, () => {
      setCountriesOnSearch(country.value)
    })

    return {
      userProfile,
      ModalHandlerService,
      userFields,
      country,
      countries,
      city,
      cities,
      visibleCountries,
      buttonIsDisabled,
      loading,
      ...toRefs(errors),
      setCountriesOnSearch,
      checkForEmptyErrorsOnCurrentStep,
      updateUserProfile
    }
  }
})
