
import { defineComponent, computed, PropType } from 'vue'
import leadingZero from '@/filters/leadingZero'
import hoursByInterval from '@/data/json/hours.json'

import AppDropdown from '@common/AppDropdown.vue'

enum AVAILABLE_DIRECTIONS {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export class Time {
  constructor(
    public hour: number,
    public minute: number
  ) {}
}

export default defineComponent({
  name: 'AppTimePicker',
  emits: ['update:time'],
  components: {
    AppDropdown
  },
  props: {
    minute: {
      type: [Number, String],
      required: true
    },
    hour: {
      type: Number,
      required: true
    },
    startTime: {
      type: Object as PropType<Time>,
      required: false
    },
    endTime: {
      type: Object as PropType<Time>,
      required: false
    },
    displayDirection: {
      type: String,
      required: false,
      default: 'bottom',
      validator: (value: AVAILABLE_DIRECTIONS): boolean => Object.values(AVAILABLE_DIRECTIONS).includes(value)
    }
  },
  setup(props, { emit }) {
    const timeIntervals = computed(() => {
      if (props.endTime) {
        const currentIndex = hoursByInterval.findIndex(item => item.hour === props.endTime?.hour && Number(item.minute) === props.endTime?.minute) + 1
        return hoursByInterval.slice(currentIndex, hoursByInterval.length)
      }

      const currentIndex = hoursByInterval.findIndex(item => item.hour === props.startTime?.hour && Number(item.minute) === props.startTime?.minute)
      return hoursByInterval.slice(0, currentIndex)
    })

    const startTimeDefined = computed(() => !!props.startTime)

    function selectTime(toggleVisibility: () => boolean, time: Time) {
      toggleVisibility()
      emit('update:time', time, startTimeDefined.value)
    }

    return {
      timeIntervals,
      leadingZero,
      selectTime
    }
  }
})
