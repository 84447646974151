
export default {
  name: 'Alert',
  props: {
    theme: {
      type: [String, Boolean],
      required: false
    },
    icon: {
      type: String,
      required: false,
      default: 'success'
    },
    borderless: {
      type: Boolean,
      required: false,
      default: false
    },
    iconSize: {
      type: Number,
      required: false,
      default: 20
    }
  }
}
