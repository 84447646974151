import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "quiz-lightning-round-stage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuizLightningRoundStageIntro = _resolveComponent("QuizLightningRoundStageIntro")!
  const _component_QuizLightningRound = _resolveComponent("QuizLightningRound")!
  const _component_QuizGroupNavigationTransition = _resolveComponent("QuizGroupNavigationTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_QuizGroupNavigationTransition, {
      direction: _ctx.isNavigationDirectionForward ? 'forward' : 'backward'
    }, {
      default: _withCtx(() => [
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.LIGHTNING_ROUND, _ctx.QUIZ_LIGHTNING_ROUND_DEFINITION.INTRO))
          ? (_openBlock(), _createBlock(_component_QuizLightningRoundStageIntro, {
              key: 0,
              "onQuizNavigation:next": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isNavigationDirectionForward = true))
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.QUIZ_GREAT_DEBATE_DATA, (round) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (_ctx.QuizService.getCurrentIndex() === round.id)
              ? (_openBlock(), _createBlock(_component_QuizLightningRound, {
                  key: round.id,
                  data: round,
                  "onQuizNavigation:next": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isNavigationDirectionForward = true)),
                  "onQuizNavigation:prev": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isNavigationDirectionForward = false))
                }, null, 8, ["data"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 256))
      ]),
      _: 1
    }, 8, ["direction"])
  ]))
}