import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-edit", _ctx.variation && `app-edit--${_ctx.variation}`])
  }, [
    _createVNode(_component_AppIcon, { name: "edit" })
  ], 2))
}