import ModalHandlerService from '@/services/ModalHandlerService'
import { NotificationStatus, Notification } from '@/types/notification'
import { store } from '@/store'

export default async function acceptTeamInviteWithClearNotification(): Promise<void> {
  if (localStorage.getItem('inviteDetails')) {
    const userProfile = store.getters['user/getUserProfile']
    const inviteDetails = JSON.parse(localStorage.getItem('inviteDetails') || '')
    if (inviteDetails) {
      try {
        const membership = await store.dispatch('teams/addUserToTeam', { inviteCode: inviteDetails.inviteCode })
        await store.dispatch('user/switchActiveTeam', { teamId: membership.teamId, isNewTeam: true })

        // TODO I'm not sure why we are using `userProfile` here - I suspect this is an attempt to not show the modal if the user still needs to go through the onboarding wizard but this should be reviewed and refactored
        if (userProfile && Object.keys(userProfile)?.length > 1) {
          ModalHandlerService.open('invite-accepted-successful')
          localStorage.removeItem('inviteDetails')
        }
        // If the user has a notification of the team invite lets set it to Completed so they aren't prompted to accept the invite again
        const inviteNotification = store.getters['user/getNotifications'].find((n: Notification) => n.context.inviteCode === inviteDetails.inviteCode)
        if (inviteNotification) {
          await store.dispatch('user/updateNotification', { notificationId: inviteNotification.id, status: NotificationStatus.Completed })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
