import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mention-list" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "mention-list__jira-item" }
const _hoisted_4 = { class: "mention-list__jira-item__key" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "mention-list__item mention-list__user-item__full-name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppLoadingIcon = _resolveComponent("AppLoadingIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.items.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("button", {
            class: _normalizeClass(["mention-list__item", { 'is-selected': index === _ctx.selectedIndex, 'is-searching': _ctx.isSearching }]),
            key: index,
            onClick: ($event: any) => (_ctx.selectItem(index))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AppIcon, {
                class: "mention-list__jira-item__icon",
                name: "jira-blue"
              }),
              _createElementVNode("span", _hoisted_4, _toDisplayString(item.key), 1),
              _createElementVNode("span", {
                class: "mention-list__jira-item__summary",
                innerHTML: item.summaryHtml
              }, null, 8, _hoisted_5)
            ])
          ], 10, _hoisted_2))
        }), 128))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, " No result ")),
    (_ctx.isSearching)
      ? (_openBlock(), _createBlock(_component_AppLoadingIcon, {
          key: 2,
          class: "mention-list__search-loader",
          width: "16",
          height: "16"
        }))
      : _createCommentVNode("", true)
  ]))
}