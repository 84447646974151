<template>
  <div class="quiz-interaction-button-wrapper aspect-ratio-5-6">
    <button class="quiz-interaction-button" @click="interactionButtonClickHandler">
      <AppIcon
        v-if="!image"
        :name="icon"
        class="quiz-interaction-button__icon"
      />

      <QuizTransition>
        <img v-if="image" class="quiz-interaction-button__image" :src="image" alt="My Gif" />
      </QuizTransition>
    </button>
  </div>

  <button
    v-if="image"
    class="quiz-button"
    @click="quizButtonClickHandler"
  >
    <AppIcon name="trash" />
  </button>
</template>

<script>
import { defineComponent } from 'vue'
import QuizTransition from '@transition/QuizTransition.vue'

export default defineComponent({
  name: 'QuizInteractionButton',
  emits: ['user-action:click', 'user-action:delete'],
  components: {
    QuizTransition
  },
  props: {
    image: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const interactionButtonClickHandler = () => {
      if (!props.image) {
        emit('user-action:click')
      }
    }

    const quizButtonClickHandler = () => {
      emit('user-action:delete')
    }

    return {
      interactionButtonClickHandler,
      quizButtonClickHandler
    }
  }
})
</script>

<style lang="scss">
.quiz-interaction-button-wrapper {
  border-radius: 6px;
  background-color: var(--color-blue-100);
  cursor: pointer;
  user-select: none;

  .quiz-interaction-button {
    &__icon {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--color-blue-600)
    }

    &__image {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: cover;
    }
  }
}

.quiz-button {
  margin: 0 auto;
  display: block;
  margin-top: 12px;
  cursor: pointer;
}
</style>
