export enum ReactionId {
    LIKE = 'Like',
    LOVE = 'Love',
    HAHA = 'Haha',
    CELEBRATE = 'Celebrate',
    AMAZING = 'Amazing',
    WOW = 'Wow',
    SAD = 'Sad',
    GRATITUDE = 'Gratitude'
}

export default class Reaction {
  constructor(
    public id: ReactionId,
    public userId: string,
    public created: string
  ) {}
}
