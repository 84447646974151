
import { defineComponent } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Pagination, Navigation, A11y, Scrollbar, Mousewheel, Autoplay } from 'swiper'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

SwiperCore.use([Pagination, Navigation, A11y, Scrollbar, Mousewheel, Autoplay])

export default defineComponent({
  props: {
    sliderData: {
      type: Array,
      required: true
    },
    displaySliderButtons: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Swiper,
    SwiperSlide
  }
})
