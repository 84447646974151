import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuizInterestPicker = _resolveComponent("QuizInterestPicker")!
  const _component_QuizCard = _resolveComponent("QuizCard")!

  return (_openBlock(), _createBlock(_component_QuizCard, {
    title: "",
    class: "quiz-personal-stage-interests",
    "onQuizNavigation:close": _cache[1] || (_cache[1] = ($event: any) => (_ctx.QuizService.close())),
    prev: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_QuizInterestPicker, {
        modelValue: _ctx.selectedInterests,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedInterests) = $event)),
        onNavigateQuiz: _ctx.navigateNext
      }, null, 8, ["modelValue", "onNavigateQuiz"])
    ]),
    _: 1
  }))
}