import { CheckInItemCategory } from './checkinItemCategory'

export default class CheckIn {
  constructor(
        public id: number,
        public teamId: string,
        public title: string,
        public startTime: string,
        public categories: CheckInItemCategory[], // the categories that can be applied to `CheckInItem`s during this cycle
        public daysInCycle: number, // the number of days in the cycle - determines the expected cycle end time and used in setting up reminder jobs
        public open: boolean, // indicates whether the pulse board for this cycle is open or closed
        public expectedCycleCloseTime: string, // the time when the cycle is expected to end (startTime + daysInCycle)
        public actualCycleCloseTime?: string // the time when the cycle was actually closed by the team admin
  ) {}
}

export class CheckInTheme {
  constructor(
        public name: string,
        public colorIndex: number, // color indexes are assigned to themes based on the order the themes were first used
        public count: number // the number of times the theme has been used across all check-ins
  ) {}
}

export class CreateCheckInRequest {
  constructor(
    public title?: string, // Will default to 'Check In #id' if not supplied
    public daysInCycle?: number, // Will be taken from the teams CheckInConfig if not supplied
    public categories?: CheckInItemCategory[] // Will be taken from the teams CheckInConfig if not supplied
  ) {}
}
