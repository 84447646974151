import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DailySyncTaskComponent = _resolveComponent("DailySyncTaskComponent")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createBlock(_component_draggable, {
    modelValue: _ctx.tasks,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tasks) = $event)),
    animation: 300,
    "item-key": "id",
    "ghost-class": "quiz-draggable-area-item--dragging",
    class: "sync-item-container",
    handle: ".quiz-draggable-area-item__handle",
    onStart: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDragging=true)),
    onEnd: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isDragging=false, _ctx.onReorder())),
    onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.tasks))),
    group: { name: _ctx.group, pull: _ctx.isGoal || _ctx.isGoalBacklog ? 'clone' : true, put: _ctx.checkPut },
    onRemove: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onRemove())),
    onAdd: _ctx.onAdd
  }, {
    item: _withCtx(({element, index}) => [
      (_ctx.isGoalBacklog ? _ctx.isNonEmptyTask(element) && !element.assignee && !element.completed : true)
        ? (_openBlock(), _createBlock(_component_DailySyncTaskComponent, {
            key: 0,
            task: element,
            hasFocus: index === _ctx.focusedTask && element.category !== _ctx.DailySyncTaskCategory.DRE,
            editable: _ctx.editable && !_ctx.isDragging,
            isBacklog: _ctx.isBacklog,
            showAssignee: _ctx.showAssignee,
            showGoal: !_ctx.isGoal && !_ctx.isGoalBacklog,
            isGoal: _ctx.isGoal,
            isGoalBacklog: _ctx.isGoalBacklog,
            onSubmit: ($event: any) => (_ctx.submitTask(index)),
            onCreate: ($event: any) => (_ctx.$emit('create', element)),
            onDelay: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delay'))),
            onDelete: ($event: any) => (_ctx.deleteTask(index, element), _ctx.$emit('update:modelValue', _ctx.tasks), _ctx.$emit('delete', element)),
            onFocus: ($event: any) => (_ctx.focusedTask = index),
            onChange: ($event: any) => (_ctx.$emit('update:modelValue', _ctx.tasks), _ctx.$emit('change', element)),
            onAssigned: _ctx.onAssign,
            onUnassigned: _ctx.onUnassign,
            onSend: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRemove()))
          }, null, 8, ["task", "hasFocus", "editable", "isBacklog", "showAssignee", "showGoal", "isGoal", "isGoalBacklog", "onSubmit", "onCreate", "onDelete", "onFocus", "onChange", "onAssigned", "onUnassigned"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "group", "onAdd"]))
}