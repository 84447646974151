
import { computed, defineComponent } from 'vue'

import AppQuiz from '@core/AppQuiz.vue'
import AppOverlay from '@common/AppOverlay.vue'
import SharedCreateGroupModal from '@shared/SharedCreateGroupModal.vue'
import SharedUserOnboardingCards from '@shared/SharedUserOnboardingCards.vue'
import SharedUserAddedToTeamModal from '@shared/SharedUserAddedToTeamModal.vue'
import SharedCreateOrgModal from '@shared/SharedCreateOrgModal.vue'
import AddMemberModal from '@feature/user-settings/team/AddMemberModal.vue'
import FadeTransition from '@transition/FadeTransition.vue'
import QuizToggleTransition from '@transition/QuizToggleTransition.vue'

import QuizService from '@/services/QuizService'
import { useStore } from '@/store'

export default defineComponent({
  name: 'SharedComponentsFacility',
  components: {
    SharedUserAddedToTeamModal,
    AppQuiz,
    AppOverlay,
    SharedCreateGroupModal,
    SharedUserOnboardingCards,
    SharedCreateOrgModal,
    FadeTransition,
    QuizToggleTransition,
    AddMemberModal
  },
  setup() {
    const store = useStore()
    const teams = computed(() => store.getters['user/getMemberships'])

    return {
      QuizService,
      teams
    }
  }
})
