import Conversation, { ItemId } from '@/types/comments'

export function conversationBelongsToSync(conversation: Conversation, itemId: ItemId): boolean {
  return conversation.itemId.teamId === itemId.teamId && conversation.itemId.userId === itemId.userId && conversation.itemId.date === itemId.date
}

export function conversationBelongsToSocialConnect(conversation: Conversation, itemId: ItemId): boolean {
  return conversation.itemId.teamId === itemId.teamId && conversation.itemId.date === itemId.date
}

export function conversationBelongsToDreInsights(conversation: Conversation, itemId: ItemId): boolean {
  return conversation.itemId.teamId === itemId.teamId && conversation.itemId.cycleId === itemId.cycleId
}

export function isNonEmptyRichText(text?: string): boolean {
  return text !== undefined && text.trim().length > 0 && text !== '<p></p>'
}
