
import { defineComponent, PropType } from 'vue'

enum APP_EDIT_VARIATIONS {
  AVATAR = 'avatar',
  SPACER = 'spacer',
  SPACER_SMALL = 'spacer-small',
  CENTERED = 'centered'
}

export default defineComponent({
  props: {
    variation: {
      type: String as PropType<APP_EDIT_VARIATIONS>,
      required: false,
      validator: (value: APP_EDIT_VARIATIONS): boolean => Object.values(APP_EDIT_VARIATIONS).includes(value)
    }
  }
})
