import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quiz-basic-stage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuizBasicStageIntro = _resolveComponent("QuizBasicStageIntro")!
  const _component_QuizBasicStageSkills = _resolveComponent("QuizBasicStageSkills")!
  const _component_QuizBasicStageDescription = _resolveComponent("QuizBasicStageDescription")!
  const _component_QuizBasicStageGifs = _resolveComponent("QuizBasicStageGifs")!
  const _component_QuizGroupNavigationTransition = _resolveComponent("QuizGroupNavigationTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_QuizGroupNavigationTransition, {
      direction: _ctx.isNavigationDirectionForward ? 'forward' : 'backward'
    }, {
      default: _withCtx(() => [
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.BASIC, _ctx.QUIZ_BASIC_STAGE_DEFINITION.INTRO))
          ? (_openBlock(), _createBlock(_component_QuizBasicStageIntro, {
              key: 0,
              "onQuizNavigation:next": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isNavigationDirectionForward = true))
            }))
          : _createCommentVNode("", true),
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.BASIC, _ctx.QUIZ_BASIC_STAGE_DEFINITION.SKILLS))
          ? (_openBlock(), _createBlock(_component_QuizBasicStageSkills, {
              key: 1,
              "onQuizNavigation:next": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isNavigationDirectionForward = true)),
              "onQuizNavigation:prev": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isNavigationDirectionForward = false))
            }))
          : _createCommentVNode("", true),
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.BASIC, _ctx.QUIZ_BASIC_STAGE_DEFINITION.DESCRIPTION))
          ? (_openBlock(), _createBlock(_component_QuizBasicStageDescription, {
              key: 2,
              "onQuizNavigation:next": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isNavigationDirectionForward = true)),
              "onQuizNavigation:prev": _cache[4] || (_cache[4] = ($event: any) => (_ctx.isNavigationDirectionForward = false))
            }))
          : _createCommentVNode("", true),
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.BASIC, _ctx.QUIZ_BASIC_STAGE_DEFINITION.GIFS))
          ? (_openBlock(), _createBlock(_component_QuizBasicStageGifs, {
              key: 3,
              "onQuizNavigation:next": _cache[5] || (_cache[5] = ($event: any) => (_ctx.isNavigationDirectionForward = true)),
              "onQuizNavigation:prev": _cache[6] || (_cache[6] = ($event: any) => (_ctx.isNavigationDirectionForward = false))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["direction"])
  ]))
}