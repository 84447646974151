import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "quiz-work-stage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuizWorkStageIntro = _resolveComponent("QuizWorkStageIntro")!
  const _component_QuizWorkStageContributionStyle = _resolveComponent("QuizWorkStageContributionStyle")!
  const _component_QuizWorkStageContributionPreset = _resolveComponent("QuizWorkStageContributionPreset")!
  const _component_QuizWorkStageContribution = _resolveComponent("QuizWorkStageContribution")!
  const _component_QuizWorkStageCustomizer = _resolveComponent("QuizWorkStageCustomizer")!
  const _component_QuizGroupNavigationTransition = _resolveComponent("QuizGroupNavigationTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_QuizGroupNavigationTransition, {
      direction: _ctx.isNavigationDirectionForward ? 'forward' : 'backward'
    }, {
      default: _withCtx(() => [
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.WORK, _ctx.QUIZ_WORK_STAGE_DEFINITION.INTRO))
          ? (_openBlock(), _createBlock(_component_QuizWorkStageIntro, {
              key: 0,
              "onQuizNavigation:next": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isNavigationDirectionForward = true))
            }))
          : _createCommentVNode("", true),
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.WORK, _ctx.QUIZ_WORK_STAGE_DEFINITION.STYLE))
          ? (_openBlock(), _createBlock(_component_QuizWorkStageContributionStyle, {
              key: 1,
              "onQuizNavigation:next": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isNavigationDirectionForward = true)),
              "onQuizNavigation:prev": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isNavigationDirectionForward = false))
            }))
          : _createCommentVNode("", true),
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.WORK, _ctx.QUIZ_WORK_STAGE_DEFINITION.PRESET))
          ? (_openBlock(), _createBlock(_component_QuizWorkStageContributionPreset, {
              key: 2,
              "onQuizNavigation:next": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isNavigationDirectionForward = true)),
              "onQuizNavigation:prev": _cache[4] || (_cache[4] = ($event: any) => (_ctx.isNavigationDirectionForward = false))
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.QUIZ_CONTRIBUTION_STYLE_DATA, (contribution) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (_ctx.QuizService.getCurrentIndex() === contribution.id)
              ? (_openBlock(), _createBlock(_component_QuizWorkStageContribution, {
                  key: contribution.id,
                  data: contribution,
                  "onQuizNavigation:next": _cache[5] || (_cache[5] = ($event: any) => (_ctx.isNavigationDirectionForward = true)),
                  "onQuizNavigation:prev": _cache[6] || (_cache[6] = ($event: any) => (_ctx.isNavigationDirectionForward = false))
                }, null, 8, ["data"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 256)),
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.WORK, _ctx.QUIZ_WORK_STAGE_DEFINITION.CUSTOMIZER))
          ? (_openBlock(), _createBlock(_component_QuizWorkStageCustomizer, {
              key: 3,
              "onQuizNavigation:next": _cache[7] || (_cache[7] = ($event: any) => (_ctx.isNavigationDirectionForward = true)),
              "onQuizNavigation:prev": _cache[8] || (_cache[8] = ($event: any) => (_ctx.isNavigationDirectionForward = false))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["direction"])
  ]))
}