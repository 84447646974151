export enum DreTrayMessageCategory {
  CONVERSATION = 'Conversation',
  DAILY_SUMMARY = 'DailySummary',
  INDIVIDUAL_STRATEGY = 'IndividualStrategy'
}

export class DreTrayPill {
  constructor(
      public text: string, // text that will be shown in the pill
      public response: string, // a static response that will be displayed if the user clicks the pill
      public responsePrompt?: string // a prompt for a dynamic (AI) response that will be generated if the user clicks the pill
  ) {}
}

export class DreTrayMessage {
  constructor(
        public id: string,
        public teamId: string,
        public userId: string,
        public time: string,
        public author: 'dre' | 'user',
        public message: string,
        public pills: DreTrayPill[],
        public read = false, // indicates whether the user has seen the message
        public category = DreTrayMessageCategory.CONVERSATION
  ) {}
}
