
import { computed, defineComponent, PropType } from 'vue'

import { UserProfile } from '@/types/user'
import AppAvatar from '@common/AppAvatar.vue'

export default defineComponent({
  name: 'CheckInAssigneesAvatar',
  components: {
    AppAvatar
  },
  props: {
    user: {
      type: Object as PropType<UserProfile>,
      required: true
    },
    size: {
      type: String,
      required: false
    },
    preventRemove: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const iconSize = computed(() => {
      switch (props.size) {
        case 'large': return 32
        case 'medium': return 18
        case 'normal': return 14
        case 'smaller': return 10
        case 'tiny': return 6
        default: return 12
      }
    })

    return {
      iconSize
    }
  }
})
