import ApiService from '@/services/ApiService'
import { Notification as AppNotification, NotificationStatus } from '@/types/notification'
import NotificationSettings, { UpdateNotificationSettingsRequest } from '@/types/notificationSettings'
import { NotificationState, RootState } from '@/types/store'
import { Module } from 'vuex'
import { PushSubscription } from 'web-push'

const notifications: Module<NotificationState, RootState> = {
  namespaced: true,
  state: {
    notifications: [] as AppNotification[],
    notificationSettings: undefined,
    notificationsDataLoaded: false
  },
  getters: {
    getNotifications: (state) => state.notifications,
    getNotificationSettings: (state) => state.notificationSettings
  },
  mutations: {
    setNotifications(state, value: AppNotification[]) {
      state.notifications = value
    },
    setNotificationsDataLoaded(state, value: boolean) {
      state.notificationsDataLoaded = value
    },
    addNewNotification(state, value: AppNotification) {
      if (value.type === 'TeamInvite') {
        // Remove any duplicate TeamInvite notifications
        const duplicateInvite = (n: AppNotification) => {
          return n.type === 'TeamInvite' && n.context?.teamId === value.context?.teamId
        }
        state.notifications = state.notifications.filter((n) => !duplicateInvite(n))
      }
      state.notifications.unshift(value)
    },
    setNotificationSettings(state, notificationSettings: NotificationSettings) {
      state.notificationSettings = notificationSettings
    },
    setWebPushSubscriptions(state, webPushSubscriptions: PushSubscription[]) {
      if (!state.notificationSettings) {
        state.notificationSettings = {} as NotificationSettings
      }
      state.notificationSettings.webPushSubscriptions = webPushSubscriptions
    },
    setEnablePushNotifications(state, enablePushNotifications: boolean) {
      if (!state.notificationSettings) {
        state.notificationSettings = {} as NotificationSettings
      }
      state.notificationSettings.enablePushNotifications = enablePushNotifications
    },
    setEnableNotificationEmails(state, enableNotificationEmails: boolean) {
      if (!state.notificationSettings) {
        state.notificationSettings = {} as NotificationSettings
      }
      state.notificationSettings.enableNotificationEmails = enableNotificationEmails
    },
    setEnableProductEmails(state, enableProductEmails: boolean) {
      if (!state.notificationSettings) {
        state.notificationSettings = {} as NotificationSettings
      }
      state.notificationSettings.enableProductEmails = enableProductEmails
    }
  },
  actions: {
    clearActiveTeamData({ commit }) {
      commit('setNotifications', [])
      commit('setNotificationsDataLoaded', false)
    },
    async loadActiveTeamData({ commit }, params: { teamId?: string }): Promise<void> {
      const requestUrl = `notifications${params.teamId ? `?teamId=${params.teamId}` : ''}`
      const response = await ApiService.sendRequest(requestUrl, {
        method: 'GET',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Could not retrieve notifications')
      } else {
        commit('setNotifications', await response.json())
        commit('setNotificationsDataLoaded', true)
      }
    },
    async getNotificationSettings({ commit }): Promise<void> {
      const response = await ApiService.sendRequest('notification-settings', {
        method: 'GET',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Could not retrieve notification-settings')
      } else {
        commit('setNotificationSettings', await response.json())
      }
    },
    async updateNotificationSettings({ commit }, request: UpdateNotificationSettingsRequest): Promise<void> {
      const response = await ApiService.sendRequest('notification-settings', {
        method: 'PATCH',
        requiresAuth: true,
        body: JSON.stringify(request)
      })
      if (response.ok) {
        if (request.webPushSubscriptions) {
          commit('setWebPushSubscriptions', request.webPushSubscriptions)
        }
        if (request.enablePushNotifications !== undefined) {
          commit('setEnablePushNotifications', request.enablePushNotifications)
        }
        if (request.enableNotificationEmails !== undefined) {
          commit('setEnableNotificationEmails', request.enableNotificationEmails)
        }
        if (request.enableProductEmails !== undefined) {
          commit('setEnableProductEmails', request.enableProductEmails)
        }
      } else {
        throw new Error('Notification settings could not be updated.')
      }
    },
    async nudgeUserForQuiz(_, request: { teamId: string, userId: string }): Promise<void> {
      const response = await ApiService.sendRequest('nudge-user-for-quiz', {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify(request)
      })

      if (!response.ok) {
        throw new Error(`Could not nudge user ${request.userId}`)
      }
    },
    async markAllNotificationsAsRead({ commit, state }): Promise<void> {
      const newNotifications = state.notifications.filter((n) => n.status === NotificationStatus.New)
      if (newNotifications.length) {
        // Modify all notifications that were in the New state to either Completed or ActionRequired
        const updates = newNotifications.map((n) => {
          let updatedStatus = NotificationStatus.Completed
          if (n.type === 'TeamInvite') {
            updatedStatus = NotificationStatus.ActionRequired
          }
          return { notificationId: n.id, status: updatedStatus }
        })
        // Update the frontend store first so that the number of unread messages icon disappears when they close the notification panel
        const updatedNotifications = state.notifications.map((n) => {
          const updatedStatus = updates.find((u) => u.notificationId === n.id)?.status
          n.status = updatedStatus ?? n.status
          return n
        })
        commit('setNotifications', updatedNotifications)
        // Update the notification states in the backend
        const response = await ApiService.sendRequest('notifications', {
          method: 'PUT',
          requiresAuth: true,
          body: JSON.stringify({ updates })
        })
        if (!response.ok) {
          console.info(`There was an error connecting to the API: ${response.status} - ${JSON.stringify(await response.json())}`)
        }
      }
    },
    async updateNotification({ commit, state }, params: { notificationId: string, status: NotificationStatus }): Promise<void> {
      const response = await ApiService.sendRequest('notifications', {
        method: 'PUT',
        requiresAuth: true,
        body: JSON.stringify({ updates: [params] })
      })
      if (response.ok) {
        // Once the backend update is successful apply the same updates to the store to keep the UI in sync
        const updatedNotifications = state.notifications
        const toUpdate = updatedNotifications.find((n) => n.id === params.notificationId)
        if (toUpdate) {
          toUpdate.status = params.status
        }
        commit('setNotifications', updatedNotifications)
      } else {
        console.info(`There was an error connecting to the API: ${response.status} - ${JSON.stringify(await response.json())}`)
      }
    }
  }
}

export default notifications
