
import { computed, defineComponent, ref } from 'vue'
import DreTrayMessageComponent from '@/components/feature/dre-tray/DreTrayMessage.vue'
import { DreTrayMessage, DreTrayPill, DreTrayMessageCategory } from '@/types/dreTray'
import { useStore } from '@/store'
import { v4 } from 'uuid'
import AppDre from '@common/AppDre.vue'
import CommentSpinner from '@global/CommentSpinner.vue'
import { dreTrayOpen } from '@/utils/peekabooUtils'

export default defineComponent({
  name: 'DreTray',
  components: {
    DreTrayMessageComponent,
    AppDre,
    CommentSpinner
  },
  setup() {
    const store = useStore()
    const messages = computed<DreTrayMessage[]>(() => store.getters['dreTray/getMessages'])
    const unread = computed<number>(() => messages.value.filter(e => !e.read).length)
    const prompt = ref<string>()
    const isEditing = computed(() => (prompt.value?.length ?? 0) > 0)
    const isWaitingForResponse = ref(false)

    function toggleDreTray() {
      dreTrayOpen.value = !dreTrayOpen.value
      markRead()
    }

    function clearPrompt() {
      prompt.value = ''
    }

    async function submitPrompt() {
      if (prompt.value?.length) {
        const thinkingTimer = setTimeout(() => {
          isWaitingForResponse.value = true
        }, 1200)
        const teamId = store.getters['user/getActiveMembership']?.teamId
        const userId = store.getters['user/getActiveMembership']?.userId
        const message = new DreTrayMessage(v4(), teamId, userId, new Date().toISOString(), 'user', prompt.value, [])
        store.dispatch('dreTray/upsertMessage', { message, expectResponse: true }).then(() => { clearInterval(thinkingTimer); isWaitingForResponse.value = false })
        prompt.value = ''
      }
    }

    async function onPillSelect(pill: DreTrayPill) {
      const teamId = store.getters['user/getActiveMembership']?.teamId
      const userId = store.getters['user/getActiveMembership']?.userId
      const pillPrompt = new DreTrayMessage(v4(), teamId, userId, new Date().toISOString(), 'user', pill.text, [], false, DreTrayMessageCategory.INDIVIDUAL_STRATEGY)
      store.dispatch('dreTray/upsertMessage', { message: pillPrompt, expectResponse: false })
      setTimeout(() => {
        isWaitingForResponse.value = true
        setTimeout(() => {
          const pillResponse = new DreTrayMessage(v4(), teamId, userId, new Date().toISOString(), 'dre', pill.response, [], false, DreTrayMessageCategory.INDIVIDUAL_STRATEGY)
          store.dispatch('dreTray/upsertMessage', { message: pillResponse, expectResponse: false })
          isWaitingForResponse.value = false
        }, 2000)
      }, 1200)
    }

    function markRead() {
      messages.value.filter(e => !e.read).forEach(e => store.dispatch('dreTray/upsertMessage', { message: { ...e, read: true }, expectResponse: false }))
    }

    return {
      dreTrayOpen,
      toggleDreTray,
      messages,
      prompt,
      isEditing,
      clearPrompt,
      submitPrompt,
      isWaitingForResponse,
      onPillSelect,
      unread,
      markRead
    }
  }
})
