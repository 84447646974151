/* eslint-disable @typescript-eslint/no-explicit-any */
import EmojiList from '@/components/common/EmojiList.vue'
import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion'
import { VueRenderer } from '@tiptap/vue-3'
import tippy, { GetReferenceClientRect } from 'tippy.js'

interface editorEmoji {
  group: string,
  name: string,
  fallBackImage: string,
  shortcodes: string[],
  tags: string[]
}

export function getEmojiSuggestion(editor: any, query: string): string[] {
  const filteredArray = editor.storage.emoji.emojis
    .filter((emoji: editorEmoji) => priorityScore(emoji, query))
    .map((emoji: editorEmoji) => ({ emoji, score: priorityScore(emoji, query) }))
    .sort((a: { score: number }, b: { score: number }) => b.score - a.score)
    .map((e: {emoji: editorEmoji }) => e.emoji)
    .slice(0, 6)
  return filteredArray
}

function priorityScore(emoji: editorEmoji, query: string) {
  if (emoji.name === query.toLowerCase()) return 4
  else if (emoji.name.startsWith(query.toLowerCase())) return 3
  else if (emoji.shortcodes.find(shortcode => shortcode.startsWith(query.toLowerCase()))) return 2
  else if (emoji.tags.find(tag => tag.startsWith(query.toLowerCase()))) return 1
  return 0
}

export function emojiRenderer(): any {
  let component: VueRenderer
  let popup: any

  return {
    onStart: (props: SuggestionProps) => {
      component = new VueRenderer(EmojiList, {
        props,
        editor: props.editor
      })

      popup = tippy('body', {
        getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: 'manual',
        placement: 'bottom-start'
      })
    },

    onUpdate(props: SuggestionProps) {
      component.updateProps(props)

      popup[0].setProps({
        getReferenceClientRect: props?.clientRect
      })
    },

    onKeyDown(props: SuggestionKeyDownProps) {
      if (props.event.key === 'Escape') {
        popup[0].hide()

        return true
      }

      return component.ref?.onKeyDown(props)
    },

    onExit() {
      popup[0].destroy()
      component.destroy()
    }
  }
}
