import { CheckInItemCategory } from './checkinItemCategory'

export enum CheckInItemStatus {
    None = 'None',
    ActionRequired = 'ActionRequired',
    Resolved = 'Resolved',
    WontDo = 'WontDo'
}

export default class CheckInItem {
  constructor(
        public id: number,
        public teamId: string,
        public checkInId: number,
        public description: string,
        public createdAt: string,
        public updatedAt: string,
        public category: CheckInItemCategory,
        public themes: string[],
        public creator: string,
        public upvoteUsers: string[] = [],
        public status = CheckInItemStatus.None,
        public assignees: string[] = [],
        public commentCount = 0
  ) {}
}

export class CreateCheckInItemRequest {
  constructor(
      public description: string,
      public category: CheckInItemCategory,
      public themes?: string[],
      public checkInId?: number // If not supplied the item will be added to the latest CheckIn
  ) {}
}

export class UpdateCheckInItemRequest {
  constructor(
      public description?: string,
      public category?: CheckInItemCategory,
      public themes?: string[],
      public upvoteUsers?: string[],
      public status?: CheckInItemStatus,
      public assignees?: string[],
      public actionCreatedTime?: string,
      public actionResolvedTime?: string
  ) {}
}
