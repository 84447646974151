
import { defineComponent, ref } from 'vue'

import { QUIZ_GREAT_DEBATE_DATA } from '@/data/static'

import QuizService, { QUIZ_STAGE_DEFINITION, QUIZ_LIGHTNING_ROUND_DEFINITION } from '@/services/QuizService'

import QuizLightningRoundStageIntro from '@feature/quiz/quiz-lightning-round-stage/QuizLightningRoundStageIntro.vue'
import QuizLightningRound from '@feature/quiz/quiz-lightning-round-stage/QuizLightningRound.vue'

import QuizGroupNavigationTransition from '@transition/QuizGroupNavigationTransition.vue'

export default defineComponent({
  name: 'QuizLightningRoundStage',
  components: {
    QuizLightningRoundStageIntro,
    QuizLightningRound,
    QuizGroupNavigationTransition
  },
  setup() {
    const isNavigationDirectionForward = ref(false)

    return {
      QuizService,
      isNavigationDirectionForward,
      QUIZ_STAGE_DEFINITION,
      QUIZ_LIGHTNING_ROUND_DEFINITION,
      QUIZ_GREAT_DEBATE_DATA
    }
  }
})
