import inputValidationErrors from '@/data/input-validation-errors'
import inputValidationRegex from '@/data/input-validation-regex'

export const shallowValidate = (fields: { [key: string]: string | boolean }): { [key: string]: string } => {
  return Object.keys(fields)
    .reduce((errorObject: { [key: string]: string }, field: string): { [key: string]: string } => {
      if (Object.prototype.hasOwnProperty.call(inputValidationErrors, field)) {
        if (typeof fields[field] === 'string' && fields[field] === '') {
          return { ...errorObject, ...{ [field]: inputValidationErrors[field].empty } }
        }
      }

      return errorObject
    }, {})
}

export default (fields: { [key: string]: string | boolean | undefined | Record<string, string | undefined>}): { [key: string]: Record<string, string> } => {
  return Object.keys(fields)
    .reduce((errorObject: { [key: string]: Record<string, string> }, field: string): { [key: string]: Record<string, string> } => {
      if (Object.prototype.hasOwnProperty.call(inputValidationRegex, field)) {
        const availableTestCases = Object.keys(inputValidationRegex[field])
        const failedTestCases = availableTestCases.filter((key: string) => {
          return !inputValidationRegex[field][key].test(fields[field] as string)
        })

        const mappedErrorObject = failedTestCases.reduce((object, key) => {
          return Object.assign(object, { [key]: inputValidationErrors[field][key] })
        }, {})

        return failedTestCases.length
          ? { ...errorObject, ...{ [field]: mappedErrorObject } }
          : errorObject
      }

      return errorObject
    }, {})
}
