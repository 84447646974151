import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name"]
const _hoisted_3 = {
  key: 0,
  class: "app-textarea__msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-textarea", { 'app-textarea--error': _ctx.error, 'app-textarea--resizeable': _ctx.resizeable }])
  }, [
    _createElementVNode("label", { for: _ctx.id }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _withDirectives(_createElementVNode("textarea", _mergeProps({
      id: _ctx.id,
      name: _ctx.id,
      placeholder: "Type something..."
    }, _ctx.$attrs, {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus', $event.target.value))),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur', $event.target.value)))
    }), null, 16, _hoisted_2), [
      [_vModelText, _ctx.inputValue]
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(typeof _ctx.error === 'string' ? _ctx.error : ''), 1))
      : _createCommentVNode("", true)
  ], 2))
}