
import { defineComponent, PropType } from 'vue'

enum AppChipTheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  ELEVATED = 'elevated',
  HIGHLIGHTED = 'highlighted',
  SHARED = 'shared',
  SOFT_ELEVATED = 'soft-elevated',
  INTEREST = 'interest',
  INTEREST_LARGE = 'interest-large',
  SLICER = 'slicer'
}

export default defineComponent({
  name: 'AppChip',
  emits: ['on-remove'],
  props: {
    text: {
      type: String,
      required: true,
      default: ''
    },
    divider: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false
    },
    theme: {
      type: String as PropType<AppChipTheme>,
      required: false,
      default: 'primary',
      validator: (value: AppChipTheme): boolean => Object.values(AppChipTheme).includes(value)
    }
  }
})
