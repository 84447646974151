import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "app-quiz" }
const _hoisted_2 = { class: "app-quiz__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuizBasicStage = _resolveComponent("QuizBasicStage")!
  const _component_QuizWorkStage = _resolveComponent("QuizWorkStage")!
  const _component_QuizAvailabilityStage = _resolveComponent("QuizAvailabilityStage")!
  const _component_QuizPersonalStage = _resolveComponent("QuizPersonalStage")!
  const _component_QuizLightningRoundStage = _resolveComponent("QuizLightningRoundStage")!
  const _component_QuizFinishStage = _resolveComponent("QuizFinishStage")!
  const _component_QuizGroupNavigationTransition = _resolveComponent("QuizGroupNavigationTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_QuizGroupNavigationTransition, { direction: "forward" }, {
        default: _withCtx(() => [
          (Object.values(_ctx.QUIZ_STAGE_DEFINITION).includes(_ctx.QuizService.getCurrentStage()))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.QUIZ_STAGE_DEFINITION.BASIC === _ctx.QuizService.getCurrentStage())
                  ? (_openBlock(), _createBlock(_component_QuizBasicStage, { key: 0 }))
                  : _createCommentVNode("", true),
                (_ctx.QUIZ_STAGE_DEFINITION.WORK === _ctx.QuizService.getCurrentStage())
                  ? (_openBlock(), _createBlock(_component_QuizWorkStage, { key: 1 }))
                  : _createCommentVNode("", true),
                (_ctx.QUIZ_STAGE_DEFINITION.AVAILABILITY === _ctx.QuizService.getCurrentStage())
                  ? (_openBlock(), _createBlock(_component_QuizAvailabilityStage, { key: 2 }))
                  : _createCommentVNode("", true),
                (_ctx.QUIZ_STAGE_DEFINITION.PERSONAL === _ctx.QuizService.getCurrentStage())
                  ? (_openBlock(), _createBlock(_component_QuizPersonalStage, { key: 3 }))
                  : _createCommentVNode("", true),
                (_ctx.QUIZ_STAGE_DEFINITION.LIGHTNING_ROUND === _ctx.QuizService.getCurrentStage())
                  ? (_openBlock(), _createBlock(_component_QuizLightningRoundStage, { key: 4 }))
                  : _createCommentVNode("", true)
              ], 64))
            : (_openBlock(), _createBlock(_component_QuizFinishStage, { key: 1 }))
        ]),
        _: 1
      })
    ])
  ]))
}