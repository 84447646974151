import ApiService from '@/services/ApiService'
import { MixPanelService } from '@/services/MixPanelService'
import { SocialConnect, UpdateSocialConnectRequest } from '@/types/socialConnect'
import { RootState, SocialConnectState } from '@/types/store'
import { daysFromNow, formatAsDailySyncDate } from '@/utils/dailySyncDates'
import { DateTime } from 'luxon'
import { Module } from 'vuex'

const socialConnect: Module<SocialConnectState, RootState> = {
  namespaced: true,
  state: {
    socialConnects: {} as Record<string, SocialConnect>,
    socialConnectDataLoaded: false
  },
  getters: {
    getSocialConnects: (state) => state.socialConnects,
    isSocialConnectDataLoaded: (state) => state.socialConnectDataLoaded
  },
  mutations: {
    setSocialConnects(state, socialConnects: Record<string, SocialConnect>) {
      state.socialConnects = socialConnects
    },
    upsertSocialConnect(state, socialConnect: SocialConnect) {
      state.socialConnects[socialConnect.date] = socialConnect
    },
    setSocialConnectDataLoaded(state, value: boolean) {
      state.socialConnectDataLoaded = value
    }
  },
  actions: {
    clearActiveTeamData({ commit }) {
      commit('setSocialConnects', {})
      commit('setSocialConnectDataLoaded', false)
    },
    async loadActiveTeamData({ dispatch }, params: { teamId: string }): Promise<void> {
      // fetch social connects for the past two weeks
      await dispatch('getSocialConnect', { teamId: params.teamId, startDate: formatAsDailySyncDate(daysFromNow(-14)), days: 14 })
    },
    async getSocialConnect({ commit }, params: { teamId: string, startDate: string, days: number }): Promise<void> {
      try {
        const response = await ApiService.sendTrackedRequest(`social-connect/teams/${params.teamId}/date/${params.startDate}?limit=${params.days}`, {
          method: 'GET',
          requiresAuth: true
        })
        commit('setSocialConnects', response.socialConnects)
        commit('setSocialConnectDataLoaded', true)
      } catch (error) {
        throw new Error('Could not retrieve social connects')
      }
    },
    async updateSocialConnect({ commit }, params: { current: SocialConnect, updates: UpdateSocialConnectRequest }): Promise<void> {
      const updated = { ...params.current, ...params.updates } as SocialConnect
      updated.lastUpdatedAt = params.updates.lastUpdatedAt ?? DateTime.utc().toISO()
      commit('upsertSocialConnect', updated)
      const response = await ApiService.sendRequest(`social-connect/teams/${updated.teamId}/date/${updated.date}`, {
        method: 'PUT',
        requiresAuth: true,
        body: JSON.stringify(updated)
      })
      if (!response.ok) {
        throw new Error('Could not update social connect')
      }
      MixPanelService.instance.onUpdateSocialConnect(params.updates)
    }
  }
}

export default socialConnect
