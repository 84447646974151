interface InputValidationRegex {
  [key: string]: {
    [key: string]: RegExp
  }
}

export default Object.seal<InputValidationRegex>({
  password: {
    case: /^(?=.*[A-Z])(?=.*[a-z]).{1,}$/,
    digit: /^(?=.*[0-9]).{1,}$/,
    minLength: /^.{8,}$/
  },
  email: {
    format: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  },
  confirmationCode: {
    minLength: /^\d{6}$/
  },
  termsOfService: {
    checked: /true/
  },
  firstName: {
    minLength: /^.{2,}$/,
    maxLength: /^.{0,20}$/,
    charactersOnly: /^[a-zA-Z]+$/
  },
  lastName: {
    minLength: /^.{2,}$/,
    maxLength: /^.{0,20}$/,
    charactersOnly: /^[a-zA-Z]+$/
  },
  displayName: {
    maxLength: /^(.{0,30})?$/
  },
  teamName: {
    maxLength: /^.{0,30}$/
  },
  teamDescription: {
    maxLength: /^(.{0,100})?$/
  }
})
