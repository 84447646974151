
import { defineComponent } from 'vue'

import QuizService, { QUIZ_STAGE_DEFINITION } from '@/services/QuizService'

import QuizBasicStage from '@feature/quiz/quiz-basic-stage/QuizBasicStage.vue'
import QuizWorkStage from '@feature/quiz/quiz-work-stage/QuizWorkStage.vue'
import QuizAvailabilityStage from '@feature/quiz/quiz-availability-stage/QuizAvailabilityStage.vue'
import QuizPersonalStage from '@feature/quiz/quiz-personal-stage/QuizPersonalStage.vue'
import QuizLightningRoundStage from '@feature/quiz/quiz-lightning-round-stage/QuizLightningRoundStage.vue'
import QuizFinishStage from '@feature/quiz/quiz-finish-stage/QuizFinishStage.vue'

import QuizGroupNavigationTransition from '@transition/QuizGroupNavigationTransition.vue'

export default defineComponent({
  name: 'AppQuiz',
  components: {
    QuizBasicStage,
    QuizWorkStage,
    QuizAvailabilityStage,
    QuizPersonalStage,
    QuizLightningRoundStage,
    QuizFinishStage,
    QuizGroupNavigationTransition
  },
  setup() {
    return {
      QuizService,
      QUIZ_STAGE_DEFINITION
    }
  }
})
