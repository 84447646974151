
import { defineComponent, ref } from 'vue'

import FadeTransition from '@transition/FadeTransition.vue'
import MobileNotificationsTransition from '@transition/MobileNotificationsTransition.vue'
import QuizToggleTransition from '@transition/QuizToggleTransition.vue'
import QuizTransition from '@transition/QuizTransition.vue'
import SlideFadeTransition from '@transition/SlideFadeTransition.vue'

const SUPPORTED_VARIANTS = ['simple', 'team', 'mobile-navigation', 'team-switch', 'searchable']
const SUPPORTED_TRANSITIONS = ['FadeTransition', 'MobileNotificationsTransition', 'QuizToggleTransition', 'QuizTransition', 'SlideFadeTransition']

export default defineComponent({
  name: 'AppDropdown',
  components: {
    FadeTransition,
    MobileNotificationsTransition,
    QuizToggleTransition,
    QuizTransition,
    SlideFadeTransition
  },
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    type: {
      type: String,
      required: false,
      validator: (value: string): boolean => SUPPORTED_VARIANTS.includes(value)
    },
    error: {
      type: [Boolean, String],
      required: false
    },
    transition: {
      type: String,
      required: false,
      default: 'FadeTransition',
      validator: (value: string): boolean => SUPPORTED_TRANSITIONS.includes(value)
    }
  },
  setup(props, { emit }) {
    const contentVisible = ref(props.visible ? props.visible : false)

    function updateVisibility(value: boolean): void {
      contentVisible.value = value
      emit(`app-dropdown-${contentVisible.value ? 'opened' : 'closed'}`)
    }

    function hideDropdownContent(): void {
      if (contentVisible.value) contentVisible.value = false
      emit('app-dropdown-closed')
    }

    function toggleVisibility(): void {
      contentVisible.value = !contentVisible.value
      emit(`app-dropdown-${contentVisible.value ? 'opened' : 'closed'}`)
    }

    return {
      contentVisible,
      updateVisibility,
      toggleVisibility,
      hideDropdownContent
    }
  }
})
