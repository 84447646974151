import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "app-dropdown__handler"
}
const _hoisted_3 = {
  key: 2,
  class: "app-dropdown__msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_clicked_outside = _resolveDirective("clicked-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-dropdown", [
    _ctx.error && 'app-dropdown--error',
    _ctx.contentVisible && 'app-dropdown--content-visible',
    _ctx.type && `app-dropdown--${_ctx.type}`
  ]])
  }, [
    (!!_ctx.$slots.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _renderSlot(_ctx.$slots, "label")
        ]))
      : _createCommentVNode("", true),
    (!!_ctx.$slots.handler)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "handler", {
            visibility: { update: _ctx.updateVisibility, toggle: _ctx.toggleVisibility },
            contentVisible: _ctx.contentVisible
          })
        ]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.transition), null, {
      default: _withCtx(() => [
        (_ctx.contentVisible || !_ctx.$slots.handler)
          ? _renderSlot(_ctx.$slots, "default", {
              key: 0,
              contentVisible: _ctx.contentVisible,
              visibility: { update: _ctx.updateVisibility, toggle: _ctx.toggleVisibility }
            })
          : _createCommentVNode("", true)
      ]),
      _: 3
    })),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(typeof _ctx.error === 'string' ? _ctx.error : ''), 1))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_clicked_outside, _ctx.hideDropdownContent]
  ])
}