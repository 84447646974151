// event.js
import CTZ from '@/data/json/country_city_tz.json'

export function useCountrySearch(value?: string): string[] {
  if (!value) {
    return CTZ.map((e) => e.country)
  } else {
    return CTZ.map((e) => e.country).filter((e: string) => e.toLowerCase().startsWith(value.toLowerCase()))
  }
}

export function useCitiesForCountry(country?: string): string[] {
  if (country) {
    return CTZ.find((e) => e.country === country)?.cities.map((c) => c.name) ?? []
  } else {
    return []
  }
}

export function useTimezoneForCity(country?: string, city?: string): string | undefined {
  if (country && city) {
    return CTZ.find((e) => e.country === country)?.cities.find((c) => c.name === city)?.tz ?? undefined
  }
  return undefined
}
