const isEmpty = (value: unknown): boolean => {
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0
  } else if (typeof value === 'object' && value instanceof Object) {
    return Object.keys(value).length === 0
  } else {
    throw new Error('The provided value is not suited for an `isEmpty` check.')
  }
}

export default isEmpty
