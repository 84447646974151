import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "quiz-interests-picker" }
const _hoisted_2 = { class: "quiz-interests-picker__header" }
const _hoisted_3 = { class: "quiz-interests-picker__title" }
const _hoisted_4 = { class: "quiz-interests-picker__list-holder" }
const _hoisted_5 = {
  key: 0,
  class: "quiz-interests-picker__items quiz-interests-picker__items--step-1"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "quiz-interests-picker__icon" }
const _hoisted_8 = { class: "quiz-interests-picker__text-content" }
const _hoisted_9 = {
  key: 1,
  class: "quiz-interests-picker__items quiz-interests-picker__items--step-2"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "quiz-interests-picker__icon" }
const _hoisted_12 = { class: "quiz-interests-picker__text-content" }
const _hoisted_13 = {
  key: 2,
  class: "quiz-interests-picker__items quiz-interests-picker__items--step-3"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "quiz-interests-picker__icon" }
const _hoisted_16 = { class: "quiz-interests-picker__text-content" }
const _hoisted_17 = { class: "quiz-interests-picker__state" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_SlideLeftRightTransition = _resolveComponent("SlideLeftRightTransition")!
  const _component_SlideFadeTransition = _resolveComponent("SlideFadeTransition")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.currentStepActive)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "quiz-interests-picker__arrow",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
          }, [
            _createVNode(_component_AppIcon, {
              name: "arrow",
              size: 14
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.getCategoryLabel()), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_SlideLeftRightTransition, { direction: _ctx.animateDirection }, {
        default: _withCtx(() => [
          (_ctx.currentStepActive === _ctx.INTERESTS_STEPS.CATEGORY)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.INTERESTS.categories, (category, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    onClick: ($event: any) => (_ctx.handleSelect(category.id)),
                    class: _normalizeClass({ 'quiz-interests-picker__item--selected': _ctx.countCategoryItems(category.id) > 0 })
                  }, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(category.emoji), 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(category.label), 1),
                    _createElementVNode("span", {
                      class: _normalizeClass(["quiz-interests-picker__count", { 'quiz-interests-picker__count--selected': _ctx.countCategoryItems(category.id) > 0 }])
                    }, _toDisplayString(_ctx.countCategoryItems(category.id)), 3)
                  ], 10, _hoisted_6))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.currentStepActive === _ctx.INTERESTS_STEPS.SUBCATEGORY)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subCategories, (subCategory, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    onClick: ($event: any) => (_ctx.handleSelect(subCategory.id)),
                    class: _normalizeClass({ 'quiz-interests-picker__item--selected' : _ctx.countSubCategoryItems(subCategory.id) > 0 })
                  }, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString(subCategory.emoji), 1),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(subCategory.label), 1),
                    _createElementVNode("span", {
                      class: _normalizeClass(["quiz-interests-picker__count", { 'quiz-interests-picker__count--selected': _ctx.countSubCategoryItems(subCategory.id) > 0 }])
                    }, _toDisplayString(_ctx.countSubCategoryItems(subCategory.id)), 3)
                  ], 10, _hoisted_10))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.currentStepActive === _ctx.INTERESTS_STEPS.ITEMS)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    onClick: ($event: any) => (_ctx.handleSelect(item.id)),
                    class: _normalizeClass({ 'selected' : _ctx.itemsSelected.some((selected) => selected.id === item.id) })
                  }, [
                    _createElementVNode("span", _hoisted_15, _toDisplayString(item.emoji), 1),
                    _createElementVNode("span", _hoisted_16, _toDisplayString(item.label), 1),
                    _createElementVNode("span", _hoisted_17, [
                      _withDirectives(_createVNode(_component_AppIcon, {
                        name: "check",
                        size: 13
                      }, null, 512), [
                        [_vShow, _ctx.itemsSelected.some((selected) => selected.id === item.id)]
                      ])
                    ])
                  ], 10, _hoisted_14))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["direction"])
    ]),
    _createVNode(_component_AppButton, {
      disabled: !_ctx.isPickerValid,
      onClick: _ctx.handleButtonClick,
      class: "quiz-interests-picker__next",
      theme: _ctx.currentStepActive !== _ctx.INTERESTS_STEPS.CATEGORY ? 'secondary' : null
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SlideFadeTransition, { isGroup: true }, {
          default: _withCtx(() => [
            (_ctx.currentStepActive !== _ctx.INTERESTS_STEPS.CATEGORY)
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, "Back"))
              : (_openBlock(), _createElementBlock("span", _hoisted_19, "Continue"))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["disabled", "onClick", "theme"])
  ]))
}