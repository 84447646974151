
import { useStore } from '@/store'
import { DailySyncTask, DailySyncTaskCategory } from '@/types/dailySync'
import DailySyncTaskComponent from '@feature/daily-sync/DailySyncTask.vue'
import { v4 } from 'uuid'
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import draggable from 'vuedraggable'
import { isDesktop } from '@/helpers/detectMobile'
import { isNonEmptyTask } from '@/utils/dailySyncUtils'
import { BacklogService } from '@/services/BacklogService'

export default defineComponent({
  name: 'DailySyncTaskList',
  emits: ['update:modelValue', 'create', 'delete', 'change', 'delay'],
  components: {
    DailySyncTaskComponent,
    draggable
  },
  props: {
    modelValue: {
      type: Array as PropType<DailySyncTask[]>,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    group: {
      type: String,
      required: false
    },
    isBacklog: {
      type: Boolean,
      default: false
    },
    showAssignee: {
      type: Boolean,
      default: false
    },
    autoAssign: {
      type: Boolean,
      default: true
    },
    goalId: {
      type: Number,
      required: false
    },
    isGoal: {
      type: Boolean,
      default: false
    },
    isGoalBacklog: {
      type: Boolean,
      default: false
    },
    allowCreate: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const tasks = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    const store = useStore()
    const userId = store.getters['user/getActiveMembership']?.userId
    const isDragging = ref(false)
    const focusedTask = ref(props.editable && !props.isBacklog && isDesktop ? tasks.value.length - 1 : -1) // manipulate this index to give focus to a task in the list (such as after another task has been deleted)

    function submitTask(index: number) {
      focusedTask.value = index + 1
      if (focusedTask.value === tasks.value.length) {
        addEmptyTask()
      }
    }

    function deleteTask(index: number, task: DailySyncTask) {
      tasks.value.splice(index, 1)
      onRemove()
      focusedTask.value = Math.max(0, index - 1) // shift focus to the previous (or first) task in the list
      if ((props.isGoal || props.isGoalBacklog) && task.assignee === userId) {
        store.commit('dailySync/removeFromBacklog', task)
      }
    }

    function addEmptyTask() {
      if (props.allowCreate && tasks.value.every(e => isNonEmptyTask(e)) && !props.isGoalBacklog) {
        tasks.value.push(new DailySyncTask(v4(), '', DailySyncTaskCategory.TASK, false, [], false, props.autoAssign ? userId : undefined, props.goalId))
      }
    }

    function onRemove() {
      if (tasks.value.length === 0) {
        addEmptyTask() // if there are no tasks remaining then add a new empty task
      }
    }

    function onReorder() {
      const emptyIndex = tasks.value.findIndex(e => !isNonEmptyTask(e))
      if (emptyIndex !== -1 && emptyIndex !== tasks.value.length - 1) {
        tasks.value.splice(emptyIndex, 1)
        addEmptyTask()
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function onAdd(event: any) {
      if (props.autoAssign) {
        const task = event?.item?.__draggable_context?.element
        task.assignee = userId
      }
      onReorder()
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function checkPut(_to: any, _from: any, dragEl: any) {
      if (props.isGoal || props.isGoalBacklog) {
        return false
      }
      const taskId = dragEl.__draggable_context.element.id
      return !tasks.value.some(e => e.id === taskId)
    }

    function onAssign(evt: { userId: string, task: DailySyncTask }) {
      if ((props.isGoal || props.isGoalBacklog) && evt.userId === userId) {
        BacklogService.instance.sendToBacklog(evt.task)
      }
    }

    function onUnassign(evt: { previousAssignee: string, task: DailySyncTask }) {
      if ((props.isGoal || props.isGoalBacklog) && evt.previousAssignee === userId) {
        store.commit('dailySync/removeFromBacklog', evt.task)
      }
    }

    onMounted(() => {
      if (props.editable) {
        addEmptyTask()
      }
    })

    return {
      tasks,
      focusedTask,
      submitTask,
      deleteTask,
      isDragging,
      DailySyncTaskCategory,
      onRemove,
      onReorder,
      checkPut,
      isNonEmptyTask,
      onAssign,
      onUnassign,
      onAdd
    }
  }
})
