
import { PropType } from 'vue'

enum ButtonSizeVariations {
  SMALL = 'small',
  PREFERENCE = 'preference'
}

export default {
  name: 'QuizSelectableButton',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String as PropType<ButtonSizeVariations>,
      required: false,
      validator: (value: ButtonSizeVariations): boolean => Object.values(ButtonSizeVariations).includes(value)
    }
  }
}
