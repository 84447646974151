import ApiService from '@/services/ApiService'
import { MixPanelService } from '@/services/MixPanelService'
import { DreInsightsCycle } from '@/types/dreInsights'
import Reaction from '@/types/reaction'
import { DreInsightsState, RootState } from '@/types/store'
import { Module } from 'vuex'

const dreInsights: Module<DreInsightsState, RootState> = {
  namespaced: true,
  state: {
    currentCycle: {} as DreInsightsCycle
  },
  getters: {
    getCurrentCycle: (state) => state.currentCycle
  },
  mutations: {
    setCurrentCycle(state, cycle: DreInsightsCycle) {
      state.currentCycle = cycle
    },
    upsertCycle(state, cycle: DreInsightsCycle) {
      if (state.currentCycle.id === cycle.id) {
        state.currentCycle = cycle
      }
    }
  },
  actions: {
    clearActiveTeamData({ commit }) {
      commit('setCurrentCycle', {})
    },
    async loadActiveTeamData({ dispatch }, params: { teamId: string }): Promise<void> {
      await dispatch('getCurrentCycle', params)
    },
    async getCurrentCycle({ commit }, params: { teamId: string }): Promise<void> {
      try {
        const response = await ApiService.sendTrackedRequest(`dre-insights-cycle/${params.teamId}`, {
          method: 'GET',
          requiresAuth: true
        })
        commit('setCurrentCycle', response[0])
      } catch (error) {
        throw new Error('Could not retrieve current dre insights cycle')
      }
    },
    async submitResponse({ commit }, params: { teamId: string, cycleId: number, questionId: number, score: number }) {
      const response = await ApiService.sendRequest(`dre-insights-cycle/${params.teamId}/${params.cycleId}/responses`, {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify({ questionId: params.questionId, score: params.score })
      })
      if (!response.ok) {
        throw new Error('Could not add dre insights response')
      } else {
        const updatedCycle = await response.json()
        commit('setCurrentCycle', updatedCycle)
        MixPanelService.instance.onAddDreInsightsResponse(params.cycleId, params.questionId, params.score)
      }
    },
    async updateDreInsightsCycle({ commit }, params: { teamId: string, cycleId: number, reactions: Reaction[] }) {
      const response = await ApiService.sendRequest(`dre-insights-cycle/${params.teamId}/${params.cycleId}`, {
        method: 'PUT',
        requiresAuth: true,
        body: JSON.stringify({ reactions: params.reactions })
      })
      if (!response.ok) {
        throw new Error('Could not update insights cycle')
      } else {
        const updatedCycle = await response.json()
        commit('setCurrentCycle', updatedCycle)
      }
    }
  }
}

export default dreInsights
