
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'AppTextarea',
  inheritAttrs: false,
  emits: ['update:modelValue', 'focus', 'blur'],
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    resizeable: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: [Boolean, String],
      required: false,
      default: false
    },
    modelValue: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    return {
      inputValue
    }
  }
})
