import { store } from '@/store'
import { LocationQuery } from 'vue-router'
import router from '..'

export default async function autoChangeActiveTeam(query: LocationQuery) : Promise<void> {
  const teamId = query.teamId
  const activeTeamId = store.getters['user/getActiveMembership']?.teamId
  if (teamId && activeTeamId && teamId !== activeTeamId) {
    try {
      await store.dispatch('user/switchActiveTeam', { teamId, isNewTeam: false })
      router.replace({ path: router.currentRoute.value.path, query: query })
    } catch (error) {
      console.info(error)
    }
  }
}
