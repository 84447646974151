
import { computed, defineComponent, inject, ref } from 'vue'

import AccordionTransition from '@/components/transition/AccordionTransition.vue'

interface Accordion {
  active: number | null
}

export default defineComponent({
  name: 'AppAccordionItem',
  components: {
    AccordionTransition
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0
    },
    elevated: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const Accordion = inject('Accordion') as Accordion
    const isVisible = computed(() => activeIndex.value === Accordion.active)
    const activeIndex = ref(props.activeIndex)

    function openAccordion() {
      if (isVisible.value) {
        Accordion.active = null
      } else {
        Accordion.active = activeIndex.value
      }
    }

    function startAnimation(el: HTMLElement) {
      el.style.height = el.scrollHeight + 'px'
    }

    function endAnimation(el: HTMLElement) {
      el.style.height = ''
    }

    return {
      isVisible,
      openAccordion,
      startAnimation,
      endAnimation
    }
  }
})
