
import { defineComponent, onMounted, ref, computed } from 'vue'
import WindowEventsService, { WINDOW_EVENTS } from '@/services/WindowEventsService'
import { throttle } from '@/helpers/throttle'
import serviceWorkerUtils from '@/registerServiceWorker'
import ListTransition from '@/components/transition/ListTransition.vue'
import AppDismissibleBanner from '@common/AppDismissibleBanner.vue'
import { DreTrayMessage } from '@/types/dreTray'
import { useStore } from '@/store'

type ScrollDirection = 'top' | 'bottom'

export default defineComponent({
  name: 'AppMobileFooter',
  emits: ['openDreTray'],
  components: {
    ListTransition,
    AppDismissibleBanner
  },
  setup(props, { emit }) {
    const store = useStore()
    const messages = computed<DreTrayMessage[]>(() => store.getters['dreTray/getMessages'])
    const unread = computed<number>(() => messages.value.filter(e => !e.read).length)

    const navigationRefElement = ref<HTMLElement>()
    const updateExists = computed(() => serviceWorkerUtils.serviceWorkerWaiting.value)

    function handleRefreshApp() {
      serviceWorkerUtils.updateServiceWorker()
    }

    function dismissUpdateNotification() {
      serviceWorkerUtils.serviceWorkerWaiting.value = false
    }

    function openDreTray() {
      emit('openDreTray')
      markRead()
    }

    function markRead() {
      messages.value.filter(e => !e.read).forEach(e => store.dispatch('dreTray/upsertMessage', { message: { ...e, read: true }, expectResponse: false }))
    }

    onMounted(() => {
      if (navigationRefElement.value) {
        const el = navigationRefElement.value
        let previousScrollPosition = 0

        WindowEventsService.on(WINDOW_EVENTS.SCROLL, throttle(() => {
          const scrollTop = (window.pageYOffset !== undefined)
            ? window.pageYOffset
            : (document.documentElement || document.body.parentNode || document.body).scrollTop

          const direction: ScrollDirection = scrollTop > previousScrollPosition
            ? 'top'
            : 'bottom'

          /* if (direction === 'top') {
            el.classList.add('app-mobile-navigation--hidden')
          } else {
            el.classList.remove('app-mobile-navigation--hidden')
          } */

          previousScrollPosition = scrollTop
        }, 140))
      }
    })

    return {
      navigationRefElement,
      updateExists,
      handleRefreshApp,
      dismissUpdateNotification,
      openDreTray,
      messages,
      unread
    }
  }
})
