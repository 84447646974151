import { GreatDebateCategory, WorkLocation, WorkWeekSchedule } from '@/types/user'
import { QUIZ_WORK_STAGE_DEFINITION, QUIZ_LIGHTNING_ROUND_DEFINITION } from '@/services/QuizService'
import { UserOnBoardingCard, IQuizContributionDescription, IQuizContributionPicture, IQuizContributionPreset, IQuizReachOutData, IQuizGreatDebate, IContributionComparison } from '@/types/quiz'
import { FILTER_OPTIONS, SLICER_FILTER_OPTION } from '@/types/global'

export const WORKING_HOURS_ALL_DAYS_DISABLED = new WorkWeekSchedule(
  {
    works: false,
    startHour: 9,
    startMinute: 0,
    endHour: 17,
    endMinute: 0,
    location: WorkLocation.Office
  },
  {
    works: false,
    startHour: 9,
    startMinute: 0,
    endHour: 17,
    endMinute: 0,
    location: WorkLocation.Office
  },
  {
    works: false,
    startHour: 9,
    startMinute: 0,
    endHour: 17,
    endMinute: 0,
    location: WorkLocation.Office
  },
  {
    works: false,
    startHour: 9,
    startMinute: 0,
    endHour: 17,
    endMinute: 0,
    location: WorkLocation.Office
  },
  {
    works: false,
    startHour: 9,
    startMinute: 0,
    endHour: 17,
    endMinute: 0,
    location: WorkLocation.Office
  },
  {
    works: false,
    startHour: 9,
    startMinute: 0,
    endHour: 17,
    endMinute: 0,
    location: WorkLocation.Office
  },
  {
    works: false,
    startHour: 9,
    startMinute: 0,
    endHour: 17,
    endMinute: 0,
    location: WorkLocation.Office
  }
)

export const AVAILABLE_TEAM_INDUSTRIES: string[] = [
  'Administration',
  'Customer service',
  'Customer Success',
  'Design',
  'Engineering',
  'Finance',
  'Human resources',
  'Information technology',
  'Leadership team',
  'Legal',
  'Logistics',
  'Marketing',
  'Operations',
  'Procurement',
  'Product',
  'Research and Development',
  'Sales',
  'Other'
]

export const AVAILABLE_ORG_INDUSTRIES: string[] = [
  'Accounting and Auditing',
  'Advertising and Marketing',
  'Agriculture and Farming',
  'Architecture and Planning',
  'Automotive and Transportation',
  'Banking and Financial Services',
  'Biotechnology and Pharmaceuticals',
  'Business Services',
  'Chemicals and Plastics',
  'Communications and Media',
  'Construction and Engineering',
  'Consulting and Management',
  'Consumer Goods and Services',
  'Education and Training',
  'Energy and Utilities',
  'Entertainment and Recreation',
  'Environmental Services',
  'Food and Beverage',
  'Government and Public Administration',
  'Healthcare and Medical Services',
  'Hospitality and Tourism',
  'Information Technology and Services',
  'Insurance',
  'Legal Services',
  'Manufacturing',
  'Nonprofit and Social Services',
  'Real Estate and Property Management',
  'Retail and Wholesale',
  'Telecommunications',
  'Transportation and Logistics',
  'Other',
  'Prefer not to share'
]

export const AVAILABLE_TEAM_SIZES: string[] = [
  'Just me',
  '1 - 10',
  '10 - 50',
  '50 - 100',
  '100 - 500',
  '500 - 1000',
  '1000+',
  'Prefer not to share'
]

export const AVAILABLE_USER_ROLES: string[] = [
  'Executive',
  'Management',
  'Sales',
  'Marketing',
  'Product Management',
  'Operations',
  'Finance',
  'Legal',
  'Human resources',
  'Technology',
  'Research and Development',
  'Customer service',
  'Consulting',
  'Education',
  'Freelancer/Contractor',
  'Student/Intern',
  'Other',
  'Prefer not to share'
]

export const USER_ONBOARDING_CARDS: UserOnBoardingCard[] = [
  {
    id: 1,
    title: 'Welcome to cadrelo',
    description: 'We’ll get you started right away. Let’s fill in some basic details so we can personalise your experience.',
    inputs: [
      {
        name: 'firstName',
        id: 'user-on-boarding-first-name',
        label: 'First name',
        type: 'text',
        required: true,
        placeholder: 'Enter first name',
        maxLength: 20
      },
      {
        name: 'lastName',
        id: 'user-on-boarding-last-name',
        label: 'Last name',
        type: 'text',
        required: true,
        placeholder: 'Enter last name',
        maxLength: 20
      },
      {
        name: 'country',
        id: 'user-on-boarding-country',
        label: 'Country',
        type: 'text',
        required: true,
        placeholder: 'Select country'
      },
      {
        name: 'city',
        id: 'user-on-boarding-city',
        label: 'City',
        type: 'text',
        required: true,
        placeholder: 'Select city'
      }
    ]
  },
  {
    id: 2,
    title: 'Create a workspace',
    description: 'We are all about amplifying teamwork.Start by creating a workspace.',
    inputs: [
      {
        name: 'teamName',
        id: 'user-on-boarding-organisation-name',
        label: 'Workspace name',
        type: 'text',
        required: true,
        placeholder: 'Enter workspace name',
        maxLength: 20
      },
      {
        name: 'teamType',
        id: 'user-on-boarding-organisation-type',
        label: 'What is your company\'s industry?',
        type: 'text',
        required: true,
        placeholder: 'Select company industry'
      },
      {
        name: 'teamSize',
        id: 'user-on-boarding-organisation-size',
        label: 'How large is your company',
        type: 'text',
        required: true,
        placeholder: 'Select company size'
      },
      {
        name: 'userRole',
        id: 'user-on-boarding-organisation-size',
        label: 'What is your role?',
        type: 'text',
        required: true,
        placeholder: 'Select your role in company'
      }
    ]
  }
]

export const QUIZ_CONTRIBUTION_STYLE_DATA: IQuizContributionDescription[] = [
  {
    id: QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_EXPLORER,
    description: 'Do you like to stick to the tried and true or explore new ways of doing things?',
    picture: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-explorer.svg?data'),
    min: {
      title: 'Practical',
      description: '(consistent, cautious)'
    },
    max: {
      title: 'Explorer',
      description: '(curious, inventive)'
    }
  },
  {
    id: QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_STRUCTURED,
    description: 'For help, look at your desk. Is it in order and neatly aligned or are things left “just the way you like them”?',
    picture: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-structured.svg?data'),
    min: {
      title: 'Flexible',
      description: '(easy going, big picture)'
    },
    max: {
      title: 'Structured',
      description: '(organized, process)'
    }
  },
  {
    id: QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_OUTGOING,
    description: 'Where do you get your energy from – people or quiet moments of introspection?',
    picture: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-outgoing.svg?data'),
    min: {
      title: 'Reserved',
      description: '(quiet, private)'
    },
    max: {
      title: 'Outgoing',
      description: '(energetic, talkative)'
    }
  },
  {
    id: QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_SUPPORTIVE,
    description: 'Bold enough to go against the grain or are you always looking to make sure everyone and everything is harmonious?',
    picture: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-supportive.svg?data'),
    min: {
      title: 'Challenger',
      description: '(competitive, bold)'
    },
    max: {
      title: 'Supportive',
      description: '(adaptive, harmonious)'
    }
  },
  {
    id: QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_REACTIVE,
    description: 'Do people think of you as ‘chilled’ and unflappable, or do you wear your emotions on your sleeve?',
    picture: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-reactive.svg?data'),
    min: {
      title: 'Resilient',
      description: '(calm, even tempered)'
    },
    max: {
      title: 'Reactive',
      description: '(sensitive, passionate)'
    }
  }
]

export const SYNC_FILTER_OPTIONS: SLICER_FILTER_OPTION[] = [
  {
    id: FILTER_OPTIONS.TEAM,
    title: 'Team',
    iconName: 'team-rings'
  },
  {
    id: FILTER_OPTIONS.GOAL,
    title: 'Goal',
    iconName: 'flag-bold'
  },
  {
    id: FILTER_OPTIONS.USER,
    title: 'User',
    iconName: 'person-placeholder'
  }
]

export const QUIZ_CONTRIBUTION_STYLE_PICTURES: IQuizContributionPicture[] = [
  {
    id: 0,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-0.svg?data')
  },
  {
    id: 1,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-1.svg?data')
  },
  {
    id: 2,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-2.svg?data')
  },
  {
    id: 3,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-3.svg?data')
  },
  {
    id: 4,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-4.svg?data')
  },
  {
    id: 5,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-5.svg?data')
  },
  {
    id: 6,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-6.svg?data')
  },
  {
    id: 7,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-7.svg?data')
  },
  {
    id: 8,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-8.svg?data')
  },
  {
    id: 9,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-9.svg?data')
  },
  {
    id: 10,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-10.svg?data')
  },
  {
    id: 11,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-11.svg?data')
  },
  {
    id: 12,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-12.svg?data')
  },
  {
    id: 13,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-13.svg?data')
  },
  {
    id: 14,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-14.svg?data')
  },
  {
    id: 15,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-15.svg?data')
  },
  {
    id: 16,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-16.svg?data')
  },
  {
    id: 17,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-17.svg?data')
  },
  {
    id: 18,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-18.svg?data')
  },
  {
    id: 19,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-19.svg?data')
  },
  {
    id: 20,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-20.svg?data')
  },
  {
    id: 21,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-21.svg?data')
  },
  {
    id: 22,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-22.svg?data')
  },
  {
    id: 23,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-23.svg?data')
  },
  {
    id: 24,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-24.svg?data')
  },
  {
    id: 25,
    src: require('@/assets/images/svg/quiz/quiz-work-stage-contribution-image-25.svg?data')
  }
]

export const QUIZ_CONTRIBUTION_PRESETS: IQuizContributionPreset[] = [
  {
    id: 'ResultsOriented',
    title: 'Results-oriented',
    imageId: 21,
    text: 'These team members naturally organize work and take charge. They tend to be socially confident, competitive, and energetic. Eyes on the prize.',
    data: {
      explorer: 50,
      structured: 80,
      outgoing: 80,
      supportive: 30,
      reactive: 20
    }
  },
  {
    id: 'RelationshipFocused',
    title: 'Relationship-focused',
    imageId: 22,
    text: 'These team members tend to be warm, diplomatic, and approachable. They naturally focus on relationships, finely attuned to others’ feelings: the glue that creates cohesion.',
    data: {
      explorer: 50,
      structured: 50,
      outgoing: 80,
      supportive: 80,
      reactive: 40
    }
  },
  {
    id: 'ProcessFocused',
    title: 'Process and rule focused',
    imageId: 23,
    text: 'These team members pay attention to details, processes, and rules; always colouring within the lines. They tend to be reliable, organized, and conscientious.',
    data: {
      explorer: 50,
      structured: 80,
      outgoing: 30,
      supportive: 40,
      reactive: 20
    }
  },
  {
    id: 'InnovationFocused',
    title: 'Innovation focused',
    imageId: 24,
    text: 'These team members naturally focus on innovation, anticipating problems and recognizing when the team needs to change. They tend to be imaginative, curious, and open to new experiences.',
    data: {
      explorer: 80,
      structured: 30,
      outgoing: 50,
      supportive: 40,
      reactive: 40
    }
  },
  {
    id: 'Pragmatic',
    title: 'Pragmatic',
    imageId: 25,
    text: 'These team members are practical. They prefer to draw from past experiences over new ideas and untested theories. People might consider them to be prudent, calm, and level-headed.',
    data: {
      explorer: 30,
      structured: 60,
      outgoing: 50,
      supportive: 40,
      reactive: 20
    }
  }
]

export const QUIZ_REACHING_OUT_DATA: IQuizReachOutData[] = [
  {
    text: 'Ping me on chat',
    value: 'PingMe',
    emoji: '💬'
  },
  {
    text: 'Call me',
    value: 'CallMe',
    emoji: '📞'
  },
  {
    text: 'See me',
    value: 'SeeMe',
    emoji: '👋'
  },
  {
    text: 'Email me',
    value: 'EmailMe',
    emoji: '✉️'
  }
]

export const QUIZ_GREAT_DEBATE_DATA: IQuizGreatDebate[] = [
  {
    id: QUIZ_LIGHTNING_ROUND_DEFINITION.COFFE_OR_TEA,
    title: 'Drink of choice',
    value: GreatDebateCategory.CoffeeOrTea,
    description: 'Is it about the delicious rainbow of tantalising herbal flavours or the raw surge of power that you need out of your drink!',
    selectable: {
      first: {
        emoji: '☕',
        title: 'Coffee',
        description: 'Give me the power to fuel my day!'
      },
      second: {
        emoji: '🍵',
        title: 'Tea',
        description: 'Love the low buzz and good taste!'
      }
    }
  },
  {
    id: QUIZ_LIGHTNING_ROUND_DEFINITION.EARLY_BIRD_OR_NIGHT_OWL,
    title: 'Circadian rhythm',
    description: 'Dread the thought of rising early with the sun or are you all about that early morning routine.',
    value: GreatDebateCategory.EarlyBirdOrNightOwl,
    selectable: {
      first: {
        emoji: '🐓',
        title: 'Early bird',
        description: 'I greet the day with the rising of the sun.'
      },
      second: {
        emoji: '🦉',
        title: 'Night owl',
        description: 'Twilight is when I’m at my best.'
      }
    }
  },
  {
    id: QUIZ_LIGHTNING_ROUND_DEFINITION.CATS_OR_DOGS,
    title: 'Furry friends',
    value: GreatDebateCategory.CatsOrDogs,
    description: 'Look, you can’t say you’re into both. It’s known that you’re either a cat person or a dog person!',
    selectable: {
      first: {
        emoji: '🐈',
        title: 'Cats',
        description: 'An independent devious mastermind.'
      },
      second: {
        emoji: '🐕',
        title: 'Dogs',
        description: 'A servant that’s all about me.'
      }
    }
  },
  {
    id: QUIZ_LIGHTNING_ROUND_DEFINITION.NIGHT_IN_OR_NIGHT_OUT,
    title: 'Social outing',
    value: GreatDebateCategory.NightInOrNightOut,
    description: 'You’re invited to hang out with some friends. Choice of venue: a) dance party b) living room sofa.',
    selectable: {
      first: {
        emoji: '🛋',
        title: 'Night in',
        description: 'Quiet, cosy and low key.'
      },
      second: {
        emoji: '💃🏻',
        title: 'Night out',
        description: 'Let me burn up that floor with my moves.'
      }
    }
  },
  {
    id: QUIZ_LIGHTNING_ROUND_DEFINITION.BEACH_OR_MOUNTAINS,
    title: 'Sand or stone',
    value: GreatDebateCategory.BeachOrMountains,
    description: 'Is it more about the feet at the pool selfie or hiking through nature leaving nothing behind.',
    selectable: {
      first: {
        emoji: '🏖️',
        title: 'Beach',
        description: 'Love the feel of sand and sun on my skin.'
      },
      second: {
        emoji: '⛰️',
        title: 'Mountains',
        description: 'Silence, birds chirping and nature.'
      }
    }
  }
]

export const CONTRIBUTION_STYLE_COMPARISON: IContributionComparison = {
  explorer: {
    'low-low': 'You and %s play it safe, preferring to use tried and tested approaches you know will work. Not keen on spending time in the clouds on unproven ideas, you’re likely to prioritise pragmatism, guided by your own experiences. Just be careful you don’t get stuck in ‘the way things are done.’ When working with %s, sit back and reflect whether you’re missing another view point that may push you to think differently.',
    'low-medium': 'Not the types to run around re-inventing all the wheels for fun, you and %s both like to use tested approaches and think through things drawing from experience. However, while %s prefers pragmatism like you, they’ll also consider new approaches and ways of thinking about old problems. When working together, try to be open and encourage diverse thinking that will create opportunities for better outcomes.',
    'low-high': 'Curiously cautious, or cautiously curious? You and %s view and approach situations and challenges differently. While you prefer consistency and pragmatism, sticking with what’s been tried and tested, they tend to enjoy exploring new ideas and concepts, readily looking for and testing new ways of doing things. Together, you’re a force to be reckoned with. A blend of your differences allows you to  consider the right approach, broadly flexing between new and exciting ways of working and practical and consistent approaches.',
    'medium-low': 'Not the types to run around re-inventing all the wheels, you and %s both like to use tested, logical approaches and think through things drawing from experience. But you could say %s is a bit more reserved than you when it comes to trying new things and thinking about different ways to approach old problems. When working together, try to encourage open and diverse thinking that will create opportunities for better outcomes.',
    'medium-medium': 'Like two peas in a pod, you and %s are both super flexible when it comes to your team contributions. You can flex between really creative and innovative ways of thinking as well as more consistent and pragmatic approaches. Talk about going with the flow 🔥',
    'medium-high': 'You and %s both welcome the chance to push boundaries and try new and exciting things. While %s has an unquenchable curiosity and flare for innovation, you might prefer to think about whether doing something differently is appropriate or a more traditional approach is better. Our best tip? Work together to encourage dialogue that will allow both of you to find the right approach.',
    'high-low': 'Curiously cautious, or cautiously curious? You and %s view and approach situations and challenges differently. While you enjoy exploring new ideas and concepts, readily looking for and testing new ways of doing things, they tend to prefer consistency and pragmatism, sticking with what’s been tried and tested. Together, you’re a force to be reckoned with. A blend of your differences allows you to consider the right approach, broadly flexing between new and exciting ways of working and practical and consistent approaches.',
    'high-medium': 'You and %s both welcome the chance to push boundaries and try new and exciting things. While you bring an unqenchable curiosity and flare for innovation, %s is little more reserved, preferring to think about whether doing something differently is appropriate or a traditional approach is better. Our best tip? Work together to encourage dialogue that will allow both of you to find the right approach.',
    'high-high': 'You and %s are a pair of boundary pushers. You both view work and team challenges as opportunities to push against what’s always been done and try new things. Each of you bring curiosity and a love to innovate, but when working together, you’ll have to think about whether doing something differently is actually required or whether it’s simply because you both have a preference for new!'
  },
  structured: {
    'low-low': 'You sah chiiiill. You and %s have a very relaxed approach, preferring to go with the flow and work through ambiguity with ease. Just be mindful, your styles are super similar and therefore, detailed planning or process may slip by the wayside. Eep. ',
    'low-medium': 'Well, that’s easy (going). You and %s both have a very relaxed approach, going with the flow and working through ambiguity as needed. Just be mindful, that while your styles are similar, %s flexes their style to be more detailed and structured when neccessary. When working together, enjoy your relaxed nature but ensure you don’t overlook any necessary planning or process. ',
    'low-high': 'Woh! You and %s are opposites in how much structure and organisation you like to bring to the team. While you are more flexible and fluid, %s prefers more planning and organisation. Find the middle ground between free flow and order 😉 There’s room for both styles, so just be mindful to accommodate and add structure where necessary.',
    'medium-low': 'Well, that’s easy (going). You both have a very relaxed approach, going with the flow and working through ambiguity as needed. Just be mindful, that while your styles are similar, %s prefers to stick to that style. Unlike you, they may find it challenging to apply more detail and structure when neccessary. When working together, enjoy your relaxed nature but ensure you don’t overlook any necessary planning or process. ',
    'medium-medium': 'Legend. You and %s can change your style to suit the team, depending on the situation and whether it calls for more structure, planning and thought, or if you need to be more fluid and flexible in how you work within the team. Like chameleons, you adapt to your environment.',
    'medium-high': 'You and %s share a similar preference for structure and  organisation within your team. They’re a firm fan of order, highly detail minded and calculated in how they coordinate their efforts. Complementing your style, you can each help the team work more deliberately. However, while you can work with structure, you can also flex and be more fluid in your approach. When working with %s, make sure you grasp the opportunities to help them navigate situations or challenges more fluidly where they may not have all of the information.',
    'high-low': 'Woh! You and %s are opposites in terms of how much structure and organisation you like to bring to the team. While you are more planned and organised (how neat is your desk, exactly?), %s prefers a more flexible and fluid approach. But flex doesn’t necessarily mean chaos. There’s room for both styles, so just be mindful to accommodate and flex where necessary.',
    'high-medium': 'You and %s share a similar preference for structure and  organisation within your team. Your mutual preference for details and planning how you coordinate efforts help the team work more deliberately. But while you’re a firm fan of order, be mindful that %s tends to be more fluid in their approach. When working together, be open to opportunities to lean on %s, and navigate situations or challenges more fluidly where you may not have all of the information.',
    'high-high': 'You and %s probably have the tidiest desks in all the land, sharing a preference for structure and organisation. You’re detail minded and considered in how you coordinate your efforts within the team. A dream duo, you each can help the team work more deliberately. But, it sometimes pays to go with the flow. So, just be mindful not to miss any opportunities to get more fluid and responsive in certain situations and challenges where you may not have all of the information.'
  },
  outgoing: {
    'low-low': '#ISO looked good on you. Not ones for constant social interaction, both you and %s like to work solo or in small groups. You’re both happy to focus on your tasks and trust one another to do your thing. But be mindful to check in on each other and connect from time to time! ',
    'low-medium': 'You and %s both like to spend time working solo or in small groups. Neither of you require constant social interaction, but %s is happier when they can float between focused tasks and more energetic group work. They tend to enjoy more frequent social connection, so be mindful to check in on each other and connect from time to time. ',
    'low-high': 'Oof! Your preference for calm and quiet might contrast with %s’s high energy and sociableness. While you thrive in teams that are quieter, thoughtful and reserved, %s likes teams that are lively, social and loud. Be mindful of each other’s preferences and understand that sometimes each of these experiences can be draining for the other person. Find a middle ground between high energy situations such as meetings, committees or parties and quieter self-paced work.',
    'medium-low': 'While you’re happy to float between focused tasks and livelier group work, %s has a preference for quiet, focused work. They don’t require constant social interaction, so are happiest when they can work alone on their tasks, trusting others to do their thing as well.  Be mindful to balance your energy with them and other team members who may prefer to work in this more low-key way.',
    'medium-medium': 'Enjoying the best of both worlds, you and %s happily balance time between social interactions with team members and focused, quiet work. You both are able to adapt your style to suit different situations and different team members. When working together, try to align on the level of energy and way of working needed to get the best outcome.',
    'medium-high': 'You and %s value connection with team members, welcoming opportunities to think out loud, talk and engage with others. However, while you both enjoy social interaction, %s is a tad more outgoing than you, so may find it challenging to work on quieter solo tasks. Since they enjoy more frequent social connection, be mindful to check in with them and connect from time to time. But also, find time to re-energize and work in low-key ways where necessary for your needs. ',
    'high-low': 'Heads up! Your high energy and sociableness could occasionally get on %s’s nerves 😨  While you thrive in teams that are lively, social and loud, %s likes teams that are quieter, thoughtful and reserved. Be mindful of each other’s preferences and understand that sometimes each of these experiences can be draining for the other person. Find a middle ground between high energy situations such as meetings, committees or parties and quieter self-paced work.',
    'high-medium': 'No doubt, you are the life of the party (or team meeting!). You and %s are similar in that you both value connection with other team members, relishing opportunities to think out loud, talk and generally engage with others. But while you may both like social interaction, %s is a tad more reserved and may value some time alone to work on quieter, focused tasks. So, be mindful to balance your high energy with them and other team members who may prefer to work in a more low-key way.',
    'high-high': 'Look out, world! Here’s two high energy people who love to get social and bring the group together. Both you and %s  value connection with other team members and relish opportunities to think out loud, talk with others, and have meetings (any reason is a good reason to mingle!). While you two may be like social peas in a pod, just be mindful to balance your high energy with other team members who may prefer to work in a more low-key way.'
  },
  supportive: {
    'low-low': 'Easy, tigers! You both love to challenge the status quo and achieve success in your team. Niether of you is afraid to voice their opinion if you feel that the right topics aren’t being raised or questions asked. This is great when you both agree, however be mindful that there could be some friction at times where you do disagree, as you’re both so similar in your approach and won’t shy away from asking the hard questions.',
    'low-medium': '%s has a team contribution style that is highly cooperative and supportive. However, like you, they’re able to engage in difficult conversations where necessary to achieve a better outcome. While neither of you are afraid to voice your opinions, you have a stronger preference to speak up if you feel that the right topics aren’t being raised or questions asked. So, when working together, it’ll be important to collaborate on challenging issues and take into account each others perspectives.',
    'low-high': 'You and %s approach disagreements differently. You like to focus on the outcome and standing up for what you believe is right, whereas %s prefers flexing and adapting to the needs of the group or individuals. Which means you may need to proceed with care when working together. It’ll be important to co-create a social contract on how you plan to manage disagreements and decision making within the team.',
    'medium-low': '%s will stand up and voice their opinion, focusing on the outcome and what they believe is right. While you are also comfortable challenging ideas and raising difficult conversations, you’re also happy to place the team’s needs ahead of your personal viewpoints. When working together, keep your objectives firmly in mind while considering what the team needs, and harness the strength in your flexible approach.',
    'medium-medium': 'Fabulous. You and %s can both be highly supportive and cooperative while also able to bring up difficult conversations in order to acheive a better outcome. When working together, keep your objectives firmly in mind while considering the team’s needs, and harness the strength in your flexible approach.',
    'medium-high': 'When working in a team, you’re both highly adaptable to the needs of the wider group. While %s has a strong preference to place the teams’ needs ahead of personal viewpoints, you are more comfortable challenging and raising difficult conversations where necessary. When working together, keep your objectives firmly in mind while considering what the team needs, and harness the strength in your flexible approach.',
    'high-low': 'You and %s approach disagreements differently. You prefer flexing and adapting to the needs of the group or individuals, whereas %s tends to focus on the outcome and standing up for what they believe is right. Which means you may need to proceed with care when working together. It’ll be important to co-create a social contract on how you plan to manage disagreements and decision making within the team.',
    'high-medium': 'When working in a team, you’re both highly adaptable to the needs of the wider group. While you’re both generally happy to place the team’s needs ahead of personal viewpoints, %s has a stronger preference to challenge and raise difficult conversations where necessary. Be mindful that your desire for cooperation, support and a positive environment doesn’t suppress disruption and challenges. Work together to bring up, and work through difficult topics.',
    'high-high': 'As pursuers of harmony, you are both highly adaptable to the needs of the wider group, happily placing the team’s needs ahead of personal viewpoints. Just be mindful that your desire for cooperation, support and a positive environment doesn’t suppress disruption and challenges. Work together to bring up, and work through difficult topics.'
  },
  reactive: {
    'low-low': 'Two cool cucumbers, you and %s are calm under pressure. You don’t sweat the small stuff, keeping composure in the face of adversity. In the workplace, you’ll both get on just great! But remember that other team members could be more emotional or reactive to situations which don’t faze you. Showing concern and empathy will help you both build connection and foster trust even though you may not think it’s a big deal.',
    'low-medium': 'Two cool cucumbers under pressure. However, while you don’t ever tend to sweat the small stuff in the face of adversity, %s can at times, wear their emotions on their sleeve. A perfectly human thing! Showing concern and empathy will help you both build connection and foster trust, even though you may not think it’s a big deal, as you’re processing setbacks and challenges with less of an emotional response.',
    'low-high': 'You’re a cool cucumber who takes setbacks and regular team drama in your stride, rarely reacting emotionally. That’s in contrast to  %s, who views team challenges in a more emotionally attuned way, experiencing them at a greater emotional depth (whether expressing sensitivity or enthusiasm!). As you preceive your environments through different lenses, listening, being empathic, and understanding the different angles you come from will be key.',
    'medium-low': 'Two cool cucumbers under pressure. While neither of you tend to sweat the small stuff, %s can be particularly calm in the face of adversity. Be mindful, there are times when you can wear your emotions on your sleeve (a perfectly human thing!) It just means that when working with %s, you’ll need to consider that they may not place the same level of emphasis on emotions as you. Showing concern and empathy will help both of you build connection and foster trust. Use emotional information to help each other, step back, check in and decide on the best course forward. ',
    'medium-medium': 'A pair of emotional chameleons, you can flex between experiencing  team dramas or setbacks with great emotional depth (intuitively anticipating problems ahead of time), as well as being more even tempered, calm and relaxed about it all. Use emotional information to help each other, step back and check in, and decide on the best course forward. ',
    'medium-high': 'You can both experience various situations through an emotional lense. And while you don’t tend sweat the small stuff, you can also wear your emotions on your sleeve, much like %s. Emotions are super important at work, so this just means that neither of you ignore how you or others are feeling.  Emotional understanding can help bring a sense of empathy and connectedness. It can also help you anticipate problems ahead of time. However, when working with each other, consider the importance of emotional regulation and harnessing emotions in a strategic way that brings people together.',
    'high-low': 'You’re passionately passionate, tending to wear your heart on your sleeve. As someone who views setbacks and team challenges in a more emotionally attuned way, you can experience them at a greater emotional depth than others (whether expressing sensitivity or enthusiasm!) That’s in contrast to %s, who takes setbacks and  team drama in their stride: a cool cucumber who rarely reacts emotionally. As you perceive your environments through different lenses, listening, being empathic, and understanding the different angles you come from will be key.',
    'high-medium': 'You can both experience various situations through an emotional lense. That is, you wear your emotions on your sleeve, choosing not to ignore how you and others are feeling. Emotions are super important at work and they can help bring a sense of understanding, empathy and connectedness. They can also help you anticipate problems ahead of time. However, when working with %s, consider that they may not place the same level of emphasis on emotions as you in every situation. Use emotional information to help each other, step back, check in and decide on the best course forward. ',
    'high-high': 'You’re a passionate pair feeling feelings passionately 😵 You tend to experience things through an emotional lense, meaning you wear your emotions on the sleeve, always mindful of how you and others are feeling. Emotions are super important at work, as they can help bring a sense of understanding, empathy and connectedness. They can also help you anticipate problems ahead of time. However, when working together, consider that you both tend to experience your environment in this way, and so, sometimes you could compound how the other is feeling.'
  }
}
