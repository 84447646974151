
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'AppSwitch',
  emits: ['update:modelValue', 'user-action:toggle'],
  props: {
    id: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    return {
      inputValue
    }
  }
})
