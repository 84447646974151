<template>
  <p
    v-if="title"
    class="user-onboarding-cards__title user-onboarding-cards__heading"
  >
    {{ title }}
  </p>

  <p
    v-if="description"
    class="user-onboarding-cards__description"
  >
    {{ description }}
  </p>
</template>

<script>
export default {
  name: 'UserOnBoardingTextContentStructure',
  props: {
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    }
  }
}
</script>
