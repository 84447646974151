import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, vShow as _vShow, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-describedby", "aria-hidden"]
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 3,
  class: "modal__actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppOverlay = _resolveComponent("AppOverlay")!
  const _component_FadeTransition = _resolveComponent("FadeTransition")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
    _createVNode(_component_FadeTransition, { duration: { enter: 350, leave: 350 } }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_AppOverlay, {
          theme: _ctx.ModalHandlerService.getCurrentModalProps(_ctx.id)?.theme,
          onMousedown: _withModifiers(_ctx.handleOverlayClick, ["stop"]),
          variant: _ctx.ModalHandlerService.getCurrentModalProps(_ctx.id)?.variant,
          class: _normalizeClass({ 'app-overlay-nav-minimized': _ctx.navMinimized })
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass(["modal", [_ctx.theme && `modal--${_ctx.theme}`]]),
              role: "dialog",
              "aria-describedby": `${_ctx.id}-title`,
              "aria-hidden": _ctx.ModalHandlerService.isCurrentlyActive(_ctx.id),
              "aria-modal": "true",
              onMousedown: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
            }, [
              (_ctx.hasCloseButton)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "modal__close",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                  }, [
                    _createVNode(_component_AppIcon, {
                      name: "close",
                      size: "16"
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.hasCopyButton)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "modal__copy",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.copyURLToClipBoard && _ctx.copyURLToClipBoard(...args)))
                  }, [
                    _createVNode(_component_AppIcon, {
                      name: "share",
                      size: "22"
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.title)
                ? (_openBlock(), _createElementBlock("h3", {
                    key: 2,
                    id: `${_ctx.id}-title`
                  }, _toDisplayString(_ctx.title), 9, _hoisted_2))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "default", {
                props: _ctx.ModalHandlerService.getCurrentModalProps(_ctx.id)
              }),
              (!!_ctx.$slots.actions)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "actions")
                  ]))
                : _createCommentVNode("", true)
            ], 42, _hoisted_1), [
              [_vShow, _ctx.ModalHandlerService.isCurrentlyActive(_ctx.id)]
            ])
          ]),
          _: 3
        }, 8, ["theme", "onMousedown", "variant", "class"]), [
          [_vShow, _ctx.ModalHandlerService.isCurrentlyActive(_ctx.id)]
        ])
      ]),
      _: 3
    })
  ]))
}