
import { defineComponent, watchEffect, computed } from 'vue'

import ModalHandlerService from '@/services/ModalHandlerService'
import BodyLockService from '@/services/BodyLockService'
import NavigationPanelHandlerService from '@/services/NavigationPanelHandlerService'
import ToastNotificationService from '@/services/ToastNotificationService'
import WindowEventsService, { WINDOW_EVENTS } from '@/services/WindowEventsService'

import AppOverlay from '@common/AppOverlay.vue'
import FadeTransition from '@transition/FadeTransition.vue'
import { SuccessMessages } from '@/data/messages'

const AVAILABLE_MODAL_THEMES = [
  'barebone',
  'account-picture-upload',
  'onboarding',
  'add-note',
  'edit-note',
  'disable-slack',
  'disable-jira',
  'disable-check-in',
  'daily-sync-overlay-panel',
  'goals-objective',
  'close-board',
  'board-insights',
  'create-group',
  'add-group-member',
  'sync-overlay'
]

export default defineComponent({
  name: 'Modal',
  emits: ['closeModal'],
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    title: {
      type: String
    },
    theme: {
      type: String,
      required: false,
      validator: (theme: string) => AVAILABLE_MODAL_THEMES.includes(theme)
    },
    disableScrollLock: {
      type: Boolean,
      default: false
    },
    preventClose: {
      type: Boolean,
      default: false
    },
    hasCloseButton: {
      type: Boolean,
      default: false
    },
    hasCopyButton: {
      type: Boolean,
      default: false
    },
    navMinimized: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AppOverlay,
    FadeTransition
  },
  setup(props, { emit }) {
    const isNavigationPanelActive = computed(() => NavigationPanelHandlerService.getCurrentlyActive())
    const isModalActive = computed(() => !!ModalHandlerService.getCurrentlyActive())

    const setHeight = () => {
      const viewHeight = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${viewHeight}px`)
    }

    WindowEventsService.on(WINDOW_EVENTS.RESIZE, () => {
      setHeight()
    })

    WindowEventsService.on(WINDOW_EVENTS.LOAD, () => {
      setHeight()
    })

    function handleOverlayClick() {
      if (!props.disableScrollLock && !props.preventClose) {
        BodyLockService.unlock()
        handleESCEvent()
      }
    }

    function onEscape(e: { keyCode: number }) {
      if (e.keyCode === 27 && !props.preventClose) {
        closeModal()
      }
    }

    function handleESCEvent() {
      if (!isModalActive.value && !props.preventClose) {
        document.removeEventListener('keydown', onEscape)
      }
    }

    function closeModal() {
      emit('closeModal')
      handleESCEvent()

      if (!props.preventClose) {
        ModalHandlerService.reset()
      }

      if (!props.disableScrollLock) {
        BodyLockService.unlock()
      }
    }

    async function copyURLToClipBoard() {
      try {
        await navigator.clipboard.writeText(`${window.location.href}`)
        ToastNotificationService.push({ type: 'info', message: SuccessMessages.URL_COPIED_TO_CLIPBOARD })
      } catch (error) {
        if (error instanceof Error) {
          ToastNotificationService.push({ type: 'error', message: error.message })
        }
      }
    }

    watchEffect(() => {
      if (!props.disableScrollLock) {
        if (isModalActive.value || isNavigationPanelActive.value) {
          BodyLockService.lock()
        } else {
          BodyLockService.unlock()
        }

        if (isModalActive.value) {
          document.addEventListener('keydown', onEscape)
        }
      }
    })

    return {
      ModalHandlerService,
      handleOverlayClick,
      closeModal,
      copyURLToClipBoard
    }
  }
})
