import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mention-list" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["set"]
const _hoisted_4 = { class: "mention-list__user-item__details" }
const _hoisted_5 = ["textContent"]
const _hoisted_6 = {
  key: 1,
  class: "mention-list__item mention-list__user-item__full-name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAvatar = _resolveComponent("AppAvatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.items.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("button", {
            class: _normalizeClass(["mention-list__item", { 'is-selected': index === _ctx.selectedIndex }]),
            key: index,
            onClick: ($event: any) => (_ctx.selectItem(index))
          }, [
            _createElementVNode("div", {
              class: "mention-list__user-item",
              set: _ctx.user = JSON.parse(item)
            }, [
              _createVNode(_component_AppAvatar, {
                user: _ctx.user.profile,
                size: "tiny"
              }, null, 8, ["user"]),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.user.profile?.firstName && _ctx.user.profile?.lastName)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      textContent: _toDisplayString(`${_ctx.user.profile.firstName} ${_ctx.user.profile.lastName}`),
                      class: "mention-list__user-item__full-name"
                    }, null, 8, _hoisted_5))
                  : _createCommentVNode("", true)
              ])
            ], 8, _hoisted_3)
          ], 10, _hoisted_2))
        }), 128))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, " No result "))
  ]))
}