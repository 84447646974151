
import Link from '@tiptap/extension-link'
import { JiraMention } from '@/helpers/AppTextEditorJiraMention'
import { emojiRenderer, getEmojiSuggestion } from '@/helpers/emojiUtils'
import { getMentionSuggestions, mentionRenderer } from '@/helpers/mentionUtils'
import { useStore } from '@/store'
import { Membership } from '@/types/membership'
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import { computed, defineComponent, onBeforeUnmount, ref, watch } from 'vue'

export default defineComponent({
  components: {
    EditorContent
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: true
    },
    hasFocus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'focus', 'blur', 'submit', 'delete'],
  setup(props, { emit }) {
    const store = useStore()

    const teamMembers = computed<Membership[]>(() => store.getters['teams/getTeamMembersWithFullName'])
    const isFocused = ref(false)
    const editorContentRef = ref()
    const rootRef = ref()

    let blurTimer: ReturnType<typeof setTimeout> | null = null

    const editor = new Editor({
      editorProps: {
        handleDOMEvents: {
          keydown: (_, event): boolean => {
            if (event.key === 'Enter') {
              if (event.shiftKey) {
                editor.commands.enter()
              } else if (!isSuggestionPopupActive()) {
                event.preventDefault()
                emit('submit')
              }
            }

            return false
          },
          keyup: (_, event): boolean => {
            if (event.key === 'Backspace') {
              emit('delete')
            }

            return false
          },
          drop: (_, event): boolean => {
            event.preventDefault()
            return false
          }
        }
      },
      extensions: [
        StarterKit.configure({
          dropcursor: {
            width: 0,
            color: 'transparent'
          },
          orderedList: false,
          bulletList: false,
          listItem: false
        }),
        Underline,
        Placeholder.configure({
          placeholder: 'Add a new task..',
          emptyNodeClass: 'is-editor-empty'
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'mention'
          },
          renderLabel({ node }) {
            const user = JSON.parse(node.attrs.id)
            const userInfo = '@' + user.profile?.firstName + user.profile?.lastName
            return userInfo
          },
          suggestion: {
            items: (params: { query: string }): string[] => getMentionSuggestions(params.query, teamMembers.value),
            render: mentionRenderer
          }
        }),
        JiraMention,
        Emoji.configure({
          emojis: gitHubEmojis,
          enableEmoticons: true,
          suggestion: {
            items: (params: { query: string }): string[] => getEmojiSuggestion(editor, params.query),
            render: emojiRenderer
          }
        }),
        Link.configure({
          openOnClick: props.editable
        })
      ],
      editable: props.editable,
      content: props.modelValue,
      onUpdate: () => {
        emit('update:modelValue', editor.getHTML())
      },
      onFocus({ event }) {
        if (blurTimer) {
          clearTimeout(blurTimer)
        }
        isFocused.value = true
        emit('focus', event)
      },
      onBlur({ event }) {
        blurTimer = setTimeout(() => {
          isFocused.value = false
          emit('blur', event)
        }, 100)
      }
    })

    watch(() => props.modelValue, (value) => {
      const isSame = editor.getHTML() === value
      if (isSame) {
        return
      }
      editor.commands.setContent(value, false)
    })

    watch(() => props.hasFocus, (focus) => {
      if (focus) {
        editor.commands.focus('end')
      }
    }, { immediate: true })

    onBeforeUnmount(() => {
      const staticEditorContent = document.createElement('div') // Create new div
      rootRef.value.append(staticEditorContent) // Attach to parent (i have a ref on it called rootRef)
      staticEditorContent.outerHTML = editorContentRef.value.$el.outerHTML // Set new element to contents of old one
      editorContentRef.value.$el.style.display = 'none' // Hide old element
      editor.destroy()
    })

    function isSuggestionPopupActive() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const state = (editor.state as any)
      return state?.mention$?.active || state?.emojiSuggestion$.active || state?.jiraMention$.active
    }

    return {
      isFocused,
      rootRef,
      editorContentRef,
      editor
    }
  }
})
