class BodyLockService {
  private static instance: BodyLockService
  private locked: boolean
  private forced: boolean

  private constructor() {
    this.locked = false
    this.forced = false
  }

  public static init(): BodyLockService {
    if (!BodyLockService.instance) {
      BodyLockService.instance = new BodyLockService()
    }

    return BodyLockService.instance
  }

  public lock(force = false) {
    if (force || (!this.forced && !force)) {
      this.forced = force
      this.locked = true

      document.body.style.overflow = 'hidden'
    }
  }

  public toggle() {
    if (this.locked) this.unlock()
    this.lock()
  }

  public unlock(force = false) {
    // TODO: It's not clear what this does
    if (force || (!this.forced && !force)) {
      this.locked = false
      this.forced = force
      document.body.removeAttribute('style')
    }
  }

  public isLocked() {
    return this.locked
  }
}

const instance = BodyLockService.init()
export default instance
