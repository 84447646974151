import ApiService from '@/services/ApiService'
import AwayStatus from '@/types/awayStatus'
import { Membership, TeamRole } from '@/types/membership'
import { RootState, UserState } from '@/types/store'
import { TeamProfile } from '@/types/team'
import { User, UserProfile, UserQuiz, UserSettings } from '@/types/user'
import { Module } from 'vuex'
import waitForCurrentPreloadToFinish from '../util/waitForPreload'
import { MixPanelService } from '@/services/MixPanelService'

interface IUpdateUserParams {
  profile?: UserProfile,
  quiz?: UserQuiz,
  lastViewedSyncTime?: string,
  settings?: UserSettings
}

const user: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    user: {} as User,
    memberships: [] as Membership[],
    activeMembership: undefined,
    switchingActiveTeam: false
  },
  getters: {
    getUser: (state) => state.user,
    getUserProfile: (state) => state.user.profile,
    getUserQuiz: (state) => state.user.quiz,
    getMemberships: (state) => state.memberships,
    getActiveMembership: (state) => state.activeMembership,
    isAuthorized: (state) => state.user.verified || false,
    getActiveTeamRole: (state) => state.activeMembership?.role,
    isSwitchingActiveTeam: (state) => state.switchingActiveTeam
  },
  mutations: {
    setUser(state, value: User) {
      state.user = value
    },
    setMemberships(state, value: Membership[]) {
      state.memberships = value
    },
    setActiveMembership(state, value: Membership) {
      state.activeMembership = value
    },
    setActiveTeamRole(state, value: { userId: string, newRole: TeamRole }) {
      if (state.activeMembership && state.user.userId === value.userId) {
        state.activeMembership.role = value.newRole
      }
    },
    setActiveTeamAwayStatus(state, value: { userId: string, awayStatus: AwayStatus }) {
      if (state.activeMembership && state.user.userId === value.userId) {
        state.activeMembership.awayStatus = value.awayStatus
      }
    },
    addMembership(state, value: Membership) {
      state.memberships.push(value)
    },
    removeMembership(state, teamId: string) {
      state.memberships = state.memberships.filter((membership: Membership) => membership.teamId !== teamId)
      if (state.activeMembership?.teamId === teamId) {
        state.activeMembership = state.memberships.slice(-1)[0]
      }
    },
    syncChangesToUserQuiz(state, updatedUserQuiz: UserQuiz) {
      state.user.quiz = {
        ...state.user.quiz,
        ...updatedUserQuiz
      }
    },
    syncChangesToUserProfile(state, updatedUserProfile: UserProfile) {
      state.user.profile.displayName = updatedUserProfile.displayName ?? state.user.profile.displayName
      state.user.profile.firstName = updatedUserProfile.firstName ?? state.user.profile.firstName
      state.user.profile.lastName = updatedUserProfile.lastName ?? state.user.profile.lastName
      state.user.profile.pictureId = updatedUserProfile.pictureId ?? state.user.profile.pictureId
      state.user.profile.location = updatedUserProfile.location ?? state.user.profile.location
      state.user.profile.jobTitle = updatedUserProfile.jobTitle ?? state.user.profile.jobTitle
      state.user.profile.anniversary = updatedUserProfile.anniversary ?? state.user.profile.anniversary
      state.user.profile.birthday = updatedUserProfile.birthday ?? state.user.profile.birthday
    },
    syncChangesToTeamProfile(state, updatedTeamProfile: TeamProfile) {
      if (state.activeMembership) {
        state.activeMembership.teamProfile = updatedTeamProfile
        const indexOfTeamInMemberships = state.memberships.findIndex((e) => e.teamId === state.activeMembership?.teamId)
        if (indexOfTeamInMemberships !== -1) {
          state.memberships[indexOfTeamInMemberships].teamProfile = updatedTeamProfile
        }
      }
    },
    setSwitchingActiveTeam(state, switchingActiveTeam: boolean) {
      state.switchingActiveTeam = switchingActiveTeam
    },
    setLastViewedSyncTime(state, value: string) {
      state.user.lastViewedSyncTime = value
    },
    updateUserSettings(state, settings: UserSettings) {
      state.user.settings = settings
    }
  },
  actions: {
    async getCurrentUser({ commit }): Promise<void> {
      const response = await ApiService.sendRequest('authenticated-user', {
        method: 'GET',
        requiresAuth: true
      })

      if (response.ok) {
        const userData = await response.json()
        const user = userData.user as User
        commit('setUser', user)
        commit('setMemberships', userData.memberships)
        commit('setActiveMembership', userData.activeTeam)
        MixPanelService.instance.onSignIn(user, userData.activeTeam)
      } else {
        console.info(`There was an error connecting to the API: ${response.status} - ${JSON.stringify(await response.json())}`)
      }
    },
    async updateUser({ state, commit }, params: IUpdateUserParams): Promise<void> {
      if (params.lastViewedSyncTime) {
        commit('setLastViewedSyncTime', params.lastViewedSyncTime)
      }
      await ApiService.sendRequest('authenticated-user', {
        method: 'PATCH',
        requiresAuth: true,
        body: JSON.stringify(params)
      })

      if (params.profile) {
        if (!params.quiz) {
          MixPanelService.instance.onProfileUpdate(state.user.profile, params.profile)
        }
        commit('syncChangesToUserProfile', params.profile)
        commit('teams/syncChangesToUserProfile', { userId: state.user.userId, updatedUserProfile: params.profile }, { root: true })
      }

      if (params.quiz) {
        MixPanelService.instance.onQuizUpdate(params.quiz, state.user.quiz)
        commit('syncChangesToUserQuiz', params.quiz)
        if (params.quiz.workWeekSchedule) {
          commit('teams/updateUserSchedule', { userId: state.user.userId, schedule: params.quiz.workWeekSchedule }, { root: true })
        }
      }

      if (params.settings) {
        commit('updateUserSettings', params.settings)
      }
    },
    async switchActiveTeam({ commit, dispatch, getters }, params: { teamId: string, isNewTeam: boolean }): Promise<void> {
      commit('setSwitchingActiveTeam', true)
      try {
        const response = await ApiService.sendRequest('active-team', {
          method: 'PUT',
          requiresAuth: true,
          body: JSON.stringify(params)
        })

        if (response.ok) {
          const userData = await response.json()
          commit('setUser', userData.user)
          const memberships: Membership[] = getters.getMemberships
          const alreadyTeamMember = memberships?.find((team) => team.teamId === params.teamId)
          if (params.isNewTeam && !alreadyTeamMember) {
            commit('addMembership', userData.activeTeam)
          }
          commit('setActiveMembership', userData.activeTeam)
          MixPanelService.instance.registerActiveTeam(userData.activeTeam)
          await waitForCurrentPreloadToFinish() // Avoid a race condition where a login and team switch are both running at the same time
          dispatch('clearActiveTeamDataFromStores', undefined, { root: true })
          dispatch('loadActiveTeamDataIntoStores', { activeTeamId: userData.activeTeam.teamId }, { root: true })
        } else {
          commit('teams/setShowNotATeamMemberOverlay', true, { root: true })
          throw new Error(`There was an error switching to team ${params.teamId}: ${response.status} - ${JSON.stringify(await response.json())}`)
        }
      } finally {
        commit('setSwitchingActiveTeam', false)
      }
    }
  }
}

export default user
