
import { defineComponent, computed, reactive, ref } from 'vue'
import { WorkLocation, WorkDaySchedule } from '@/types/user'

import { WORKING_HOURS_ALL_DAYS_DISABLED } from '@/data/static'
import QuizService from '@/services/QuizService'

import trim from '@/filters/trim'
import capitalize from '@/filters/capitalize'

import QuizCard from '@feature/quiz/QuizCard.vue'
import AppSpacer from '@common/AppSpacer.vue'
import AppTimePicker, { Time } from '@common/AppTimePicker.vue'
import AppDropdown from '@common/AppDropdown.vue'

export default defineComponent({
  name: 'QuizAvailabilityStageWorkingHours',
  emits: ['quiz-navigation:next', 'quiz-navigation:prev'],
  components: {
    QuizCard,
    AppSpacer,
    AppTimePicker,
    AppDropdown
  },
  setup(_, { emit }) {
    const { workWeekSchedule } = QuizService.getAvailabilityStageData()

    /**
     * !!!TODO!!!
     * !!!I M P O R T A N T!!!
     * There's a major issue with the way reactiviy works here
     * In essence, every nested key of an object becomes a proxy
     * And created unwanted 2-way databinding with the store
     */

    const DEFAULT_SELECTION = computed(() => {
      const userHasSelectedDays = Object.entries(workWeekSchedule).some(([_, item]) => item.works)
      if (userHasSelectedDays) {
        return workWeekSchedule
      } else {
        return WORKING_HOURS_ALL_DAYS_DISABLED
      }
    })

    const workingHoursData = reactive<Record<string, WorkDaySchedule>>({
      monday: { ...DEFAULT_SELECTION.value.monday },
      tuesday: { ...DEFAULT_SELECTION.value.tuesday },
      wednesday: { ...DEFAULT_SELECTION.value.wednesday },
      thursday: { ...DEFAULT_SELECTION.value.thursday },
      friday: { ...DEFAULT_SELECTION.value.friday },
      saturday: { ...DEFAULT_SELECTION.value.saturday },
      sunday: { ...DEFAULT_SELECTION.value.sunday }
    })

    const selectedWorkingDays = computed(() => {
      return Object.fromEntries(Object.entries(workingHoursData).filter(([_, item]) => item.works))
    })

    const schedule = ref()

    const updateWorkingDay = (day: any) => {
      workingHoursData[day].works = !workingHoursData[day].works

      if (!workingHoursData[day].works) {
        workingHoursData[day].startHour = 9
        workingHoursData[day].startMinute = 0
        workingHoursData[day].endHour = 17
        workingHoursData[day].endMinute = 0
        workingHoursData[day].location = WorkLocation.Office
      }
    }

    function navigateNext() {
      emit('quiz-navigation:next')
      QuizService.save({
        workWeekSchedule: workingHoursData
      })
      QuizService.next()
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')
      QuizService.prev()
    }

    function handleClick(daySchedule: WorkDaySchedule) {
      schedule.value = daySchedule
    }

    function updateTime(updatedTime: Time, startTimeDefined: boolean) {
      if (startTimeDefined) {
        schedule.value.startHour = updatedTime.hour
        schedule.value.startMinute = Number(updatedTime.minute)
      } else {
        schedule.value.endHour = updatedTime.hour
        schedule.value.endMinute = Number(updatedTime.minute)
      }
    }

    return {
      QuizService,
      selectedWorkingDays,
      WorkLocation,
      location,
      workingHoursData,
      navigateNext,
      navigatePrev,
      updateWorkingDay,
      trim,
      capitalize,
      handleClick,
      updateTime
    }
  }
})
