import { ref, Ref } from 'vue'
import { NavigationPanel } from '@/types/panel'

class NavigationPanelHandlerService {
  private static instance: NavigationPanelHandlerService
  private currentlyActivePanel: Ref<NavigationPanel | null>

  private constructor() {
    this.currentlyActivePanel = ref<NavigationPanel | null>(null)
  }

  public static init(): NavigationPanelHandlerService {
    if (!NavigationPanelHandlerService.instance) {
      NavigationPanelHandlerService.instance = new NavigationPanelHandlerService()
    }

    return NavigationPanelHandlerService.instance
  }

  public open(panel: NavigationPanel) {
    this.currentlyActivePanel.value = panel
  }

  public toggle(panel: NavigationPanel) {
    if (this.currentlyActivePanel.value === panel) {
      this.close()
    } else {
      this.open(panel)
    }
  }

  public close() {
    this.currentlyActivePanel.value = null
  }

  public getCurrentlyActive() {
    return this.currentlyActivePanel.value
  }

  public isCurrentlyActive(panel: string) {
    return this.getCurrentlyActive() === panel
  }
}

const instance = NavigationPanelHandlerService.init()
Object.freeze(instance)

export default instance
