import { store } from '@/store'
import CheckInItem from '@/types/checkinItem'
import { Callback } from '@/types/global'
import { UserProfile } from '@/types/user'
import { goalUpdated } from '@/composables/sideBarManager'
import { Goal } from '@/types/objective'

export interface IWebSocketMessage {
  messageType: string,
  message: Record<string, unknown>
}

export class WebSocketService {
  public static instance = new WebSocketService()
  private ws?: WebSocket
  private keepAliveHandle?: number
  private onConnect?: Callback

  private constructor() { }

  public connect(onConnect?: Callback): void {
    if (!this.ws) {
      this.onConnect = onConnect
      this.refreshConnection()
    }
  }

  public disconnect(): void {
    if (this.ws) {
      this.ws.close()
      this.ws = undefined
    }
    if (this.keepAliveHandle) {
      clearInterval(this.keepAliveHandle)
    }
  }

  public joinPlay(playCode: string, profile: UserProfile, userId?: string): void {
    if (this.ws) {
      const joinPlay = {
        route: 'join-play',
        playCode,
        profile,
        userId
      }
      this.ws.send(JSON.stringify(joinPlay))
    } else {
      this.connect(() => this.joinPlay(playCode, profile, userId))
    }
  }

  public sendMessage(message: Record<string, unknown>): void {
    if (this.ws) {
      this.ws.send(JSON.stringify(message))
    }
  }

  private refreshConnection(): void {
    const userId = store.getters['user/getUser']?.userId
    if (userId) {
      this.ws = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_URL}?userId=${userId}`)
      this.initListeners()
    }
  }

  private initListeners() {
    if (this.ws) {
      this.ws.onopen = (ev) => {
        console.log(`websocket connected: ${JSON.stringify(ev)}`)
        if (this.onConnect) {
          console.log('invoking onConnect callback')
          this.onConnect()
          this.onConnect = undefined
        }
        this.keepAlive()
      }
      this.ws.onerror = (ev) => {
        console.log(`websocket error: ${JSON.stringify(ev)}`)
      }
      this.ws.onclose = () => {
        console.log('websocket disconnected')
        store.commit('play/setConnectionId', undefined)
        this.refreshConnection()
      }
      this.ws.onmessage = (ev) => {
        if (ev.data) {
          const message = JSON.parse(ev.data) as IWebSocketMessage
          if (message.messageType === 'notification') {
            if (this.isNotificationValidForTeam(message.message.teamId as string)) {
              store.commit('notifications/addNewNotification', message.message)
              if (message.message.type === 'UserJoinedTeam') {
                store.commit('teams/handleUserJoinedTeamNotification', message.message.context)
              }
            }
          }

          if (message.messageType === 'check-in-item') {
            const checkInItem = message.message.item as CheckInItem

            if (this.isViewingTeam(checkInItem.teamId)) {
              const currentUserId = store.getters['user/getUser'].userId
              if (message.message.userId !== currentUserId) {
                store.commit('checkin/upsertCheckInItem', message.message.item)
              }
            }
          }

          if (message.messageType === 'delete-check-in-item') {
            if (this.isViewingTeam(message.message.teamId as string)) {
              store.commit('checkin/deleteCheckInItem', message.message)
            }
          }

          if (message.messageType === 'join-play-response') {
            store.dispatch('play/handleJoinPlayResponse', message.message)
          }

          if (message.messageType === 'play') {
            store.commit('play/setPlay', message.message)
          }

          if (message.messageType === 'daily-sync') {
            if (this.isViewingTeam(message.message.teamId as string)) {
              store.commit('dailySync/upsertDailySync', message.message)
            }
          }

          if (message.messageType === 'delete-daily-sync') {
            if (this.isViewingTeam(message.message.teamId as string)) {
              store.commit('dailySync/deleteDailySync', message.message)
            }
          }

          if (message.messageType === 'goal') {
            if (this.isViewingTeam(message.message.teamId as string)) {
              const goal = message.message as unknown as Goal
              store.commit('goals/syncChangesForGoal', goal)
              /* console.log(`updating goal with tasks ${JSON.stringify(goal.tasks)}`) */
              goalUpdated(goal)
              store.commit('dailySync/syncTaskUpdates', goal.tasks ?? [])
            }
          }

          if (message.messageType === 'delete-goal') {
            if (this.isViewingTeam(message.message.teamId as string)) {
              store.commit('goals/deleteGoal', message.message)
            }
          }

          if (message.messageType === 'social-connect') {
            if (this.isViewingTeam(message.message.teamId as string)) {
              store.commit('socialConnect/upsertSocialConnect', message.message)
            }
          }

          if (message.messageType === 'dre-insights') {
            if (this.isViewingTeam(message.message.teamId as string)) {
              store.commit('dreInsights/upsertCycle', message.message)
            }
          }
        }
      }
    }
  }

  // Some web socket messages should only flow through to stores if the user is currently viewing the team the message is associated with
  private isViewingTeam(teamId: string) {
    const activeTeamId = store.getters['user/getActiveMembership']?.teamId
    return activeTeamId === teamId
  }

  // New notifications should only flow through to stores if they are either tied to the current team or they are global notifications
  private isNotificationValidForTeam(teamId: string) {
    return this.isViewingTeam(teamId) || teamId === 'global'
  }

  private keepAlive() {
    if (this.keepAliveHandle) {
      clearInterval(this.keepAliveHandle)
    }
    /* API Gateway will maintain idle connections for up to 10 minutes so we send a message every 9 minutes
    * to keep the connection alive
    */
    this.keepAliveHandle = window.setInterval(() => this.ws?.send('keep-alive'), 9 * 60000)
  }
}
