
import { ref, reactive, defineComponent, toRefs } from 'vue'
import { IFormErrors } from '@/types/global'
import { ErrorMessages } from '@/data/messages'

import Modal from '@common/Modal.vue'

import ImageUploadService from '@/services/ImageUploadService'
import ModalHandlerService from '@/services/ModalHandlerService'
import ToastNotificationService from '@/services/ToastNotificationService'

export default defineComponent({
  name: 'SharedProfilePictureUpload',
  components: {
    Modal
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Change profile picture'
    },
    defaultAvatar: {
      type: String,
      default: require('@/assets/images/svg/avatar-placeholder.svg?data')
    },
    isInQueue: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const env = process.env.VUE_APP_DOMAIN === 'production' ? '' : `.${process.env.VUE_APP_DOMAIN}`
    const uploading = ref(false)
    const inputFile = ref<HTMLInputElement>()
    const form = ref<HTMLFormElement>()
    const fileBase64 = ref<string | ArrayBuffer | null>('')
    const errors = reactive<IFormErrors>({
      fileUploadErrors: {}
    })

    const cancelUpload = () => {
      if (props.isInQueue) {
        ModalHandlerService.open('create-team')
      } else {
        ModalHandlerService.close()
      }

      errors.fileUploadErrors = {}
    }

    async function handleDrop(event: DragEvent, isInputFile: boolean) {
      const uploadLimit = 20.097 // ~20 MB
      let file

      errors.fileUploadErrors = {}

      if (isInputFile && inputFile.value) {
        [file] = inputFile.value.files as FileList
      } else if (event) {
        [file] = event.dataTransfer?.files as FileList
      }

      if (file && (file.size / 1024 / 1024) > uploadLimit && form.value) {
        errors.fileUploadErrors = {
          upload: ErrorMessages.FILE_SIZE_EXCEEDS_MAXIMUM
        }

        form.value.reset()
      } else if (file) {
        const { result, error } = await ImageUploadService.read(file)

        if (error) {
          errors.fileUploadErrors = {
            upload: error
          }
        } else if (result) {
          fileBase64.value = result

          ModalHandlerService.close()
          ModalHandlerService.open(`${props.id}-crop`)

          if (form.value) {
            form.value.reset()
          }
        } else {
          ToastNotificationService.push({
            type: 'error',
            message: ErrorMessages.UNSUCCESSFUL_PICTURE_PROCESSING
          })
        }
      } else {
        ToastNotificationService.push({ type: 'error', message: ErrorMessages.UNSUCCESSFUL_PICTURE_UPLOAD })
      }
    }

    return {
      ...toRefs(errors),
      env,
      uploading,
      inputFile,
      form,
      fileBase64,
      ImageUploadService,
      ModalHandlerService,
      handleDrop,
      cancelUpload
    }
  }
})
