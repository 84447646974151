import ApiService from '@/services/ApiService'
import { MixPanelService } from '@/services/MixPanelService'
import DailySync, { DailySyncTask, UpsertDailySyncRequest } from '@/types/dailySync'
import { DailySyncState, RootState } from '@/types/store'
import { formatAsDailySyncDate, daysFromNow } from '@/utils/dailySyncDates'
import { Module } from 'vuex'
import { DateTime } from 'luxon'
import { store } from '@/store'
import { getDailySyncForUser } from '@/utils/dailySyncUtils'

const dailySync: Module<DailySyncState, RootState> = {
  namespaced: true,
  state: {
    dailySyncs: {} as Record<string, DailySync[]>,
    earliestDate: undefined,
    dailySyncsDataLoaded: false,
    backlog: [],
    backlogLoaded: false,
    syncDayOffset: 0
  },
  getters: {
    getDailySyncs: (state) => state.dailySyncs,
    getEarliestDate: (state) => state.earliestDate,
    isDailySyncsDataLoaded: (state) => state.dailySyncsDataLoaded,
    getBacklog: (state) => state.backlog,
    isBacklogLoaded: (state) => state.backlogLoaded,
    getSyncDayOffset: (state) => state.syncDayOffset
  },
  mutations: {
    setDailySyncs(state, dailySyncs: Record<string, DailySync[]>) {
      state.dailySyncs = dailySyncs
    },
    addDailySyncs(state, newDailySyncs: Record<string, DailySync[]>) {
      state.dailySyncs = { ...state.dailySyncs, ...newDailySyncs }
    },
    upsertDailySync(state, dailySync: DailySync) {
      const currentDailySyncs = state.dailySyncs[dailySync.date] ?? []
      const newDailySyncs = currentDailySyncs.filter((e) => e.userId !== dailySync.userId)
      newDailySyncs.push(dailySync)
      state.dailySyncs[dailySync.date] = newDailySyncs
      state.backlog = state.backlog.filter(b => !dailySync.tasks?.some(d => d.id === b.id))
    },
    deleteDailySync(state, params: { userId: string, date: string }) {
      const currentDailySyncs = state.dailySyncs[params.date] ?? []
      const updatedDailySyncs = currentDailySyncs.filter((e) => e.userId !== params.userId)
      state.dailySyncs[params.date] = updatedDailySyncs
    },
    setEarliestDate(state, date?: string) {
      state.earliestDate = date
    },
    setDailySyncsDataLoaded(state, value: boolean) {
      state.dailySyncsDataLoaded = value
    },
    setBacklog(state, value: DailySyncTask[]) {
      state.backlog = value
    },
    setBacklogLoaded(state, value: boolean) {
      state.backlogLoaded = value
    },
    removeFromBacklog(state, task: DailySyncTask) {
      state.backlog = state.backlog.filter(e => e.id !== task.id)
    },
    syncTaskUpdates(state, tasks: DailySyncTask[]) {
      tasks.forEach(task => {
        const backlogIndex = state.backlog.findIndex(e => e.id === task.id)
        if (backlogIndex !== -1) {
          state.backlog[backlogIndex] = task
        } else if (task.assignee && task.assignee === store.getters['user/getActiveMembership']?.userId) {
          const assigneeDailySync = getDailySyncForUser(DateTime.now(), task.assignee)
          if (!assigneeDailySync?.tasks?.find(e => e.id === task.id)) {
            state.backlog.push(task)
          }
        }
      })
    },
    setSyncDayOffset(state, offset: number) {
      state.syncDayOffset = offset
    }
  },
  actions: {
    clearActiveTeamData({ commit }) {
      commit('setDailySyncs', {})
      commit('setEarliestDate', undefined)
      commit('setDailySyncsDataLoaded', false)
      commit('setBacklogLoaded', false)
    },
    async loadActiveTeamData({ dispatch }, params: { teamId: string }): Promise<void> {
      // fetch daily syncs for the past two weeks and tomorrow based on users current timezone (we fetch tomorrow in case team mates in other timezones are a day ahead)
      await Promise.all([
        dispatch('getDailySync', { teamId: params.teamId, startDate: formatAsDailySyncDate(daysFromNow(-14)), days: 15 }),
        dispatch('getBacklog', { teamId: params.teamId })
      ])
    },
    async getDailySync({ commit }, params: { teamId: string, startDate: string, days: number }): Promise<void> {
      try {
        const response = await ApiService.sendTrackedRequest(`teams/${params.teamId}/daily-sync/${params.startDate}?limit=${params.days}`, {
          method: 'GET',
          requiresAuth: true
        })

        commit('addDailySyncs', response.dailySyncs)
        commit('setEarliestDate', params.startDate)
        commit('setDailySyncsDataLoaded', true)
      } catch (error) {
        throw new Error('Could not retrieve daily syncs')
      }
    },
    async getBacklog({ commit }, params: { teamId: string }): Promise<void> {
      try {
        const response = await ApiService.sendTrackedRequest(`teams/${params.teamId}/backlog`, {
          method: 'GET',
          requiresAuth: true
        })
        commit('setBacklog', response)
        commit('setBacklogLoaded', true)
      } catch (error) {
        throw new Error('Could not retrieve backlog')
      }
    },
    async upsertDailySync({ commit }, params: { teamId: string, userId: string, date: string, request: UpsertDailySyncRequest }): Promise<void> {
      const dailySync = { teamId: params.teamId, userId: params.userId, date: params.date, ...params.request } as DailySync
      dailySync.lastUpdatedAt = params.request.lastUpdatedAt ?? DateTime.utc().toISO()

      const response = await ApiService.sendRequest(`teams/${params.teamId}/daily-sync/${params.date}?userId=${params.userId}`, {
        method: 'PUT',
        requiresAuth: true,
        body: JSON.stringify(params.request)
      })

      if (!response.ok) {
        throw new Error('Could not update daily sync')
      } else {
        commit('upsertDailySync', dailySync)
        MixPanelService.instance.onUpsertDailySync(dailySync)
      }
    },
    async upsertBacklog({ commit }, params: { teamId: string, tasks: DailySyncTask[] }): Promise<void> {
      commit('setBacklog', params.tasks)
      const response = await ApiService.sendRequest(`teams/${params.teamId}/backlog`, {
        method: 'PUT',
        requiresAuth: true,
        body: JSON.stringify({ tasks: params.tasks })
      })
      if (!response.ok) {
        throw new Error('Could not update backlog')
      }
    },
    async deleteDailySync({ commit }, params: { teamId: string, userId: string, date: string }): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/daily-sync/${params.date}`, {
        method: 'DELETE',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Could not delete daily sync')
      } else {
        commit('deleteDailySync', { userId: params.userId, date: params.date })
      }
    }
  }
}

export default dailySync
