import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "org-selector__team-info"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "org-selector__team-info"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "org-selector__dropdown" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 1,
  class: "org-selector__dropdown-message"
}
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAvatar = _resolveComponent("AppAvatar")!
  const _component_AppLogo = _resolveComponent("AppLogo")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppDropdown = _resolveComponent("AppDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["org-selector", [
    _ctx.minimized && 'org-selector--minimized',
    _ctx.theme && `org-selector--${_ctx.theme}`
  ]])
  }, [
    _createVNode(_component_AppDropdown, null, {
      handler: _withCtx((props) => [
        _createElementVNode("button", {
          class: "org-selector__handler",
          onClick: props.visibility.toggle
        }, [
          (_ctx.activeMembership)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_AppAvatar, {
                  rounded: "",
                  team: _ctx.activeMembership.teamProfile,
                  elevated: true
                }, null, 8, ["team"]),
                (!_ctx.minimized)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.activeMembership.teamProfile.name), 1))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_AppLogo, { class: "side-nav__logo" }),
                (!_ctx.minimized)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Cadrelo"))
                  : _createCommentVNode("", true)
              ])),
          (!_ctx.minimized)
            ? (_openBlock(), _createBlock(_component_AppIcon, {
                key: 2,
                name: _ctx.theme ? 'arrow-slim' : 'arrow'
              }, null, 8, ["name"]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_1)
      ]),
      default: _withCtx((props) => [
        _createElementVNode("ul", _hoisted_6, [
          (_ctx.teams.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.teams, (team) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass(["org-selector__dropdown-item", { 'org-selector__dropdown-item--selected': _ctx.activeMembership?.teamId === team.teamId }]),
                  key: team.teamId,
                  onClick: ($event: any) => (_ctx.setActiveTeam(team, props.visibility.toggle))
                }, [
                  _createVNode(_component_AppAvatar, {
                    rounded: "",
                    team: team.teamProfile,
                    elevated: true
                  }, null, 8, ["team"]),
                  _createElementVNode("span", null, _toDisplayString(team.teamProfile.name), 1)
                ], 10, _hoisted_7))
              }), 128))
            : (_openBlock(), _createElementBlock("p", _hoisted_8, " You haven't created your own workspace yet ")),
          _createElementVNode("button", {
            onClick: ($event: any) => (_ctx.handleCreateTeam(props.visibility.toggle))
          }, " Create new workspace ", 8, _hoisted_9)
        ])
      ]),
      _: 1
    })
  ], 2))
}