
import { useStore } from '@/store'
import { computed, defineComponent, onMounted, ref, watchEffect, watch } from 'vue'
import { useRoute } from 'vue-router'

import BodyLockService from '@/services/BodyLockService'

import AppOverlay from '@common/AppOverlay.vue'
import UserOnboardingCards from '@feature/user-onboarding/UserOnboardingCards.vue'

export default defineComponent({
  name: 'SharedUseOnboardingCards',
  components: {
    AppOverlay,
    UserOnboardingCards
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const isTeamInvite = ref(false)
    const requiresOnboarding = ref(false)
    const getUserProfile = computed(() => store.getters['user/getUserProfile'])
    const isAuthorized = computed(() => store.getters['user/isAuthorized'])
    const hasRequiredProfileData = computed(() => Boolean(getUserProfile.value?.firstName && getUserProfile.value?.lastName && getUserProfile.value?.location))

    function handleRequireOnBoarding() {
      if (!isAuthorized.value || route.name === 'Sign up') {
        requiresOnboarding.value = false
      } else if (!hasRequiredProfileData.value && isAuthorized.value && route.name !== 'Sign up') {
        requiresOnboarding.value = true
      }
    }

    async function handleTeamInvite() {
      // If the user followed an invitation link we will have stashed the invite code in localStorage
      if (localStorage.getItem('inviteDetails')) {
        if (!hasRequiredProfileData.value && isAuthorized.value && route.name !== 'Sign up') {
          requiresOnboarding.value = true
        } else {
          requiresOnboarding.value = false
        }
      } else {
        handleRequireOnBoarding()
      }
    }

    onMounted(async () => {
      if (getUserProfile.value?.firstName && getUserProfile.value?.lastName) {
        await handleTeamInvite()
      }
    })

    watchEffect(() => {
      if (!requiresOnboarding.value) {
        BodyLockService.unlock()
      } else {
        const teams = store.getters['user/getMemberships']
        isTeamInvite.value = teams.length === 1
        BodyLockService.lock()
      }
    })

    watch(route, () => {
      handleRequireOnBoarding()
    })

    return {
      isTeamInvite,
      requiresOnboarding
    }
  }
})
