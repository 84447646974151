<template>
  <svg
    class="app-icon"
    aria-hidden="true"
    :style="size ? `height: ${size}px; width: ${size}px;` : false"
  >
    <use :xlink:href="`#icon-${$props.name}`"></use>
  </svg>
</template>

<script>
const requireAll = (requireContext) => requireContext.keys().map(requireContext)
const req = require.context('../../assets/icons', false, /\.svg$/)
requireAll(req)

export default {
  name: 'AppIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: [Number, String],
      required: false
    }
  }
}
</script>

<style>
.app-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  overflow: hidden;
}
</style>
