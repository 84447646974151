<template>
  <nav
    class="main-navigation-mobile-menu"
    :class="{ 'main-navigation-mobile-menu--active': isMobileNavigationActive }"
  >
    <button
      @click="NavigationPanelHandlerService.toggle(NavigationPanel.MobilePanel)"
      class="main-navigation-mobile-menu__button"
    >
      <div />
      <div />
      <div />
    </button>

    <teleport to="#panels">
      <MobileNavigationTransition>
        <MobileNavigationPanel
          v-if="(isXSBreakpoint || isSMBreakpoint) && isMobileNavigationActive"
          @close-panel="NavigationPanelHandlerService.close()"
        />
      </MobileNavigationTransition>
    </teleport>
  </nav>
</template>

<script>
import { defineComponent, computed } from 'vue'
import NavigationPanelHandlerService from '@/services/NavigationPanelHandlerService'
import WindowEventsService, { CSS_BREAKPOINTS } from '@/services/WindowEventsService'
import MobileNavigationPanel from '@feature/main-navigation/MobileNavigationPanel.vue'
import { NavigationPanel } from '@/types/panel'

import MobileNavigationTransition from '@transition/MobileNavigationTransition'

export default defineComponent({
  name: 'MainNavigationMobileMenu',
  components: {
    MobileNavigationPanel,
    MobileNavigationTransition
  },
  setup() {
    const isXSBreakpoint = computed(() => WindowEventsService.matches(CSS_BREAKPOINTS.xs))
    const isSMBreakpoint = computed(() => WindowEventsService.matches(CSS_BREAKPOINTS.sm))
    const isMobileNavigationActive = computed(() => NavigationPanelHandlerService.isCurrentlyActive(NavigationPanel.MobilePanel))

    return {
      NavigationPanelHandlerService,
      NavigationPanel,
      isMobileNavigationActive,
      isXSBreakpoint,
      isSMBreakpoint

    }
  }
})
</script>

<style lang="scss">
.main-navigation-mobile-menu {
  $self: &;
  overflow: hidden;

  &__button {
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    appearance: none;
    background: none;
    border: none;
    width: 100%;
    max-width: 24px;
    min-height: 30px;
    cursor: pointer;

    @include breakpoint-up('md') {
      display: none;
    }

    > div {
      transition: all 200ms ease-in-out;
      width: 100%;
      height: 3px;
      background-color: var(--color-primary);
      border-radius: 24px;

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  &#{$self}--active {
    #{$self}__button {
      max-width: 19px;
      max-height: 19px;
      height: 20px;

      > div {
        transition: all 200ms ease-in-out;

        &:first-child {
          transform: rotate(45deg) translate(6px, 6px);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          transform: rotate(-45deg) translate(4px, -3.5px);
        }
      }
    }
  }
}
</style>
