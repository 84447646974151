
import { defineComponent, ref, computed } from 'vue'

import { QUIZ_CONTRIBUTION_PRESETS, QUIZ_CONTRIBUTION_STYLE_PICTURES } from '@/data/static'

import QuizService from '@/services/QuizService'

import AppInteractionButton from '@common/AppInteractionButton.vue'
import QuizSelectableButton from '@feature/quiz/QuizSelectableButton.vue'
import QuizCard from '@feature/quiz/QuizCard.vue'

export default defineComponent({
  name: 'QuizWorkStageCustomizer',
  emits: ['quiz-navigation:next', 'quiz-navigation:prev'],
  components: {
    AppInteractionButton,
    QuizSelectableButton,
    QuizCard
  },
  setup(_, { emit }) {
    const { contributionStyle } = QuizService.getWorkStageData()
    const hasSelectedPicture = ref(!!contributionStyle?.styleImageId || false)
    const isSelectingPicture = ref(false)
    const selectedPictureId = ref(parseInt(contributionStyle?.styleImageId) || 0)
    const selectedPictureObject = computed(() => {
      const pictureObject = QUIZ_CONTRIBUTION_STYLE_PICTURES.find((picture) => picture.id === selectedPictureId.value)
      return pictureObject
        ? { ...pictureObject, alt: 'User\'s contribution style image' }
        : undefined
    })

    function selectPicture(id: number) {
      selectedPictureId.value = id
      hasSelectedPicture.value = true
    }

    function navigateNext() {
      QuizService.save({
        styleImageId: selectedPictureId.value
      })

      QuizService.stop()
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')
      QuizService.prev()
    }

    return {
      QuizService,
      selectedPictureId,
      isSelectingPicture,
      navigateNext,
      navigatePrev,
      selectPicture,
      selectedPictureObject,
      hasSelectedPicture,
      QUIZ_CONTRIBUTION_PRESETS,
      QUIZ_CONTRIBUTION_STYLE_PICTURES
    }
  }
})
