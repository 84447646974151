import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "inline-comments" }
const _hoisted_2 = {
  key: 0,
  class: "inline-comments__show-more"
}
const _hoisted_3 = { class: "inline-comments__comment-list" }
const _hoisted_4 = { class: "comment-entry" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommentComponent = _resolveComponent("CommentComponent")!
  const _component_AppAvatar = _resolveComponent("AppAvatar")!
  const _component_AppTextEditor = _resolveComponent("AppTextEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.showHiddenComments && _ctx.hiddenCommentCount > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showHiddenComments = !_ctx.showHiddenComments))
          }, "View " + _toDisplayString(_ctx.hiddenCommentCount) + " more comments", 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.defaultVisibleComments, (comment) => {
        return (_openBlock(), _createElementBlock("div", {
          key: comment.id
        }, [
          _createVNode(_component_CommentComponent, {
            comment: comment,
            itemId: _ctx.itemId,
            itemType: _ctx.itemType,
            replyEditorOpen: _ctx.commentWithOpenReply === comment.id,
            "onUserAction:reply": _ctx.onReply,
            "onUserAction:cancelReply": _ctx.closeReply,
            "onUserAction:postReply": _ctx.onPostReply
          }, null, 8, ["comment", "itemId", "itemType", "replyEditorOpen", "onUserAction:reply", "onUserAction:cancelReply", "onUserAction:postReply"])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AppAvatar, {
        user: _ctx.currentUser.profile,
        size: "small"
      }, null, 8, ["user"]),
      (!_ctx.expandCommentInput)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.displayCommentInput && _ctx.displayCommentInput(...args))),
            class: "dummy-comment-input"
          }, "Write comment"))
        : _createCommentVNode("", true),
      _withDirectives(_createVNode(_component_AppTextEditor, {
        ref: "newCommentEditor",
        class: "comment-entry__editor",
        modelValue: _ctx.commentInput,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.commentInput) = $event)),
        placeholder: "Write a comment",
        borderless: !_ctx.expandCommentInput,
        "has-icons": _ctx.expandCommentInput,
        allowCancel: true,
        onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.commentWithOpenReply = undefined)),
        "onUserAction:post": _ctx.postComment,
        "onUserAction:cancel": _cache[4] || (_cache[4] = ($event: any) => (_ctx.commentInput = '', _ctx.expandCommentInput = false)),
        loading: _ctx.isSavingComment,
        emojiPickerExpandsUp: true
      }, null, 8, ["modelValue", "borderless", "has-icons", "onUserAction:post", "loading"]), [
        [_vShow, _ctx.expandCommentInput]
      ])
    ])
  ]))
}