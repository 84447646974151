<template>
  <div class="app-basic-dropdown">
    <div class="app-basic-dropdown__header" v-if="!!$slots.header">
      <slot name="header" />
    </div>

    <div class="app-basic-dropdown__items">
      <slot name="items" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppBasicDropdown'
}
</script>

<style lang="scss">
.app-basic-dropdown {
  $border-radius: 12px;
  border-radius: $border-radius;
  text-align: left;
  border: 1px solid var(--color-grey-200);
  background: var(--color-white);
  box-shadow: 0 15px 35px 0 rgba(0,0,0,0.1);
  min-width: 225px;
  z-index: 1;

  &__header {
    color: var(--color-basalt);
    border-radius: 12px 12px 0px 0px;
    font-weight: 600;
    border-bottom: 1px solid var(--color-grey-100);
    padding: 13px 22px 10px 26px;
    user-select: none;

    p {
      margin: 0;
    }
  }

  &__items {
    padding: 0 12px 12px 12px;
    border-radius: 0 0 12px 12px;

    a,
    button {
      color: var(--color-black);
      display: block;
      width: 100%;
      background: none;
      border: none;
      padding: 8px 0 8px 14px;
      margin-top: calc(4 * $sf);
      cursor: pointer;
      text-align: left;
      font-weight: 700;
      font-size: 16px;
      text-decoration: none;
      line-height: 22px;
      border: 2px solid transparent;
      border-radius: 5px;

      &:hover {
        color: var(--color-charoite);
      }

      &:focus {
        border: 2px solid var(--color-blue-200);
      }

      &:active {
        background: var(--color-blue-200);
      }
    }

    a + a {
      margin-top: $sf;
    }

    button {
      margin-top: $sf;
    }
  }
}
</style>
