
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'

/** Components */
import AppButtonMini from '@common/AppButtonMini.vue'

/** Filters / Helpers */
import leadingZero from '@/filters/leadingZero'

export default defineComponent({
  name: 'WidgetTimetracker',
  emits: ['timetracker:stop'],
  components: { AppButtonMini },
  setup() {
    const store = useStore()
    const trackingEntity = computed(() => store.getters['timetracker/getActiveEntity'])
    const trackingState = computed(() => store.getters['timetracker/getActiveTrackingStatus'])

    /**
     * Stops the timer from tracking
     */
    function stopTimer() {
      store.dispatch('timetracker/stopTracking')
    }

    return {
      trackingEntity,
      trackingState,
      leadingZero,
      stopTimer
    }
  }
})
