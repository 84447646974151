import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "name", "aria-labelledby", "value", "disabled"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("label", _mergeProps({
    class: ["app-checkbox", [
    _ctx.error && 'app-checkbox--error',
    _ctx.theme && `app-checkbox--${_ctx.theme}`,
    _ctx.variant && `app-checkbox--${_ctx.variant}`,
    _ctx.disabled && `app-checkbox--disabled`
  ]]
  }, _ctx.$attrs), [
    _withDirectives(_createElementVNode("input", {
      id: _ctx.id,
      name: _ctx.id,
      "aria-labelledby": _ctx.id ? `label-${_ctx.id}` : '',
      value: _ctx.value,
      disabled: _ctx.disabled,
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur', $event.target.value)))
    }, null, 40, _hoisted_1), [
      [_vModelCheckbox, _ctx.inputValue]
    ]),
    _createElementVNode("span", {
      id: _ctx.id ? `label-${_ctx.id}` : ''
    }, [
      _createVNode(_component_AppIcon, {
        name: _ctx.variant === _ctx.APP_CHECKBOX_VARIANTS.SMALL ? 'check-bold' : 'check',
        class: "app-checkbox__check-icon"
      }, null, 8, ["name"]),
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_2)
  ], 16))
}