<template>
  <QuizCard
    class="app-quiz-reaching-out"
    title="Best way to reach out to me"
    description="Rank order your preferred ways for others to reach you."
    @quiz-navigation:back="navigatePrev"
    @quiz-navigation:close="QuizService.close()"
    :is-vertically-aligned="true"
    :prev="!QuizService.editing.value"
  >
    <div class="app-quiz-reaching-out__divider">
      <AppSpacer>drag to prioritise top to bottom</AppSpacer>
    </div>

    <draggable
      :list="data"
      :animation="300"
      item-key="text"
      ghost-class="quiz-draggable-area-item--dragging"
      class="quiz-draggable-area"
      tag="ul"
      @start="isDragging=true"
      @end="isDragging=false"
    >
      <template #item="{ element }">
        <li class="quiz-draggable-area-item">
          <span class="quiz-draggable-area-item__emoji">{{ element.emoji }}</span>
          <span class="quiz-draggable-area-item__text">{{ element.text }}</span>
          <div
            class="quiz-draggable-area-item__handle"
            role="button"
          >
            <AppIcon name="drag-vertical-handle" />
          </div>
        </li>
      </template>
    </draggable>

    <template #actions>
      <AppButton @click="navigateNext" :loading="QuizService.isSaving()">
        Continue
      </AppButton>
    </template>
  </QuizCard>
</template>

<script>
import { defineComponent, ref } from 'vue'

import { QUIZ_REACHING_OUT_DATA } from '@/data/static'

import QuizService from '@/services/QuizService'

import AppSpacer from '@common/AppSpacer.vue'
import QuizCard from '@feature/quiz/QuizCard.vue'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'QuizAvailabilityStageReachingOut',
  components: {
    AppSpacer,
    QuizCard,
    draggable
  },
  setup(_, { emit }) {
    const { touchingBase } = QuizService.getAvailabilityStageData()
    const data = ref(QUIZ_REACHING_OUT_DATA)
    const isDragging = ref(false)

    if (touchingBase?.preferredContact) {
      data.value = data.value.sort((a, b) => touchingBase.preferredContact.indexOf(a.value) - touchingBase.preferredContact.indexOf(b.value))
    }

    function navigateNext() {
      emit('quiz-navigation:next')
      QuizService.save({ preferredContact: data.value.map((d) => d.value) })
      QuizService.next()
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')
      QuizService.prev()
    }

    return {
      data,
      isDragging,
      QuizService,
      navigatePrev,
      navigateNext
    }
  }
})
</script>

<style lang="scss">
.app-quiz-reaching-out {
  .quiz-card-header {
    margin-bottom: 0;
  }

  &__divider {
    margin-bottom: 24px;

    .app-spacer {
      margin-top: 0;
    }
  }

  .quiz-draggable-area {
    margin: 0;
    padding: 0;
  }

  .quiz-draggable-area-item {
    $self: &;
    position: relative;
    display: flex;
    align-items: center;
    background: var(--color-white);
    border: 1px solid var(--color-grey-200);
    border-radius: 5px;
    padding: 13px 18px;
    user-select: none;
    opacity: 1;
    transition: opacity 200ms ease-in-out;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &__emoji {
      font-size: 20px;
      line-height: 1;
      margin-right: 16px;
    }

    &__text {
      font-family: $gilroy;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
    }

    &__handle {
      position: absolute;
      right: 0;
      color: var(--color-primary);
      background: var(--color-white);
      padding: 10px;
      line-height: 1;
      border-left: 1px solid var(--color-grey-200);
      border-radius: 5px;
    }

    &#{$self}--dragging {
      opacity: 0.5;
      background: var(--color-grey-200);
      border: 1px solid var(--color-primary);
    }
  }
}
</style>
