import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditorContent = _resolveComponent("EditorContent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["daily-sync-task-editor", {
    'daily-sync-task-editor__focus': _ctx.isFocused,
    'daily-sync-task-editor__disabled': !_ctx.editable,
  }]),
    ref: "rootRef"
  }, [
    _createVNode(_component_EditorContent, {
      editor: _ctx.editor,
      ref: "editorContentRef"
    }, null, 8, ["editor"])
  ], 2))
}