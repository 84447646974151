
import { defineComponent, ref } from 'vue'

import QuizService from '@/services/QuizService'

import QuizCard from '@feature/quiz/QuizCard.vue'
import QuizSelectableButton from '@feature/quiz/QuizSelectableButton.vue'

enum MEETING_ARRANGEMENTS {
  CLUSTERED = 'Clustered',
  SPREAD = 'Spread'
}

export default defineComponent({
  name: 'QuizAvailabilityStageMeetingsArrangement',
  emits: ['quiz-navigation:next', 'quiz-navigation:prev'],
  components: {
    QuizCard,
    QuizSelectableButton
  },
  setup(_, { emit }) {
    const { touchingBase } = QuizService.getAvailabilityStageData()
    const selectedArrangement = ref(touchingBase?.meetingSpread || '')

    function navigateNext() {
      emit('quiz-navigation:next')
      QuizService.save({
        meetingSpread: selectedArrangement.value
      })
      QuizService.next()
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')
      QuizService.prev()
    }

    return {
      QuizService,
      selectedArrangement,
      MEETING_ARRANGEMENTS,
      navigateNext,
      navigatePrev
    }
  }
})
