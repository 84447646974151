
import { ref, defineComponent, PropType, nextTick } from 'vue'

import AppSlider from '@common/AppSlider.vue'
import Swiper from 'swiper'

export default defineComponent({
  name: 'AppTabs',
  components: {
    AppSlider
  },
  props: {
    tabs: {
      type: Array as PropType<string[]>,
      required: true
    },
    count: {
      type: Number,
      default: 0
    },
    elevated: {
      type: Boolean,
      required: false
    }
  },
  emits: ['user-action:click', 'active-slide-element'],
  setup(_, { emit }) {
    const currentTabIndex = ref(0)
    const swiper = ref<Swiper | null>(null)
    const left = ref(0)
    const width = ref(0)

    function setOffsetAndHeight() {
      const element = swiper.value?.slides[currentTabIndex.value]

      if (element instanceof HTMLElement) {
        left.value = element.offsetLeft
        width.value = element.clientWidth
      }
    }

    const setActiveTab = (id?: number, tab?: string) => {
      if (typeof id === 'number' && typeof tab === 'string') {
        currentTabIndex.value = id
        emit('user-action:click', tab)
      }

      nextTick(() => {
        setOffsetAndHeight()
      })
    }

    function onSwiperInit(swiperInstance: Swiper) {
      swiper.value = swiperInstance
      setOffsetAndHeight()
    }

    return {
      currentTabIndex,
      left,
      width,
      setActiveTab,
      onSwiperInit
    }
  }
})
