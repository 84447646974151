import { Auth } from 'aws-amplify'
import ApiServicePendingRequestTracker from './ApiServicePendingRequestTracker'

type RequestMethod = 'POST' | 'GET' | 'PATCH' | 'DELETE' | 'PUT'

export interface IApiRequest {
  method: RequestMethod,
  requiresAuth: boolean,
  body?: BodyInit
  abortController?: AbortController
}

class ApiService {
  private static instance: ApiService
  private apiPath: string
  private pendingRequestTracker: ApiServicePendingRequestTracker

  private constructor() {
    this.apiPath = process.env.VUE_APP_CADRELO_API_PATH as string
    this.pendingRequestTracker = new ApiServicePendingRequestTracker()
  }

  public static init(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService()
    }

    return ApiService.instance
  }

  public async sendRequest(endpoint: string, requestOptions: IApiRequest) {
    return await fetch(
      `${this.apiPath}/${endpoint}`,
      await this.constructRequest(requestOptions)
    )
  }

  /*
    This function differs from the sendRequest function in that it utilises `ApiServicePendingRequestTracker.ts` to track
    pending requests and avoid issuing duplicate requests to the backend API. This function is only suitable to be used for
    GET requests that return application/json. This function will return the result of calling await response.json() on the
    `Response` of the tracked request.
  */
  public async sendTrackedRequest(endpoint: string, requestOptions: IApiRequest) {
    const pendingRequest = this.pendingRequestTracker.findPendingRequest(endpoint)
    if (pendingRequest) {
      console.log(`Found a pending request to ${endpoint} - awaiting result of the pending request instead of issuing a new one`)
      return await pendingRequest
    } else {
      return await this.pendingRequestTracker.trackRequest(endpoint, this.sendRequest(endpoint, requestOptions))
    }
  }

  private async constructRequest({ method, requiresAuth, body, abortController }: IApiRequest): Promise<RequestInit> {
    const requestInit: RequestInit = {
      method,
      headers: await this.getHeaders(requiresAuth),
      body,
      keepalive: true
    }
    if (abortController) {
      requestInit.signal = abortController.signal
    }
    return requestInit
  }

  private async getJwt() {
    const currentSession = await Auth.currentSession()
    return currentSession.getIdToken().getJwtToken()
  }

  private async getHeaders(requiresAuth: boolean): Promise<HeadersInit> {
    const headers: HeadersInit = {
      'Content-Type': 'application/json'
    }

    if (requiresAuth) {
      headers.Authorization = `Bearer ${await this.getJwt()}`
    }

    return headers
  }
}

const instance = ApiService.init()
Object.freeze(instance)

export default instance
