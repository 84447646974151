
import { defineComponent, ref } from 'vue'

import QuizService from '@/services/QuizService'

import QuizCard from '@feature/quiz/QuizCard.vue'

export default defineComponent({
  name: 'QuizBasicStageDescription',
  emits: ['quiz-navigation:next', 'quiz-navigation:prev'],
  components: {
    QuizCard
  },
  setup(_, { emit }) {
    const { aboutMe } = QuizService.getBasicStageData()
    const nickname = ref('')
    const description = ref(aboutMe)

    function navigateNext() {
      emit('quiz-navigation:next')
      QuizService.save({ aboutMe: description.value, displayName: nickname.value })
      QuizService.next()
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')
      QuizService.prev()
    }

    return {
      QuizService,
      navigateNext,
      navigatePrev,
      description,
      nickname
    }
  }
})
