<template>
  <div class="app-loading-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="25 25 50 50"
      xml:space="preserve"
      fill="currentColor"
      :width="width"
      :height="height"
    >
      <path fill="currentColor"
        d="M73 50c0-12.7-10.3-23-23-23S27 37.3 27 50m3.9 0c0-10.5 8.5-19.1 19.1-19.1S69.1 39.5 69.1 50">
      </path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AppLoadingIcon',
  props: {
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>

<style lang="scss">
.app-loading-icon {
  color: var(--color-primary);

  svg {
    animation: spin 1s linear infinite;
  }
}
</style>
