import { computed, watch } from 'vue'
import { store } from '@/store'
import { Plan, Team } from '@/types/team'
import router from '@/router'
import { DateTime } from 'luxon'

const activeTeam = computed<Team>(() => store.getters['teams/getActiveTeam'])
const activeTeamMembers = computed(() => store.getters['teams/getActiveTeamMembers'])

export function subscriptionChecker(): void {
  watch([activeTeam, router.currentRoute], () => {
    if (router.currentRoute.value.meta.requiresSubscription && !isOnFreeTrial() && !isValidSubscription()) {
      router.replace({ path: 'subscriptions' })
    }
  })
}

export function isValidSubscription(): boolean {
  const subscription = activeTeam.value?.subscription
  if (subscription) {
    if (subscription.plan !== Plan.FREE && !subscription.paid) {
      return false
    }
    if (subscription.plan === Plan.FREE && activeTeamMembers.value.length > 5) {
      return false
    }
  } else if (activeTeamMembers.value.length > 5) {
    return false
  }
  return true
}

export function isPaidOrTrialSubscription(): boolean {
  const subscription = activeTeam.value?.subscription

  if (subscription) {
    if (isOnFreeTrial() || subscription.plan === Plan.TEAM) {
      return true
    } else {
      return false
    }
  } else if (activeTeamMembers.value.length > 5) {
    return false
  }
  return true
}

export function displayFreeTrialWarning(): boolean {
  const days = daysLeftInFreeTrial()
  return days <= 7 && days > 0
}

export function isOnFreeTrial(): boolean {
  return daysLeftInFreeTrial() > 0
}

export function daysLeftInFreeTrial(): number {
  const subscription = activeTeam.value?.subscription
  if (subscription?.freeTrialStart) {
    const start = DateTime.fromISO(subscription.freeTrialStart, { zone: 'utc' })
    return 14 - Math.floor(Math.abs(start.diffNow('days').days))
  }
  return 0
}
