
enum VARIANTS {
  NORMAL = 'normal',
  HAPPY = 'happy',
  SAD = 'sad',
  HOVER = 'hover'
}

export default {
  name: 'AppDre',
  props: {
    variant: {
      type: String,
      required: false,
      default: VARIANTS.NORMAL,
      validator: (value: VARIANTS): boolean => Object.values(VARIANTS).includes(value)
    },
    animate: {
      type: Boolean,
      default: false
    }
  }
}
