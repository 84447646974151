
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppDismissibleBanner',
  emits: ['user-action:update', 'user-action:dismiss'],
  props: {
    message: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    buttonText: {
      type: String,
      required: true
    }
  }
})
