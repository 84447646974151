
import { JiraMentionData } from '@/helpers/AppTextEditorJiraMention'
import { defineComponent, PropType, ref, watch } from 'vue'
export default defineComponent({
  props: {
    items: {
      type: Array as PropType<JiraMentionData[]>,
      required: true
    },
    command: {
      type: Function,
      required: true
    },
    isSearching: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const selectedIndex = ref(0)

    function onKeyDown({ event }: { event: KeyboardEvent }) {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }
      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }
      if (event.key === 'Enter') {
        enterHandler()
        return true
      }
      return false
    }

    function upHandler() {
      selectedIndex.value = ((selectedIndex.value + props.items.length) - 1) % props.items.length
    }

    function downHandler() {
      selectedIndex.value = (selectedIndex.value + 1) % props.items.length
    }

    function enterHandler() {
      selectItem(selectedIndex.value)
    }

    function selectItem(index: number) {
      const item = props.items[index]
      if (item) {
        props.command({ id: JSON.stringify({ key: item.key, summary: item.summary, issueUrl: item.issueUrl }) })
      }
    }

    watch(props.items, () => {
      selectedIndex.value = 0
    })
    return {
      selectedIndex,
      onKeyDown,
      selectItem
    }
  }
})
