declare const window: Record<string, unknown>

export default {
  mounted(el: HTMLElement, binding: { value: string }): void {
    if (binding.value !== undefined && (window.Cypress ||
      process.env.NODE_ENV === 'test')) {
      el.setAttribute('data-test', binding.value)
    }
  }
}
