import { Module } from 'vuex'
import ApiService from '@/services/ApiService'

import Comment from '@root/backend/src/common/entities/conversation/Comment'

import { ConversationState, RootState } from '@/types/store'
import Conversation, { AddCommentRequest, CommentType, EditCommentRequest, ItemId } from '@/types/comments'
import { MixPanelService } from '@/services/MixPanelService'
import { conversationBelongsToDreInsights, conversationBelongsToSocialConnect, conversationBelongsToSync } from '@/utils/conversationUtils'

const conversation: Module<ConversationState, RootState> = {
  namespaced: true,
  state: {
    commentsLoaded: false,
    comments: [] as Comment[],
    dailySyncConversations: [] as Conversation[],
    socialConnectConversations: [] as Conversation[],
    dreInsightsConversations: [] as Conversation[]
  },
  getters: {
    areCommentsLoaded: (state) => state.commentsLoaded,
    getComments: (state) => state.comments,
    getDailySyncConversation: (state) => (itemId: ItemId) => {
      return state.dailySyncConversations.find(e => conversationBelongsToSync(e, itemId))
    },
    getSocialConnectConversation: (state) => (itemId: ItemId) => {
      return state.socialConnectConversations.find(e => conversationBelongsToSocialConnect(e, itemId))
    },
    getDreInsightsConversation: (state) => (itemId: ItemId) => {
      return state.dreInsightsConversations.find(e => conversationBelongsToDreInsights(e, itemId))
    }
  },
  mutations: {
    setCommentsLoaded(state, value: boolean) {
      state.commentsLoaded = value
    },
    syncChangesForComments(state, comments: Comment[]) {
      state.comments = comments
    },
    upsertDailySyncConversation(state, conversation: Conversation) {
      state.dailySyncConversations = state.dailySyncConversations.filter(e => !conversationBelongsToSync(e, conversation.itemId))
      state.dailySyncConversations.push(conversation)
    },
    upsertSocialConnectConversation(state, conversation: Conversation) {
      state.socialConnectConversations = state.socialConnectConversations.filter(e => !conversationBelongsToSocialConnect(e, conversation.itemId))
      state.socialConnectConversations.push(conversation)
    },
    upsertDreInsightsConversation(state, conversation: Conversation) {
      state.dreInsightsConversations = state.dreInsightsConversations.filter(e => !conversationBelongsToDreInsights(e, conversation.itemId))
      state.dreInsightsConversations.push(conversation)
    }
  },
  actions: {
    async addComment({ commit }, params: { itemType: CommentType, request: AddCommentRequest }): Promise<void> {
      const response = await ApiService.sendRequest(`${params.itemType}/conversation/comments`, {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify(params.request)
      })

      if (!response.ok) {
        throw new Error('Could not add new comment')
      } else {
        const conversation = await response.json()
        commit('syncChangesForComments', conversation.comments)
        if (params.itemType === CommentType.DAILY_SYNC) {
          commit('upsertDailySyncConversation', conversation)
        }
        if (params.itemType === CommentType.SOCIAL_CONNECT) {
          commit('upsertSocialConnectConversation', conversation)
        }
        if (params.itemType === CommentType.DRE_INSIGHTS) {
          commit('upsertDreInsightsConversation', conversation)
        }
        MixPanelService.instance.onAddComment(params.itemType, params.request.content)
      }
    },
    async getDailySyncConversations({ commit }, params: { dailySyncItemIds: ItemId[] }): Promise<void> {
      const responses = await Promise.all(params.dailySyncItemIds.map(e =>
        ApiService.sendRequest(`daily-sync/conversation/${e.teamId}/${e.userId}/${e.date}`, {
          method: 'GET',
          requiresAuth: true
        })
      ))
      const conversations = await Promise.all(responses.filter(e => e.ok).map(e => e.json()))
      conversations.forEach(conversation => {
        commit('upsertDailySyncConversation', conversation)
      })
    },
    async getSocialConnectConversation({ commit }, itemId: ItemId): Promise<void> {
      const response = await ApiService.sendRequest(`social-connect/conversation/${itemId.teamId}/${itemId.date}`, {
        method: 'GET',
        requiresAuth: true
      })
      if (response.ok) {
        commit('upsertSocialConnectConversation', await response.json())
      }
    },
    async getDreInsightsConversation({ commit }, itemId: ItemId): Promise<void> {
      const response = await ApiService.sendRequest(`dre-insights/conversation/${itemId.teamId}/${itemId.cycleId}`, {
        method: 'GET',
        requiresAuth: true
      })
      if (response.ok) {
        commit('upsertDreInsightsConversation', await response.json())
      }
    },
    async getComments({ commit }, params: { itemType: CommentType, getCommentsRequest: ItemId }): Promise<void> {
      const request = params.getCommentsRequest
      let apiPath = `${params.itemType}/conversation/${request.teamId}/${request?.userId}/${request?.date}`

      if (params.itemType === CommentType.CHECK_IN) {
        apiPath = `${params.itemType}/conversation/${request.teamId}/${request.itemId}`
      }

      if (params.itemType === CommentType.GOAL) {
        apiPath = `${params.itemType}/conversation/${request.teamId}/${request.goalId}`
      }

      commit('setCommentsLoaded', false)
      const response = await ApiService.sendRequest(apiPath, {
        method: 'GET',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Could not load comments')
      } else {
        const conversation = await response.json()
        const sortedComments = conversation.comments.sort((a: Comment, b: Comment) => a.created.localeCompare(b.created))
        commit('setCommentsLoaded', true)
        commit('syncChangesForComments', sortedComments)
      }
    },
    async updateComment({ commit }, params: { itemType: CommentType, request: EditCommentRequest, commentId: string }): Promise<void> {
      const response = await ApiService.sendRequest(`conversation/comments/${params.commentId}?type=${params.itemType}`, {
        method: 'PUT',
        requiresAuth: true,
        body: JSON.stringify(params.request)
      })

      if (!response.ok) {
        throw new Error('Could not update comment')
      } else {
        const conversation = await response.json()
        commit('syncChangesForComments', conversation.comments)
        if (params.itemType === CommentType.DAILY_SYNC) {
          commit('upsertDailySyncConversation', conversation)
        }
        if (params.itemType === CommentType.SOCIAL_CONNECT) {
          commit('upsertSocialConnectConversation', conversation)
        }
        if (params.itemType === CommentType.DRE_INSIGHTS) {
          commit('upsertDreInsightsConversation', conversation)
        }
      }
    }
  }
}

export default conversation
