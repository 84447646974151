
import { computed, defineComponent, watch, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useStore } from '@/store'
import { DateTime } from 'luxon'
import { extractMentionedUsers } from '@/helpers/mentionUtils'

import { dateNow, timeDiffCalc } from '@/utils/calculateTimeDiff'
import DailySync, { DailySyncTask, UpsertDailySyncRequest } from '@/types/dailySync'
import { WorkDaySchedule } from '@/types/user'

import { getDailySyncForUser, getUserScheduleForDay } from '@/utils/dailySyncUtils'

import ModalHandlerService from '@/services/ModalHandlerService'
import ToastNotificationService from '@/services/ToastNotificationService'

import AppButtonMini from '@common/AppButtonMini.vue'
import WidgetTimetracker from './WidgetTimetracker.vue'
import { closeGoalPeekaboo } from '@/composables/sideBarManager'
import DailySyncOverlay from '@/components/feature/daily-sync/DailySyncOverlay.vue'

export default defineComponent({
  name: 'FocusWidget',
  components: {
    AppButtonMini,
    WidgetTimetracker,
    DailySyncOverlay
  },
  props: {
    minimized: {
      type: Boolean,
      required: false
    }
  },
  setup() {
    const DAY_IN_MILLISECONDS = 86400000
    const store = useStore()
    const route = useRoute()
    const today = new Date()
    const syncs = computed<Record<string, DailySync[]>>(() => store.getters['dailySync/getDailySyncs'])
    const currentDayOffset = computed(() => store.getters['dailySync/getSyncDayOffset'])
    const isDailySyncsEnabled = computed<boolean>(() => store.getters['teams/getActiveTeam']?.dailySyncEnabled)
    const user = computed(() => store.getters['user/getUser'])
    const dayBeingViewed = computed<string>(() => dateNow.value.toISODate())
    const dailySyncsForDayBeingViewed = computed(() => getDailySyncForUser(DateTime.fromISO(dayBeingViewed.value), user.value.userId))

    const isSyncOlderThanDay = computed(() => today.getTime() - new Date(dailySyncsForDayBeingViewed.value?.lastUpdatedAt as string).getTime() > DAY_IN_MILLISECONDS)
    const dailySyncs = computed(() => syncs.value[dayBeingViewed.value])
    const userDailySync = computed<DailySync | undefined>(() => dailySyncs.value?.find((e) => e.userId === user.value.userId))
    const userHasSubmittedSync = computed<boolean>(() => userDailySync.value?.draft === false)
    const dailySyncOverlayActive = computed(() => ModalHandlerService.isCurrentlyActive('daily-sync-overlay'))

    const activeTrackedTask = computed<DailySyncTask>(() => store.getters['timetracker/getActiveTask'])
    const isTrackerRunning = computed<boolean>(() => store.getters['timetracker/getActiveTrackingStatus'])

    const userSchedule = ref<WorkDaySchedule | undefined>(getUserScheduleForDay(user.value?.userId, userDailySync.value?.date as string))

    function openSyncOverlay() {
      if (!dailySyncOverlayActive.value) {
        ModalHandlerService.open('daily-sync-overlay', {
          theme: 'transparent',
          variant: 'sync-overlay'
        })
        closeGoalPeekaboo()
      } else {
        ModalHandlerService.close()
      }
    }

    watch(
      () => route.fullPath,
      async () => {
        ModalHandlerService.close()
      }
    )

    async function updateDaily({ start, end }: { start: string, end: string }) {
      if (typeof start === 'undefined' || typeof end === 'undefined') return

      const tasks = userDailySync.value?.tasks ?? []
      try {
        const request = new UpsertDailySyncRequest(
          tasks.map((task) => {
            if (task.id === activeTrackedTask.value?.id) {
              if (task.timeblocks) {
                task.timeblocks.push({ start, end })
              } else {
                task.timeblocks = [{ start, end }]
              }
            }

            return task
          }),
          [...extractMentionedUsers(userDailySync.value?.message), ...tasks.flatMap(e => extractMentionedUsers(e.text))],
          userDailySync.value?.message ?? '',
          userDailySync.value?.feelingEmoji ?? '',
          userSchedule.value?.works ? userSchedule.value : undefined,
          userDailySync.value?.reactions ?? [],
          DateTime.utc().toISO(),
          userDailySync.value?.lastCommentTime ?? '',
          false
        )
        const teamId = store.getters['user/getActiveMembership']?.teamId
        await store.dispatch('dailySync/upsertDailySync', { teamId, userId: user.value.userId, date: userDailySync.value?.date, request })
      } catch (error) {
        if (error instanceof Error) {
          ToastNotificationService.push({ type: 'error', message: error.message })
        }
      }
    }

    return {
      today,
      isSyncOlderThanDay,
      dailySyncsForDayBeingViewed,
      userHasSubmittedSync,
      userDailySync,
      isDailySyncsEnabled,
      dayBeingViewed,
      dailySyncOverlayActive,
      activeTrackedTask,
      isTrackerRunning,
      timeDiffCalc,
      openSyncOverlay,
      updateDaily
    }
  }
})
