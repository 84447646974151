import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d2a79b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "app-avatar__picture",
  key: "app-avatar-picture"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: "app-avatar__initials app-avatar__initials--unassigned",
  key: "app-avatar-unassigned"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_FadeTransition = _resolveComponent("FadeTransition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-avatar", [
    _ctx.size && `app-avatar--${_ctx.size}`,
    _ctx.rounded && 'app-avatar--rounded',
    _ctx.isAvatarLoaded && 'app-avatar--loaded',
    _ctx.elevated && 'app-avatar--elevated'
  ]])
  }, [
    _createVNode(_component_FadeTransition, { isGroup: "" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            src: `https://content${_ctx.env}.cadrelo.com/${_ctx.dynamicPictureID}_${_ctx.pictureSize}`,
            alt: "",
            onLoad: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isAvatarLoaded = true)),
            onError: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isAvatarLoaded = false))
          }, null, 40, _hoisted_2)
        ], 512), [
          [_vShow, _ctx.isAvatarLoaded && _ctx.dynamicPictureID]
        ]),
        _withDirectives(_createElementVNode("div", {
          class: "app-avatar__initials",
          key: "app-avatar-initials"
        }, _toDisplayString(_ctx.initials), 513), [
          [_vShow, _ctx.initials && (!_ctx.dynamicPictureID && !_ctx.isAvatarLoaded)]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AppIcon, {
            name: "user-circle",
            size: "11"
          })
        ], 512), [
          [_vShow, (!_ctx.user && _ctx.showIfEmpty)]
        ])
      ]),
      _: 1
    })
  ], 2))
}