import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "update-banner" }
const _hoisted_2 = { class: "update-banner__text-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "update-banner__message"
}
const _hoisted_4 = {
  key: 1,
  href: "https://cadrelo.notion.site/cadrelo/Cadrelo-Release-notes-a32bb29485964921b81940ec1b74773c",
  target: "_blank",
  class: "update-banner__subtitle"
}
const _hoisted_5 = { class: "update-banner__buttons-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.message)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.message), 1))
        : _createCommentVNode("", true),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("a", _hoisted_4, _toDisplayString(_ctx.subtitle), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "update-banner__update-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('user-action:update')))
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.buttonText), 1)
      ]),
      _createElementVNode("button", {
        class: "update-banner__close-button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('user-action:dismiss')))
      }, [
        _createVNode(_component_AppIcon, {
          name: "close",
          size: 12
        })
      ])
    ])
  ]))
}