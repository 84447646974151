import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quiz-availability-stage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuizAvailabilityStageIntro = _resolveComponent("QuizAvailabilityStageIntro")!
  const _component_QuizAvailabilityStageMeetingsTOD = _resolveComponent("QuizAvailabilityStageMeetingsTOD")!
  const _component_QuizAvailabilityStageMeetingsArrangement = _resolveComponent("QuizAvailabilityStageMeetingsArrangement")!
  const _component_QuizAvailabilityStageReachingOut = _resolveComponent("QuizAvailabilityStageReachingOut")!
  const _component_QuizAvailabilityStageWorkingHours = _resolveComponent("QuizAvailabilityStageWorkingHours")!
  const _component_QuizGroupNavigationTransition = _resolveComponent("QuizGroupNavigationTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_QuizGroupNavigationTransition, {
      direction: _ctx.isNavigationDirectionForward ? 'forward' : 'backward'
    }, {
      default: _withCtx(() => [
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.AVAILABILITY, _ctx.QUIZ_AVAILABILITY_STAGE_DEFINITION.INTRO))
          ? (_openBlock(), _createBlock(_component_QuizAvailabilityStageIntro, {
              key: 0,
              "onQuizNavigation:next": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isNavigationDirectionForward = true))
            }))
          : _createCommentVNode("", true),
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.AVAILABILITY, _ctx.QUIZ_AVAILABILITY_STAGE_DEFINITION.MEETINGS_TIME_OF_THE_DAY))
          ? (_openBlock(), _createBlock(_component_QuizAvailabilityStageMeetingsTOD, {
              key: 1,
              "onQuizNavigation:next": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isNavigationDirectionForward = true)),
              "onQuizNavigation:prev": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isNavigationDirectionForward = false))
            }))
          : _createCommentVNode("", true),
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.AVAILABILITY, _ctx.QUIZ_AVAILABILITY_STAGE_DEFINITION.MEETINGS_ARRANGEMENT))
          ? (_openBlock(), _createBlock(_component_QuizAvailabilityStageMeetingsArrangement, {
              key: 2,
              "onQuizNavigation:next": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isNavigationDirectionForward = true)),
              "onQuizNavigation:prev": _cache[4] || (_cache[4] = ($event: any) => (_ctx.isNavigationDirectionForward = false))
            }))
          : _createCommentVNode("", true),
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.AVAILABILITY, _ctx.QUIZ_AVAILABILITY_STAGE_DEFINITION.REACHING_OUT))
          ? (_openBlock(), _createBlock(_component_QuizAvailabilityStageReachingOut, {
              key: 3,
              "onQuizNavigation:next": _cache[5] || (_cache[5] = ($event: any) => (_ctx.isNavigationDirectionForward = true)),
              "onQuizNavigation:prev": _cache[6] || (_cache[6] = ($event: any) => (_ctx.isNavigationDirectionForward = false))
            }))
          : _createCommentVNode("", true),
        (_ctx.QuizService.isCurrentlyDisplayed(_ctx.QUIZ_STAGE_DEFINITION.AVAILABILITY, _ctx.QUIZ_AVAILABILITY_STAGE_DEFINITION.WORKING_HOURS))
          ? (_openBlock(), _createBlock(_component_QuizAvailabilityStageWorkingHours, {
              key: 4,
              "onQuizNavigation:next": _cache[7] || (_cache[7] = ($event: any) => (_ctx.isNavigationDirectionForward = true)),
              "onQuizNavigation:prev": _cache[8] || (_cache[8] = ($event: any) => (_ctx.isNavigationDirectionForward = false))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["direction"])
  ]))
}