
import { defineComponent, ref } from 'vue'
import AppMobileHeader from '@/components/core/AppMobileHeader.vue'
import AppMobileFooter from '@/components/core/AppMobileFooter.vue'
import SideNavigation from '@/components/core/SideNavigation.vue'
import DreTray from '@/components/core/DreTray.vue'
import FreeTrial from '@/components/core/FreeTrial.vue'
import { dreTrayOpen, peekabooOpen } from '@/utils/peekabooUtils'

export default defineComponent({
  name: 'MainLayout',
  components: {
    AppMobileHeader,
    AppMobileFooter,
    SideNavigation,
    DreTray,
    FreeTrial
  },
  setup() {
    const isPanelMaximized = ref(true)
    const resizePanelAction = (maximized: boolean) => {
      isPanelMaximized.value = maximized
    }

    function openDreTray() {
      dreTrayOpen.value = true
    }

    return {
      isPanelMaximized,
      resizePanelAction,
      openDreTray,
      peekabooOpen
    }
  }
})
