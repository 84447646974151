import { SUPPORTED_GOAL_STATUSES } from '@/data/objectives'
import CheckIn from '@/types/checkin'
import CheckInItem, { CheckInItemStatus, UpdateCheckInItemRequest } from '@/types/checkinItem'
import { CommentType } from '@/types/comments'
import DailySync, { DailySyncTaskCategory } from '@/types/dailySync'
import { Membership } from '@/types/membership'
import { Goal, Objective } from '@/types/objective'
import { UpdateSocialConnectRequest } from '@/types/socialConnect'
import { Team, TeamValue } from '@/types/team'
import { User, UserProfile, UserQuiz } from '@/types/user'
import mixpanel from 'mixpanel-browser'

const env = process.env.VUE_APP_DOMAIN === 'production' ? '' : `.${process.env.VUE_APP_DOMAIN}`
const token = process.env.VUE_APP_DOMAIN === 'production' ? 'b5588e9ad21b5ff58ac122bd470cb087' : '8fd850af1e13c75ad3af5de620dc0f38'

export class MixPanelService {
    public static instance = new MixPanelService()

    private constructor() {
      mixpanel.init(token)
    }

    public onSignIn(user: User, activeMembership?: Membership): void {
      if (activeMembership) {
        this.registerActiveTeam(activeMembership)
      }
      mixpanel.identify(user.userId)
      mixpanel.register({
        email: user.email
      })
      mixpanel.track('Signed In')
      mixpanel.people.set({
        $email: user.email,
        $created: user.createdAt,
        Verified: user.verified,
        ...this.getUserProfileUpdates(user.profile)
      })
    }

    public registerActiveTeam(membership: Membership): void {
      mixpanel.set_group('teamId', membership.teamId)
      mixpanel.get_group('teamId', membership.teamId).set({
        $name: membership.teamProfile.name,
        industry: membership.teamProfile.industry
      })
    }

    public onProfileUpdate(original: UserProfile, update: UserProfile): void {
      if (!original.pictureId && update.pictureId) {
        mixpanel.track('Added Profile Picture')
      } else if (!original.location && update.location) {
        mixpanel.track('Completed Onboarding')
      } else {
        mixpanel.track('Updated Profile')
      }
      mixpanel.people.set(this.getUserProfileUpdates(update))
    }

    public onQuizUpdate(update: UserQuiz, original?: UserQuiz): void {
      if (!original?.touchingBase && update.touchingBase) {
        mixpanel.track('Started Profile Quiz')
      } else if (!original?.greatDebate && update.greatDebate) {
        mixpanel.track('Completed Profile Quiz')
      } else if (!original?.contributionStyle && update.contributionStyle) {
        mixpanel.track('Completed Contribution Style')
      } else {
        mixpanel.track('Updated Quiz Profile')
      }
    }

    public onCreateTeam(team: Team): void {
      mixpanel.track('Created Team', this.getTeamUpdates(team))
    }

    public onUpdateTeam(team: Team): void {
      mixpanel.track('Updated Team', this.getTeamUpdates(team))
    }

    public onUpdateTeamValues(values: TeamValue[]): void {
      mixpanel.track('Updated Team Values', { values: values.map(e => e.title) })
    }

    public onDeleteTeam(): void {
      mixpanel.track('Deleted Team')
    }

    public onSendTeamInvites(users: string[], resend: boolean): void {
      users.forEach(e => {
        mixpanel.track('Sent Invite', { invitedUser: e, resend })
      })
    }

    public onJoinedTeam(teamId: string): void {
      mixpanel.track('Joined Team', { teamId })
    }

    public onUpsertDailySync(dailySync: DailySync): void {
      mixpanel.track('Upsert Daily Sync', {
        date: dailySync.date,
        itemCount: dailySync.tasks?.length ?? 0,
        completedCount: dailySync.tasks?.filter(e => e.completed).length ?? 0,
        tasks: dailySync.tasks?.filter(e => e.category === DailySyncTaskCategory.TASK).map(e => e.text),
        blockers: dailySync.tasks?.filter(e => e.category === DailySyncTaskCategory.BLOCKED).map(e => e.text),
        discussions: dailySync.tasks?.filter(e => e.category === DailySyncTaskCategory.DISCUSS).map(e => e.text),
        feedback: dailySync.tasks?.filter(e => e.category === DailySyncTaskCategory.FEEDBACK).map(e => e.text),
        mood: dailySync.feelingEmoji,
        teamTalk: dailySync.message,
        mentions: dailySync.mentionedUsers
      })
    }

    public onAddComment(itemType: CommentType, comment?: string): void {
      switch (itemType) {
        case CommentType.DAILY_SYNC: mixpanel.track('Post Comment - Daily Sync', { comment }); break
        case CommentType.CHECK_IN: mixpanel.track('Post Comment - Pulse Note', { comment }); break
        case CommentType.GOAL: mixpanel.track('Post Comment - Goal', { comment }); break
        case CommentType.SOCIAL_CONNECT: mixpanel.track('Post Comment - Social Connect', { comment }); break
        case CommentType.DRE_INSIGHTS: mixpanel.track('Post Comment - Dre Insights', { comment }); break
      }
    }

    public onCreateCheckIn(checkIn: CheckIn): void {
      mixpanel.track('Created Pulse Board', { cycle: checkIn.id, categories: checkIn.categories[0].label, daysInCycle: checkIn.daysInCycle })
    }

    public onCloseCheckIn(cycle: number): void {
      mixpanel.track('Closed Pulse Board', { cycle })
    }

    public onCreateCheckInItem(checkInItem: CheckInItem): void {
      mixpanel.track('Created Pulse Note', { cycle: checkInItem.id, itemId: checkInItem.id, sentiment: checkInItem.category.sentiment, category: checkInItem.category.label, description: checkInItem.description, themes: checkInItem.themes })
    }

    public onUpdateCheckInItem(itemId: number, updates: UpdateCheckInItemRequest): void {
      if (updates.themes) {
        mixpanel.track('Updated Pulse Note - Themes', { itemId, themes: updates.themes })
      }
      if (updates.upvoteUsers?.length) {
        mixpanel.track('Updated Pulse Note - Votes', { itemId, votes: updates.upvoteUsers.length })
      }
      if (updates.status === CheckInItemStatus.ActionRequired) {
        mixpanel.track('Updated Pulse Note - Action Required', { itemId })
      }
      if (updates.status === CheckInItemStatus.Resolved) {
        mixpanel.track('Updated Pulse Note - Resolved', { itemId })
      }
      if (updates.assignees?.length) {
        mixpanel.track('Updated Pulse Note - Assignees', { itemId, assignees: updates.assignees.length })
      }
    }

    public onAddObjective(objective: Objective): void {
      mixpanel.track('Created Objective', { objectiveId: objective.id, title: objective.title, status: objective.status })
    }

    public onUpdateObjective(objective: Objective): void {
      mixpanel.track('Updated Objective', { objectiveId: objective.id, title: objective.title, status: objective.status })
    }

    public onAddGoal(goal: Goal): void {
      mixpanel.track('Created Goal', { goalId: goal.id, title: goal.title, status: goal.status, completionPercentage: goal.completionPercentage, dueDate: goal.dueDate })
    }

    public onUpdateGoal(goalId?: number, status?: SUPPORTED_GOAL_STATUSES, completionPercentage?: number): void {
      if (status) {
        mixpanel.track('Updated Goal - Status', { goalId, status })
      }
      if (completionPercentage) {
        mixpanel.track('Updated Goal - Completion Percentage', { goalId, completionPercentage })
      }
    }

    public onUpdateSocialConnect(updates: UpdateSocialConnectRequest): void {
      mixpanel.track('Update Social Connect', {
        content: updates.content,
        reactions: updates.reactions
      })
    }

    public onAddDreInsightsResponse(cycleId: number, questionId: number, score: number): void {
      mixpanel.track('Dre Insights Response', {
        cycleId,
        questionId,
        score
      })
    }

    private getUserProfileUpdates(profile: UserProfile) {
      return {
        $avatar: profile.pictureId ? `https://content${env}.cadrelo.com/${profile.pictureId}_small` : undefined,
        $name: `${profile.firstName} ${profile.lastName}`,
        $first_name: profile.firstName,
        $last_name: profile.lastName,
        jobTitle: profile.jobTitle,
        $country_code: profile.location?.country,
        $city: profile.location?.city,
        $timezone: profile.location?.timezone
      }
    }

    private getTeamUpdates(team: Team) {
      return {
        teamId: team.teamId,
        name: team.profile.name,
        industry: team.profile.industry
      }
    }
}
