
import { computed, defineComponent, ref } from 'vue'
import { USER_ONBOARDING_CARDS } from '@/data/static'
import { useStore } from '@/store'

import AppSlider from '@common/AppSlider.vue'
import Swiper from 'swiper/types/swiper-class'
import UserOnBoardingTextContentStructure from './UserOnBoardingTextContentStructure.vue'
import UserOnboardingCreateProfile from './UserOnboardingCreateProfile.vue'
import UserOnboardingCreateOrg from './UserOnboardingCreateOrg.vue'

import ModalHandlerService from '@/services/ModalHandlerService'

export default defineComponent({
  name: 'UserOnboardingCards',
  emits: ['onboarding-flow-finished'],
  components: {
    AppSlider,
    UserOnBoardingTextContentStructure,
    UserOnboardingCreateProfile,
    UserOnboardingCreateOrg
  },
  props: {
    isTeamInvite: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const swiper = ref<Swiper | null>()
    const activeMembershipTeamName = computed(() => store.getters['user/getActiveMembership']?.teamProfile.name)
    const invitedByTeamDescription = computed(() => `You have joined ${activeMembershipTeamName.value}. Let’s fill in some basic details so we can personalise your experience.`)

    function slideNext() {
      if (swiper.value) {
        swiper.value.allowSlideNext = true
        swiper.value.slideNext()
      }
    }

    function handleCreateProfile() {
      if (props.isTeamInvite) {
        emit('onboarding-flow-finished')
      } else {
        slideNext()
      }
    }

    function onSwiperInit(swiperInstance: Swiper) {
      swiper.value = swiperInstance
      swiper.value.detachEvents()
    }

    return {
      USER_ONBOARDING_CARDS,
      swiper,
      ModalHandlerService,
      activeMembershipTeamName,
      invitedByTeamDescription,
      onSwiperInit,
      handleCreateProfile
    }
  }
})
