
import { Membership } from '@/types/membership'
import { defineComponent, PropType, ref, watch } from 'vue'
import AppAvatar from '@common/AppAvatar.vue'
export default defineComponent({
  components: {
    AppAvatar
  },
  props: {
    items: {
      type: Array as PropType<Membership[]>,
      required: true
    },
    command: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const selectedIndex = ref(0)

    function onKeyDown({ event }: { event: KeyboardEvent }) {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }
      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }
      if (event.key === 'Enter') {
        enterHandler()
        return true
      }
      return false
    }

    function upHandler() {
      selectedIndex.value = ((selectedIndex.value + props.items.length) - 1) % props.items.length
    }

    function downHandler() {
      selectedIndex.value = (selectedIndex.value + 1) % props.items.length
    }

    function enterHandler() {
      selectItem(selectedIndex.value)
    }

    function selectItem(index: number) {
      const item = props.items[index]
      if (item) {
        props.command({ id: item })
      }
    }

    watch(props.items, () => {
      selectedIndex.value = 0
    })
    return {
      selectedIndex,
      onKeyDown,
      selectItem
    }
  }
})
