
import { ref, defineComponent, DefineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { SuccessMessages, ErrorMessages } from '@/data/messages'
import BodyLockService from '@/services/BodyLockService'
import ImageUploadService from '@/services/ImageUploadService'
import ModalHandlerService from '@/services/ModalHandlerService'
import ToastNotificationService from '@/services/ToastNotificationService'
import AppRangeSlider from '@common/AppRangeSlider.vue'
import Modal from '@common/Modal.vue'
import { Cropper, CircleStencil, RectangleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { Membership } from '@/types/membership'
export default defineComponent({
  name: 'SharedProfilePictureCrop',
  components: {
    Modal,
    Cropper,
    AppRangeSlider
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Change profile picture'
    },
    defaultAvatar: {
      type: String,
      default: require('@/assets/images/svg/avatar-placeholder.svg?data')
    },
    isTeamAvatar: {
      type: Boolean,
      default: false
    },
    isNewUser: {
      type: Boolean,
      default: false
    },
    isInQueue: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()
    const newPictureURL = ImageUploadService.getUrlData()
    const fileBase64 = computed(() => ImageUploadService.getSelectedFileResult())
    const userProfile = computed(() => store.getters['user/getUserProfile'])
    const activeMembership = computed<Membership>(() => store.getters['user/getActiveMembership'])
    const dynamicPicture = computed(() => {
      if (props.isTeamAvatar) {
        if (activeMembership.value?.teamProfile?.pictureId && !props.isInQueue) {
          return activeMembership.value.teamProfile.pictureId
        } else if (newPictureURL.value?.pictureId) {
          return newPictureURL.value?.pictureId
        }
      }
      return userProfile.value.pictureId
    })

    const env = process.env.VUE_APP_DOMAIN === 'production' ? '' : `.${process.env.VUE_APP_DOMAIN}`
    const uploading = ref(false)
    const cropper = ref<DefineComponent>()
    const zoom = ref(0)

    async function handlePictureUpload() {
      const cropperData = cropper.value?.getResult()
      if (cropperData) {
        uploading.value = true
        try {
          await ImageUploadService.upload(cropperData, props.isTeamAvatar, props.isNewUser)
          await new Promise((resolve, reject) => {
            const img = new Image()
            img.src = `https://content${env}.cadrelo.com/${dynamicPicture.value}_large`
            img.onload = resolve
            img.onerror = reject
          })
          if (props.isInQueue) {
            ModalHandlerService.queue('create-team')
          }
          ModalHandlerService.close()
          ToastNotificationService.push({
            type: 'info',
            message: props.isTeamAvatar ? SuccessMessages.ORGANISATION_PICTURE_UPDATED : SuccessMessages.ACCOUNT_PICTURE_UPDATED
          })
        } catch (error) {
          ModalHandlerService.close()
          if (!props.isNewUser) {
            ToastNotificationService.push({ type: 'error', message: error instanceof Error ? error.message : ErrorMessages.UNSUCCESSFUL_PICTURE_UPLOAD })
          }
        } finally {
          BodyLockService.unlock()
          uploading.value = false
        }
      } else {
        ToastNotificationService.push({ type: 'error', message: ErrorMessages.UNSUCCESSFUL_CROP })
      }
    }

    function onZoom(value: string) {
      if (cropper.value) {
        cropper.value.zoom(
          1 + parseFloat(value) - zoom.value
        )
        zoom.value = parseFloat(value)
        const minWidth = cropper.value.sizeRestrictions.minWidth
        const imageWidth = cropper.value.imageSize.width
        cropper.value.zoom(
          (imageWidth - zoom.value * (imageWidth - minWidth)) /
            (imageWidth - parseFloat(value) * (imageWidth - minWidth))
        )
      }
    }
    function onChange() {
      if (cropper.value) {
        const { coordinates, imageSize } = cropper.value
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          zoom.value =
            (cropper.value.imageSize.height - cropper.value.coordinates.height) /
              (cropper.value.imageSize.height - cropper.value.sizeRestrictions.minHeight)
        } else {
          zoom.value =
            (cropper.value.imageSize.width - cropper.value.coordinates.width) /
              (cropper.value.imageSize.width - cropper.value.sizeRestrictions.minWidth)
        }
      }
    }
    function defaultSize(cropper: typeof Cropper) {
      return {
        width: Math.min(cropper.imageSize.height, cropper.imageSize.width),
        height: Math.min(cropper.imageSize.height, cropper.imageSize.width)
      }
    }
    function stencilSize(cropper: typeof Cropper) {
      return {
        width: Math.min(cropper.boundaries.height, cropper.boundaries.width) - 48,
        height: Math.min(cropper.boundaries.height, cropper.boundaries.width) - 48
      }
    }
    function cancelUpload() {
      if (uploading.value) {
        ImageUploadService.cancelUploadRequest()
        uploading.value = false
        ImageUploadService.resetProgress()
      }
      if (props.isInQueue) {
        ModalHandlerService.queue('create-team')
      }
      ModalHandlerService.close()
    }
    return {
      env,
      userProfile,
      uploading,
      cropper,
      fileBase64,
      ImageUploadService,
      zoom,
      handlePictureUpload,
      onZoom,
      onChange,
      CircleStencil,
      RectangleStencil,
      defaultSize,
      stencilSize,
      cancelUpload
    }
  }
})
