
import AppLayout from '@/layouts/AppLayout.vue'
import SharedComponentsFacility from '@shared/SharedComponentsFacility.vue'
import { defineComponent } from 'vue'

import { useStore } from '@/store'

import WindowEventsService, { WINDOW_EVENTS } from '@/services/WindowEventsService'
import NavigationPanelHandlerService from '@/services/NavigationPanelHandlerService'
import { subscriptionChecker } from '@/services/SubscriptionService'

export default defineComponent({
  components: {
    AppLayout,
    SharedComponentsFacility
  },
  setup() {
    WindowEventsService.on(WINDOW_EVENTS.RESIZE, () => {
      const isNavigationPanelActive = !!NavigationPanelHandlerService.getCurrentlyActive()

      if (isNavigationPanelActive) {
        NavigationPanelHandlerService.close()
      }
    })

    // eslint-disable-next-line
    // @ts-ignore
    if (window.Cypress) {
      // eslint-disable-next-line
      // @ts-ignore
      window.$store = useStore()
    }

    subscriptionChecker()

    return {}
  }
})
