import { App } from 'vue'

const registerGlobalComponents = (app: App<Element>): void => {
  const requireComponent = require.context('./components/global', false, /App[A-Z]\w+\.vue$/)

  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName)
    const componentName = fileName.split('/').pop()?.replace(/\.\w+$/, '') as string

    app.component(componentName, componentConfig.default || componentConfig)
  })
}

const registerCustomDirectives = (app: App<Element>): void => {
  const requireDirective = require.context('./directives', false, /\w\S*\.ts$/)

  requireDirective.keys().forEach((fileName) => {
    const directive = requireDirective(fileName)
    const directiveName = fileName.split('/').pop()?.replace(/\.\w+$/, '') as string

    app.directive(directiveName, directive.default)
  })
}

export default {
  registerCustomDirectives,
  registerGlobalComponents
}
