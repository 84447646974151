
import { defineComponent, PropType, reactive, ref, toRefs } from 'vue'
import { useStore } from '@/store'
import { AVAILABLE_ORG_INDUSTRIES, AVAILABLE_TEAM_SIZES, AVAILABLE_USER_ROLES } from '@/data/static'
import { TeamProfile } from '@/types/team'
import { UserOnBoardingCard } from '@/types/quiz'
import ModalHandlerService from '@/services/ModalHandlerService'
import { IFormErrors } from '@/types/global'
import FormValidationService, { shallowValidate } from '@/services/FormValidationService'
import isEmpty from '@/helpers/empty'
import ToastNotificationService from '@/services/ToastNotificationService'

import AppDropdown from '@common/AppDropdown.vue'
import AccountPictureUpload from '@/components/feature/user-settings/personal/AccountPictureUpload.vue'
import ImageUploadService from '@/services/ImageUploadService'

export default defineComponent({
  name: 'UserOnboardingCreateOrg',
  components: {
    AccountPictureUpload,
    AppDropdown
  },
  props: {
    data: {
      type: Object as PropType<UserOnBoardingCard>,
      required: true
    }
  },
  emits: ['navigate:next', 'navigate:skip'],
  setup(_, { emit }) {
    const store = useStore()
    const newPictureURL = ImageUploadService.getUrlData()

    const fields = reactive({
      teamName: '',
      teamType: '',
      teamSize: '',
      role: ''
    })

    const buttonIsDisabled = ref(true)
    const loading = ref(false)
    const teamDisplayName = ref(fields.teamName)

    const errors = reactive<IFormErrors>({
      fieldEmptyErrors: {},
      specialErrors: {}
    })

    async function createTeam() {
      errors.fieldEmptyErrors = shallowValidate({ teamName: fields.teamName })
      errors.specialErrors = FormValidationService({ teamName: fields.teamName })

      if (isEmpty(errors.fieldEmptyErrors) && isEmpty(errors.specialErrors)) {
        loading.value = true
        teamDisplayName.value = fields.teamName
        try {
          await store.dispatch('teams/createTeam', new TeamProfile(
            fields.teamName,
            newPictureURL.value?.pictureId,
            fields.teamType,
            fields.teamSize,
            fields.role
          ))
        } catch (error) {
          if (error instanceof Error) {
            ToastNotificationService.push({ type: 'error', message: error.message })
          }
        } finally {
          loading.value = false
          emit('navigate:next')
          ModalHandlerService.open('add-member-modal-onboarding', { theme: 'white' })
        }
      }
    }

    function validate() {
      errors.fieldEmptyErrors = shallowValidate({ teamName: fields.teamName })
      errors.specialErrors = FormValidationService({ teamName: fields.teamName })

      if (isEmpty(errors.fieldEmptyErrors) && isEmpty(errors.specialErrors)) {
        buttonIsDisabled.value = false
      } else {
        buttonIsDisabled.value = true
      }
    }

    function navigateSkip() {
      emit('navigate:skip')
    }

    return {
      AVAILABLE_ORG_INDUSTRIES,
      AVAILABLE_TEAM_SIZES,
      AVAILABLE_USER_ROLES,
      ModalHandlerService,
      ...toRefs(fields),
      ...toRefs(errors),
      loading,
      buttonIsDisabled,
      createTeam,
      navigateSkip,
      validate
    }
  }
})
