export function formatAsDailySyncDate(date: Date): string {
  return `${date.getFullYear()}-${padTwoDigit(date.getMonth() + 1)}-${padTwoDigit(date.getDate())}` // returns string like 2022-04-30 using local time
}

export function daysFromNow(days: number): Date {
  const now = new Date()
  now.setDate(now.getDate() + days)
  return now
}

function padTwoDigit(num: number): string {
  return num < 10 ? `0${num}` : `${num}`
}
