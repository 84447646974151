
import { defineComponent, ref } from 'vue'

import { QUIZ_CONTRIBUTION_PRESETS, QUIZ_CONTRIBUTION_STYLE_PICTURES } from '@/data/static'

import QuizService, { QUIZ_WORK_STAGE_DEFINITION } from '@/services/QuizService'

import AppSlider from '@common/AppSlider.vue'
import QuizCard from '@feature/quiz/QuizCard.vue'
import Swiper from 'swiper'

export default defineComponent({
  name: 'QuizWorkStageContributionPreset',
  emits: ['quiz-navigation:next', 'quiz-navigation:prev'],
  components: {
    AppSlider,
    QuizCard
  },
  setup(_, { emit }) {
    const { contributionStyle } = QuizService.getWorkStageData()
    const selectedPreset = ref(contributionStyle?.presetChosen || QUIZ_CONTRIBUTION_PRESETS[0].id)

    function setActiveIndex(swiperInstance: Swiper) {
      selectedPreset.value = QUIZ_CONTRIBUTION_PRESETS[swiperInstance.activeIndex].id
    }

    function setDefaultSlide(swiperInstance: Swiper) {
      swiperInstance.slideTo(QUIZ_CONTRIBUTION_PRESETS.findIndex((preset) => preset.id === selectedPreset.value))
    }

    function navigateNext() {
      const preset = QUIZ_CONTRIBUTION_PRESETS.find((preset) => preset.id === selectedPreset.value)

      QuizService.save({
        presetChosen: selectedPreset.value
      })

      if (preset) {
        QuizService.save({
          styleName: preset.title,
          styleImageId: preset.imageId.toString(),
          explorer: preset.data.explorer,
          structured: preset.data.structured,
          supportive: preset.data.supportive,
          reactive: preset.data.reactive,
          outgoing: preset.data.outgoing
        })
      }

      emit('quiz-navigation:next')
      QuizService.skipToIndex(QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_EXPLORER)
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')
      QuizService.prev()
    }

    return {
      QuizService,
      navigateNext,
      navigatePrev,
      setActiveIndex,
      setDefaultSlide,
      QUIZ_CONTRIBUTION_PRESETS,
      QUIZ_CONTRIBUTION_STYLE_PICTURES
    }
  }
})
