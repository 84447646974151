
import { defineComponent, PropType } from 'vue'

import AppButtonMini from '@common/AppButtonMini.vue'
import AppAvatar from '@common/AppAvatar.vue'
import AppBasicDropdown from '@common/AppBasicDropdown.vue'
import AppDropdown from '@common/AppDropdown.vue'
import { Membership } from '@/types/membership'

export enum OPEN_DIRECTION {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export default defineComponent({
  name: 'AppAssigneesDropdown',
  emits: ['user-action:assign-user'],
  components: {
    AppButtonMini,
    AppAvatar,
    AppBasicDropdown,
    AppDropdown
  },
  props: {
    userList: {
      type: Array as PropType<Membership[]>,
      required: true
    },
    assignedUsers: {
      type: Array as PropType<Membership[]>,
      required: true
    },
    openDirection: {
      type: String,
      required: false,
      default: OPEN_DIRECTION.BOTTOM,
      validator: (value: OPEN_DIRECTION): boolean => Object.values(OPEN_DIRECTION).includes(value)
    }
  },
  setup() {
    return {
      OPEN_DIRECTION
    }
  }
})
