import { store } from '@/store'
import DailySync, { DailySyncTask } from '@/types/dailySync'
import { WorkDaySchedule, WorkWeekSchedule } from '@/types/user'
import { DateTime, Interval } from 'luxon'
import { stringifyQuery } from 'vue-router'
import { isNonEmptyRichText } from './conversationUtils'

export enum IndicatorColors {
  GREEN = 'green',
  YELLOW = 'yellow',
  GRAY = 'gray',
  RED = 'red',
}

export function getDailySyncForUser(start: DateTime, userId: string): DailySync | undefined {
  const dailySyncs: Record<string, DailySync[]> = store.getters['dailySync/getDailySyncs']
  // work backwards for up to 14 days until we find a submitted daily sync for this user
  for (let i = 0; i <= 14; i++) {
    const dayToCheck = start.minus({ day: i })
    const dailySyncForUser = dailySyncs[dayToCheck.toISODate()]?.find(e => e.userId === userId)
    if (dailySyncForUser) {
      return dailySyncForUser
    }
  }
  // special case handling to find a daily sync older than the 14 days
  for (const day in dailySyncs) {
    const syncs = dailySyncs[day]
    const userSync = syncs.find(e => e.userId === userId)
    if (userSync) {
      return userSync
    }
  }
  return undefined
}

export function deepCopyDailySyncTaskList(tasks: DailySyncTask[]): DailySyncTask[] {
  return tasks.map(e => new DailySyncTask(e.id, e.text, e.category, e.completed, e.history, e.hidden, e.assignee, e.goalId, undefined, e.timeblocks))
}

export function updateTaskHistoryForDate(task: DailySyncTask, date: string): DailySyncTask {
  const historyForDate = task.history.find(e => e.date === date)
  if (historyForDate) {
    historyForDate.category = task.category
    historyForDate.completed = task.completed
  } else {
    task.history.push({ date, category: task.category, completed: task.completed })
  }
  return task
}

export function isNonEmptyTask(task: DailySyncTask): boolean {
  return isNonEmptyRichText(task.text)
}

export function getUserScheduleForDay(userId: string, day: string): WorkDaySchedule | undefined {
  // first check for a day schedule override from a submitted sync on the specified day
  const dailySyncs: Record<string, DailySync[]> = store.getters['dailySync/getDailySyncs']
  const dailySyncForUser = dailySyncs[day]?.find(e => e.userId === userId)
  if (dailySyncForUser?.scheduleOverride) {
    return dailySyncForUser.scheduleOverride
  }
  // if there is no schedule override then take the day schedule from the users standard work week schedule
  const userWeekSchedule: WorkWeekSchedule = store.getters['teams/getUserSchedules'].find((e: { userId: string }) => e.userId === userId)?.schedule
  if (userWeekSchedule) {
    const userDaySchedule = getScheduleForDay(DateTime.fromISO(day).toJSDate().getDay(), userWeekSchedule)
    if (userDaySchedule) {
      return new WorkDaySchedule(userDaySchedule.works, userDaySchedule.startHour, userDaySchedule.startMinute, userDaySchedule.endHour, userDaySchedule.endMinute, userDaySchedule.location)
    }
  }
  return undefined
}

function getScheduleForDay(day: number, schedule: WorkWeekSchedule): WorkDaySchedule | undefined {
  switch (day) {
    case 0: return schedule.sunday
    case 1: return schedule.monday
    case 2: return schedule.tuesday
    case 3: return schedule.wednesday
    case 4: return schedule.thursday
    case 5: return schedule.friday
    case 6: return schedule.saturday
    default: return undefined
  }
}

export default function getAvailabilityIndicatorColor(userHasSynced: boolean, timezone?: string, userSchedule?: WorkDaySchedule): IndicatorColors {
  if (timezone && userSchedule?.works && isCurrentlyInsideWorkHours(timezone, userSchedule)) {
    return userHasSynced ? IndicatorColors.GREEN : IndicatorColors.YELLOW
  } else {
    return IndicatorColors.GRAY
  }
}

function isCurrentlyInsideWorkHours(timezone: string, daySchedule: WorkDaySchedule): boolean {
  const startTime = DateTime.fromObject({ hour: daySchedule?.startHour, minute: daySchedule?.startMinute }, { zone: timezone })
  const endTime = DateTime.fromObject({ hour: daySchedule?.endHour, minute: daySchedule?.endMinute }, { zone: timezone })
  const workTime = Interval.fromDateTimes(startTime, endTime)
  const now = DateTime.now()
  return workTime.contains(now)
}
