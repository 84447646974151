import { Goal } from '@/types/objective'
import router from '@/router'
export interface SideBarListener {
  onCreateGoal: () => void
  onEditGoal: (goal: Goal) => void
  onGoalUpdated: (goal: Goal) => void
  onClosePeekaboo: () => void
}

let sideBarListener: SideBarListener

export function registerSideBarListener(listener: SideBarListener): void {
  sideBarListener = listener
}

export function createGoal(): void {
  if (sideBarListener) {
    sideBarListener.onCreateGoal()
  }
}

export function editGoal(goal: Goal): void {
  if (sideBarListener) {
    sideBarListener.onEditGoal(goal)
  }
}

export function goalUpdated(goal: Goal): void {
  if (sideBarListener) {
    sideBarListener.onGoalUpdated(goal)
  }
}

export function closeGoalPeekaboo(): void {
  if (sideBarListener) {
    router.replace({ path: router.currentRoute.value.path, query: undefined })
    sideBarListener.onClosePeekaboo()
  }
}
