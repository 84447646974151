
import { defineComponent, computed, onMounted, PropType } from 'vue'

export enum APP_CHECKBOX_THEMES {
  SECONDARY = 'secondary',
  TASK_STATUS = 'task-status'
}

export enum APP_CHECKBOX_VARIANTS {
  SMALL = 'small',
}

export default defineComponent({
  name: 'AppCheckbox',
  inheritAttrs: false,
  emits: ['update:modelValue', 'blur'],
  props: {
    id: {
      type: String,
      required: false
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      type: [Boolean, Array],
      required: false,
      default: false
    },
    value: {
      type: [String, Object],
      required: false
    },
    theme: {
      type: String as PropType<APP_CHECKBOX_THEMES>,
      required: false,
      validator: (value: APP_CHECKBOX_THEMES): boolean => Object.values(APP_CHECKBOX_THEMES).includes(value)
    },
    variant: {
      type: String as PropType<APP_CHECKBOX_VARIANTS>,
      required: false,
      validator: (value: APP_CHECKBOX_VARIANTS): boolean => Object.values(APP_CHECKBOX_VARIANTS).includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, attrs }) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    onMounted(() => {
      if (attrs.checked !== undefined) {
        inputValue.value = true
      }
    })

    return {
      inputValue,
      APP_CHECKBOX_VARIANTS
    }
  }
})
