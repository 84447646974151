
import { defineComponent, PropType, ref } from 'vue'

import { IQuizContributionDescription } from '@/types/quiz'

import QuizService, { QUIZ_WORK_STAGE_DEFINITION } from '@/services/QuizService'

import AppRangeSlider from '@/components/common/AppRangeSlider.vue'
import QuizCard from '@feature/quiz/QuizCard.vue'

type ContributionStyleProperties = 'explorer' | 'structured' | 'supportive' | 'reactive' | 'outgoing'

export default defineComponent({
  name: 'QuizWorkStageContribution',
  emits: ['quiz-navigation:next', 'quiz-navigation:prev'],
  components: {
    AppRangeSlider,
    QuizCard
  },
  props: {
    data: {
      type: Object as PropType<IQuizContributionDescription>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { contributionStyle } = QuizService.getWorkStageData()
    let currentContributionStyle: ContributionStyleProperties = 'explorer'

    switch (props.data.id) {
      case QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_EXPLORER: currentContributionStyle = 'explorer'; break
      case QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_STRUCTURED: currentContributionStyle = 'structured'; break
      case QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_OUTGOING: currentContributionStyle = 'outgoing'; break
      case QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_SUPPORTIVE: currentContributionStyle = 'supportive'; break
      case QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_REACTIVE: currentContributionStyle = 'reactive'; break
    }

    const value = ref(contributionStyle[currentContributionStyle])

    function navigateNext() {
      emit('quiz-navigation:next')

      if (contributionStyle) {
        QuizService.save({ [currentContributionStyle]: value.value })
      }

      QuizService.next()
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')

      if (props.data.id === QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_EXPLORER) {
        QuizService.skipToIndex(QUIZ_WORK_STAGE_DEFINITION.STYLE)
      } else {
        QuizService.prev()
      }
    }

    return {
      QuizService,
      navigateNext,
      navigatePrev,
      value
    }
  }
})
