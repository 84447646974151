
import { onMounted, ref, computed, defineComponent } from 'vue'
import { store } from '@/store'
import WindowEventsService, { WINDOW_EVENTS } from '@/services/WindowEventsService'

import AppSpacer from '@common/AppSpacer.vue'
import AppButtonMini from '@common/AppButtonMini.vue'

import SideNavigationMainRoutes from '@/components/feature/main-navigation/SideNavigationMainRoutes.vue'
import SideNavigationSecondary from '@/components/feature/main-navigation/SideNavigationSecondary.vue'

import SideNavTransition from '@transition/SideNavTransition.vue'

import FocusWidget from '@/components/UserInterface/FocusWidget.vue'
import OrgDropdown from '@/components/UserInterface/OrgDropdown.vue'
import ModalHandlerService from '@/services/ModalHandlerService'

import { TeamRole } from '@/types/membership'

export default defineComponent({
  name: 'SideNavigation',
  emits: ['user-action:resize-panel'],
  components: {
    AppSpacer,
    AppButtonMini,
    SideNavigationMainRoutes,
    SideNavigationSecondary,
    SideNavTransition,
    FocusWidget,
    OrgDropdown
  },
  setup(_, { emit }) {
    const MINIMIZE_CRITERIA = 1200 // width of maximized panel + 1440 content width
    const maximized = ref(localStorage.getItem('isSideNavMaximized') !== 'false')
    const userIsAdmin = computed(() => store.getters['user/getActiveTeamRole'] !== TeamRole.Member)
    const dailySyncOverlayActive = computed(() => ModalHandlerService.isCurrentlyActive('daily-sync-overlay'))
    const shouldAnimate = ref(false)

    const isDailySyncsLoaded = computed<boolean>(() => store.getters['teams/isTeamDataLoaded'] && store.getters['teams/getActiveTeam']?.dailySyncEnabled && store.getters['dailySync/isDailySyncsDataLoaded'])

    function manageSidebarState() {
      maximized.value = !maximized.value
      localStorage.setItem('isSideNavMaximized', JSON.stringify(maximized.value))
      shouldAnimate.value = true
      emit('user-action:resize-panel', maximized.value)
    }

    WindowEventsService.on(WINDOW_EVENTS.RESIZE, () => {
      if (maximized.value && window.innerWidth < MINIMIZE_CRITERIA) {
        maximized.value = false
        localStorage.setItem('isSideNavMaximized', JSON.stringify(maximized.value))
        emit('user-action:resize-panel', maximized.value)
      }
    })

    onMounted(() => {
      emit('user-action:resize-panel', maximized.value)
    })

    return {
      maximized,
      userIsAdmin,
      dailySyncOverlayActive,
      shouldAnimate,
      manageSidebarState,
      isDailySyncsLoaded
    }
  }
})
