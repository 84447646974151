/* eslint-disable no-console */
import { ref } from 'vue'
import { Workbox } from 'workbox-window'

let wb: Workbox | null
const serviceWorkerWaiting = ref(false)

const updateServiceWorker = (): void => {
  if (wb) {
    wb.messageSW({ type: 'SKIP_WAITING' })
  }
}

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`)
  wb.addEventListener('controlling', () => {
    window.location.reload()
  })
  wb.addEventListener('waiting', () => {
    console.log('registerServiceWorker received waiting event')
    serviceWorkerWaiting.value = true
  })
  wb.register()
} else {
  wb = null
}

export default { serviceWorkerWaiting, updateServiceWorker }
