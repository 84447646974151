import ApiService from '@/services/ApiService'
import CheckIn, { CheckInTheme, CreateCheckInRequest } from '@/types/checkin'
import CheckInConfig, { UpsertCheckInConfigRequest } from '@/types/checkinConfig'
import CheckInItem, { CheckInItemStatus, CreateCheckInItemRequest, UpdateCheckInItemRequest } from '@/types/checkinItem'
import { CheckInState, RootState } from '@/types/store'
import { Module } from 'vuex'
import { DEFAULT_CHECKIN_THEMES } from '@/data/checkin-themes'
import { MixPanelService } from '@/services/MixPanelService'

const checkIns: Module<CheckInState, RootState> = {
  namespaced: true,
  state: {
    checkInConfig: {} as CheckInConfig,
    checkIns: [],
    checkInItems: [],
    checkInThemes: [],
    actionsLoadedToCycle: undefined,
    checkInItemsLoaded: false,
    checkInConfigLoaded: false
  },
  getters: {
    getCheckInConfig: (state) => state.checkInConfig,
    getCheckIns: (state) => state.checkIns,
    getItemsForACheckIn: (state) => (checkInId: number) => state.checkInItems.filter((e) => e?.checkInId === checkInId),
    getCategoriesForACheckIn: (state) => (checkInId: number) => state.checkIns.find((e) => e.id === checkInId)?.categories,
    getActions: (state) => () => state.checkInItems.filter((e) => e.status === CheckInItemStatus.ActionRequired || e.status === CheckInItemStatus.Resolved),
    getCheckInThemes: (state) => state.checkInThemes,
    getAllCheckInItems: (state) => state.checkInItems,
    getActionsLoadedToCycle: (state) => state.actionsLoadedToCycle,
    getCheckInItemsLoaded: (state) => state.checkInItemsLoaded,
    getCheckInConfigLoaded: (state) => state.checkInConfigLoaded
  },
  mutations: {
    setCheckInConfig(state, checkInConfig: CheckInConfig) {
      state.checkInConfig = checkInConfig
    },
    setCheckIns(state, checkIns: CheckIn[]) {
      state.checkIns = checkIns.sort((a, b) => b.id - a.id)
    },
    closeCheckIn(state, checkInId: number) {
      const toUpdate = state.checkIns.find(e => e.id === checkInId)
      if (toUpdate) {
        toUpdate.open = false
        toUpdate.actualCycleCloseTime = new Date().toISOString()
      }
    },
    addItemsForACheckIn(state, params: { checkInId: number, checkInItems: CheckInItem[] }) {
      const existingItems = state.checkInItems.filter((e) => e.checkInId !== params.checkInId)
      state.checkInItems = existingItems.concat(params.checkInItems)
    },
    setCheckInItems(state, checkInItems: CheckInItem[]) {
      state.checkInItems = checkInItems
    },
    setCheckInThemes(state, checkInThemes: CheckInTheme[]) {
      state.checkInThemes = checkInThemes
    },
    setActionsLoadedToCycle(state, loadedToCycle?: number) {
      state.actionsLoadedToCycle = loadedToCycle
    },
    setCheckInItemsLoaded(state, checkInItemsLoaded: boolean) {
      state.checkInItemsLoaded = checkInItemsLoaded
    },
    setCheckInConfigLoaded(state, checkInConfigLoaded: boolean) {
      state.checkInConfigLoaded = checkInConfigLoaded
    },
    upsertCheckInItem(state, value: CheckInItem) {
      state.checkInItems = state.checkInItems.filter((n) => n.id !== value.id)
      state.checkInItems.push(value)
      value.themes.forEach((theme) => {
        if (!state.checkInThemes.some((e) => e.name === theme)) {
          // Add the new theme
          state.checkInThemes.push(new CheckInTheme(theme, state.checkInThemes.length % 14, 1))
        }
      })
    },
    deleteCheckInItem(state, value: { checkInItemId: number }) {
      state.checkInItems = state.checkInItems.filter((e) => e.id !== value.checkInItemId)
      const remainingThemes = [...new Set(state.checkInItems.flatMap((e) => e.themes))]
      state.checkInThemes = state.checkInThemes.filter((e) => remainingThemes.some((theme) => theme === e.name))
    }
  },
  actions: {
    clearActiveTeamData({ commit }) {
      commit('setCheckInConfig', {})
      commit('setCheckIns', [])
      commit('setCheckInItems', [])
      commit('setCheckInThemes', [])
      commit('setActionsLoadedToCycle', undefined)
      commit('setCheckInItemsLoaded', false)
      commit('setCheckInConfigLoaded', false)
    },
    async loadActiveTeamData({ state, dispatch, commit }, params: { teamId: string }): Promise<void> {
      commit('setCheckInItemsLoaded', false)

      await Promise.all([
        dispatch('getCheckInConfig', params.teamId),
        dispatch('getCheckIns', params.teamId)
      ])

      if (state.checkInConfig.enabled && state.checkIns.length === 0) {
        await dispatch('createCheckIn', { teamId: params.teamId, request: {} })
      }

      await Promise.all([
        dispatch('getCheckInItems', params),
        dispatch('getCheckInThemes', params.teamId),
        dispatch('getCheckInActions', params)
      ])
    },
    async getCheckInConfig({ commit }, teamId: string): Promise<void> {
      commit('setCheckInConfigLoaded', false)
      const response = await ApiService.sendRequest(`teams/${teamId}/check-in-config`, {
        method: 'GET',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Could not retrieve check-in-config')
      } else {
        const checkInConfig = await response.json()
        commit('setCheckInConfig', checkInConfig)
        commit('setCheckInConfigLoaded', true)
      }
    },
    async getCheckIns({ commit }, teamId: string): Promise<void> {
      try {
        const response = await ApiService.sendTrackedRequest(`teams/${teamId}/check-ins`, {
          method: 'GET',
          requiresAuth: true
        })
        commit('setCheckIns', response.checkIns)
      } catch (error) {
        throw new Error('Could not retrieve check-ins')
      }
    },
    async getCheckInItems({ commit }, params: { teamId: string, checkInId?: number }): Promise<void> {
      commit('setCheckInItemsLoaded', false)
      let url = `teams/${params.teamId}/check-in?includeItems=true`
      // Note that if checkInId is not provided the endpoint will return the latest check-in by default
      if (params.checkInId) {
        url = `${url}&checkInId=${params.checkInId}`
      }

      const response = await ApiService.sendRequest(url, {
        method: 'GET',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Could not retrieve check-in items')
      } else {
        const items = await response.json()
        commit('setCheckInItemsLoaded', true)
        const checkInItems = items.checkInItems
        if (checkInItems?.length) {
          const checkInId = checkInItems.at(-1)?.checkInId
          commit('addItemsForACheckIn', { checkInId, checkInItems })
        }
      }
    },
    async getCheckInThemes({ commit }, teamId: string): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${teamId}/check-in-themes`, {
        method: 'GET',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Could not retrieve check-in items')
      } else {
        const checkInThemes: CheckInTheme[] = (await response.json()).themes
        checkInThemes.sort((a, b) => b.count - a.count)
        const themes = [...new Set([...DEFAULT_CHECKIN_THEMES, ...checkInThemes])]
          .reduce((unique: CheckInTheme[], theme: CheckInTheme) => {
            if (!unique.some(obj => obj.name === theme.name)) {
              unique.push(theme)
            }
            return unique
          }, [])

        commit('setCheckInThemes', themes)
      }
    },
    async getCheckInActions({ commit }, params: { teamId: string, start?: number }): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/check-in-actions${params.start ? `?start=${params.start}` : ''}`, {
        method: 'GET',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Could not retrieve check-in items')
      } else {
        const data = await response.json()
        const checkInActions: CheckInItem[] = data.actions
        const actionsLoadedToCycle: number = data.minCycle + 1
        checkInActions.forEach((item) => commit('upsertCheckInItem', item))
        commit('setActionsLoadedToCycle', actionsLoadedToCycle)
      }
    },
    async createCheckInItem({ commit }, params: { teamId: string, request: CreateCheckInItemRequest}): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/check-in-items`, {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify(params.request)
      })
      if (response.ok) {
        const checkInItemData = await response.json()
        commit('upsertCheckInItem', checkInItemData)
        MixPanelService.instance.onCreateCheckInItem(checkInItemData)
      } else {
        throw new Error('Note could not be created.')
      }
    },
    async createCheckIn({ state, commit }, params: { teamId: string, request: CreateCheckInRequest}): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/check-ins`, {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify(params.request)
      })
      if (response.ok) {
        const checkIn = await response.json()
        const checkIns = [...state.checkIns, checkIn]
        commit('setCheckIns', checkIns)
        MixPanelService.instance.onCreateCheckIn(checkIn)
      } else {
        throw new Error('CheckIn could not be created.')
      }
    },
    async closeCheckIn({ commit }, params: { teamId: string, checkInId: number}): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/check-ins/${params.checkInId}`, {
        method: 'PATCH',
        requiresAuth: true,
        body: JSON.stringify({ open: false })
      })
      if (response.ok) {
        commit('closeCheckIn', params.checkInId)
        MixPanelService.instance.onCloseCheckIn(params.checkInId)
      } else {
        throw new Error('CheckIn could not be closed.')
      }
    },
    async updateCheckInItem({ commit, getters }, params: { teamId: string, checkInItemId: number, request: UpdateCheckInItemRequest}): Promise<void> {
      const foundCheckInItem: CheckInItem = getters.getAllCheckInItems.find((item: CheckInItem) => item.id === params.checkInItemId)
      if (foundCheckInItem) {
        const updatedCheckInItem = {
          id: foundCheckInItem.id,
          teamId: foundCheckInItem.teamId,
          checkInId: foundCheckInItem.checkInId,
          description: params.request.description ?? foundCheckInItem.description,
          createdAt: foundCheckInItem.createdAt,
          updatedAt: foundCheckInItem.updatedAt,
          category: params.request.category ?? foundCheckInItem.category,
          themes: params.request.themes ?? foundCheckInItem.themes,
          creator: foundCheckInItem.creator,
          upvoteUsers: params.request.upvoteUsers ?? foundCheckInItem.upvoteUsers,
          status: params.request.status ?? foundCheckInItem.status,
          assignees: params.request.assignees ?? foundCheckInItem.assignees,
          commentCount: foundCheckInItem.commentCount
        }
        commit('upsertCheckInItem', updatedCheckInItem)
      }

      const response = await ApiService.sendRequest(`teams/${params.teamId}/check-in-items/${params.checkInItemId}`, {
        method: 'PATCH',
        requiresAuth: true,
        body: JSON.stringify(params.request)
      })

      if (!response.ok) {
        commit('upsertCheckInItem', foundCheckInItem) // fallback
        throw new Error('Note could not be updated.')
      }
      MixPanelService.instance.onUpdateCheckInItem(params.checkInItemId, params.request)
    },
    async deleteCheckInItem({ commit }, params: { teamId: string, checkInItemId: number}): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/check-in-items/${params.checkInItemId}`, {
        method: 'DELETE',
        requiresAuth: true
      })
      if (response.ok) {
        commit('deleteCheckInItem', { checkInItemId: params.checkInItemId })
      } else {
        throw new Error('Note could not be deleted.')
      }
    },
    async upsertCheckInConfig({ commit }, params: { teamId: string, request: UpsertCheckInConfigRequest}): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/check-in-config`, {
        method: 'PUT',
        requiresAuth: true,
        body: JSON.stringify(params.request)
      })
      if (response.ok) {
        commit('setCheckInConfig', new CheckInConfig(params.teamId, params.request.enabled, params.request.daysInCycle, params.request.categories))
      } else {
        throw new Error('Note could not be created.')
      }
    },
    // Finds a CheckIn by checkInId. Will load the CheckIn list if it is not already loaded.
    async findCheckIn({ state, dispatch, rootGetters }, params: { checkInId: number }): Promise<CheckIn | undefined> {
      if (!state.checkInItemsLoaded) {
        await dispatch('getCheckIns', rootGetters['user/getActiveMembership']?.teamId)
      }
      return state.checkIns.find(e => e.id === params.checkInId)
    },
    // Finds a CheckInItem by loading all items for CheckIn checkInId.
    async findCheckInItem({ state, dispatch, rootGetters }, params: { checkInItemId: number, checkInId: number }): Promise<CheckInItem | undefined> {
      let item = state.checkInItems.find(e => e.id === params.checkInItemId && e.checkInId === params.checkInId)
      if (!item) {
        await dispatch('getCheckInItems', { teamId: rootGetters['user/getActiveMembership']?.teamId, checkInId: params.checkInId })
        item = state.checkInItems.find(e => e.id === params.checkInItemId && e.checkInId === params.checkInId)
      }
      return item
    }
  }
}

export default checkIns
