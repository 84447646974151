import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid no-gap" }
const _hoisted_2 = { class: "col-12 col-md-6 col-xl-5" }
const _hoisted_3 = {
  id: "main",
  role: "main",
  class: "auth-layout"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLogo = _resolveComponent("AppLogo")!
  const _component_AppMarketing = _resolveComponent("AppMarketing")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("main", _hoisted_3, [
        _createVNode(_component_AppLogo, {
          class: "auth-layout__logo",
          theme: "dark",
          type: "text"
        }),
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _createVNode(_component_AppMarketing, { class: "col-12 col-md-6 col-xl-7" })
  ]))
}