import TouchingBase from '@root/backend/src/common/entities/quiz/TouchingBase'
import LightningRound from '@root/backend/src/common/entities/quiz/LightningRound'
import ContributionStyle from '@root/backend/src/common/entities/quiz/ContributionStyle'
import { QuizInterestItem } from './quiz'

export class User {
  constructor(
      public userId: string,
      public email: string,
      public createdAt: string,
      public verified: boolean,
      public profile: UserProfile,
      public activeTeamId?: string,
      public quiz?: UserQuiz,
      public lastViewedSyncTime?: string, // used to determine which syncs have been updated since the last time this user viewed them
      public settings = new UserSettings() // used to track settings and state such as UI preferences and whether onboarding steps have been completed
  ) {}
}

export class UserProfile {
  constructor(
      public displayName?: string,
      public firstName?: string,
      public lastName?: string,
      public pictureId?: string,
      public location?: UserLocation,
      public jobTitle?: string,
      public birthday?: string,
      public anniversary?: string
  ) {}
}

export class UserQuiz {
  constructor(
      public aboutMe?: string,
      public touchingBase?: TouchingBase,
      public professionalSkills?: string[],
      public contributionStyle?: ContributionStyle,
      public hobbies?: string[],
      public lightningRound?: LightningRound,
      public giphyIds?: string[],
      public interests?: QuizInterestItem[],
      public passions?: string[],
      public greatDebate?: GreatDebateItem[],
      public workWeekSchedule?: WorkWeekSchedule
  ) {}
}

export class UserLocation {
  constructor(
      public country?: string,
      public city?: string,
      public timezone?: string
  ) {}
}

export class UserSettings {
  constructor(
      public syncOnboarding = false
  ) {}
}

export enum GreatDebateCategory {
  CoffeeOrTea = 'CoffeeOrTea',
  EarlyBirdOrNightOwl = 'EarlyBirdOrNightOwl',
  CatsOrDogs = 'CatsOrDogs',
  NightInOrNightOut = 'NightInOrNightOut',
  BeachOrMountains = 'BeachOrMountains'
}

export class GreatDebateItem {
  constructor(
      public category: GreatDebateCategory,
      public firstOption: boolean // indicates the user chose the first option (e.g. Coffee if category is CoffeeOrTea)
  ) {}
}

export class WorkWeekSchedule {
  constructor(
    public monday: WorkDaySchedule,
    public tuesday: WorkDaySchedule,
    public wednesday: WorkDaySchedule,
    public thursday: WorkDaySchedule,
    public friday: WorkDaySchedule,
    public saturday: WorkDaySchedule,
    public sunday: WorkDaySchedule
  ) {}
}

export enum WorkLocation {
  Office = 'Office',
  Remote = 'Remote',
  Mix = 'Mix'
}

export class WorkDaySchedule {
  constructor(
      public works: boolean, // indicates whether the user normally works on this day
      public startHour?: number,
      public startMinute?: number,
      public endHour?: number,
      public endMinute?: number,
      public location?: WorkLocation
  ) {}
}

export enum UserRoles {
  OWNER = 'Owner',
  ADMIN = 'Admin',
  MEMBER = 'Member'
}

export enum UserRolesDescription {
  OWNER = 'Can change team settings and invite members',
  ADMIN = 'Can change team settings and invite members',
  MEMBER = 'Can take part of team activities and see team profiles'
}
