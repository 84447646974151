
import { defineComponent, ref } from 'vue'

import { QUIZ_CONTRIBUTION_STYLE_DATA } from '@/data/static'

import QuizService, { QUIZ_STAGE_DEFINITION, QUIZ_WORK_STAGE_DEFINITION } from '@/services/QuizService'

import QuizWorkStageIntro from '@feature/quiz/quiz-work-stage/QuizWorkStageIntro.vue'
import QuizWorkStageContribution from '@feature/quiz/quiz-work-stage/QuizWorkStageContribution.vue'
import QuizWorkStageContributionStyle from '@/components/feature/quiz/quiz-work-stage/QuizWorkStageContributionStyle.vue'
import QuizWorkStageContributionPreset from '@/components/feature/quiz/quiz-work-stage/QuizWorkStageContributionPreset.vue'
import QuizWorkStageCustomizer from '@/components/feature/quiz/quiz-work-stage/QuizWorkStageCustomizer.vue'

import QuizGroupNavigationTransition from '@transition/QuizGroupNavigationTransition.vue'

export default defineComponent({
  name: 'QuizWorkStage',
  components: {
    QuizWorkStageIntro,
    QuizWorkStageContribution,
    QuizWorkStageContributionStyle,
    QuizWorkStageContributionPreset,
    QuizWorkStageCustomizer,
    QuizGroupNavigationTransition
  },
  setup() {
    const isNavigationDirectionForward = ref(false)

    return {
      QuizService,
      QUIZ_STAGE_DEFINITION,
      QUIZ_WORK_STAGE_DEFINITION,
      QUIZ_CONTRIBUTION_STYLE_DATA,
      isNavigationDirectionForward
    }
  }
})
