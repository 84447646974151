import Reaction from './reaction'
import { WorkDaySchedule } from './user'

export enum DailySyncTaskCategory {
  TASK = 'Task',
  BLOCKED = 'Blocked',
  DISCUSS = 'Discuss',
  FEEDBACK = 'Feedback',
  DRE = 'Dre'
}

export class DailySyncTask {
  constructor(
      public id: string,
      public text: string,
      public category: DailySyncTaskCategory,
      public completed: boolean,
      public history: {
          date: string,
          category: DailySyncTaskCategory,
          completed: boolean
      }[], // tracks the state of the task at the end of each day
      public hidden = false,
      public assignee?: string,
      public goalId?: number,
      public labels?: string[],
      public timeblocks?: {
          start: string,
          end: string
      }[]
  ) {}
}

export default class DailySync {
  constructor(
        public teamId: string,
        public userId: string,
        public date: string,
        public lastUpdatedAt: string,
        public feelingEmoji?: string,
        public scheduleOverride?: WorkDaySchedule,
        public message?: string,
        public mentionedUsers?: string[],
        public tasks?: DailySyncTask[],
        public reactions?: Reaction[],
        public commentCount = 0,
        public lastCommentTime?: string, // used to refresh the conversation when there is a new comment
        public draft = false
  ) {}
}

export class UpsertDailySyncRequest {
  constructor(
    public tasks: DailySyncTask[],
    public mentionedUsers: string[],
    public message?: string,
    public feelingEmoji?: string,
    public scheduleOverride?: WorkDaySchedule,
    public reactions?: Reaction[],
    public lastUpdatedAt?: string, // if not supplied this will automatically be set to current time
    public lastCommentTime?: string, // used to refresh the conversation when there is a new comment
    public draft = false
  ) {}
}

export enum WorkingLocations {
  OFFICE = 'Office',
  REMOTE = 'Remote',
  MIX = 'Mix',
  AWAY = 'Away',
  N_A = 'N/A'
}

export class DailySyncMood {
  constructor(
      public emoji: string,
      public title: string
  ) {}
}

export interface TaskTimer {
  hours: number,
  minutes: number,
  seconds: number
}
