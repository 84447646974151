import Reaction from './reaction'

export enum CommentType {
  DAILY_SYNC = 'daily-sync',
  CHECK_IN = 'check-in-item',
  GOAL = 'goal',
  SOCIAL_CONNECT = 'social-connect',
  DRE_INSIGHTS = 'dre-insights'
}
export interface ItemId {
  teamId: string,
  userId?: string,
  date?: string,
  itemId?: number,
  goalId?: number,
  cycleId?: number
}

export class Comment {
  constructor(
      public id: string,
      public created: string,
      public author: string,
      public content: string,
      public mentions: string[],
      public replies: Comment[],
      public reactions: Reaction[],
      public updated?: string,
      public deleted?: string
  ) {}
}

export class AddCommentRequest {
  constructor(
      public itemId: ItemId,
      public content: string,
      public mentions: string[],
      public replyToCommentId?: string
  ) {}
}

export default class Conversation {
  constructor(
      public itemId: ItemId,
      public itemDescription: string,
      public itemAuthor: string,
      public commenters: string[] = [],
      public mentioned: string[] = [],
      public comments: Comment[] = []
  ) {}
}

export class EditCommentRequest {
  constructor(
      public itemId: ItemId,
      public content: string,
      public mentions: string[],
      public markAsDeleted = false,
      public reactions: Reaction[]
  ) {}
}
