import { computed, ref, Ref, ComputedRef } from 'vue'
import { store } from '@/store'

import { IQuizStageDefinitions } from '@/types/quiz'

import BodyLockService from '@/services/BodyLockService'
import ModalHandlerService from '@/services/ModalHandlerService'
import { UserQuiz, UserProfile, WorkWeekSchedule, WorkLocation } from '@/types/user'

export enum QUIZ_STAGE_DEFINITION {
  BASIC = 0,
  WORK,
  AVAILABILITY,
  PERSONAL,
  LIGHTNING_ROUND
}

export enum QUIZ_BASIC_STAGE_DEFINITION {
  INTRO = 0,
  SKILLS,
  DESCRIPTION,
  GIFS
}

export enum QUIZ_WORK_STAGE_DEFINITION {
  INTRO = 0,
  STYLE,
  PRESET,
  CONTRIBUTION_EXPLORER,
  CONTRIBUTION_STRUCTURED,
  CONTRIBUTION_OUTGOING,
  CONTRIBUTION_SUPPORTIVE,
  CONTRIBUTION_REACTIVE,
  CUSTOMIZER
}

export enum QUIZ_AVAILABILITY_STAGE_DEFINITION {
  INTRO = 0,
  MEETINGS_TIME_OF_THE_DAY,
  MEETINGS_ARRANGEMENT,
  REACHING_OUT,
  WORKING_HOURS
}

export enum QUIZ_PERSONAL_STAGE_DEFINITION {
  INTRO = 0,
  INTERESTS,
  PASSIONS
}

export enum QUIZ_LIGHTNING_ROUND_DEFINITION {
  INTRO = 0,
  COFFE_OR_TEA,
  EARLY_BIRD_OR_NIGHT_OWL,
  CATS_OR_DOGS,
  NIGHT_IN_OR_NIGHT_OUT,
  BEACH_OR_MOUNTAINS
}

const QUIZ_FINISH_STAGE_ID = 3

class QuizService {
  private static instance: QuizService
  private definitions: Ref<IQuizStageDefinitions>
  private stage: Ref<QUIZ_STAGE_DEFINITION>
  private index: Ref<number>
  private queue: Ref<number[]>
  private initialQueueLength: Ref<number>
  private saving: Ref<boolean>
  ongoing: Ref<boolean>
  editing: Ref<boolean>

  private constructor() {
    this.editing = ref(false)
    this.queue = ref([])
    this.ongoing = ref(false)
    this.stage = ref(0)
    this.index = ref(0)
    this.initialQueueLength = ref(0)
    this.saving = ref(false)
    this.definitions = ref(this.generateQuizDefinitionsAndMapData())
  }

  get isCompleted() {
    const quiz: ComputedRef<UserQuiz> = computed(() => store.getters['user/getUserQuiz'])
    return quiz.value?.interests && Object.keys(quiz.value.interests).length > 0
  }

  private generateQuizDefinitionsAndMapData(): IQuizStageDefinitions {
    const quiz: UserQuiz = store.getters['user/getUserQuiz']
    const userProfile: UserProfile = store.getters['user/getUserProfile']

    return {
      [QUIZ_STAGE_DEFINITION.BASIC]: {
        stageId: QUIZ_STAGE_DEFINITION.BASIC,
        stageLength: Object.keys(QUIZ_BASIC_STAGE_DEFINITION).filter(key => isNaN(Number(key))).length,
        data: {
          aboutMe: quiz?.aboutMe || '',
          displayName: userProfile?.displayName || '',
          professionalSkills: quiz?.professionalSkills || [],
          giphyIds: quiz?.giphyIds || []
        }
      },
      [QUIZ_STAGE_DEFINITION.WORK]: {
        stageId: QUIZ_STAGE_DEFINITION.WORK,
        stageLength: Object.keys(QUIZ_WORK_STAGE_DEFINITION).filter(key => isNaN(Number(key))).length,
        data: {
          contributionStyle: {
            explorer: quiz?.contributionStyle?.explorer ?? 50,
            structured: quiz?.contributionStyle?.structured ?? 50,
            outgoing: quiz?.contributionStyle?.outgoing ?? 50,
            supportive: quiz?.contributionStyle?.supportive ?? 50,
            reactive: quiz?.contributionStyle?.reactive ?? 50,
            styleName: quiz?.contributionStyle?.styleName || '',
            styleImageId: quiz?.contributionStyle?.styleImageId ?? '',
            presetChosen: quiz?.contributionStyle?.presetChosen
          }
        }
      },
      [QUIZ_STAGE_DEFINITION.AVAILABILITY]: {
        stageId: QUIZ_STAGE_DEFINITION.AVAILABILITY,
        stageLength: Object.keys(QUIZ_AVAILABILITY_STAGE_DEFINITION).filter(key => isNaN(Number(key))).length,
        data: {
          touchingBase: {
            meetingTime: quiz?.touchingBase?.meetingTime,
            meetingSpread: quiz?.touchingBase?.meetingSpread,
            preferredContact: quiz?.touchingBase?.preferredContact
          },
          workWeekSchedule: quiz?.workWeekSchedule || new WorkWeekSchedule(
            {
              works: true,
              startHour: 9,
              startMinute: 0,
              endHour: 17,
              endMinute: 0,
              location: WorkLocation.Office
            },
            {
              works: true,
              startHour: 9,
              startMinute: 0,
              endHour: 17,
              endMinute: 0,
              location: WorkLocation.Office
            },
            {
              works: true,
              startHour: 9,
              startMinute: 0,
              endHour: 17,
              endMinute: 0,
              location: WorkLocation.Office
            },
            {
              works: true,
              startHour: 9,
              startMinute: 0,
              endHour: 17,
              endMinute: 0,
              location: WorkLocation.Office
            },
            {
              works: true,
              startHour: 9,
              startMinute: 0,
              endHour: 17,
              endMinute: 0,
              location: WorkLocation.Office
            },
            {
              works: false,
              startHour: 9,
              startMinute: 0,
              endHour: 17,
              endMinute: 0,
              location: WorkLocation.Office
            },
            {
              works: false,
              startHour: 9,
              startMinute: 0,
              endHour: 17,
              endMinute: 0,
              location: WorkLocation.Office
            }
          )
        }
      },
      [QUIZ_STAGE_DEFINITION.PERSONAL]: {
        stageId: QUIZ_STAGE_DEFINITION.PERSONAL,
        stageLength: Object.keys(QUIZ_PERSONAL_STAGE_DEFINITION).filter(key => isNaN(Number(key))).length,
        data: {
          interests: quiz?.interests || [],
          passions: quiz?.passions || []
        }
      },
      [QUIZ_STAGE_DEFINITION.LIGHTNING_ROUND]: {
        stageId: QUIZ_STAGE_DEFINITION.PERSONAL,
        stageLength: Object.keys(QUIZ_LIGHTNING_ROUND_DEFINITION).filter(key => isNaN(Number(key))).length,
        data: {
          greatDebate: quiz?.greatDebate || []
        }
      }
    }
  }

  public static init(): QuizService {
    if (!QuizService.instance) {
      QuizService.instance = new QuizService()
    }

    return QuizService.instance
  }

  public start() {
    if (!this.ongoing.value) {
      let stage = 0
      const existingQuizData = computed(() => Object.keys(store.getters['user/getUserQuiz']))

      const isBasicStageCompleted = ['professionalSkills', 'aboutMe', 'displayName', 'giphyIds'].some((s) => existingQuizData.value.includes(s))
      const isAvailabilityStageCompleted = ['touchingBase', 'workWeekSchedule'].some((s) => existingQuizData.value.includes(s))
      const isPersonalStageCompleted = ['passions', 'interests'].some((s) => existingQuizData.value.includes(s))
      const isLightningRoundStageCompleted = existingQuizData.value.includes('greatDebate')

      if (isBasicStageCompleted) {
        stage = QUIZ_STAGE_DEFINITION.WORK
      }

      if (isAvailabilityStageCompleted) {
        stage = QUIZ_STAGE_DEFINITION.PERSONAL
      }

      if (isPersonalStageCompleted) {
        stage = QUIZ_STAGE_DEFINITION.LIGHTNING_ROUND
      }

      if (isLightningRoundStageCompleted) {
        stage = QUIZ_FINISH_STAGE_ID
      }

      this.startWith(stage, 0)
    }
  }

  public startWith(stage: number, index: number) {
    this.definitions.value = this.generateQuizDefinitionsAndMapData()
    this.stage.value = stage
    this.index.value = index
    this.ongoing.value = true
    BodyLockService.lock()
  }

  public async stop() {
    this.saving.value = true
    await this.setStagePayload()
    this.saving.value = false
    this.close()
  }

  public close() {
    this.stage.value = 0
    this.index.value = 0
    this.ongoing.value = false
    this.editing.value = false
    this.queue.value = []

    if (!ModalHandlerService.getCurrentlyActive()) {
      BodyLockService.unlock()
    }
  }

  public isSaving() {
    return this.saving.value
  }

  public isOngoing() {
    return this.ongoing.value
  }

  public isEditing() {
    return this.editing.value
  }

  public isCurrentlyDisplayed(stage: number, index: number) {
    return (
      this.stage.value === stage &&
      this.index.value === index
    )
  }

  public next(): void {
    const currentStageLength = this.definitions.value[this.stage.value].stageLength
    const expectedNextIndex = this.index.value + 1
    const editLastStep = expectedNextIndex !== this.initialQueueLength.value

    if (this.queue.value.length) {
      this.processQueue()
      return
    } else if (this.editing.value && editLastStep) {
      this.stop()
      return
    }

    if (currentStageLength === expectedNextIndex) {
      this.setStagePayload()
      this.stage.value += 1
      this.index.value = 0
    } else {
      this.index.value += 1
    }
  }

  public prev() {
    if (this.index.value - 1 < 0) {
      this.stage.value -= 1
      this.index.value = 0
    } else {
      this.index.value -= 1
    }
  }

  public edit(stage: number, index: number | number[]) {
    this.editing.value = true

    if (Array.isArray(index)) {
      this.startWith(stage, index.slice().shift() as number)
      this.queue.value = index.slice()
      this.initialQueueLength.value = this.queue.value.length
      this.processQueue()
    } else {
      this.startWith(stage, index)
    }
  }

  public save(payload: Record<string, unknown>) {
    switch (this.stage.value) {
      case QUIZ_STAGE_DEFINITION.WORK: Object.assign(this.definitions.value[QUIZ_STAGE_DEFINITION.WORK].data.contributionStyle, payload, {}); break
      case QUIZ_STAGE_DEFINITION.AVAILABILITY:
          payload?.workWeekSchedule
            ? Object.assign(this.definitions.value[QUIZ_STAGE_DEFINITION.AVAILABILITY].data, payload, {})
            : Object.assign(this.definitions.value[QUIZ_STAGE_DEFINITION.AVAILABILITY].data.touchingBase, payload, {}); break
      case QUIZ_STAGE_DEFINITION.PERSONAL: Object.assign(this.definitions.value[QUIZ_STAGE_DEFINITION.PERSONAL].data, payload, {}); break
      case QUIZ_STAGE_DEFINITION.LIGHTNING_ROUND: Object.assign(this.definitions.value[QUIZ_STAGE_DEFINITION.LIGHTNING_ROUND].data, payload, {}); break
      default: Object.assign(this.definitions.value[QUIZ_STAGE_DEFINITION.BASIC].data, payload, {}); break
    }
  }

  public skipToIndex(index: number) {
    this.index.value = index
  }

  public skipToStage(index: number) {
    this.stage.value = index
    this.index.value = 0
  }

  public getCurrentStage() {
    return this.stage.value
  }

  public getCurrentIndex() {
    return this.index.value
  }

  // TODO: TS is throwing an error when using union, hardcoding for now
  public getBasicStageData() {
    return this.definitions.value[QUIZ_STAGE_DEFINITION.BASIC].data
  }

  public getWorkStageData() {
    return this.definitions.value[QUIZ_STAGE_DEFINITION.WORK].data
  }

  public getAvailabilityStageData() {
    return this.definitions.value[QUIZ_STAGE_DEFINITION.AVAILABILITY].data
  }

  public getPersonalStageData() {
    return this.definitions.value[QUIZ_STAGE_DEFINITION.PERSONAL].data
  }

  public getLightningRoundStageData() {
    return this.definitions.value[QUIZ_STAGE_DEFINITION.LIGHTNING_ROUND].data
  }

  private processQueue() {
    const nextInQueue = this.queue.value.shift()

    if (nextInQueue) {
      this.index.value = nextInQueue
    }
  }

  private async setStagePayload() {
    try {
      await store.dispatch('user/updateUser', {
        quiz: this.definitions.value[this.stage.value]?.data,
        profile: new UserProfile(
          this.getBasicStageData().displayName,
          undefined,
          undefined,
          undefined,
          undefined
        )
      })
    } catch (error) {
      console.info(error)
    }
  }
}

const instance = QuizService.init()
Object.freeze(instance)

export default instance
