
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/store'
import { Goal } from '@/types/objective'
import DailySyncTaskList from '@feature/daily-sync/DailySyncTaskList.vue'
import { isNonEmptyTask } from '@/utils/dailySyncUtils'
import { DailySyncTask } from '@/types/dailySync'

import AccordionItem from '@common/AppAccordionItem.vue'
import Accordion from '@common/AppAccordion.vue'

export default defineComponent({
  name: 'GoalsBacklog',
  components: {
    DailySyncTaskList,
    Accordion,
    AccordionItem
  },
  setup() {
    const store = useStore()
    const isSaving = ref(false)
    const goals = computed<Goal[]>(() => store.getters['goals/getGoals'].filter((e: Goal) => unassignedTasks(e).length && e.status !== 'Completed'))

    function unassignedTasks(goal: Goal): DailySyncTask[] {
      return goal.tasks.filter(e => isNonEmptyTask(e) && !e.assignee && !e.completed)
    }

    function manualDelay() {
      isSaving.value = true
      setTimeout(() => {
        isSaving.value = false
      }, 1000)
    }

    async function updateTask(task: DailySyncTask) {
      console.log('ge')
      if (isNonEmptyTask(task)) {
        const teamId = store.getters['user/getActiveMembership']?.teamId
        const goalId = task.goalId
        await store.dispatch('goals/updateTaskOnGoal', { goalId, teamId, task })
      }
    }

    async function deleteTask(task: DailySyncTask) {
      const teamId = store.getters['user/getActiveMembership']?.teamId
      const goalId = task.goalId
      await store.dispatch('goals/deleteTaskOnGoal', { goalId, teamId, taskId: task.id })
    }

    return {
      goals,
      updateTask,
      deleteTask,
      unassignedTasks,
      isSaving,
      manualDelay
    }
  }
})
