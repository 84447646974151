
import { defineComponent, ref } from 'vue'

import { QUIZ_GREAT_DEBATE_DATA } from '@/data/static'

import QuizService, { QUIZ_STAGE_DEFINITION, QUIZ_PERSONAL_STAGE_DEFINITION } from '@/services/QuizService'

import QuizPersonalStageIntro from '@feature/quiz/quiz-personal-stage/QuizPersonalStageIntro.vue'
import QuizPersonalStageInterests from '@feature/quiz/quiz-personal-stage/QuizPersonalStageInterests.vue'
import QuizPersonalStagePassions from '@feature/quiz/quiz-personal-stage/QuizPersonalStagePassions.vue'

import QuizGroupNavigationTransition from '@transition/QuizGroupNavigationTransition.vue'

export default defineComponent({
  name: 'QuizAvailabilityStage',
  components: {
    QuizPersonalStageIntro,
    QuizPersonalStageInterests,
    QuizPersonalStagePassions,
    QuizGroupNavigationTransition
  },
  setup() {
    const isNavigationDirectionForward = ref(false)

    return {
      QuizService,
      isNavigationDirectionForward,
      QUIZ_STAGE_DEFINITION,
      QUIZ_PERSONAL_STAGE_DEFINITION,
      QUIZ_GREAT_DEBATE_DATA
    }
  }
})
