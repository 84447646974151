
import { defineComponent, ref, onBeforeUnmount, PropType, computed } from 'vue'
import { useStore } from '@/store'

import { Notification, NotificationStatus } from '@/types/notification'
import { ErrorMessages, SuccessMessages } from '@/data/messages'
import { timeDiffCalc } from '@/utils/calculateTimeDiff'

import ModalHandlerService from '@/services/ModalHandlerService'
import ToastNotificationService from '@/services/ToastNotificationService'
import { Membership } from '@/types/membership'

import NotificationItem from '@feature/main-navigation/MainNotificationItem.vue'
import AppAvatar from '@common/AppAvatar.vue'
import { UserProfile } from '@/types/user'

export default defineComponent({
  name: 'MainNotificationPanel',
  inheritAttrs: false,
  components: {
    NotificationItem,
    AppAvatar
  },
  props: {
    notifications: {
      type: Array as PropType<Array<Notification>>,
      required: true
    }
  },
  setup() {
    const store = useStore()
    const teamMembers = computed<Membership[]>(() => store.getters['teams/getTeamMembersWithFullName'])
    const emptyNotificationsPanel = SuccessMessages.NOTIFICATIONS_EMPTY_LIST
    const acceptingInviteId = ref<string | null>(null)
    const decliningInviteId = ref<string | null>(null)

    async function acceptInvite(notification: Notification) {
      acceptingInviteId.value = notification.id
      try {
        const membership = await store.dispatch('teams/addUserToTeam', { inviteCode: notification.context.inviteCode })

        await Promise.all([
          store.dispatch('user/switchActiveTeam', { teamId: membership.teamId, isNewTeam: true }),
          store.dispatch('notifications/updateNotification', { notificationId: notification.id, status: NotificationStatus.Completed })
        ])

        ModalHandlerService.open('invite-accepted-successful')
      } catch {
        ToastNotificationService.push({ type: 'error', message: ErrorMessages.UNSUCCESSFUL_ADD_TO_ORGANISATION })
      }
      acceptingInviteId.value = null
    }

    async function declineInvite(notification: Notification) {
      decliningInviteId.value = notification.id
      await store.dispatch('notifications/updateNotification', { notificationId: notification.id, status: NotificationStatus.Dismissed })
      decliningInviteId.value = null
    }

    // Returns true if the notification is a team invite which has not already been accepted
    function isActionableTeamInvite(notification: Notification) {
      if (notification.context?.teamId) {
        if (store.getters['user/getMemberships'].some((m: Membership) => m.teamId === notification.context?.teamId)) {
          return false // user is already in the team
        }
      }
      return notification.type === 'TeamInvite' && notification.status !== 'Completed'
    }

    function isCheckInReminder(notification: Notification) {
      return notification.type === 'CheckInStartReminder' || notification.type === 'CheckInFinishingReminder' || notification.type === 'CheckInFinishedReminder'
    }

    function isDailySyncUpdated(notification: Notification) {
      return notification.type === 'DailySyncUpdated'
    }

    function isCheckInItemUpdated(notification: Notification) {
      return notification.type === 'CheckInItemUpdated'
    }

    function isObjectiveUpdated(notification: Notification) {
      return notification.type === 'ObjectiveUpdated'
    }

    function isGoalUpdated(notification: Notification) {
      return notification.type === 'GoalUpdated'
    }

    function isSocialConnectUpdated(notification: Notification) {
      return notification.type === 'SocialConnectUpdated'
    }

    function isDreInsightsUpdated(notification: Notification) {
      return notification.type === 'DreInsightsUpdated'
    }

    function getTeamMember(userId: string): UserProfile | undefined {
      return teamMembers.value.find((e: Membership) => e.userId === userId)?.userProfile
    }

    return {
      timeDiffCalc,
      NotificationStatus,
      emptyNotificationsPanel,
      acceptInvite,
      declineInvite,
      acceptingInviteId,
      decliningInviteId,
      isActionableTeamInvite,
      isCheckInReminder,
      getTeamMember,
      isDailySyncUpdated,
      isCheckInItemUpdated,
      isObjectiveUpdated,
      isGoalUpdated,
      isSocialConnectUpdated,
      isDreInsightsUpdated
    }
  }
})
