import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-alert__icon" }
const _hoisted_2 = { class: "app-alert__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", {
    role: "alert",
    class: _normalizeClass(["app-alert", [$props.theme && `app-alert--${$props.theme}`, $props.borderless && `app-alert--borderless`]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppIcon, {
        name: $props.icon,
        size: $props.iconSize
      }, null, 8, ["name", "size"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}