
import { ref, defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { SuccessMessages, ErrorMessages } from '@/data/messages'
import { UserProfile } from '@/types/user'

import ImageUploadService from '@/services/ImageUploadService'
import ModalHandlerService from '@/services/ModalHandlerService'
import ToastNotificationService from '@/services/ToastNotificationService'

import AppEdit from '@common/AppEdit.vue'
import { Membership } from '@/types/membership'
import { TeamProfile } from '@/types/team'

import SharedProfilePictureCrop from '@shared/SharedProfilePictureCrop.vue'
import SharedProfilePictureUpload from '@shared/SharedProfilePictureUpload.vue'

enum PICTURE_UPLOAD_VARIATION {
  SIMPLE = 'simple',
}

enum PICTURE_UPLOAD_THEMES {
  SQUARED = 'SQUARED',
}

export default defineComponent({
  name: 'AccountPictureUpload',
  components: {
    AppEdit,
    SharedProfilePictureCrop,
    SharedProfilePictureUpload
  },
  props: {
    id: {
      type: String,
      required: true
    },
    defaultAvatar: {
      type: String,
      default: require('@/assets/images/svg/avatar-placeholder.svg?data')
    },
    variation: {
      type: String,
      required: false,
      validator: (value: PICTURE_UPLOAD_VARIATION): boolean => Object.values(PICTURE_UPLOAD_VARIATION).includes(value)
    },
    theme: {
      type: String,
      required: false,
      in: PICTURE_UPLOAD_THEMES
    },
    title: {
      type: String,
      required: false
    },
    isTeamAvatar: {
      type: Boolean,
      default: false
    },
    isInQueue: {
      type: Boolean,
      default: false
    },
    isNewUser: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()
    const env = process.env.VUE_APP_DOMAIN === 'production' ? '' : `.${process.env.VUE_APP_DOMAIN}`
    const removing = ref(false)
    const userProfile = computed(() => store.getters['user/getUserProfile'])
    const activeMembership = computed<Membership>(() => store.getters['user/getActiveMembership'])

    const isSimpleVariation = computed(() => props.variation === PICTURE_UPLOAD_VARIATION.SIMPLE)
    const variationTitle = computed(() => props.isTeamAvatar ? 'Workspace picture' : 'Profile picture')
    const newPictureURL = ImageUploadService.getUrlData()

    const dynamicPictureURL = computed(() => {
      if (props.isTeamAvatar) {
        if (activeMembership.value?.teamProfile?.pictureId && !props.isInQueue) {
          return setCdnUrl(activeMembership.value?.teamProfile?.pictureId)
        } else if (newPictureURL.value?.pictureId && props.isNewUser) {
          return setCdnUrl(newPictureURL.value?.pictureId)
        }
      } else if (userProfile.value.pictureId) {
        return setCdnUrl(userProfile.value.pictureId)
      }
      return props.defaultAvatar
    })

    function setCdnUrl(pictureId: string) {
      return `https://content${env}.cadrelo.com/${pictureId}_large`
    }

    function handleEditModal() {
      ModalHandlerService.open(props.id)
    }

    const hasAvatar = computed(() => {
      if (props.isTeamAvatar) {
        return activeMembership.value?.teamProfile?.pictureId && !isSimpleVariation.value
      }
      return userProfile.value.pictureId && !isSimpleVariation.value
    })

    async function removeProfilePicture() {
      removing.value = true
      if (props.isTeamAvatar) {
        try {
          await store.dispatch('teams/updateTeam', {
            teamId: activeMembership.value.teamId,
            profile: new TeamProfile(
              activeMembership.value?.teamProfile.name,
              '',
              activeMembership.value?.teamProfile.industry,
              activeMembership.value?.teamProfile.size,
              activeMembership.value?.teamProfile.role
            )
          })
          ToastNotificationService.push({ type: 'info', message: SuccessMessages.ORGANISATION_DETAILS_UPDATED })
        } catch (error) {
          if (error instanceof Error) {
            ToastNotificationService.push({ type: 'error', message: ErrorMessages.UNSUCCESSFUL_ORGANISATION_DETAILS_UPDATE })
          }
        } finally {
          removing.value = false
        }
      } else {
        try {
          await store.dispatch('user/updateUser', { profile: new UserProfile(undefined, undefined, undefined, '') })
          ToastNotificationService.push({
            type: 'info',
            message: SuccessMessages.ACCOUNT_PICTURE_REMOVED
          })
        } catch (error) {
          ToastNotificationService.push({
            type: 'error',
            message: ErrorMessages.UNSUCCESSFUL_PICTURE_UPDATE
          })
        } finally {
          removing.value = false
        }
      }
    }

    function handleClickOnAvatar() {
      if (!userProfile.value.pictureId) {
        handleEditModal()
      }
    }

    return {
      PICTURE_UPLOAD_VARIATION,
      env,
      userProfile,
      removing,
      isSimpleVariation,
      ImageUploadService,
      ModalHandlerService,
      activeMembership,
      dynamicPictureURL,
      variationTitle,
      hasAvatar,
      removeProfilePicture,
      handleClickOnAvatar,
      handleEditModal
    }
  }
})
