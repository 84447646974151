import { InjectionKey } from 'vue'
import {
  useStore as useFactoryStore,
  createStore,
  Store
} from 'vuex'
import { RootState } from '@/types/store'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import teams from '@/store/modules/teams'
import groups from '@/store/modules/groups'
import checkin from '@/store/modules/checkin'
import notifications from '@/store/modules/notifications'
import play from '@/store/modules/play'
import integrations from '@/store/modules/integrations'
import timetracker from './modules/timetracker'
import dailySync from './modules/dailySync'
import conversation from './modules/conversation'
import goals from './modules/goals'
import socialConnect from './modules/socialConnect'
import dreInsights from './modules/dreInsights'
import dreTray from './modules/dreTray'
import { clearLoginExpirationTime, isLoginExpired } from '@/helpers/rememberMe'
import { WebSocketService } from '@/services/WebSocketService'
import { WebPushService } from '@/services/WebPushService'

export const store = createStore<RootState>({
  state: {
    preloadingData: false
  },
  getters: {
    isPreloadingData: (state) => state.preloadingData
  },
  mutations: {
    setPreloadingData(state, preloadingData: boolean) {
      state.preloadingData = preloadingData
    }
  },
  actions: {
    async login({ dispatch }) {
      if (isLoginExpired()) {
        clearLoginExpirationTime()
        await dispatch('auth/signOut')
      } else {
        // The user data is loaded first at which point the basic frame of the application is loaded and skeleton loaders are displayed for features
        await dispatch('user/getCurrentUser')
        // The feature data for the users active team is loaded next
        dispatch('loadActiveTeamDataIntoStores')
        // Lastly we setup the websocket connection and ensure web push subscriptions are up-to-date
        initNotifications()
      }
    },
    clearActiveTeamDataFromStores({ dispatch }) {
      dispatch('teams/clearActiveTeamData')
      dispatch('dailySync/clearActiveTeamData')
      dispatch('goals/clearActiveTeamData')
      dispatch('checkin/clearActiveTeamData')
      dispatch('notifications/clearActiveTeamData')
      dispatch('integrations/clearActiveTeamData')
      dispatch('socialConnect/clearActiveTeamData')
      dispatch('dreInsights/clearActiveTeamData')
      dispatch('dreTray/clearActiveTeamData')
      dispatch('groups/clearActiveTeamData')
    },
    async loadActiveTeamDataIntoStores({ commit, dispatch, getters }) {
      commit('setPreloadingData', true)
      try {
        const teamId = getters['user/getActiveMembership']?.teamId
        // Loading team data is split into two phases with the data required to render the current page being loaded first
        const pageLoadOrder = getPageLoadOrder(teamId)
        await Promise.all(pageLoadOrder.primaryPageLoads.map((page) => dispatch(`${page}/loadActiveTeamData`, { teamId })))
        await Promise.all(pageLoadOrder.secondaryPageLoads.map((page) => dispatch(`${page}/loadActiveTeamData`, { teamId })))
      } finally {
        commit('setPreloadingData', false)
      }
    }
  },
  modules: {
    auth,
    user,
    teams,
    checkin,
    notifications,
    integrations,
    play,
    dailySync,
    conversation,
    goals,
    socialConnect,
    dreInsights,
    dreTray,
    groups,
    timetracker
  }
})

function getPageLoadOrder(teamId?: string): { primaryPageLoads: string[], secondaryPageLoads: string[] } {
  const primaryPageLoads = []
  const secondaryPageLoads = ['notifications', 'dreTray']
  if (teamId) {
    primaryPageLoads.push('teams')
    primaryPageLoads.push('groups')
    secondaryPageLoads.push('integrations')
    if (isLoadingPage('/goals')) {
      primaryPageLoads.push('goals')
      secondaryPageLoads.push('dailySync')
      secondaryPageLoads.push('socialConnect')
      secondaryPageLoads.push('dreInsights')
      secondaryPageLoads.push('checkin')
    } else if (isLoadingPage('/pulse')) {
      primaryPageLoads.push('checkin')
      secondaryPageLoads.push('dailySync')
      secondaryPageLoads.push('socialConnect')
      secondaryPageLoads.push('dreInsights')
      secondaryPageLoads.push('goals')
    } else {
      primaryPageLoads.push('dailySync')
      primaryPageLoads.push('socialConnect')
      primaryPageLoads.push('dreInsights')
      secondaryPageLoads.push('checkin')
      secondaryPageLoads.push('goals')
    }
  }
  return { primaryPageLoads, secondaryPageLoads }
}

function isLoadingPage(page: string) {
  return location.pathname?.startsWith(page) || location.hash?.startsWith(`#${page}`)
}

function initNotifications() {
  WebSocketService.instance.connect()
  if ('Notification' in window && Notification.permission === 'granted') {
    WebPushService.instance.updatePushSubscriptions()
  }
}

export const key: InjectionKey<Store<RootState>> = Symbol(1)
export const useStore = (): Store<RootState> => useFactoryStore(key)
