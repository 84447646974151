import { DateTime } from 'luxon'
import { ref } from 'vue'

export const dateNow = ref(DateTime.now())
setInterval(() => {
  dateNow.value = DateTime.now()
}, 60000)

export function timeDiffCalc (dateFuture: string, trimMinutes: boolean, returnDaysDifference = false) : string {
  let diffInMilliSeconds = Math.abs(new Date(dateFuture).valueOf() - dateNow.value.valueOf()) / 1000
  let minutesFormat = 'minute'

  const days = Math.floor(diffInMilliSeconds / 86400)
  diffInMilliSeconds -= days * 86400

  const hours = Math.floor(diffInMilliSeconds / 3600) % 24
  diffInMilliSeconds -= hours * 3600

  const minutes = Math.floor(diffInMilliSeconds / 60) % 60
  diffInMilliSeconds -= minutes * 60

  let difference = ''
  let daysDifference = ''

  if (minutes < 1 && hours < 1 && days < 1) {
    return 'a few moments ago'
  }

  if (minutes > 0) {
    if (trimMinutes) {
      minutesFormat = 'min'
    }

    difference = (minutes === 1) ? `${minutes} ${minutesFormat}` : `${minutes} ${minutesFormat}s`
  }

  if (hours > 0) {
    difference = (hours === 1) ? `${hours} hour ` : `${hours} hours`
  }

  if (days > 0) {
    daysDifference = (days === 1) ? `${days} day` : `${days} days`
  }

  if (days > 0 && days < 7) {
    difference = (days === 1) ? `${days} day` : `${days} days`
  }

  if (days > 6) {
    const weeks = Math.floor(days / 7)
    difference = (weeks === 1) ? `${weeks} week` : `${weeks} weeks`
  }

  if (returnDaysDifference) {
    if (days === 0) {
      return `${difference}`
    } else {
      return `${daysDifference}`
    }
  }

  return `${difference} ago`
}
