import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppOverlay = _resolveComponent("AppOverlay")!
  const _component_FadeTransition = _resolveComponent("FadeTransition")!
  const _component_AppQuiz = _resolveComponent("AppQuiz")!
  const _component_QuizToggleTransition = _resolveComponent("QuizToggleTransition")!
  const _component_SharedCreateGroupModal = _resolveComponent("SharedCreateGroupModal")!
  const _component_SharedUserAddedToTeamModal = _resolveComponent("SharedUserAddedToTeamModal")!
  const _component_SharedCreateOrgModal = _resolveComponent("SharedCreateOrgModal")!
  const _component_SharedUserOnboardingCards = _resolveComponent("SharedUserOnboardingCards")!
  const _component_AddMemberModal = _resolveComponent("AddMemberModal")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
    _createVNode(_component_FadeTransition, { duration: { enter: 350, leave: 350 } }, {
      default: _withCtx(() => [
        (_ctx.QuizService.isOngoing())
          ? (_openBlock(), _createBlock(_component_AppOverlay, {
              key: 0,
              variant: "quiz-card",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.QuizService.isEditing() ? _ctx.QuizService.close() : null))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_QuizToggleTransition, null, {
      default: _withCtx(() => [
        (_ctx.QuizService.isOngoing())
          ? (_openBlock(), _createBlock(_component_AppQuiz, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_SharedCreateGroupModal),
    _createVNode(_component_SharedUserAddedToTeamModal),
    _createVNode(_component_SharedCreateOrgModal),
    _createVNode(_component_FadeTransition, { duration: { enter: 350, leave: 350 } }, {
      default: _withCtx(() => [
        _createVNode(_component_SharedUserOnboardingCards)
      ]),
      _: 1
    }),
    (_ctx.teams?.length)
      ? (_openBlock(), _createBlock(_component_AddMemberModal, {
          key: 0,
          theme: "onboarding",
          id: "add-member-modal-onboarding"
        }))
      : _createCommentVNode("", true)
  ]))
}