
import { defineComponent, PropType } from 'vue'

enum AVAILABLE_DIRECTIONS {
  FORWARD = 'forward',
  BACKWARD = 'backward'
}

export default defineComponent({
  name: 'SlideLeftRightTransition',
  props: {
    direction: {
      type: String as PropType<AVAILABLE_DIRECTIONS>,
      required: true
    }
  },
  setup() {
    return {
      AVAILABLE_DIRECTIONS
    }
  }
})
