
import { defineComponent } from 'vue'

import AppLogo from '@common/AppLogo.vue'
import AppMarketing from '@common/AppMarketing.vue'

export default defineComponent({
  name: 'AuthLayout',
  components: {
    AppLogo,
    AppMarketing
  }
})
