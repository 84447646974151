
import { defineComponent, PropType } from 'vue'
import { Membership } from '@/types/membership'
import Reaction, { ReactionId } from '@/types/reaction'
import { useStore } from '@/store'
export default defineComponent({
  name: 'ReactionDisplay',
  components: {
  },
  props: {
    reactions: {
      type: Array as PropType<Reaction[]>,
      required: false
    }
  },
  setup(props) {
    const store = useStore()

    function reactionCount() {
      return props.reactions?.length ?? 0
    }

    function includesReaction(id: ReactionId) {
      return props.reactions?.some(e => e.id === id)
    }

    function reactionAuthors(id?: ReactionId) {
      const members: Membership[] = store.getters['teams/getActiveTeamMembers']
      return props.reactions?.filter(e => e.id === id || id === undefined).map(e => members.find(m => m.userId === e.userId)) ?? []
    }

    return {
      reactionCount,
      includesReaction,
      reactionAuthors,
      ReactionId
    }
  }
})
