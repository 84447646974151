
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'DailySyncOnboarding',
  components: {

  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }) {
    const showOnboarding = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    const onboardingStep = ref(1)

    function setOnboardingStep(step: number) {
      onboardingStep.value = step
    }

    function hideOnboarding() {
      onboardingStep.value = 1
      showOnboarding.value = false
    }

    return {
      showOnboarding,
      onboardingStep,
      setOnboardingStep,
      hideOnboarding
    }
  }
})
