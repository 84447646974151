
import { defineComponent, PropType, computed, ref } from 'vue'
import { UserProfile } from '@/types/user'
import FadeTransition from '@transition/FadeTransition.vue'
import { TeamProfile } from '@/types/team'

enum SIZE_VARIATIONS {
  TINY = 'small',
  SMALLER = 'small',
  SMALL = 'small',
  NORMAL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export default defineComponent({
  name: 'AppAvatar',
  components: {
    FadeTransition
  },
  props: {
    user: {
      type: Object as PropType<UserProfile>,
      required: false
    },
    team: {
      type: Object as PropType<TeamProfile>,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: SIZE_VARIATIONS.SMALL,
      validator: (value: string): boolean => {
        return Object.keys(SIZE_VARIATIONS)
          .map((variation) => variation.toLowerCase())
          .includes(value)
      }
    },
    rounded: {
      type: Boolean,
      required: false
    },
    showIfEmpty: {
      type: Boolean,
      default: false
    },
    elevated: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const env = process.env.VUE_APP_DOMAIN === 'production' ? '' : `.${process.env.VUE_APP_DOMAIN}`
    const isAvatarLoaded = ref(false)
    const dynamicPictureID = computed(() => props.team ? props.team.pictureId : props.user?.pictureId)

    const initials = computed(() => {
      if (props.team) {
        return props.team.name?.charAt(0)
      }

      return props.user?.firstName && props.user?.lastName
        ? `${props.user.firstName?.charAt(0)}${props.user.lastName?.charAt(0)}`
        : props.user?.displayName?.charAt(0)
    })

    const pictureSize = computed(() => {
      const mappedValueToKey = props.size.toUpperCase() as keyof typeof SIZE_VARIATIONS

      return SIZE_VARIATIONS[mappedValueToKey]
    })

    return {
      env,
      initials,
      isAvatarLoaded,
      pictureSize,
      dynamicPictureID
    }
  }
})
