
import { defineComponent, ref, onMounted, onUpdated } from 'vue'

import { ERRORS } from '@/data/messages'

import ToastNotificationService from '@/services/ToastNotificationService'
import QuizService, { QUIZ_STAGE_DEFINITION } from '@/services/QuizService'

import QuizCard from '@feature/quiz/QuizCard.vue'
import QuizInteractionButton from '@feature/quiz/QuizInteractionButton.vue'

type GiphyCustomResponse = { gif: string }

export default defineComponent({
  name: 'QuizBasicStageGifs',
  emits: ['quiz-navigation:next', 'quiz-navigation:prev'],
  components: {
    QuizCard,
    QuizInteractionButton
  },
  setup(_, { emit }) {
    const { giphyIds } = QuizService.getBasicStageData()
    const searchTerm = ref('')
    const fetchedGifs = ref<GiphyCustomResponse[]>([])
    const selectedGifs = ref<string[]>(giphyIds.slice())
    const activeGifSlotIndex = ref(0)
    const displayGifSlider = ref(false)
    const searchTimeout = ref<number | null>(null)
    const isLoading = ref(false)

    /** GIPHY API config */
    const giphyApiKey = process.env.VUE_APP_GIPHY_API_KEY
    const giphyPayloadLimit = 10

    async function fetchGifsFromEndpoint(): Promise<GiphyCustomResponse[] | undefined> {
      const url = `https://api.giphy.com/v1/gifs/${searchTerm.value ? `search?&q=${searchTerm.value}` : 'trending?'}&api_key=${giphyApiKey}&limit=${giphyPayloadLimit}`

      try {
        const response = await fetch(url)
        const { data } = await response.json()
        const formattedData = data.map((gif: { images: { downsized: { url: string } } }) => ({ gif: gif.images.downsized.url }))

        return formattedData
      } catch (error) {
        console.log(error)
        ToastNotificationService.push({
          type: 'error',
          message: ERRORS.GIPHY_GENERIC
        })
      }
    }

    function performSearch(value: string) {
      searchTerm.value = value
      isLoading.value = true

      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value)
      }

      searchTimeout.value = window.setTimeout(getGifs, 300)
    }

    function selectGif(index: number) {
      searchTerm.value = ''
      displayGifSlider.value = !displayGifSlider.value
      activeGifSlotIndex.value = index
    }

    function setSelectedGif(gif: string) {
      displayGifSlider.value = false
      selectedGifs.value.splice(activeGifSlotIndex.value - 1, 1, gif)
      performSearch('')
    }

    async function getGifs() {
      const gifs = await fetchGifsFromEndpoint()
      isLoading.value = false

      if (gifs) {
        fetchedGifs.value = gifs
      }
    }

    function navigateNext() {
      emit('quiz-navigation:next')
      QuizService.save({
        giphyIds: selectedGifs.value.filter((e) => e !== '')
      })

      QuizService.next()

      if (!QuizService.editing.value) {
        QuizService.skipToStage(QUIZ_STAGE_DEFINITION.AVAILABILITY)
      }
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')
      QuizService.prev()
    }

    onMounted(getGifs)
    onUpdated(getGifs)

    return {
      QuizService,
      displayGifSlider,
      selectGif,
      selectedGifs,
      fetchedGifs,
      isLoading,
      setSelectedGif,
      searchTerm,
      performSearch,
      navigateNext,
      navigatePrev
    }
  }
})
