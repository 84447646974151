
import { computed, defineComponent, PropType } from 'vue'
import { useStore } from '@/store'
import { DreTrayMessage } from '@/types/dreTray'
import AppAvatar from '@common/AppAvatar.vue'
import { timeDiffCalc } from '@/utils/calculateTimeDiff'

export default defineComponent({
  name: 'DreTrayMessage',
  emits: ['on-pill-select'],
  components: {
    AppAvatar
  },
  props: {
    message: {
      type: Object as PropType<DreTrayMessage>,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const isDre = computed(() => props.message.author === 'dre')
    const userProfile = computed(() => store.getters['user/getUserProfile'])

    return {
      isDre,
      userProfile,
      timeDiffCalc
    }
  }
})
