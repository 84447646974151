
import { computed, defineComponent, ref } from 'vue'
import Modal from '@common/Modal.vue'
import { SuccessMessages } from '@/data/messages'
import { useStore } from '@/store'
import ModalHandlerService from '@/services/ModalHandlerService'

export default defineComponent({
  name: 'SharedUserAddedToTeamModal',
  components: {
    Modal
  },
  setup() {
    const store = useStore()
    const inviteModalTitle = ref(SuccessMessages.TEAM_INVITATION_ACCEPTED_TITLE)
    const inviteModalMessage = computed(() => `${SuccessMessages.TEAM_INVITATION_ACCEPTED} <strong>${store.getters['user/getActiveMembership']?.teamProfile?.name}</strong>`)

    const closeModal = () => {
      ModalHandlerService.close()
    }

    return {
      inviteModalTitle,
      inviteModalMessage,
      closeModal
    }
  }
})
