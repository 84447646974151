import { store } from '@/store'

export default async function waitForCurrentPreloadToFinish(): Promise<void> {
  const preloadHasFinished = (resolve: () => void) => {
    if (store.getters.isPreloadingData === false) {
      resolve()
    } else {
      console.log('Waiting 200ms for preload to finish')
      setTimeout(() => preloadHasFinished(resolve), 200)
    }
  }
  return new Promise(preloadHasFinished)
}
