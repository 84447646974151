
import { defineComponent, PropType } from 'vue'

import { IPictureObject } from '@/types/global'

export default defineComponent({
  name: 'AppInteractionButton',
  props: {
    description: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    image: {
      type: Object as PropType<IPictureObject | undefined>,
      required: false
    }
  },
  setup(props, { emit }) {
    function interactionButtonClickHandler() {
      if (!props.image) {
        emit('user-action:click')
      }
    }

    return {
      interactionButtonClickHandler
    }
  }
})
