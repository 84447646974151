
import { defineComponent, onMounted, onUnmounted } from 'vue'
import BodyLockService from '@/services/BodyLockService'
import AppOverlay from '@common/AppOverlay.vue'

export default defineComponent({
  name: 'AppTeamSwitchLoader',
  components: {
    AppOverlay
  },
  setup() {
    onMounted(() => {
      BodyLockService.lock()
    })

    onUnmounted(() => {
      BodyLockService.unlock()
    })
  }
})
