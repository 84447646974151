
import { defaultMood, MOOD_OPTIONS } from '@/data/daily-sync'
import AppDropdown from '@common/AppDropdown.vue'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'DailySyncMoodSelector',
  components: {
    AppDropdown
  },
  props: {
    modelValue: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const selectedMood = computed({
      get: () => MOOD_OPTIONS.find((e) => e.emoji === props.modelValue) ?? defaultMood,
      set: (value) => emit('update:modelValue', value.emoji)
    })

    return {
      selectedMood,
      MOOD_OPTIONS
    }
  }
})
