import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-chip__icon"
}
const _hoisted_2 = { class: "app-chip__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-chip", [
      _ctx.divider && 'app-chip--has-divider',
      _ctx.theme && `app-chip--${_ctx.theme}`
    ]])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createVNode(_component_AppIcon, {
            name: _ctx.icon,
            size: 14
          }, null, 8, ["name"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1),
    _createElementVNode("button", {
      class: "app-chip__remove",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-remove')))
    }, [
      _createVNode(_component_AppIcon, {
        name: "close",
        size: _ctx.theme === 'secondary' ? 8 : 7
      }, null, 8, ["size"])
    ])
  ], 2))
}