
import { onMounted, defineComponent, computed, ref } from 'vue'
import { useStore } from '@/store'

import DailySync from '@/types/dailySync'
import { User } from '@/types/user'
import { dateNow } from '@/utils/calculateTimeDiff'

import Modal from '@common/Modal.vue'
import DailySyncUserCard from '@/components/feature/daily-sync/DailySyncUserCard.vue'
import DailySyncSubmission from '@/components/feature/daily-sync/DailySyncSubmission.vue'

import ModalHandlerService from '@/services/ModalHandlerService'
import WindowEventsService, { WINDOW_EVENTS } from '@/services/WindowEventsService'

import AppTabs from '@common/AppTabs.vue'
import GoalsBacklog from '@/components/feature/side-bar/GoalsBacklog.vue'
import DailySyncBacklog from '@feature/daily-sync/DailySyncBacklog.vue'

export default defineComponent({
  name: 'DailySyncOverlay',
  emits: ['close-modal'],
  components: {
    Modal,
    DailySyncUserCard,
    DailySyncSubmission,
    AppTabs,
    GoalsBacklog,
    DailySyncBacklog
  },
  setup() {
    enum VIEWS {
      GOALS = 'Goals', BACKLOG = 'Personal backlog'
    }

    const user = computed<User>(() => store.getters['user/getUser'])
    const dailySyncs = computed<Record<string, DailySync[]>>(() => store.getters['dailySync/getDailySyncs'])
    const isDailySyncsLoaded = computed<boolean>(() => store.getters['teams/isTeamDataLoaded'] && store.getters['teams/getActiveTeam']?.dailySyncEnabled && store.getters['dailySync/isDailySyncsDataLoaded'])
    const dayBeingViewedOffset = ref(0)
    const dayBeingViewed = computed<string>(() => dateNow.value.plus({ days: dayBeingViewedOffset.value }).toISODate())
    const dailySyncsForDayBeingViewed = computed<DailySync[]>(() => dailySyncs.value[dayBeingViewed.value])
    const usersDailySyncForDayBeingViewed = computed<DailySync | undefined>(() => dailySyncsForDayBeingViewed.value?.find((e) => e.userId === user.value.userId))
    const userHasSubmittedSyncForDayBeingViewed = computed<boolean>(() => usersDailySyncForDayBeingViewed.value?.draft === false)

    const trackingState = computed(() => store.getters['timetracker/getActiveTrackingStatus'])

    const dailySyncOverlayActive = computed(() => ModalHandlerService.isCurrentlyActive('daily-sync-overlay'))

    const view = ref<VIEWS>(VIEWS.BACKLOG)
    const store = useStore()
    const isBacklogActive = ref(false)
    const CSS_BREAKPOINT_MD = 1024
    const isMobile = ref(false)

    const backlogVisible = computed(() => {
      if (isMobile.value) {
        return isBacklogActive.value
      }
      return true
    })

    const syncVisible = computed(() => !isBacklogActive.value)

    WindowEventsService.on(WINDOW_EVENTS.RESIZE, () => {
      isMobile.value = window.innerWidth < CSS_BREAKPOINT_MD
    })

    function setView(selectedView: VIEWS) {
      view.value = selectedView
    }

    function dailyPosted() {
      isBacklogActive.value = false
    }

    onMounted(() => {
      isMobile.value = window.innerWidth < CSS_BREAKPOINT_MD
    })

    return {
      user,
      ModalHandlerService,
      isBacklogActive,
      backlogVisible,
      syncVisible,
      dailyPosted,
      VIEWS,
      view,
      setView,
      usersDailySyncForDayBeingViewed,
      isDailySyncsLoaded,
      userHasSubmittedSyncForDayBeingViewed,
      dayBeingViewed,
      dailySyncOverlayActive,
      trackingState
    }
  }
})
