
import { computed, defineComponent, PropType } from 'vue'

import QuizService, { QUIZ_AVAILABILITY_STAGE_DEFINITION, QUIZ_STAGE_DEFINITION } from '@/services/QuizService'
import { WorkDaySchedule, WorkLocation } from '@/types/user'

import AppDropdown from '@common/AppDropdown.vue'
import AppTimePicker, { Time } from '@common/AppTimePicker.vue'

export default defineComponent({
  name: 'DailySyncAvailabilitySelector',
  emits: ['update:modelValue'],
  components: {
    AppTimePicker,
    AppDropdown
  },
  props: {
    modelValue: {
      type: Object as PropType<WorkDaySchedule>,
      default: () => ({}) // v-model is not required here
    }
  },
  setup(props, { emit }) {
    const schedule = computed({
      get: () => props.modelValue,
      set: (value: WorkDaySchedule) => emit('update:modelValue', value)
    })

    const startTime = computed(() => new Time(
      schedule.value.endHour as number,
      schedule.value.endMinute as number
    ))

    const endTime = computed(() => new Time(
      schedule.value.startHour as number,
      schedule.value.startMinute as number
    ))

    function updateTime(updatedTime: Time, startTimeDefined: boolean) {
      if (startTimeDefined) {
        schedule.value.startHour = updatedTime.hour
        schedule.value.startMinute = Number(updatedTime.minute)
      } else {
        schedule.value.endHour = updatedTime.hour
        schedule.value.endMinute = Number(updatedTime.minute)
      }

      emit('update:modelValue', schedule.value)
    }

    return {
      schedule,
      startTime,
      endTime,
      WorkLocation,
      QuizService,
      QUIZ_STAGE_DEFINITION,
      QUIZ_AVAILABILITY_STAGE_DEFINITION,
      updateTime
    }
  }
})
