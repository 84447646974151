import { ref } from 'vue'
import { DailySyncTask } from '@/types/dailySync'

export const trackingActive = ref(false)
export const trackedTask = ref('')

interface TData {
  hours: number
  minutes: number
}

export function calculateTimeTracked(tasks: DailySyncTask[]): TData {
  let totalMinutes = 0
  tasks.forEach((task) => {
    if (task.timeblocks) {
      task.timeblocks.forEach((timeblock) => {
        if (timeblock?.start && timeblock?.end) {
          const start = new Date(timeblock?.start)
          const end = new Date(timeblock?.end)
          const diff = (end.valueOf() - start.valueOf()) / 1000 / 60
          totalMinutes += diff
        }
      })
    }
  })
  return toHoursAndMinutes(totalMinutes)
}

function toHoursAndMinutes(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = Math.floor(totalMinutes % 60)

  return { hours, minutes }
}
