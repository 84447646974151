import { Module } from 'vuex'
import { Auth } from 'aws-amplify'

import { AuthState, RootState } from '@/types/store'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types'

import ApiService from '@/services/ApiService'
import { WebSocketService } from '@/services/WebSocketService'
import { setLoginExpirationTime } from '@/helpers/rememberMe'
import router from '@/router'

// Note from @rizvanadnan: With the introduction of user/team modules this module becomes obsolete
// > We should look to extract this into a service or something along those lines

const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async logIn({ dispatch, rootGetters }, params: { username: string, password: string, rememberUser: boolean, isFromSignIn?: boolean }): Promise<void> {
      await Auth.signIn(params.username, params.password)
      setLoginExpirationTime(params.rememberUser)
      await dispatch('login', {}, { root: true })
      if (!rootGetters['user/getUser'].verified && !params.isFromSignIn) {
        throw new Error('User is not verified.')
      }
    },
    async signUp({ dispatch }, params: { username: string, password: string }): Promise<void> {
      await Auth.signUp({
        ...params,
        clientMetadata: {
          redirectBaseUrl: `${process.env.VUE_APP_BASE_URL}/sign-up`
        }
      })

      await dispatch('logIn', { ...params, rememberUser: false, isFromSignIn: true })
    },
    async signOut({ dispatch, commit }) {
      try {
        await Auth.signOut()
        // localStorage.clear()
        commit('user/setUser', {}, { root: true })
        commit('teams/setActiveTeam', {}, { root: true })
        dispatch('clearActiveTeamDataFromStores', undefined, { root: true })
        WebSocketService.instance.disconnect()
        router.push('/sign-in')
      } catch (error) {
        console.info('Error signing out. Reason:', error)
      }
    },
    async signInWithGoogle(): Promise<void> {
      try {
        await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
      } catch (error) {
        console.info('Unable to sign in with Google. Reason:', error)
      }
    },
    async signInWithFacebook(): Promise<void> {
      try {
        await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook })
      } catch (error) {
        console.info('Unable to sign in with Facebook. Reason:', error)
      }
    },
    async confirmUser({ dispatch }, params: { verificationCode: string }): Promise<void> {
      const response = await ApiService.sendRequest('sign-up-verification', {
        method: 'POST',
        requiresAuth: false,
        body: JSON.stringify({
          code: params.verificationCode
        })
      })

      if (!response.ok) {
        console.info(`There was an error connecting to the API: ${response.status} - ${JSON.stringify(await response.json())}`)
      }
      await dispatch('login', {}, { root: true }) // refresh the user as they will now be verified
    },
    async resendEmail(_, params: { username: string }): Promise<void> {
      const response = await ApiService.sendRequest('resend-email-verification', {
        method: 'POST',
        requiresAuth: false,
        body: JSON.stringify({
          username: params.username
        })
      })

      if (!response.ok) {
        console.info(`There was an error connecting to the API: ${response.status} - ${JSON.stringify(await response.json())}`)
      }
    },
    async forgotPassword(_, params: { email: string }): Promise<void> {
      await Auth.forgotPassword(params.email)
    },
    async forgotPasswordSubmit(_, params: { email: string, code: string, newPassword: string }): Promise<void> {
      await Auth.forgotPasswordSubmit(params.email, params.code, params.newPassword)
    },
    async changePassword(_, params: { oldPassword: string, newPassword: string }): Promise<void> {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, params.oldPassword, params.newPassword)
    }
  }
}

export default auth
