<template>
  <FadeTransition is-group>
    <AppTeamSwitchLoader @click.stop v-if="$store.getters['user/isSwitchingActiveTeam'] && route.meta.requiresAuth" />
    <AppNoAccessOverlay v-if="$store.getters['teams/getShowNotATeamMemberOverlay']" />
  </FadeTransition>

  <component :is="layout">
    <router-view v-slot="{ Component, route }">
      <FadeTransition mode="out-in">
        <component :is="Component" :key="route.matched[0]?.path ?? route.name" />
      </FadeTransition>
    </router-view>
  </component>

  <ToastNotificationFacility />
</template>

<script>
import { defineComponent, watch, shallowRef, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'
import AppNoAccessOverlay from '@core/AppNoAccessOverlay.vue'
import AppTeamSwitchLoader from '@core/AppTeamSwitchLoader.vue'

import ToastNotificationFacility from '@/components/core/ToastNotificationFacility.vue'

import FadeTransition from '@transition/FadeTransition.vue'

export default defineComponent({
  name: 'AppLayout',
  components: {
    ToastNotificationFacility,
    FadeTransition,
    AppNoAccessOverlay,
    AppTeamSwitchLoader
  },
  setup() {
    const route = useRoute()
    const layout = shallowRef(DefaultLayout)
    const userTheme = ref(getTheme() || getMediaPreference())

    function getTheme() {
      return localStorage.getItem('user-theme')
    }

    function getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches
      if (hasDarkPreference) {
        return 'dark-theme'
      } else {
        return 'light-theme'
      }
    }

    function setTheme() {
      console.log('Theme set ' + userTheme.value)
      localStorage.setItem('user-theme', userTheme.value)
      document.documentElement.className = userTheme.value
    }

    /* onMounted(() => {
      setTheme()
    }) */

    watch(
      () => route.meta,
      async meta => {
        layout.value = meta?.layout || DefaultLayout
      },
      { immediate: true }
    )
    return { layout, route }
  }
})
</script>
