import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70516f56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-onboarding-cards__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserOnBoardingTextContentStructure = _resolveComponent("UserOnBoardingTextContentStructure")!
  const _component_UserOnboardingCreateProfile = _resolveComponent("UserOnboardingCreateProfile")!
  const _component_UserOnboardingCreateOrg = _resolveComponent("UserOnboardingCreateOrg")!
  const _component_AppSlider = _resolveComponent("AppSlider")!

  return (_openBlock(), _createBlock(_component_AppSlider, {
    class: _normalizeClass(["user-onboarding-cards", { 'user-onboarding-cards--end' : _ctx.swiper && _ctx.swiper.isEnd }]),
    spaceBetween: 0,
    slidesPerView: "auto",
    sliderData: _ctx.USER_ONBOARDING_CARDS,
    navigation: false,
    pagination: false,
    displaySliderButtons: false,
    allowTouchMove: false,
    width: null,
    onSwiper: _ctx.onSwiperInit
  }, {
    default: _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_UserOnBoardingTextContentStructure, {
          title: props.slide.title,
          description: _ctx.isTeamInvite ? _ctx.invitedByTeamDescription : props.slide.description
        }, null, 8, ["title", "description"]),
        (props.slide.id === 1)
          ? (_openBlock(), _createBlock(_component_UserOnboardingCreateProfile, {
              key: 0,
              data: props.slide,
              "onNavigate:next": _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleCreateProfile()))
            }, null, 8, ["data"]))
          : (!_ctx.isTeamInvite)
            ? (_openBlock(), _createBlock(_component_UserOnboardingCreateOrg, {
                key: 1,
                data: props.slide,
                "onNavigate:next": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onboarding-flow-finished'))),
                "onNavigate:skip": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onboarding-flow-finished')))
              }, null, 8, ["data"]))
            : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["class", "sliderData", "onSwiper"]))
}