import { Module } from 'vuex'
import ApiService from '@/services/ApiService'
import { GoalState, RootState } from '@/types/store'
import { Goal, CreateGoalRequest, UpdateGoalRequest } from '@/types/objective'

import { MixPanelService } from '@/services/MixPanelService'
import { DailySyncTask } from '@/types/dailySync'

const goals: Module<GoalState, RootState> = {
  namespaced: true,
  state: {
    goals: [] as Goal[],
    goalsLoaded: false
  },
  getters: {
    getGoals: (state) => state.goals,
    isGoalsLoaded: (state) => state.goalsLoaded
  },
  mutations: {
    setGoals(state, value: Goal[]) {
      state.goals = value
    },
    setGoalsLoaded(state, value: boolean) {
      state.goalsLoaded = value
    },
    syncChangesForGoal(state, value: Goal) {
      const goal = state.goals.find(g => g.id === value.id)
      if (goal) {
        goal.title = value.title ?? goal.title
        goal.goalRef = value.goalRef ?? goal.goalRef
        goal.goalColor = value.goalColor ?? goal.goalColor
        goal.description = value.description ?? goal.description
        goal.status = value.status ?? goal.status
        goal.completionPercentage = value.completionPercentage ?? goal.completionPercentage
        goal.owners = value.owners ?? goal.owners
        goal.contributors = value.contributors ?? goal.contributors
        goal.groups = value.groups ?? goal.groups
        goal.dueDate = value.dueDate ?? goal.dueDate
        goal.successText = value.successText ?? goal.successText
        goal.mentionedUsers = value.mentionedUsers ?? goal.mentionedUsers
        goal.commentCount = value.commentCount ?? goal.commentCount
        goal.tasks = value.tasks ?? goal.tasks
        goal.focusGoal = value.focusGoal ?? goal.focusGoal
      } else {
        state.goals.unshift(value)
      }
    },
    deleteGoal(state, value: { goalId: number }) {
      state.goals = state.goals.filter(e => e.id !== value.goalId)
    },
    addTaskToGoal(state, value: { id: number, task: DailySyncTask }) {
      const goal = state.goals.find(g => g.id === value.id)
      if (goal) {
        goal.tasks.push(value.task)
      }
    },
    updateTaskOnGoal(state, value: { id: number, task: DailySyncTask }) {
      const goal = state.goals.find(g => g.id === value.id)
      if (goal) {
        const taskIndex = goal.tasks.findIndex(t => t.id === value.task.id)
        if (taskIndex !== -1) {
          goal.tasks[taskIndex] = value.task
        }
      }
    },
    deleteTaskOnGoal(state, value: { id: number, taskId: string }) {
      const goal = state.goals.find(g => g.id === value.id)
      if (goal) {
        goal.tasks = goal.tasks.filter(e => e.id !== value.taskId)
      }
    }
  },
  actions: {
    clearActiveTeamData({ commit }) {
      commit('setGoals', [])
      commit('setGoalsLoaded', false)
    },
    async loadActiveTeamData({ commit, dispatch }, params: { teamId: string }): Promise<void> {
      await dispatch('getGoals', { teamId: params.teamId })
      commit('setGoalsLoaded', true)
    },
    async getGoals({ commit }, params: { teamId: string }): Promise<void> {
      try {
        const response = await ApiService.sendTrackedRequest(`teams/${params.teamId}/goals`, {
          method: 'GET',
          requiresAuth: true
        })
        commit('setGoals', response)
      } catch (error) {
        throw new Error('Could not retrieve backlog')
      }
    },
    async addGoal({ commit }, params: { teamId: string, goal: CreateGoalRequest }): Promise<Goal> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/goals`, {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify(params.goal)
      })

      if (!response.ok) {
        throw new Error('Could not add new goal')
      } else {
        const goal = await response.json()
        commit('syncChangesForGoal', goal)
        MixPanelService.instance.onAddGoal(goal)
        return goal
      }
    },
    async updateGoal({ commit }, params: { goalId: number, teamId: string, goal: UpdateGoalRequest }): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/goals/${params.goalId}`, {
        method: 'PATCH',
        requiresAuth: true,
        body: JSON.stringify(params.goal)
      })

      if (!response.ok) {
        throw new Error('Could not update goal')
      } else {
        commit('syncChangesForGoal', { id: params.goalId, ...params.goal })
        MixPanelService.instance.onUpdateGoal(params.goalId, params.goal.status, params.goal.completionPercentage)
      }
    },
    async deleteGoal({ commit }, params: { teamId: string, goal: Goal }): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/goals/${params.goal.id}`, {
        method: 'DELETE',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Could not delete goal')
      } else {
        commit('deleteGoal', { goalId: params.goal.id })
      }
    },
    // Find a goal by id. Will load objective data if it is not already loaded.
    async findGoal({ state, dispatch, rootGetters }, params: { goalId: number }): Promise<Goal | undefined> {
      if (!state.goalsLoaded) {
        await dispatch('loadActiveTeamData', { teamId: rootGetters['user/getActiveMembership']?.teamId })
      }
      return state.goals.find(e => e.id === params.goalId)
    },
    async addTaskToGoal({ commit }, params: { goalId: number, teamId: string, task: DailySyncTask }): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/goals/${params.goalId}/tasks/${params.task.id}`, {
        method: 'PUT',
        requiresAuth: true,
        body: JSON.stringify(params.task)
      })

      if (!response.ok) {
        throw new Error('Could not add task to goal')
      } else {
        commit('addTaskToGoal', { id: params.goalId, task: params.task })
        commit('dailySync/syncTaskUpdates', [params.task], { root: true })
      }
    },
    async updateTaskOnGoal({ commit }, params: { goalId: number, teamId: string, task: DailySyncTask }): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/goals/${params.goalId}/tasks/${params.task.id}`, {
        method: 'PUT',
        requiresAuth: true,
        body: JSON.stringify(params.task)
      })

      if (!response.ok) {
        throw new Error('Could not add task to goal')
      } else {
        commit('updateTaskOnGoal', { id: params.goalId, task: params.task })
        commit('dailySync/syncTaskUpdates', [params.task], { root: true })
      }
    },
    async deleteTaskOnGoal({ commit }, params: { goalId: number, teamId: string, taskId: string }): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/goals/${params.goalId}/tasks/${params.taskId}`, {
        method: 'DELETE',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Could not add task to goal')
      } else {
        commit('deleteTaskOnGoal', { id: params.goalId, taskId: params.taskId })
      }
    }
  }
}

export default goals
