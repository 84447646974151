interface InputValidationErrors {
  [key: string]: {
    [key: string]: string
  }
}

export default Object.seal<InputValidationErrors>({
  password: {
    case: 'Password must contain uppercase and lowercase letters.',
    digit: 'Password must contain numbers.',
    empty: 'Password is required',
    format: 'Password must be in valid format',
    minLength: 'Password must contain at least 8 characters.'
  },
  email: {
    empty: 'Email is required',
    format: 'Email is not in valid format.',
    notFound: 'User does not exist.'
  },
  confirmPassword: {
    empty: 'Password confirmation is required',
    format: 'Password must be in valid format',
    match: 'Passwords must match'
  },
  currentPassword: {
    empty: 'Current password is required'
  },
  termsOfService: {
    checked: 'You must accept our Terms of Service and Privacy policy.'
  },
  confirmationCode: {
    empty: 'Code is required',
    minLength: 'Code must be 6 digits'
  },
  firstName: {
    empty: 'First name is required',
    minLength: 'Chosen first name is too short',
    maxLength: 'Chosen first name is too long',
    charactersOnly: 'Chosen first name contains numbers or special characters'
  },
  lastName: {
    empty: 'Last name is required',
    minLength: 'Chosen last name is too short',
    maxLength: 'Chosen last name is too long',
    charactersOnly: 'Chosen last name contains numbers or special characters'
  },
  country: {
    empty: 'Country is required'
  },
  jobTitle: {
    empty: 'Job title is required'
  },
  city: {
    empty: 'City is required'
  },
  displayName: {
    maxLength: 'Chosen nickname is too long'
  },
  teamName: {
    empty: 'Team name is required',
    maxLength: 'Chosen name is too long'
  },
  teamDescription: {
    maxLength: 'Chosen description is too long'
  },
  teamType: {
    empty: 'Team type is required'
  },
  checkInItemDescription: {
    empty: 'Description is required'
  }
})
