import { ref, Ref } from 'vue'
import { v4 as generateUniqueId } from 'uuid'

export type NotificationMessageType = 'info' | 'error'

interface Notification {
  id: string,
  type: NotificationMessageType,
  message: string,
}

class ToastNotificationService {
  private static instance: ToastNotificationService
  private displayLimit: number
  private displayDurationInMS: number
  private availableNotifications: Ref<Notification[]>
  private queuedNotifications: Notification[]

  private constructor() {
    this.displayLimit = 4
    this.displayDurationInMS = 4000
    this.availableNotifications = ref([])
    this.queuedNotifications = []
  }

  public static init(): ToastNotificationService {
    if (!ToastNotificationService.instance) {
      ToastNotificationService.instance = new ToastNotificationService()
    }

    return ToastNotificationService.instance
  }

  private triggerRemovalTimeout(id: string): void {
    setTimeout(() => {
      this.availableNotifications.value = this.availableNotifications.value.filter((notification) => notification.id !== id)

      const nextInQueue = this.queuedNotifications.shift()

      if (nextInQueue) {
        this.availableNotifications.value.push(nextInQueue)
        this.triggerRemovalTimeout(nextInQueue.id)
      }
    }, this.displayDurationInMS)
  }

  public push({ id = generateUniqueId(), type, message }: { id?: string, type: NotificationMessageType, message: string }): void {
    const notification = { id, type, message }

    if (this.availableNotifications.value.length === this.displayLimit) {
      this.queuedNotifications.push(notification)
    } else if (this.queuedNotifications.length > 0) {
      const queuedNotification = this.queuedNotifications.shift()
      this.queuedNotifications.push(notification)

      if (queuedNotification) {
        this.availableNotifications.value.push(queuedNotification)
        this.triggerRemovalTimeout(queuedNotification.id)
      }
    } else {
      this.availableNotifications.value.push(notification)
      this.triggerRemovalTimeout(notification.id)
    }
  }

  public getAvailableNotifications(): Ref<Notification[]> {
    return this.availableNotifications
  }
}

const instance = ToastNotificationService.init()
Object.freeze(instance)

export default instance
