
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/store'

import { Membership } from '@/types/membership'
import { Callback } from '@/types/global'

import ModalHandlerService from '@/services/ModalHandlerService'
import AppDropdown from '@common/AppDropdown.vue'
import AppAvatar from '@common/AppAvatar.vue'
import AppLogo from '@common/AppLogo.vue'

const THEME_VARIATIONS = ['borderless']

export default defineComponent({
  name: 'OrgDropdown',
  components: {
    AppDropdown,
    AppAvatar,
    AppLogo
  },
  props: {
    minimized: {
      type: Boolean,
      required: false
    },
    theme: {
      type: String,
      required: false,
      validator: (value: string): boolean => THEME_VARIATIONS.includes(value)
    }
  },
  setup() {
    const store = useStore()
    const loading = ref(false)

    const trackingState = computed(() => store.getters['timetracker/getActiveTrackingStatus'])

    const teams = computed<Membership[]>(() => store.getters['user/getMemberships'])
    const activeMembership = computed<Membership>(() => store.getters['user/getActiveMembership'])

    function setActiveTeam(team: Membership, toggleVisibility: Callback) {
      if (loading.value) return

      toggleVisibility()

      if (activeMembership.value.teamId !== team.teamId) {
        loading.value = true
        try {
          if (trackingState.value) {
            store.dispatch('timetracker/stopTracking')
            setTimeout(() => { // nice little hack hey
              store.dispatch('user/switchActiveTeam', { teamId: team.teamId, isNewTeam: false })
            }, 1500)
          } else {
            store.dispatch('user/switchActiveTeam', { teamId: team.teamId, isNewTeam: false })
          }
        } catch (error) {
          console.info(error)
        } finally {
          loading.value = false
        }
      }
    }

    function handleCreateTeam(toggleVisibility: Callback) {
      ModalHandlerService.open('create-team')
      toggleVisibility()
    }

    return {
      teams,
      activeMembership,
      setActiveTeam,
      handleCreateTeam,
      trackingState
    }
  }
})
