import { renderSlot as _renderSlot, TransitionGroup as _TransitionGroup, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: _ctx.direction === _ctx.AVAILABLE_DIRECTIONS.FORWARD
      ? 'slide-right'
      : 'slide-left'
    ,
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["name"]))
}