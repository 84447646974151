import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserOnboardingCards = _resolveComponent("UserOnboardingCards")!
  const _component_AppOverlay = _resolveComponent("AppOverlay")!

  return (_ctx.requiresOnboarding)
    ? (_openBlock(), _createBlock(_component_AppOverlay, {
        key: 0,
        variant: "onboarding-cards",
        theme: "white"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_UserOnboardingCards, {
            isTeamInvite: _ctx.isTeamInvite,
            onOnboardingFlowFinished: _cache[0] || (_cache[0] = ($event: any) => (_ctx.requiresOnboarding = false)),
            class: "h-full"
          }, null, 8, ["isTeamInvite"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}