import { DetectedMouseEvent } from '@/types/global'
import { DirectiveBinding } from '@vue/runtime-core'

interface ClickOutsideElement extends HTMLElement {
  __vueClickEventHandler__: any
}

export default {
  beforeMount: (el: ClickOutsideElement, binding: DirectiveBinding) : void => {
    const clickOutsideHandler = (event: DetectedMouseEvent) => {
      if (!el.contains(event.target as HTMLElement) && el !== event.target) {
        binding.value(event)
      }
    }

    el.__vueClickEventHandler__ = clickOutsideHandler

    setTimeout(() => {
      const isModalParent = el.closest('.modal')
      if (isModalParent && el.classList.contains('app-dropdown--simple')) {
        isModalParent.addEventListener('click', clickOutsideHandler)
      } else {
        document.addEventListener('click', clickOutsideHandler)
      }
    }, 0)
  },
  unmounted: (el: ClickOutsideElement) : void => {
    const isModalParent = el.closest('.modal')
    if (isModalParent && el.classList.contains('app-dropdown--simple')) {
      isModalParent.removeEventListener('click', el.__vueClickEventHandler__)
    } else {
      document.removeEventListener('click', el.__vueClickEventHandler__)
    }
  }
}
