
import { defineComponent, onBeforeUnmount, computed, ref, watch, onBeforeMount } from 'vue'
import { useStore } from '@/store'

import Link from '@tiptap/extension-link'
import Mention from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import { Editor, EditorContent, Extensions } from '@tiptap/vue-3'

import { Membership } from '@/types/membership'
import { mentionRenderer, getMentionSuggestions } from '@/helpers/mentionUtils'
import { emojiRenderer, getEmojiSuggestion } from '@/helpers/emojiUtils'
import { MIN_CHARS_INCLUDING_HTML_START_END_BRACKETS } from '@/types/global'
import data from 'emoji-mart-vue-fast/data/all.json'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src'
import { IEmoji } from '@/types/emoji'
import FadeTransition from '@transition/FadeTransition.vue'
import WindowEventsService, { CSS_BREAKPOINTS } from '@/services/WindowEventsService'
import ImageUploadService from '@/services/ImageUploadService'
import AppTextEditorImage from '@/helpers/AppTextEditorImageExtension'
import { JiraMention } from '@/helpers/AppTextEditorJiraMention'
import ToastNotificationService from '@/services/ToastNotificationService'
import { ErrorMessages } from '@/data/messages'

export default defineComponent({
  components: {
    Picker,
    EditorContent,
    FadeTransition
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: true
    },
    hasIcons: {
      type: Boolean,
      default: false
    },
    allowCancel: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Write something...'
    },
    borderless: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: 'Comment'
    },
    loading: {
      type: Boolean,
      default: false
    },
    emojiPickerExpandsUp: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    allowImages: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue', 'focus', 'blur', 'user-action:post', 'user-action:cancel', 'processing-image', 'processing-image-completed'],
  setup(props, { emit }) {
    const store = useStore()

    const teamMembers = computed<Membership[]>(() => store.getters['teams/getTeamMembersWithFullName'])
    const loader = computed(() => props.loading)
    const allowPost = computed(() => props.modelValue.length >= MIN_CHARS_INCLUDING_HTML_START_END_BRACKETS && !isProcessingImage.value)
    const isFocused = ref(false)
    const editorContentRef = ref()
    const rootRef = ref()
    const emojiData = ref(new EmojiIndex(data, { exclude: 'flags' }))
    const showEmojiPicker = ref(false)
    const isMobileView = computed(() => WindowEventsService.matches(CSS_BREAKPOINTS.xs) || WindowEventsService.matches(CSS_BREAKPOINTS.sm))
    const isProcessingImage = ref(false)
    const isJiraEnabled = computed<boolean>(() => store.getters['integrations/getJiraSettings'] !== undefined)

    let blurTimer: ReturnType<typeof setTimeout> | null = null

    watch(loader, (_, oldValue) => {
      if (oldValue) { // loader is on
        editor.commands.clearContent()
        editor.commands.clearNodes()
      }
    })

    const extensions: Extensions = [
      StarterKit.configure({
        dropcursor: {
          width: 4,
          color: '#e8effa'
        }
      }),
      Underline,
      TaskList,
      TaskItem.configure({
        nested: true
      }),
      Placeholder.configure({
        placeholder: props.placeholder,
        emptyNodeClass: 'is-editor-empty'
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention'
        },
        renderLabel({ node }) {
          const user = JSON.parse(node.attrs.id)
          const userInfo = '@' + user.profile?.firstName + user.profile?.lastName
          return userInfo
        },
        suggestion: {
          items: (params: { query: string }): string[] => getMentionSuggestions(params.query, teamMembers.value),
          render: mentionRenderer
        }
      }),
      JiraMention,
      Emoji.configure({
        emojis: gitHubEmojis,
        enableEmoticons: true,
        suggestion: {
          items: (params: { query: string }): string[] => getEmojiSuggestion(editor, params.query),
          render: emojiRenderer
        }
      }),
      // AppTextEditorImage.configure({
      //   onProcessingImageStart,
      //   onProcessingImageEnd
      // }),
      Link.configure({
        openOnClick: props.editable
      })
    ]

    if (props.allowImages) {
      extensions.push(AppTextEditorImage.configure({
        onProcessingImageStart,
        onProcessingImageEnd
      }))
    }

    const editor = new Editor({
      editorProps: {
        handleDOMEvents: {
          keydown: (_, event): boolean => {
            if (event.shiftKey && event.key === 'Enter') {
              event.preventDefault()
              editor.commands.enter()
            }

            return false
          }
        }
      },
      extensions,
      editable: props.editable,
      content: props.modelValue,
      autofocus: props.autofocus,
      onUpdate: () => {
        emit('update:modelValue', editor.getHTML())
      },
      onFocus({ event }) {
        if (blurTimer) {
          clearTimeout(blurTimer)
        }
        isFocused.value = true
        emit('focus', event)
      },
      onBlur({ event }) {
        blurTimer = setTimeout(() => {
          isFocused.value = false
          if (!showEmojiPicker.value) {
            emit('blur', event)
          }
        }, 100)
      }
    })

    watch(() => props.modelValue, (value) => {
      const isSame = editor.getHTML() === value
      if (isSame) {
        return
      }
      editor.commands.setContent(value, false)
    })

    function hideEmojiPicker() {
      showEmojiPicker.value = false
    }

    onBeforeMount(() => {
      document.addEventListener('click', hideEmojiPicker)
    })

    onBeforeUnmount(() => {
      const staticEditorContent = document.createElement('div') // Create new div
      rootRef.value.append(staticEditorContent) // Attach to parent (i have a ref on it called rootRef)
      staticEditorContent.outerHTML = editorContentRef.value.$el.outerHTML // Set new element to contents of old one
      editorContentRef.value.$el.style.display = 'none' // Hide old element
      editor.destroy()
      document.removeEventListener('click', hideEmojiPicker)
    })

    function mentionUser() {
      if (props.modelValue.length === 0) {
        editor.commands.insertContent('@')
      } else {
        editor.commands.insertContent(' @')
      }
    }

    function mentionJira() {
      if (props.modelValue.length === 0) {
        editor.commands.insertContent('/jira')
      } else {
        editor.commands.insertContent(' /jira')
      }
    }

    const onEmojiSelect = (emoji: IEmoji) => {
      let name = emoji.short_name
      if (name === 'star-struck') name = 'star_struck'
      if (name === 'rolling_on_the_floor_laughing') name = 'rofl'
      editor.chain().focus().setEmoji(name).run()
      showEmojiPicker.value = false
    }

    async function onImageInput(event: Event) {
      const fileInputElement = event.target as HTMLInputElement
      if (fileInputElement.files?.length) {
        const [file] = fileInputElement.files as FileList
        await previewImage(file)
      }
    }

    async function onImageDropped(event: DragEvent) {
      if (event.dataTransfer?.files?.length) {
        const [file] = event.dataTransfer.files as FileList
        await previewImage(file, { top: event.clientY, left: event.clientX })
      }
    }

    async function previewImage(file: File, coords?: { top: number, left: number }) {
      const uploadLimit = 20.097 // ~20 MB
      if ((file.size / 1024 / 1024) > uploadLimit) {
        ToastNotificationService.push({ type: 'error', message: ErrorMessages.FILE_SIZE_EXCEEDS_MAXIMUM })
        return
      }
      const result = await ImageUploadService.read(file, false)
      if (result.result) {
        editor.commands.setCustomImage({ src: result.result as string }, coords)
      } else {
        ToastNotificationService.push({ type: 'error', message: result.error ?? ErrorMessages.UNSUCCESSFUL_PICTURE_PROCESSING })
      }
    }

    function onProcessingImageStart() {
      isProcessingImage.value = true
      emit('processing-image')
    }

    function onProcessingImageEnd() {
      isProcessingImage.value = false
      emit('processing-image-completed')
    }

    function cancel() {
      editor.commands.clearContent()
      emit('user-action:cancel')
    }

    return {
      isFocused,
      rootRef,
      editorContentRef,
      editor,
      allowPost,
      mentionUser,
      mentionJira,
      emojiData,
      showEmojiPicker,
      onEmojiSelect,
      isMobileView,
      onImageInput,
      onImageDropped,
      isJiraEnabled,
      isProcessingImage,
      cancel
    }
  }
})
