import { UserProfile } from '@/types/user'
import { ref, Ref } from 'vue'
import { store } from '@/store'
import ApiService from './ApiService'
import { TeamProfile } from '@/types/team'

export interface IUploadResult {
  result: string | ArrayBuffer | null,
  error: string | null
}

export interface ICropperData {
  canvas: HTMLCanvasElement,
  image: {
    src: string
  }
}

class ImageUploadService {
  private static instance: ImageUploadService
  private fr: FileReader
  private progress: Ref<number>
  private fileResult: Ref<string | ArrayBuffer | null>
  private cancelHandler: Ref<AbortController>
  private urlData: Ref<{ pictureId: string }>

  private constructor() {
    this.fr = new FileReader()
    this.progress = ref(0)
    this.fileResult = ref(null)
    this.cancelHandler = ref(new AbortController())
    this.urlData = ref({
      pictureId: ''
    })
  }

  public static init(): ImageUploadService {
    if (!ImageUploadService.instance) {
      ImageUploadService.instance = new ImageUploadService()
    }

    return ImageUploadService.instance
  }

  // Fetch presigned S3 URLs which can be uploaded to for a limited time
  public async getPresignedUrls(size?: string) {
    const url = `picture-upload-url${size ? `?size=${size}` : ''}`
    const response = await ApiService.sendRequest(url, {
      method: 'GET',
      requiresAuth: true
    })
    if (response.ok) {
      return await response.json()
    } else {
      throw new Error('unable to fetch presigned URLs')
    }
  }

  public async upload(cropperData: ICropperData, isTeamAvatar = false, isNewUser = false) {
    const { canvas, image } = cropperData
    const imageData = {
      original: image.src,
      large: this.resample(canvas, 150, 150),
      medium: this.resample(canvas, 86, 86),
      small: this.resample(canvas, 44, 44)
    }

    const data = await this.getPresignedUrls()
    const { pictureId, pictureUrls } = data
    const [original, large, medium, small] = pictureUrls

    await this.trackUploadProgress([
      this.imageUploadRequest(original.preSignedUrl, imageData.original),
      this.imageUploadRequest(large.preSignedUrl, imageData.large),
      this.imageUploadRequest(medium.preSignedUrl, imageData.medium),
      this.imageUploadRequest(small.preSignedUrl, imageData.small)
    ], (value: number) => {
      this.progress.value = value
    })

    if (isNewUser && isTeamAvatar) {
      this.urlData.value = data
    }

    if (isTeamAvatar) {
      if (!isNewUser) {
        await store.dispatch('teams/updateTeam', {
          teamId: store.getters['user/getActiveMembership'].teamId,
          profile: new TeamProfile(
            store.getters['user/getActiveMembership'].teamProfile.name,
            pictureId,
            store.getters['user/getActiveMembership'].industry,
            store.getters['user/getActiveMembership'].size,
            store.getters['user/getActiveMembership'].role
          ),
          values: store.getters['teams/getActiveTeamValues']
        })
      }
    } else {
      await store.dispatch('user/updateUser', {
        profile: new UserProfile(
          undefined,
          undefined,
          undefined,
          pictureId
        )
      })
    }

    this.resetProgress()
  }

  public getUrlData() {
    return this.urlData
  }

  public getCurrentProgress() {
    return this.progress
  }

  public resetProgress() {
    this.progress.value = 0
  }

  private trackUploadProgress(promises: Promise<Response>[], progressCallback: (value: number) => void) {
    let num = 0
    progressCallback(0)

    for (const p of promises) {
      p.then(() => {
        num++
        progressCallback((num * 100) / promises.length)
      })
    }
    return Promise.all(promises)
  }

  public read(file: File, isAvatar = true): Promise<IUploadResult> {
    if (this.isFileTypeAllowed(file, isAvatar)) {
      this.fr.readAsDataURL(file)

      return new Promise((resolve, reject) => {
        this.fr.addEventListener('load', () => {
          resolve({ result: this.fr.result, error: null })
          this.fileResult.value = this.fr.result
        })

        this.fr.addEventListener('abort', reject)
      })
    }

    return Promise.resolve({ result: null, error: 'Unsupported file type' })
  }

  private isFileTypeAllowed(file: File, isAvatar = true) {
    if (isAvatar) {
      return (file.type === 'image/png' || file.type === 'image/jpeg')
    } else {
      return file.type.startsWith('image/')
    }
  }

  public async imageUploadRequest(url: string, value: string) {
    return await fetch(url, {
      method: 'PUT',
      signal: this.cancelHandler.value.signal,
      headers: {
        'Content-Type': 'image/png'
      },
      body: Buffer.from(value.replace(/^data:image\/\w+;base64,/, ''), 'base64')
    })
  }

  public cancelUploadRequest() {
    this.cancelHandler.value.abort()
    this.cancelHandler.value = new AbortController()
  }

  private resample(canvas: HTMLCanvasElement, width: number, height: number) {
    /* eslint-disable camelcase */
    const width_source = canvas.width
    const height_source = canvas.height
    width = Math.round(width)
    height = Math.round(height)

    const ratio_w = width_source / width
    const ratio_h = height_source / height
    const ratio_w_half = Math.ceil(ratio_w / 2)
    const ratio_h_half = Math.ceil(ratio_h / 2)

    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    const img = ctx.getImageData(0, 0, width_source, height_source)
    const img2 = ctx.createImageData(width, height)
    const data = img.data
    const data2 = img2.data

    for (let j = 0; j < height; j++) {
      for (let i = 0; i < width; i++) {
        const x2 = (i + j * width) * 4
        let weight = 0
        let weights = 0
        let weights_alpha = 0
        let gx_r = 0
        let gx_g = 0
        let gx_b = 0
        let gx_a = 0
        const center_y = (j + 0.5) * ratio_h
        const yy_start = Math.floor(j * ratio_h)
        const yy_stop = Math.ceil((j + 1) * ratio_h)

        for (let yy = yy_start; yy < yy_stop; yy++) {
          const dy = Math.abs(center_y - (yy + 0.5)) / ratio_h_half
          const center_x = (i + 0.5) * ratio_w
          const w0 = dy * dy
          const xx_start = Math.floor(i * ratio_w)
          const xx_stop = Math.ceil((i + 1) * ratio_w)

          for (let xx = xx_start; xx < xx_stop; xx++) {
            const dx = Math.abs(center_x - (xx + 0.5)) / ratio_w_half
            const w = Math.sqrt(w0 + dx * dx)

            if (w >= 1) {
              continue
            }

            weight = 2 * w * w * w - 3 * w * w + 1
            const pos_x = 4 * (xx + yy * width_source)

            gx_a += weight * data[pos_x + 3]
            weights_alpha += weight

            if (data[pos_x + 3] < 255) {
              weight = weight * data[pos_x + 3] / 250
            }

            gx_r += weight * data[pos_x]
            gx_g += weight * data[pos_x + 1]
            gx_b += weight * data[pos_x + 2]
            weights += weight
          }
        }

        data2[x2] = gx_r / weights
        data2[x2 + 1] = gx_g / weights
        data2[x2 + 2] = gx_b / weights
        data2[x2 + 3] = gx_a / weights_alpha
      }
    }

    canvas.width = width
    canvas.height = height

    ctx.putImageData(img2, 0, 0)

    return canvas.toDataURL()
  }

  public getSelectedFileResult() {
    if (this.fileResult.value) {
      return this.fileResult.value
    }
  }
}

const instance = ImageUploadService.init()
Object.freeze(instance)

export default instance
