
import { defineComponent, ref } from 'vue'

import QuizService, { QUIZ_STAGE_DEFINITION, QUIZ_AVAILABILITY_STAGE_DEFINITION } from '@/services/QuizService'

import QuizAvailabilityStageIntro from '@feature/quiz/quiz-availability-stage/QuizAvailabilityStageIntro.vue'
import QuizAvailabilityStageMeetingsTOD from '@feature/quiz/quiz-availability-stage/QuizAvailabilityStageMeetingsTOD.vue'
import QuizAvailabilityStageMeetingsArrangement from '@feature/quiz/quiz-availability-stage/QuizAvailabilityStageMeetingsArrangement.vue'
import QuizAvailabilityStageReachingOut from '@feature/quiz/quiz-availability-stage/QuizAvailabilityStageReachingOut.vue'
import QuizAvailabilityStageWorkingHours from '@feature/quiz/quiz-availability-stage/QuizAvailabilityStageWorkingHours.vue'

import QuizGroupNavigationTransition from '@transition/QuizGroupNavigationTransition.vue'

export default defineComponent({
  name: 'QuizAvailabilityStage',
  components: {
    QuizAvailabilityStageIntro,
    QuizAvailabilityStageMeetingsTOD,
    QuizAvailabilityStageMeetingsArrangement,
    QuizAvailabilityStageReachingOut,
    QuizAvailabilityStageWorkingHours,
    QuizGroupNavigationTransition
  },
  setup() {
    const isNavigationDirectionForward = ref(false)

    return {
      QuizService,
      QUIZ_STAGE_DEFINITION,
      QUIZ_AVAILABILITY_STAGE_DEFINITION,
      isNavigationDirectionForward
    }
  }
})
