import { ref, Ref } from 'vue'

class ModalHandlerService {
  private static instance: ModalHandlerService
  private currentlyActiveModal: Ref<string>
  private queuedModals: string[]
  private modalProps: Record<string, unknown>[]

  private constructor() {
    this.currentlyActiveModal = ref('')
    this.queuedModals = []
    this.modalProps = []
  }

  public static init(): ModalHandlerService {
    if (!ModalHandlerService.instance) {
      ModalHandlerService.instance = new ModalHandlerService()
    }

    return ModalHandlerService.instance
  }

  public open(id: string, props?: Record<string, unknown>) {
    if (this.queuedModals.length > 0) {
      this.queuedModals.push(id)
    } else {
      this.currentlyActiveModal.value = id
    }

    if (props && Object.keys(props).length > 0) {
      this.modalProps.push({
        id,
        ...props
      })
    }

    return ModalHandlerService.instance
  }

  public close() {
    this.currentlyActiveModal.value = this.queuedModals.shift() || ''

    return ModalHandlerService.instance
  }

  public queue(id: string) {
    this.queuedModals.push(id)

    return ModalHandlerService.instance
  }

  public reset() {
    this.currentlyActiveModal.value = ''
    this.queuedModals.length = 0

    return ModalHandlerService.instance
  }

  public getCurrentlyActive() {
    return this.currentlyActiveModal.value
  }

  public isCurrentlyActive(id: string) {
    return this.getCurrentlyActive() === id
  }

  public getCurrentModalProps(id: string | number) {
    return this.modalProps.find((el) => el.id === id)
  }
}

const instance = ModalHandlerService.init()
Object.freeze(instance)

export default instance
