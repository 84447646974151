import { WebSocketService } from '@/services/WebSocketService'
import { JoinPlayResponse, Play } from '@/types/play'
import { PlayState, RootState } from '@/types/store'
import { UserProfile } from '@/types/user'
import { Module } from 'vuex'

const play: Module<PlayState, RootState> = {
  namespaced: true,
  state: {
    play: {} as Play,
    connectionId: undefined
  },
  getters: {
    getPlay: (state) => state.play,
    getPlayCode: (state) => state.play.code,
    getConnectionId: (state) => state.connectionId,
    isConnected: (state) => state.play.users?.some((e) => e.connectionId === state.connectionId),
    isPendingGuest: (state) => state.play.pendingGuests?.some((e) => e.connectionId === state.connectionId),
    getPendingGuests: (state) => state.play.pendingGuests
  },
  mutations: {
    setPlay(state, play: Play) {
      state.play = play
    },
    setConnectionId(state, connectionId?: string) {
      state.connectionId = connectionId
    }
  },
  actions: {
    joinPlay(_, params: { playCode: string, profile: UserProfile, userId?: string }): void {
      WebSocketService.instance.joinPlay(params.playCode, params.profile, params.userId)
    },
    updatePlayUser(_, params: { playCode: string, x: number, y: number }): void {
      WebSocketService.instance.sendMessage({
        route: 'update-play-user',
        ...params
      })
    },
    updatePendingGuest(_, params: { playCode: string, guestConnectionId: string, admitToPlay: boolean }): void {
      WebSocketService.instance.sendMessage({
        route: 'update-pending-guest',
        ...params
      })
    },
    handleJoinPlayResponse({ commit }, response: JoinPlayResponse): void {
      if (response.success) {
        commit('setConnectionId', response.connectionId)
        commit('setPlay', response.play)
      }
    }
  }
}

export default play
