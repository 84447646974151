// Potential Improvement - Move Cognito Token Storage out of Local Storage
// Ref: https://sofyan-ahmad.medium.com/custom-aws-amplify-auth-storage-84a10be00997

// TODO - Refresh tokens are not supplied with social sign ons - once the access token expires (after 60 minutes) the user is forced to sign in again
const LOGIN_EXPIRATION_TIME = 'expirationTime'
const BASE_EXPIRATION_TIME = 3600 * 1000 * 12
const REMEMBER_ME_EXPIRATION_TIME = 3600 * 1000 * 24 * 30

export const setLoginExpirationTime = (rememberMe = false): void => {
  localStorage.setItem(
    LOGIN_EXPIRATION_TIME,
    new Date(Date.now() + (rememberMe ? REMEMBER_ME_EXPIRATION_TIME : BASE_EXPIRATION_TIME)).toISOString()
  )
}

export const isLoginExpired = (): boolean => {
  const expirationTime = localStorage.getItem(LOGIN_EXPIRATION_TIME)
  return expirationTime != null && (new Date().toISOString() > expirationTime)
}

export const clearLoginExpirationTime = (): void => {
  localStorage.removeItem(LOGIN_EXPIRATION_TIME)
}
