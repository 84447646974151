
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import { computed, defineComponent, onMounted, ref } from 'vue'
import ImageUploadService from '@/services/ImageUploadService'
import ToastNotificationService from '@/services/ToastNotificationService'
import FadeTransition from '@transition/FadeTransition.vue'
import { ErrorMessages } from '@/data/messages'

export default defineComponent({
  components: {
    NodeViewWrapper,
    FadeTransition
  },
  props: nodeViewProps,
  setup: (props) => {
    const isPreview = ref(false)
    const env = process.env.VUE_APP_DOMAIN === 'production' ? '' : `.${process.env.VUE_APP_DOMAIN}`
    const imageIsSelected = computed(() => props.selected && props.editor?.isEditable)

    onMounted(async () => {
      if (props.node?.attrs.src && props.node.attrs.src.startsWith('data:')) {
        isPreview.value = true
        try {
          if (props.extension?.options.onProcessingImageStart) {
            props.extension.options.onProcessingImageStart()
          }
          const preSignedUrls = await ImageUploadService.getPresignedUrls('user_content')
          const { pictureId, pictureUrls } = preSignedUrls
          const uploadResponse = await ImageUploadService.imageUploadRequest(pictureUrls[0].preSignedUrl, props.node.attrs.src)
          if (uploadResponse.ok) {
            if (props.updateAttributes) {
              props.updateAttributes({ src: `https://content${env}.cadrelo.com/${pictureId}_user_content` })
              isPreview.value = false
            }
          } else {
            throw new Error('Upload failed')
          }
        } catch (e) {
          ToastNotificationService.push({ type: 'error', message: ErrorMessages.UNSUCCESSFUL_PICTURE_UPLOAD })
          if (props.deleteNode) {
            props.deleteNode()
          }
        } finally {
          if (props.extension?.options.onProcessingImageEnd) {
            props.extension.options.onProcessingImageEnd()
          }
        }
      }
    })
    function setSize(size: string) {
      if (props.updateAttributes) {
        props.updateAttributes({ size })
      }
    }
    return {
      isPreview,
      setSize,
      imageIsSelected
    }
  }
})
