
import AppTextEditor from '@/components/common/AppTextEditor.vue'
import { extractMentionedUsers } from '@/helpers/mentionUtils'
import ToastNotificationService from '@/services/ToastNotificationService'
import { useStore } from '@/store'
import DailySync, { DailySyncTask } from '@/types/dailySync'
import { User, WorkDaySchedule } from '@/types/user'
import { DreTrayMessage, DreTrayMessageCategory } from '@/types/dreTray'
import { deepCopyDailySyncTaskList, getDailySyncForUser, getUserScheduleForDay, isNonEmptyTask, updateTaskHistoryForDate } from '@/utils/dailySyncUtils'
import DailySyncOnboarding from '@feature/daily-sync/DailySyncOnboarding.vue'
import DailySyncMoodSelector from '@feature/daily-sync/DailySyncMoodSelector.vue'
import DailySyncTaskList from '@feature/daily-sync/DailySyncTaskList.vue'
import DailySyncAvailabilitySelector from '@/components/feature/daily-sync/DailySyncAvailabilitySelector.vue'
import { DateTime } from 'luxon'
import { dateNow } from '@/utils/calculateTimeDiff'
import { defineComponent, ref, watch, computed, onMounted, PropType } from 'vue'
import ModalHandlerService from '@/services/ModalHandlerService'
import { BacklogEventListener, BacklogService } from '@/services/BacklogService'
import { backlogOpen } from '@/utils/peekabooUtils'
import { v4 } from 'uuid'

export default defineComponent({
  name: 'DailySyncSubmission',
  emits: ['update:modelValue', 'user-action:submit'],
  components: {
    AppTextEditor,
    DailySyncMoodSelector,
    DailySyncTaskList,
    DailySyncAvailabilitySelector,
    DailySyncOnboarding
  },
  props: {
    dayBeingViewed: {
      type: String,
      required: true
    },
    userDailySync: {
      type: Object as PropType<DailySync>,
      required: false
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const userId = store.getters['user/getActiveMembership']?.userId
    const user = computed<User>(() => store.getters['user/getUser'])
    const message = ref<string | undefined>(props.userDailySync?.message)
    const mood = ref<string | undefined>(props.userDailySync?.feelingEmoji)
    const userSchedule = ref<WorkDaySchedule | undefined>(getUserScheduleForDay(userId, props.dayBeingViewed))
    // create a deep copy of the previous sync task list so that we can edit the tasks without the changes reflecting on the previous sync record in vuex
    const previousTasks = deepCopyDailySyncTaskList(getDailySyncForUser(DateTime.fromISO(props.dayBeingViewed).minus({ day: 1 }), userId)?.tasks ?? [])
    const openTasks = ref<DailySyncTask[]>(props.userDailySync?.tasks ?? previousTasks.filter(e => !e.completed))
    const completedTasks = ref<DailySyncTask[]>(previousTasks.filter(e => e.completed))
    const expandCompletedTaskList = ref(false)
    const isSaving = ref(false)
    const isImageProcessing = ref(false)
    let savedTeamTalk = message.value
    let saveTeamTalkTimer: ReturnType<typeof setTimeout> | null = null
    let queueNextUpdate = false // this flag is used to queue updates so that only one update request is being sent at a time

    const isModalActive = computed(() => !!ModalHandlerService.getCurrentlyActive())

    const showOnboarding = ref(true)

    async function postDailySync() {
      await saveDailySync(false)
      ToastNotificationService.push({ type: 'info', message: 'Daily sync posted' })
      await store.dispatch('dreTray/getIndividualStrategy')
    }

    async function saveDailySync(draft = false) {
      if (isSaving.value === true) {
        queueNextUpdate = true
      } else {
        try {
          isSaving.value = true
          const tasksToPostToday = getTasksForToday()
          const mentionedUsers = [...extractMentionedUsers(message.value), ...tasksToPostToday.flatMap(e => extractMentionedUsers(e.text))]
          const scheduleOverride = userSchedule.value?.works ? userSchedule.value : undefined
          const upsertDailySyncRequestForToday = {
            feelingEmoji: mood.value,
            message: message.value,
            mentionedUsers,
            tasks: tasksToPostToday,
            scheduleOverride,
            draft
          }
          const teamId = store.getters['user/getActiveMembership']?.teamId
          await store.dispatch('dailySync/upsertDailySync', { teamId, userId, date: props.dayBeingViewed, request: upsertDailySyncRequestForToday })
          emit('user-action:submit')
        } catch (error) {
          if (error instanceof Error) {
            ToastNotificationService.push({ type: 'error', message: error.message })
          }
        } finally {
          isSaving.value = false
          if (queueNextUpdate) {
            queueNextUpdate = false
            await saveDailySync()
          }
        }
      }
    }

    function getTasksForToday(): DailySyncTask[] {
      const nonEmptyTasks = openTasks.value.filter(isNonEmptyTask).map(e => ({ ...e, assignee: userId }))
      nonEmptyTasks.forEach(e => updateTaskHistoryForDate(e, props.dayBeingViewed))
      return nonEmptyTasks
    }

    function hideOnboarding() {
      showOnboarding.value = false
      store.dispatch('user/updateUser', {
        settings: {
          ...user.value.settings,
          syncOnboarding: true
        }
      })
    }

    async function onTeamTalkUpdated() {
      if (saveTeamTalkTimer) {
        clearTimeout(saveTeamTalkTimer)
      }
      saveTeamTalkTimer = setTimeout(() => {
        saveChangesToTeamTalk()
      }, 2000)
    }

    async function saveChangesToTeamTalk() {
      if (saveTeamTalkTimer) {
        clearTimeout(saveTeamTalkTimer)
      }
      if (savedTeamTalk !== message.value) {
        savedTeamTalk = message.value
        await saveDailySync()
      }
    }

    async function onDeleteTask(task: DailySyncTask) {
      const goalId = task.goalId
      if (goalId) {
        await store.dispatch('goals/updateTaskOnGoal', { goalId, teamId: user.value.activeTeamId, task: { ...task, assignee: undefined } })
      }
    }

    watch(() => store.getters['teams/getUserSchedules'], () => { userSchedule.value = getUserScheduleForDay(userId, props.dayBeingViewed) })

    // watch(() => store.getters['dreTray/isDreTrayLoaded'], async (isLoaded) => {
    //   if (isLoaded) {
    //     // generate a daily sync summary if one has not already been generated for today
    //     const previousSyncSummary = store.getters['dreTray/getMessages'].find((e: DreTrayMessage) => e.category === DreTrayMessageCategory.DAILY_SUMMARY)
    //     if (!previousSyncSummary || DateTime.fromISO(previousSyncSummary.time).toISODate() !== dateNow.value.toISODate()) {
    //       await store.dispatch('dreTray/getDailySyncSummary', { teamId: user.value.activeTeamId, date: props.dayBeingViewed })
    //     }
    //   }
    // }, { immediate: true })

    watch(isModalActive, () => {
      showOnboarding.value = user.value?.settings?.syncOnboarding !== true && !isModalActive.value
    }, { immediate: true })

    const backlogListener: BacklogEventListener = {
      id: v4(),
      onSendToBacklog: (task: DailySyncTask) => {
        const deleteIndex = openTasks.value.findIndex(e => e.id === task.id)
        if (deleteIndex !== -1) {
          openTasks.value.splice(deleteIndex, 1)
          saveDailySync()
        }
      },
      onSendToTaskList: (task: DailySyncTask) => {
        task.assignee = userId
        const insertIndex = openTasks.value.filter(isNonEmptyTask).length
        openTasks.value.splice(insertIndex, 0, task)
        saveDailySync()
      }
    }

    onMounted(() => {
      backlogOpen.value = true // open backlog
      BacklogService.instance.taskListListener = backlogListener
    })

    return {
      message,
      mood,
      userSchedule,
      expandCompletedTaskList,
      isSaving,
      isImageProcessing,
      openTasks,
      completedTasks,
      postDailySync,
      saveDailySync,
      showOnboarding,
      hideOnboarding,
      onTeamTalkUpdated,
      saveChangesToTeamTalk,
      onDeleteTask
    }
  }
})
