import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "quiz-selectable-button__content" }
const _hoisted_2 = { class: "quiz-selectable-button-meta" }
const _hoisted_3 = {
  key: 0,
  class: "quiz-selectable-button-meta__title"
}
const _hoisted_4 = {
  key: 1,
  class: "quiz-selectable-button-meta__description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppPanel = _resolveComponent("AppPanel")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["quiz-selectable-button", [
      $props.selected && 'quiz-selectable-button--selected',
      $props.size && `quiz-selectable-button--${$props.size}`
    ]])
  }, [
    _createVNode(_component_AppPanel, { class: "quiz-selectable-button-panel" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("div", _hoisted_2, [
          ($props.title)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.title), 1))
            : _createCommentVNode("", true),
          ($props.description)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($props.description), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 3
    })
  ], 2))
}