import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLayout = _resolveComponent("AppLayout")!
  const _component_SharedComponentsFacility = _resolveComponent("SharedComponentsFacility")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppLayout),
    _createVNode(_component_SharedComponentsFacility)
  ], 64))
}