
import { PropType } from 'vue'

enum AVAILABLE_DIRECTIONS {
  FORWARD = 'forward',
  BACKWARD = 'backward'
}

export default {
  name: 'QuizGroupNavigationTransition',
  props: {
    direction: {
      type: String as PropType<AVAILABLE_DIRECTIONS>,
      required: true
    }
  },
  setup() {
    return {
      AVAILABLE_DIRECTIONS
    }
  }
}
