import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  class: "app-time-picker__dropdown",
  key: "app-time-picker"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppDropdown = _resolveComponent("AppDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-time-picker", [ _ctx.displayDirection && `app-time-picker--${_ctx.displayDirection}` ]])
  }, [
    _createVNode(_component_AppDropdown, null, {
      handler: _withCtx((props) => [
        _createElementVNode("div", {
          class: "app-time-picker__input",
          onClick: props.visibility.toggle
        }, _toDisplayString(_ctx.leadingZero(_ctx.hour)) + ":" + _toDisplayString(_ctx.leadingZero(_ctx.minute)), 9, _hoisted_1)
      ]),
      default: _withCtx((props) => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeIntervals, (time) => {
              return (_openBlock(), _createElementBlock("p", {
                key: time,
                onClick: ($event: any) => (_ctx.selectTime(props.visibility.toggle, time))
              }, _toDisplayString(time.hour) + ":" + _toDisplayString(time.minute), 9, _hoisted_3))
            }), 128))
          ])
        ])
      ]),
      _: 1
    })
  ], 2))
}