import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "side-nav__logo-wrapper" }
const _hoisted_2 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrgDropdown = _resolveComponent("OrgDropdown")!
  const _component_SideNavTransition = _resolveComponent("SideNavTransition")!
  const _component_AppButtonMini = _resolveComponent("AppButtonMini")!
  const _component_SideNavigationMainRoutes = _resolveComponent("SideNavigationMainRoutes")!
  const _component_AppSpacer = _resolveComponent("AppSpacer")!
  const _component_FocusWidget = _resolveComponent("FocusWidget")!
  const _component_SideNavigationSecondary = _resolveComponent("SideNavigationSecondary")!

  return (_openBlock(), _createElementBlock("aside", {
    class: _normalizeClass(["side-nav", [
    !_ctx.maximized && 'side-nav--minimized',
    _ctx.shouldAnimate && 'side-nav--animate',
    _ctx.dailySyncOverlayActive && 'side-nav--overlay'
  ]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SideNavTransition, null, {
        default: _withCtx(() => [
          (!_ctx.maximized)
            ? (_openBlock(), _createBlock(_component_OrgDropdown, {
                key: 0,
                minimized: "",
                theme: "borderless"
              }))
            : (_openBlock(), _createBlock(_component_OrgDropdown, {
                key: 1,
                minimized: !_ctx.maximized,
                theme: "borderless"
              }, null, 8, ["minimized"]))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_AppButtonMini, {
      icon: "arrow",
      class: "side-nav__button",
      onClick: _ctx.manageSidebarState
    }, null, 8, ["onClick"]),
    _createElementVNode("div", {
      class: _normalizeClass(["side-nav__primary-content", [!_ctx.maximized && 'side-nav__primary-content-minimized']])
    }, [
      _createVNode(_component_SideNavTransition, null, {
        default: _withCtx(() => [
          (_ctx.maximized)
            ? (_openBlock(), _createBlock(_component_SideNavigationMainRoutes, {
                minimized: false,
                key: "main-routes"
              }))
            : (_openBlock(), _createBlock(_component_SideNavigationMainRoutes, {
                minimized: "",
                key: "main-routes-minimized"
              })),
          (!_ctx.maximized)
            ? (_openBlock(), _createBlock(_component_AppSpacer, {
                type: "simple",
                class: _normalizeClass(["side-nav__spacer", [!_ctx.maximized && 'side-nav__spacer-minimized']]),
                key: "spacer-primary"
              }, null, 8, ["class"]))
            : _createCommentVNode("", true),
          (_ctx.isDailySyncsLoaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_FocusWidget, {
                  minimized: !_ctx.maximized
                }, null, 8, ["minimized"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ], 2),
    _createVNode(_component_SideNavTransition, null, {
      default: _withCtx(() => [
        (_ctx.maximized)
          ? (_openBlock(), _createBlock(_component_SideNavigationSecondary, {
              key: 0,
              minimized: false,
              class: "side-nav__secondary"
            }))
          : (_openBlock(), _createBlock(_component_SideNavigationSecondary, {
              key: 1,
              minimized: true,
              class: "side-nav__secondary"
            }))
      ]),
      _: 1
    })
  ], 2))
}