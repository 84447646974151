
import { defineComponent } from 'vue'

import QuizService from '@/services/QuizService'

import QuizCard from '@feature/quiz/QuizCard.vue'

export default defineComponent({
  name: 'QuizPersonalStageIntro',
  emits: ['quiz-navigation:next'],
  components: {
    QuizCard
  },
  setup(_, { emit }) {
    function navigateNext() {
      emit('quiz-navigation:next')
      QuizService.next()
    }

    return {
      QuizService,
      navigateNext
    }
  }
})
