import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c83af1be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-overlay__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-overlay", [
      _ctx.theme && `app-overlay--${_ctx.theme}`,
      _ctx.variant && `app-overlay--${_ctx.variant}`,
      !_ctx.maximized && 'app-overlay--minimized'
    ]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}