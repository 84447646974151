import { Module } from 'vuex'

import { TimetrackerState, RootState } from '@/types/store'
import { DailySyncTask, TaskTimer } from '@/types/dailySync'

const timetracker: Module<TimetrackerState, RootState> = {
  namespaced: true,
  state: {
    tracking: false,
    entity: {
      task: null,
      timer: {
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }
  },

  getters: {
    getActiveEntity: (state) => state.entity,
    getActiveTimer: (state) => state.entity.timer,
    getActiveTask: (state) => state.entity.task,
    getActiveTrackingStatus: (state) => state.tracking
  },

  mutations: {
    setEntityTask(state, task: DailySyncTask) {
      state.entity.task = task
    },

    setEntityTimer(state, timer: TaskTimer) {
      state.entity.timer = timer
    },

    setTrackingStatus(state, isTracking: boolean) {
      state.tracking = isTracking
    },

    setEntity(state, entity: { task: DailySyncTask, timer: TaskTimer }) {
      state.entity = entity
    }
  },

  actions: {
    startTracking({ commit }, args: { task: DailySyncTask, timer: TaskTimer }): void {
      commit('setTrackingStatus', true)
      commit('setEntity', {
        task: args.task,
        timer: args.timer
      })
    },

    stopTracking({ commit }): void {
      commit('setTrackingStatus', false)
      commit('setEntity', {
        task: null,
        timer: {
          hours: 0,
          minutes: 0,
          seconds: 0
        }
      })
    }
  }
}

export default timetracker
