import { Membership, TeamRole, UpdateTeamInvite, RemoveTeamMember, SendTeamInvite, CancelTeamInvite } from '@/types/membership'
import { RootState, TeamState } from '@/types/store'
import { Team, TeamProfile, TeamValue, TeamTopInterest, TeamContributionStyles, TeamGreatDebateSplit, UpdateActiveTeam } from '@/types/team'
import { Module } from 'vuex'
import { TeamInvite } from '../teamInvite'

import ApiService from '@/services/ApiService'
import { UserProfile, WorkWeekSchedule } from '@/types/user'
import AwayStatus from '@/types/awayStatus'
import { MixPanelService } from '@/services/MixPanelService'

const teams: Module<TeamState, RootState> = {
  namespaced: true,
  state: {
    activeTeam: {} as Team,
    activeTeamMembers: [] as Membership[],
    activeTeamPendingInvites: [] as TeamInvite[],
    userSchedules: [] as { userId: string, schedule?: WorkWeekSchedule }[],
    teamTopInterests: [] as TeamTopInterest[],
    teamContributionStyles: {} as TeamContributionStyles,
    teamSplits: [] as TeamGreatDebateSplit[],
    teamDataLoaded: false,
    teamInsightsDataLoaded: false,
    showNotATeamMemberOverlay: false
  },
  getters: {
    getActiveTeam: (state) => state.activeTeam,
    getActiveTeamValues: (state) => state.activeTeam.values,
    getActiveTeamMembers: (state) => state.activeTeamMembers,
    getActiveTeamPendingInvites: (state) => state.activeTeamPendingInvites,
    getUserSchedules: (state) => state.userSchedules,
    getTeamTopInterests: (state) => state.teamTopInterests,
    getTeamSplits: (state) => state.teamSplits,
    getTeamContributionStyles: (state) => state.teamContributionStyles,
    getTeamDirectoryEnabled: (state) => state.activeTeam.teamDirectoryEnabled,
    getDailySyncEnabled: (state) => state.activeTeam.dailySyncEnabled,
    getGoalsEnabled: (state) => state.activeTeam.goalsEnabled,
    isTeamDataLoaded: (state) => state.teamDataLoaded,
    isTeamInsightsDataLoaded: (state) => state.teamInsightsDataLoaded,
    getShowNotATeamMemberOverlay: (state) => state.showNotATeamMemberOverlay,
    getTeamMembersWithFullName: (state) => state.activeTeamMembers.filter((member: Membership) => member.userProfile.firstName !== undefined)
  },
  mutations: {
    setActiveTeam(state, selectedTeam: Team) {
      state.activeTeam = selectedTeam
    },
    setActiveTeamMembers(state, selectedTeamMembers: Membership[]) {
      state.activeTeamMembers = selectedTeamMembers
    },
    setActiveTeamPendingInvites(state, selectedTeamPendingInvites: TeamInvite[]) {
      state.activeTeamPendingInvites = selectedTeamPendingInvites
    },
    setUserSchedules(state, userSchedules: { userId: string, schedule?: WorkWeekSchedule }[]) {
      state.userSchedules = userSchedules
    },
    updateUserSchedule(state, userSchedule: { userId: string, schedule?: WorkWeekSchedule }) {
      const existingSchedules = state.userSchedules.filter((e) => e.userId !== userSchedule.userId)
      existingSchedules.push(userSchedule)
      state.userSchedules = existingSchedules
    },
    setTeamTopInterests(state, teamTopInterests: TeamTopInterest[]) {
      state.teamTopInterests = teamTopInterests
    },
    setTeamContributionStyles(state, teamContributionStyles: TeamContributionStyles) {
      state.teamContributionStyles = teamContributionStyles
    },
    setTeamSplits(state, teamSplits: TeamGreatDebateSplit[]) {
      state.teamSplits = teamSplits
    },
    setTeamDirectoryEnabled(state, teamDirectoryEnabled: boolean) {
      state.activeTeam.teamDirectoryEnabled = teamDirectoryEnabled
    },
    setDailySyncEnabled(state, dailySyncEnabled: boolean) {
      state.activeTeam.dailySyncEnabled = dailySyncEnabled
    },
    setGoalsEnabled(state, goalsEnabled: boolean) {
      state.activeTeam.goalsEnabled = goalsEnabled
    },
    setTeamDataLoaded(state, isLoaded: boolean) {
      state.teamDataLoaded = isLoaded
    },
    setTeamInsightsDataLoaded(state, isLoaded: boolean) {
      state.teamInsightsDataLoaded = isLoaded
    },
    setShowNotATeamMemberOverlay(state, showNotATeamMemberOverlay: boolean) {
      state.showNotATeamMemberOverlay = showNotATeamMemberOverlay
    },
    removePendingInvite(state, inviteCode: string) {
      state.activeTeamPendingInvites = state.activeTeamPendingInvites.filter((user: TeamInvite) => user.inviteCode !== inviteCode)
    },
    updatePendingUserRole(state, value: { inviteCode: string, newRole: TeamRole }) {
      const user = state.activeTeamPendingInvites.find(user => user.inviteCode === value.inviteCode)
      if (user) user.role = value.newRole
    },
    updateSelectedTeamPending(state, pendingUsers: TeamInvite[]) {
      state.activeTeamPendingInvites = [...pendingUsers, ...state.activeTeamPendingInvites]
    },
    removeTeamMember(state, teamMemberId: string) {
      state.activeTeamMembers = state.activeTeamMembers.filter((user: Membership) => {
        return user.userId !== teamMemberId
      })
    },
    updateSelectedTeamMemberRole(state, value: { userId: string, newRole: TeamRole }) {
      const user = state.activeTeamMembers.find(user => user.userId === value.userId)
      if (user) {
        user.role = value.newRole
      }
    },
    updateSelectedTeamMemberAwayStatus(state, value: { userId: string, awayStatus: AwayStatus }) {
      const user = state.activeTeamMembers.find(user => user.userId === value.userId)
      if (user) {
        user.awayStatus = value.awayStatus
      }
    },
    syncChangesToUserProfile(state, value: {userId: string, updatedUserProfile: UserProfile }) {
      const membership = state.activeTeamMembers.find((m) => m.userId === value.userId)
      if (membership) {
        membership.userProfile.displayName = value.updatedUserProfile.displayName ?? membership.userProfile.displayName
        membership.userProfile.firstName = value.updatedUserProfile.firstName ?? membership.userProfile.firstName
        membership.userProfile.lastName = value.updatedUserProfile.lastName ?? membership.userProfile.lastName
        membership.userProfile.pictureId = value.updatedUserProfile.pictureId ?? membership.userProfile.pictureId
        membership.userProfile.location = value.updatedUserProfile.location ?? membership.userProfile.location
        membership.userProfile.jobTitle = value.updatedUserProfile.jobTitle ?? membership.userProfile.jobTitle
        membership.userProfile.anniversary = value.updatedUserProfile.anniversary ?? membership.userProfile.anniversary
        membership.userProfile.birthday = value.updatedUserProfile.birthday ?? membership.userProfile.birthday
        state.activeTeamMembers = [...state.activeTeamMembers.filter((m) => m.userId !== value.userId), membership]
      }
    },
    syncChangesToTeamProfile(state, updatedTeamProfile: TeamProfile) {
      state.activeTeam.profile = updatedTeamProfile
      state.activeTeamMembers.map((current) => ({ ...current, teamProfile: updatedTeamProfile }))
    },
    syncChangesForTeamValues(state, newValues: TeamValue[]) {
      state.activeTeam.values = newValues
    },
    handleUserJoinedTeamNotification(state, params: { membership: Membership, workWeekSchedule?: WorkWeekSchedule }) {
      if (params.membership.teamId === state.activeTeam.teamId) {
        state.activeTeamMembers.push(params.membership)
        state.userSchedules.push({ userId: params.membership.userId, schedule: params.workWeekSchedule })
        const existingInvite = state.activeTeamPendingInvites.find((e) => e.email === params.membership.email)
        if (existingInvite) {
          state.activeTeamPendingInvites = state.activeTeamPendingInvites.filter((e) => e.email !== existingInvite.email)
        }
      }
    }
  },
  actions: {
    clearActiveTeamData({ commit }) {
      commit('setActiveTeam', {})
      commit('setActiveTeamMembers', [])
      commit('setActiveTeamPendingInvites', [])
      commit('setUserSchedules', [])
      commit('setTeamTopInterests', [])
      commit('setTeamContributionStyles', {})
      commit('setTeamSplits', [])
      commit('setTeamDataLoaded', false)
      commit('setTeamInsightsDataLoaded', false)
    },
    async loadActiveTeamData({ dispatch }, params: { teamId: string }): Promise<void> {
      await dispatch('getTeam', { teamId: params.teamId })
      dispatch('getTeamInsights', { teamId: params.teamId })
    },
    async getTeam({ commit }, params: { teamId: string }): Promise<void> {
      try {
        const response = await ApiService.sendTrackedRequest(`teams/${params.teamId}`, {
          method: 'GET',
          requiresAuth: true
        })

        commit('setActiveTeam', response.team)
        commit('setActiveTeamMembers', response.members)
        commit('setActiveTeamPendingInvites', response.pendingInvites)
        commit('setUserSchedules', response.userSchedules)
        commit('setTeamDataLoaded', true)
      } catch (error) {
        throw new Error('Current team could not be loaded.')
      }
    },
    async createTeam({ dispatch }, profile: TeamProfile): Promise<void> {
      const response = await ApiService.sendRequest('teams', {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify({ profile })
      })

      // After creating a new team we immediately switch it to be the active team
      if (response.ok) {
        const teamData = await response.json()
        MixPanelService.instance.onCreateTeam(teamData.team)
        await dispatch('user/switchActiveTeam', { teamId: teamData.team.teamId, isNewTeam: true }, { root: true })
      } else {
        throw new Error('Team could not be created.')
      }
    },
    async updateTeam({ state, commit }, params: UpdateActiveTeam): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}`, {
        method: 'PATCH',
        requiresAuth: true,
        body: JSON.stringify(params)
      })

      if (!response.ok) {
        throw new Error('Team could not be updated.')
      } else {
        if (params.profile !== undefined) {
          commit('syncChangesToTeamProfile', params.profile)
          commit('user/syncChangesToTeamProfile', params.profile, { root: true })
          MixPanelService.instance.onUpdateTeam(state.activeTeam)
        }
        if (params.values !== undefined) {
          commit('syncChangesForTeamValues', params.values)
          MixPanelService.instance.onUpdateTeamValues(params.values)
        }
        if (params.teamDirectoryEnabled !== undefined) {
          commit('setTeamDirectoryEnabled', params.teamDirectoryEnabled)
        }
        if (params.dailySyncEnabled !== undefined) {
          commit('setDailySyncEnabled', params.dailySyncEnabled)
        }
        if (params.goalsEnabled !== undefined) {
          commit('setGoalsEnabled', params.goalsEnabled)
        }
      }
    },
    async deleteTeam({ dispatch, commit }, params: { teamId: string }): Promise<void> {
      const teamId = params.teamId
      const response = await ApiService.sendRequest(`teams/${params.teamId}`, {
        method: 'DELETE',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      } else {
        commit('user/removeMembership', teamId, { root: true })
        dispatch('clearActiveTeamData')
        MixPanelService.instance.onDeleteTeam()
      }
    },
    async sendTeamInvite({ commit }, params: SendTeamInvite): Promise<void> {
      const promises = params.sendTeamInviteRequest.users.map(email => {
        return new Promise((resolve) => {
          resolve(ApiService.sendRequest('send-team-invite', {
            method: 'POST',
            requiresAuth: true,
            body: JSON.stringify({
              userId: params.userId,
              teamId: params.sendTeamInviteRequest.teamId,
              role: params.sendTeamInviteRequest.role,
              email
            })
          }).then((res) => res.json()).then((data) => {
            return {
              email,
              inviteCode: data,
              role: params.sendTeamInviteRequest.role
            }
          }))
        })
      })

      await Promise.all(promises).then(data => {
        if (!params.resend) {
          commit('updateSelectedTeamPending', data)
        }
      }).catch(error => console.log(`Error in promises ${error}`))
      MixPanelService.instance.onSendTeamInvites(params.sendTeamInviteRequest.users, params.resend)
    },
    async cancelTeamInvite({ commit }, params: CancelTeamInvite): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/invite/${params.inviteCode}`, {
        method: 'DELETE',
        requiresAuth: true,
        body: JSON.stringify(params)
      })

      if (!response.ok) {
        throw new Error('Something went wrong with cancel invite')
      } else {
        commit('removePendingInvite', params.inviteCode)
      }
    },
    async updateTeamInvite({ commit }, params: UpdateTeamInvite): Promise<{ error?: boolean, message?: string | unknown}> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/invite/${params.inviteCode}`, {
        method: 'PATCH',
        requiresAuth: true,
        body: JSON.stringify(params.updateTeamMemberRequest)
      })
      if (!response.ok) {
        commit('updatePendingUserRole', { inviteCode: params.inviteCode, newRole: params.oldRole })
        return {
          error: true,
          message: await response.json()
        }
      } else {
        commit('updatePendingUserRole', { inviteCode: params.inviteCode, newRole: params.updateTeamMemberRequest.role })
        return {}
      }
    },
    async removeTeamMember({ commit }, params: RemoveTeamMember): Promise<void> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/users/${params.teamMemberId}`, {
        method: 'DELETE',
        requiresAuth: true,
        body: JSON.stringify(params)
      })

      if (!response.ok) {
        throw new Error('Something went wrong with update team invite')
      } else {
        commit('removeTeamMember', params.teamMemberId)
      }
    },
    async updateTeamMemberAwayStatus({ commit }, params: { teamId: string, teamMemberId: string, awayStatus: AwayStatus }): Promise<{ error?: boolean, message?: string | unknown}> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/users/${params.teamMemberId}`, {
        method: 'PATCH',
        requiresAuth: true,
        body: JSON.stringify({ awayStatus: params.awayStatus })
      })

      if (!response.ok) {
        return {
          error: true,
          message: await response.json()
        }
      } else {
        commit('user/setActiveTeamAwayStatus', { userId: params.teamMemberId, awayStatus: params.awayStatus }, { root: true })
        commit('updateSelectedTeamMemberAwayStatus', { userId: params.teamMemberId, awayStatus: params.awayStatus })
        return { }
      }
    },
    async updateTeamMemberRole({ commit }, params: { teamId: string, teamMemberId: string, role: TeamRole }): Promise<{ error?: boolean, message?: string | unknown}> {
      const response = await ApiService.sendRequest(`teams/${params.teamId}/users/${params.teamMemberId}`, {
        method: 'PATCH',
        requiresAuth: true,
        body: JSON.stringify({ role: params.role })
      })

      if (!response.ok) {
        return {
          error: true,
          message: await response.json()
        }
      } else {
        commit('user/setActiveTeamRole', { userId: params.teamMemberId, newRole: params.role }, { root: true })
        commit('updateSelectedTeamMemberRole', { userId: params.teamMemberId, newRole: params.role })
        return { }
      }
    },
    async addUserToTeam(_, params: { inviteCode: string }): Promise<{ error?: boolean, membership?: string | unknown}> {
      const response = await ApiService.sendRequest('add-user-to-team', {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify(params)
      })
      if (!response.ok) {
        throw new Error(`Failed to add user to team with inviteCode ${params.inviteCode}: ${response.status}`)
      } else {
        const membershipData = await response.json()
        MixPanelService.instance.onJoinedTeam(membershipData.membership.teamId)
        return membershipData.membership
      }
    },
    async getTeamInsights({ commit }, params: { teamId: string }): Promise<void> {
      const response = await ApiService.sendRequest(`team-insights/${params.teamId}`, {
        method: 'GET',
        requiresAuth: true
      })

      if (response.ok) {
        const data = await response.json()
        commit('setTeamTopInterests', data.teamTopInterests)
        commit('setTeamContributionStyles', data.teamContributionStyles)
        commit('setTeamSplits', data.teamSplits)
        commit('setTeamInsightsDataLoaded', true)
      } else {
        throw new Error('Team insights could not be loaded.')
      }
    }
  }
}

export default teams
