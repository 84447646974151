import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55700ad7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-accordion__actions" }
const _hoisted_2 = { class: "app-accordion__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AccordionTransition = _resolveComponent("AccordionTransition")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["app-accordion__item", { 'app-accordion__item--elevated' : _ctx.elevated }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["app-accordion__trigger", { 'app-accordion__trigger--active': _ctx.isVisible }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openAccordion && _ctx.openAccordion(...args)))
    }, [
      _renderSlot(_ctx.$slots, "accordion-trigger", {}, undefined, true),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "accordion-actions", {}, undefined, true),
        _createElementVNode("button", null, [
          _createVNode(_component_AppIcon, {
            name: "arrow-wide",
            size: "18"
          })
        ])
      ])
    ], 2),
    _createVNode(_component_AccordionTransition, {
      onEnter: _ctx.startAnimation,
      onAfterEnter: _ctx.endAnimation,
      onBeforeLeave: _ctx.startAnimation,
      onAfterLeave: _ctx.endAnimation
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", null, [
            _renderSlot(_ctx.$slots, "accordion-content", {}, undefined, true)
          ])
        ], 512), [
          [_vShow, _ctx.isVisible]
        ])
      ]),
      _: 3
    }, 8, ["onEnter", "onAfterEnter", "onBeforeLeave", "onAfterLeave"])
  ], 2))
}