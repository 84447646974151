
import { defineComponent, ref } from 'vue'

import QuizService from '@/services/QuizService'

import QuizCard from '@feature/quiz/QuizCard.vue'
import QuizSelectableButton from '@feature/quiz/QuizSelectableButton.vue'

enum MEETING_TIME_OF_DAY {
  MORNING = 'Morning',
  AFTERNOON = 'Afternoon'
}

export default defineComponent({
  name: 'QuizAvailabilityStageMeetingsTOD',
  emits: ['quiz-navigation:next', 'quiz-navigation:prev'],
  components: {
    QuizCard,
    QuizSelectableButton
  },
  setup(_, { emit }) {
    const { touchingBase } = QuizService.getAvailabilityStageData()
    const selectedTimeOfDay = ref(touchingBase?.meetingTime || '')

    function navigateNext() {
      emit('quiz-navigation:next')
      QuizService.save({
        meetingTime: selectedTimeOfDay.value
      })
      QuizService.next()
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')
      QuizService.prev()
    }

    return {
      QuizService,
      navigateNext,
      navigatePrev,
      selectedTimeOfDay,
      MEETING_TIME_OF_DAY
    }
  }
})
