import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["app-button-mini", [$props.theme && `app-button-mini--${$props.theme}`]])
  }, [
    _createVNode(_component_AppIcon, {
      name: $props.icon,
      size: $props.iconSize
    }, null, 8, ["name", "size"])
  ], 2))
}