
import { defineComponent, computed, ref, watch, PropType } from 'vue'
import INTERESTS from '@/data/json/interests.json'
import SlideLeftRightTransition from '@transition/SlideLeftRightTransition.vue'
import SlideFadeTransition from '@transition/SlideFadeTransition.vue'
import QuizService from '@/services/QuizService'

enum INTERESTS_STEPS {
  CATEGORY,
  SUBCATEGORY,
  ITEMS,
}

interface InterestItem {
  category: number,
  subcategory:number,
  id?: number
}

type counterType = (a: number) => number

export default defineComponent({
  name: 'QuizInterestsPicker',
  components: {
    SlideLeftRightTransition,
    SlideFadeTransition
  },
  props: {
    modelValue: {
      type: Array as PropType<InterestItem[]>,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const currentStepActive = ref(0)
    const categorySelected = ref()
    const subCategorySelected = ref()
    const animateDirection = ref('forward')
    const itemsSelected = computed<InterestItem[]>({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })
    const subCategories = computed(() => {
      return INTERESTS.categories.find((category) => category.id === categorySelected.value)?.subCategories
    })

    const items = computed(() => {
      return INTERESTS.categories.find((category) => category.id === categorySelected.value)?.subCategories
        .find((subCategory) => subCategory.id === subCategorySelected.value)?.interests
    })

    const countCategoryItems: counterType = (id) => {
      return itemsSelected.value.filter((selected) => selected.category === id).length
    }

    const countSubCategoryItems: counterType = (id) => {
      return itemsSelected.value.filter((selected) => selected.subcategory === id).length
    }

    const isPickerValid = computed(() => {
      return (
        INTERESTS.categories.some((item) => countCategoryItems(item.id) !== 0) &&
        currentStepActive.value === 0
      ) || currentStepActive.value !== 0
    })

    function handleButtonClick(): void {
      if (isPickerValid.value && currentStepActive.value === 0) {
        emit('navigate-quiz')
        return
      }

      if (!currentStepActive.value) {
        currentStepActive.value++
      } else {
        currentStepActive.value--

        if (currentStepActive.value === INTERESTS_STEPS.CATEGORY) {
          categorySelected.value = null
        }
      }
    }

    function getCategoryLabel(): string {
      return INTERESTS.categories.find((category) => category.id === categorySelected.value)?.label ?? 'Select Interests'
    }

    function goBack() {
      if (currentStepActive.value === INTERESTS_STEPS.SUBCATEGORY) {
        categorySelected.value = null
      }

      if (currentStepActive.value === INTERESTS_STEPS.ITEMS) {
        subCategorySelected.value = null
      }

      currentStepActive.value--
    }

    function handleSelect(itemID: number) {
      if (currentStepActive.value === INTERESTS_STEPS.CATEGORY) {
        categorySelected.value = itemID
      }

      if (currentStepActive.value === INTERESTS_STEPS.SUBCATEGORY) {
        subCategorySelected.value = itemID
      }

      if (currentStepActive.value === INTERESTS_STEPS.ITEMS) {
        const alreadySelected = itemsSelected.value.some((selected: InterestItem) => selected.id === itemID)
        if (alreadySelected) {
          itemsSelected.value = itemsSelected.value.filter(selected => selected.id !== itemID)
        } else {
          itemsSelected.value.push({
            category: categorySelected.value,
            subcategory: subCategorySelected.value,
            id: itemID
          })
        }
      }

      if (currentStepActive.value < 2) {
        currentStepActive.value++
      }
    }

    watch(currentStepActive, (newStepIndex, oldStepIndex) => {
      if (newStepIndex > oldStepIndex) {
        animateDirection.value = 'forward'
      } else {
        animateDirection.value = 'backward'
      }
    })

    return {
      currentStepActive,
      INTERESTS_STEPS,
      INTERESTS,
      categorySelected,
      subCategories,
      subCategorySelected,
      itemsSelected,
      items,
      isPickerValid,
      animateDirection,
      QuizService,
      countSubCategoryItems,
      countCategoryItems,
      goBack,
      getCategoryLabel,
      handleSelect,
      handleButtonClick
    }
  }
})
