
import { defineComponent, ref } from 'vue'

import QuizService, { QUIZ_WORK_STAGE_DEFINITION } from '@/services/QuizService'

import QuizCard from '@feature/quiz/QuizCard.vue'
import QuizSelectableButton from '@feature/quiz/QuizSelectableButton.vue'

enum CONTRIBUTION_STYLES {
  PRESET = 'preset',
  CUSTOM = 'custom'
}

export default defineComponent({
  name: 'QuizWorkStageContributionStyle',
  emits: ['quiz-navigation:next', 'quiz-navigation:prev'],
  components: {
    QuizCard,
    QuizSelectableButton
  },
  setup(_, { emit }) {
    const selectedStyle = ref('')

    function navigateNext() {
      emit('quiz-navigation:next')

      if (selectedStyle.value === CONTRIBUTION_STYLES.CUSTOM) {
        QuizService.save({
          styleImageId: undefined,
          explorer: 50,
          structured: 50,
          supportive: 50,
          reactive: 50,
          outgoing: 50
        })
      }

      QuizService.skipToIndex(
        selectedStyle.value === CONTRIBUTION_STYLES.PRESET
          ? QUIZ_WORK_STAGE_DEFINITION.PRESET
          : QUIZ_WORK_STAGE_DEFINITION.CONTRIBUTION_EXPLORER
      )
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')
      QuizService.prev()
    }

    return {
      QuizService,
      navigateNext,
      navigatePrev,
      selectedStyle,
      CONTRIBUTION_STYLES
    }
  }
})
