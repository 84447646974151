
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { DailySyncTask } from '@/types/dailySync'
import { useStore } from '@/store'
import DailySyncTaskList from '@feature/daily-sync/DailySyncTaskList.vue'
import { isNonEmptyTask } from '@/utils/dailySyncUtils'
import ToastNotificationService from '@/services/ToastNotificationService'
import { BacklogEventListener, BacklogService } from '@/services/BacklogService'
import { backlogOpen } from '@/utils/peekabooUtils'
import { v4 } from 'uuid'

export default defineComponent({
  name: 'DailySyncBacklog',
  components: {
    DailySyncTaskList
  },
  setup() {
    const store = useStore()
    const backlog = ref<DailySyncTask[]>(store.getters['dailySync/getBacklog'])
    const backlogLoaded = computed<boolean>(() => store.getters['dailySync/isBacklogLoaded'])
    const backlogCount = computed(() => backlog.value.filter(e => isNonEmptyTask(e)).length)

    const isSaving = ref(false)
    let queueNextUpdate = false // this flag is used to queue updates so that only one update request is being sent at a time

    function toggleBacklog() {
      if (backlogOpen.value) {
        backlogOpen.value = false
      } else {
        backlogOpen.value = true
      }
    }

    async function updateBacklog() {
      if (isSaving.value === true) {
        queueNextUpdate = true
      } else {
        try {
          isSaving.value = true
          const tasks = backlog.value.filter(isNonEmptyTask)
          const teamId = store.getters['user/getActiveMembership']?.teamId
          await store.dispatch('dailySync/upsertBacklog', { teamId, tasks })
        } catch (error) {
          if (error instanceof Error) {
            ToastNotificationService.push({ type: 'error', message: error.message })
          }
        } finally {
          isSaving.value = false
          if (queueNextUpdate) {
            queueNextUpdate = false
            await updateBacklog()
          }
        }
      }
    }

    async function onDeleteTask(task: DailySyncTask) {
      const goalId = task.goalId
      if (goalId) {
        const teamId = store.getters['user/getActiveMembership']?.teamId
        await store.dispatch('goals/updateTaskOnGoal', { goalId, teamId, task: { ...task, assignee: undefined } })
      }
    }

    watch(backlogLoaded, () => {
      if (backlogLoaded.value) {
        backlog.value = store.getters['dailySync/getBacklog']
      }
    })

    const backlogListener: BacklogEventListener = {
      id: v4(),
      onSendToBacklog: (task: DailySyncTask) => {
        const insertIndex = backlog.value.filter(isNonEmptyTask).length
        backlog.value.splice(insertIndex, 0, task)
        updateBacklog()
        ToastNotificationService.push({ type: 'info', message: 'Task moved to backlog' })
      },
      onSendToTaskList: (task: DailySyncTask) => {
        const deleteIndex = backlog.value.findIndex(e => e.id === task.id)
        if (deleteIndex !== -1) {
          backlog.value.splice(deleteIndex, 1)
          updateBacklog()
          ToastNotificationService.push({ type: 'info', message: 'Task moved to focus list' })
        }
      }
    }

    onMounted(() => {
      BacklogService.instance.backlogListener = backlogListener
    })

    return {
      backlog,
      backlogCount,
      updateBacklog,
      backlogLoaded,
      backlogOpen,
      toggleBacklog,
      onDeleteTask,
      isSaving
    }
  }
})
