
import { defineComponent, reactive, provide } from 'vue'

export default defineComponent({
  name: 'AppAccordion',
  props: {
    default: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const Accordion = reactive({ active: props.default ? 0 : null })

    provide('Accordion', Accordion)
  }
})
