
export default {
  name: 'QuizCard',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    emoji: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    prev: {
      type: Boolean,
      required: false,
      default: true
    },
    isVerticallyAligned: {
      type: Boolean,
      default: false
    }
  }
}
