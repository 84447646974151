import AwayStatus from './awayStatus'
import { TeamProfile } from './team'
import { UserProfile } from './user'

export enum TeamRole {
  Owner = 'Owner',
  Admin = 'Admin',
  Member = 'Member'
}

export class UpdateTeamMemberRequest {
  constructor(
      public role?: TeamRole,
      public awayStatus?: AwayStatus
  ) {}
}

export class SendTeamInviteRequest {
  constructor(
    public users: string[],
    public teamId: string,
    public role: string
  ) {}
}

export class UpdateTeamInvite {
  constructor(
    public oldRole: string,
    public teamId: string,
    public inviteCode: string,
    public updateTeamMemberRequest: UpdateTeamMemberRequest
  ) {}
}

export class RemoveTeamMember {
  constructor(
    public requesterUserId: string,
    public teamId: string,
    public teamMemberId: string
  ) {}
}

export class CancelTeamInvite {
  constructor(
    public teamId: string,
    public inviteCode: string
  ) {}
}

export class SendTeamInvite {
  constructor(
    public userId: string,
    public sendTeamInviteRequest: SendTeamInviteRequest,
    public resend: boolean
  ) {}
}

export class Membership {
  constructor(
      public userId: string,
      public teamId: string,
      public joinedAt: string,
      public role: TeamRole,
      public email: string,
      public userProfile: UserProfile,
      public teamProfile: TeamProfile,
      public awayStatus: AwayStatus
  ) { }
}
