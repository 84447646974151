
import { defineComponent, ref, computed } from 'vue'
import { store } from '@/store'

import QuizService from '@/services/QuizService'

import AppChip from '@common/AppChip.vue'
import QuizCard from '@feature/quiz/QuizCard.vue'

export default defineComponent({
  name: 'QuizPersonalStagePassions',
  emits: ['quiz-navigation:next', 'quiz-navigation:prev'],
  components: {
    AppChip,
    QuizCard
  },
  setup(_, { emit }) {
    const { passions } = QuizService.getPersonalStageData()
    const localPassion = ref<string>('')
    const selectedPassions = ref<string[]>(passions.slice() || [])
    const activeTeam = computed(() => store.getters['teams/getActiveTeam'])

    async function updateTeamInsights() {
      await store.dispatch('teams/getTeamInsights', { teamId: activeTeam.value?.teamId })
    }

    function navigateNext() {
      emit('quiz-navigation:next')
      QuizService.save({
        passions: selectedPassions.value
      })

      if (activeTeam.value) {
        updateTeamInsights()
      }

      QuizService.next()
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')
      QuizService.prev()
    }

    function savePassion(passionToAdd: string) {
      if (!passionToAdd) return // prevent empty passion
      if (selectedPassions.value.includes(passionToAdd)) return // return early if duplicate

      selectedPassions.value.push(passionToAdd)
      localPassion.value = ''
    }

    function removePassion(passionToRemove: string) {
      selectedPassions.value = selectedPassions.value.filter((passion) => passion !== passionToRemove)
    }

    return {
      QuizService,
      localPassion,
      selectedPassions,
      navigateNext,
      savePassion,
      removePassion,
      navigatePrev
    }
  }
})
