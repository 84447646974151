
import { defineComponent, reactive, toRefs, ref, computed } from 'vue'

/* Services */
import FormValidationService, { shallowValidate } from '@/services/FormValidationService'
import ModalHandlerService from '@/services/ModalHandlerService'
import ToastNotificationService from '@/services/ToastNotificationService'

import { User } from '@/types/user'
import { CreateGroupRequest, GroupRole } from '@/types/group'
import { Team } from '@/types/team'
import { Membership } from '@/types/membership'

import isEmpty from '@/helpers/empty'

import { useStore } from '@/store'
import { AVAILABLE_TEAM_INDUSTRIES } from '@/data/static'

/* Components */
import AppDropdown from '@common/AppDropdown.vue'
import Modal from '@common/Modal.vue'
import FilterUsersSelect from '@feature/filter-slicer/FilterUsersSelect.vue'

interface FormErrors {
  fieldEmptyErrors: Record<string, unknown>,
  specialErrors: Record<string, unknown>
}

export default defineComponent({
  name: 'SharedCreateGroupModal',
  components: {
    Modal,
    AppDropdown,
    FilterUsersSelect
  },
  setup() {
    const store = useStore()
    const loading = ref(false)

    const currentAuthenticatedUser = computed<User>(() => store.getters['user/getUser'])
    const teamMembers = computed(() => store.getters['teams/getTeamMembersWithFullName'].filter((member: Membership) => member.email !== currentAuthenticatedUser.value.email))
    const activeTeam = computed<Team>(() => store.getters['teams/getActiveTeam'])
    const selectedMembers = ref<Membership[]>([])

    const fields = reactive({
      teamName: '',
      teamType: '',
      teamDescription: ''
    })

    const errors = reactive<FormErrors>({
      fieldEmptyErrors: {},
      specialErrors: {}
    })

    function getSelectedMembers(users: Membership[]) {
      selectedMembers.value = users
    }

    async function createTeam() {
      errors.fieldEmptyErrors = shallowValidate({ teamName: fields.teamName })
      errors.specialErrors = FormValidationService({ teamName: fields.teamName, teamDescription: fields.teamDescription })
      if (isEmpty(errors.fieldEmptyErrors) && isEmpty(errors.specialErrors)) {
        loading.value = true
        const groupMembership = selectedMembers.value.map(user => {
          return {
            role: GroupRole.Member,
            userId: user.userId
          }
        })

        try {
          await store.dispatch('groups/createGroup', new CreateGroupRequest(
            activeTeam.value.teamId,
            fields.teamName,
            fields.teamType,
            fields.teamDescription,
            groupMembership
          ))

          ModalHandlerService.open('create-group-successful')
        } catch (error) {
          if (error instanceof Error) {
            ToastNotificationService.push({ type: 'error', message: error.message })
          }
        } finally {
          loading.value = false
        }
      }
    }

    function handleModalClose() {
      fields.teamName = ''
      fields.teamType = ''
      fields.teamDescription = ''
      selectedMembers.value = []
      ModalHandlerService.close()
    }

    return {
      ...toRefs(errors),
      ...toRefs(fields),
      loading,
      AVAILABLE_TEAM_INDUSTRIES,
      teamMembers,
      selectedMembers,
      createTeam,
      getSelectedMembers,
      handleModalClose
    }
  }
})
