
const THEME_VARIATIONS = ['primary', 'secondary', 'danger', 'home']
const SIZE_VARIATIONS = ['small', 'large']

export default {
  name: 'AppButton',
  props: {
    theme: {
      type: String,
      required: false,
      validator: (value: string): boolean => THEME_VARIATIONS.includes(value)
    },
    size: {
      type: String,
      required: false,
      validator: (value: string): boolean => SIZE_VARIATIONS.includes(value)
    },
    disabled: {
      type: Boolean,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false
    }
  }
}
