import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { computed } from 'vue'
import { store } from '@/store'
import routes from '@/router/routes'
import { Membership } from '@/types/membership'
import stashInviteCode from './middleware/stashInviteCode'
import stashPlayRedirect from './middleware/stashPlayRedirect'
import autoChangeActiveTeam from './middleware/autoChangeActiveTeam'
import stashNextRedirectURL from './middleware/stashNextRedirectURL'
import acceptTeamInviteWithClearNotification from './middleware/acceptTeamInviteWithClearNotification'
import { Team } from '@/types/team'

const activeTeam = computed<Team>(() => store.getters['teams/getActiveTeam'])
const activeTeamMembers = computed(() => store.getters['teams/getActiveTeamMembers'])
const userIsAuthorized = computed(() => store.getters['user/isAuthorized'])

/**
 * TODOS:
 * - make `stashInviteCode` & `stashPlayRedirect` middleware
 * - move the rememberMe logic to middleware
*/

/* NOTE: The main branch is always deployed to the root of an S3 bucket whereas feature branches are deployed to subfolders which requires
 * them to use hash history since we cannot configure a default index.html fallback
 * See details https://router.vuejs.org/guide/essentials/history-mode.html
*/
const history = process.env.VUE_APP_USE_HASH_HISTORY === 'true'
  ? createWebHashHistory(process.env.BASE_URL)
  : createWebHistory(process.env.BASE_URL)

const router = createRouter({
  history,
  routes
})
router.beforeResolve(async (to, from, next) => {
  stashInviteCode(to)
  stashPlayRedirect(to)
  acceptTeamInviteWithClearNotification()
  if (to.query.teamId) {
    autoChangeActiveTeam(to.query)
  }

  if (to.params.teamId && to.params.userId) {
    return to.params.teamId === activeTeam.value.teamId &&
      activeTeamMembers.value.some((member: Membership) => member.userId === to.params.userId) ? next() : next({ name: 'TeamUsersList' })
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !userIsAuthorized.value) {
    stashNextRedirectURL(to.redirectedFrom?.path ?? to.path, to.redirectedFrom?.query ?? to.query)
    return next('/sign-in')
  } else if (to.matched.some(record => record.meta.disallowIfAuth) && userIsAuthorized.value) {
    return next('/')
  }

  return next()
})

export default router
