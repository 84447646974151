import { store } from '@/store'
import { UpdateNotificationSettingsRequest } from '@/types/notificationSettings'
import { PushSubscription } from 'web-push'

const VAPID_PUBLIC_KEY = 'BL_skNP2d4MiI95g4yt0WbBdCGjCWYEksDL3M9VuRyA-zxowQYD4ilM0Jb0B_0MvjyqJrw4UpUp9n52yUB76FMI'

export enum NOTIFICATION_PERMISSION {
  DEFAULT = 'default',
  GRANTED = 'granted'
}

export class WebPushService {
  public static instance = new WebPushService()

  private constructor() { }

  public isPermissionGranted(): boolean {
    return 'Notification' in window && Notification.permission === NOTIFICATION_PERMISSION.GRANTED
  }

  public async updatePushSubscriptions(): Promise<void> {
    const subscription = await this.getDeviceSubscription()
    if (subscription) {
      await this.syncSubscriptions(subscription)
    }
  }

  private async getDeviceSubscription(): Promise<PushSubscription | undefined> {
    const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration()
    if (serviceWorkerRegistration) {
      const subscription = await serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: VAPID_PUBLIC_KEY
      })
      return subscription.toJSON() as PushSubscription
    }
    return undefined
  }

  private async syncSubscriptions(deviceSubscription: PushSubscription): Promise<void> {
    // Get the current notification settings to check whether we already have the push subscription for this device stored
    if (!store.getters['notifications/getNotificationSettings']) {
      await store.dispatch('notifications/getNotificationSettings')
    }
    const currentSubscriptions: PushSubscription[] = store.getters['notifications/getNotificationSettings']?.webPushSubscriptions ?? []
    console.info(`There are currently ${currentSubscriptions.length} web push subscriptions registered`)
    const alreadyStored = currentSubscriptions.some((currentSub) => this.isSubscriptionEqual(currentSub, deviceSubscription))
    if (!alreadyStored) {
      console.info('Sending new web push subscription to server')
      store.dispatch('notifications/updateNotificationSettings', new UpdateNotificationSettingsRequest(
        currentSubscriptions.concat([deviceSubscription])
      ))
    }
  }

  private isSubscriptionEqual(a: PushSubscription, b: PushSubscription): boolean {
    return a.endpoint === b.endpoint && a.keys.p256dh === b.keys.p256dh && a.keys.auth === b.keys.auth
  }
}
