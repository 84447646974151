
import { defineComponent } from 'vue'

import AppLogo from '@common/AppLogo.vue'
import MainNavigationMobileMenu from '@feature/main-navigation/MainNavigationMobileMenu.vue'
import MainNavigationNotifications from '@feature/main-navigation/MainNavigationNotifications.vue'

import { NavigationPanel } from '@/types/panel'

export default defineComponent({
  name: 'AppMobileHeader',
  components: {
    AppLogo,
    MainNavigationMobileMenu,
    MainNavigationNotifications
  },
  setup() {
    return {
      NavigationPanel
    }
  }
})
