import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "filter-user-dropdown" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "filter-goal-dropdown__message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppCheckbox = _resolveComponent("AppCheckbox")!
  const _component_AppAvatar = _resolveComponent("AppAvatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppInput, {
      id: "users-search",
      modelValue: _ctx.searchTerm,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
      autocomplete: "off",
      type: "text",
      placeholder: "User"
    }, null, 8, ["modelValue"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredUsers, (filter) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        key: filter,
        class: "app-filter-chip__dropdown-item"
      }, [
        _createVNode(_component_AppCheckbox, {
          modelValue: filter.selected,
          "onUpdate:modelValue": ($event: any) => ((filter.selected) = $event),
          variant: _ctx.APP_CHECKBOX_VARIANTS.SMALL,
          id: "filters-action",
          onClick: _withModifiers(($event: any) => (_ctx.addToFilter(filter)), ["prevent"])
        }, null, 8, ["modelValue", "onUpdate:modelValue", "variant", "onClick"]),
        _createVNode(_component_AppAvatar, {
          user: filter.userProfile,
          size: "tiny"
        }, null, 8, ["user"]),
        _createElementVNode("span", {
          onClick: ($event: any) => (_ctx.addToFilter(filter), _ctx.$emit('user-action:selected'))
        }, _toDisplayString(filter.userProfile.firstName) + " " + _toDisplayString(filter.userProfile.lastName), 9, _hoisted_2)
      ], 512)), [
        [_vShow, _ctx.filteredUsers.length]
      ])
    }), 128)),
    (!_ctx.filteredUsers.length)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.searchTerm ? `Unfortunately, we couldn't find user that match your criteria` : 'Currently no members available'), 1))
      : _createCommentVNode("", true)
  ]))
}