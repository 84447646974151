import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "tabs-wrapper__tab-name-comment-count"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSlider = _resolveComponent("AppSlider")!

  return (_openBlock(), _createBlock(_component_AppSlider, {
    sliderData: _ctx.tabs,
    spaceBetween: 22,
    slidesPerView: 'auto',
    watchOverflow: true,
    slideToClickedSlide: true,
    displaySliderButtons: false,
    class: _normalizeClass(["tabs-wrapper", { 'tabs-wrapper--elevated': _ctx.elevated }]),
    onSwiper: _ctx.onSwiperInit,
    onResize: _ctx.setActiveTab,
    scrollbar: false,
    mousewheel: {
      releaseOnEdges: true,
    }
  }, {
    default: _withCtx((props) => [
      _createElementVNode("div", {
        onClick: ($event: any) => (_ctx.setActiveTab(props.index, props.slide)),
        class: _normalizeClass([{ 'tabs-wrapper__active-tab-name': props.index === _ctx.currentTabIndex }, "tabs-wrapper__tab-name"])
      }, [
        _createTextVNode(_toDisplayString(props.slide) + " ", 1),
        (props.slide === 'Comments' && _ctx.count > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.count), 1))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1),
      (props.index === 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "tabs-wrapper__active-tab",
            style: _normalizeStyle({ left: _ctx.left + 'px', width: _ctx.width + 'px' })
          }, null, 4))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["sliderData", "class", "onSwiper", "onResize"]))
}