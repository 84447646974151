import { SupportedGoalStatuses, SupportedObjectiveStatuses } from '@/types/objective'

export enum SUPPORTED_GOAL_STATUSES {
  NOT_STARTED = 'NotStarted',
  /* BEHIND = 'Behind', */
  ON_TRACK = 'OnTrack',
  COMPLETED = 'Completed',
  AT_RISK = 'AtRisk',
  ON_HOLD = 'OnHold',
}

export enum SUPPORTED_OBJECTIVE_STATUSES {
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  ON_HOLD = 'OnHold'
}

export const OBJECTIVE_MODAL_STATUS_OPTIONS: SupportedObjectiveStatuses[] = [
  {
    value: SUPPORTED_OBJECTIVE_STATUSES.IN_PROGRESS,
    label: 'In progress'
  },
  {
    value: SUPPORTED_OBJECTIVE_STATUSES.COMPLETED,
    label: 'Completed'
  },
  {
    value: SUPPORTED_OBJECTIVE_STATUSES.ON_HOLD,
    label: 'Hold'
  }
]

export const GOAL_MODAL_STATUS_OPTIONS: SupportedGoalStatuses[] = [
  {
    value: SUPPORTED_GOAL_STATUSES.NOT_STARTED,
    label: 'Not started'
  },
  {
    value: SUPPORTED_GOAL_STATUSES.ON_TRACK,
    label: 'In progress'
  },
  {
    value: SUPPORTED_GOAL_STATUSES.COMPLETED,
    label: 'Completed'
  },
  {
    value: SUPPORTED_GOAL_STATUSES.AT_RISK,
    label: 'At risk'
  },
  /* {
    value: SUPPORTED_GOAL_STATUSES.BEHIND,
    label: 'Behind'
  }, */
  {
    value: SUPPORTED_GOAL_STATUSES.ON_HOLD,
    label: 'On hold'
  }
]
