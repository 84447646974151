
import { computed, defineComponent, ref, watch, onBeforeMount } from 'vue'
import { useStore } from '@/store'
import AppButtonMini from '@common/AppButtonMini.vue'
import leadingZero from '@/filters/leadingZero'

export default defineComponent({
  name: 'AppTimeTracker',
  emits: ['timetracker:update', 'timetracker:override'],
  components: {
    AppButtonMini
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const timer = ref()

    const startTime = ref<number>(new Date().getTime())
    const activeTask = computed(() => store.getters['timetracker/getActiveTask'])
    const isTrackingCurrentTask = computed(() => store.getters['timetracker/getActiveTrackingStatus'] && activeTask.value?.id === props.task.id)

    const hours = ref<number>(0)
    const minutes = ref<number>(0)
    const seconds = ref<number>(0)

    /**
     * This is necessary to sync the timer state between the
     * "focus widget" and the "daily sync task" entities
     */
    watch([hours, minutes, seconds], () => {
      if (isTrackingCurrentTask.value) {
        store.commit('timetracker/setEntityTimer', {
          hours: hours.value,
          minutes: minutes.value,
          seconds: seconds.value
        })
      }
    })

    watch(activeTask, (n, o) => {
      if (n?.id && o?.id) {
        if (props.task.id === o.id) {
          clearInterval(timer.value)
          emit('timetracker:update', {
            start: new Date(startTime.value).toISOString(),
            end: new Date().toISOString()
          })
        }
      } else if (!n?.id) {
        if (props.task.id === o.id) {
          clearInterval(timer.value)
          emit('timetracker:update', {
            start: new Date(startTime.value).toISOString(),
            end: new Date().toISOString()
          })
        }
      }
    }, { deep: true })

    /**
     * Necessary in order to ensure there are no memory leaks
     */
    window.addEventListener('beforeunload', () => {
      clearInterval(timer.value)

      if (isTrackingCurrentTask.value) {
        localStorage.setItem(props.task.id, JSON.stringify({
          start: new Date(startTime.value).toISOString(),
          end: null
        }))
      }
    })

    onBeforeMount(() => {
      const saved = localStorage.getItem(props.task.id)

      if (saved) {
        const { start: s, end: e } = JSON.parse(saved)

        localStorage.removeItem(props.task.id)

        emit('timetracker:update', {
          start: s,
          end: e
        })
        setHHMM()

        if (e === null) {
          startTime.value = s
          continueTrackingAndSetStore()
        }
      } else {
        setHHMM()
      }
    })

    const setHHMM = () => {
      const { hours: hrs, minutes: mins, seconds: secs } = calculateHoursAndMinutesBasedOffISODate()

      hours.value = hrs
      minutes.value = mins
      seconds.value = secs
    }

    /**
     * Used for updating the timer and re-initializing the interval after the update
     */
    const updateTimer = () => {
      if (seconds.value + 1 > 59) {
        seconds.value = 0
        if (minutes.value + 1 > 59) {
          hours.value += 1
          minutes.value = 0
        } else {
          minutes.value += 1
        }
      } else {
        seconds.value += 1
      }
    }

    function startTrackingAndSetStore(): void {
      startTime.value = new Date().getTime()
      const initialTime = new Date().toISOString()
      emit('timetracker:update', {
        start: initialTime,
        end: null
      })
      continueTrackingAndSetStore()
    }

    function continueTrackingAndSetStore(): void {
      timer.value = setInterval(updateTimer, 1000)

      store.dispatch('timetracker/startTracking', {
        task: props.task,
        timer: {
          hours: hours.value,
          minutes: minutes.value,
          seconds: seconds.value
        }
      })
    }

    function toggleTimer() {
      if (isTrackingCurrentTask.value) {
        clearInterval(timer.value)
        store.dispatch('timetracker/stopTracking')
      } else {
        startTrackingAndSetStore()
      }
    }

    function overrideTimeblocks() {
      const s = new Date().getTime()

      const mInMS = minutes.value * 60000
      const hInMS = hours.value * 3600000

      const t = s - (mInMS + hInMS)

      emit('timetracker:override', {
        start: new Date(t).toISOString(),
        end: new Date().toISOString()
      })
    }

    /**
     * A method used to calculate & convert the date ISO string into hrs/mins value
     */
    function calculateHoursAndMinutesBasedOffISODate() {
      if (props.task?.timeblocks) {
        let timePassedInMilliseconds = 0

        for (let i = 0; i < props.task?.timeblocks.length; i++) {
          const block = props.task?.timeblocks[i]
          if (block.end) {
            timePassedInMilliseconds += new Date(block.end).getTime() - new Date(block.start).getTime()
          } else {
            timePassedInMilliseconds += new Date().getTime() - new Date(block.start).getTime()
          }
        }

        const hrs = timePassedInMilliseconds / (1000 * 60 * 60)
        const mins = (hrs - Math.floor(hrs)) * 60
        const secs = (mins - Math.floor(mins)) * 60

        return {
          hours: Math.floor(hrs),
          minutes: Math.floor(mins),
          seconds: Math.floor(secs)
        }
      }

      return {
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }

    function cc(val: string) {
      if (parseInt(val) >= 60) {
        hours.value = hours.value + Math.round(parseInt(val) / 60)
        minutes.value = Math.round(parseInt(val) % 60)
      } else {
        minutes.value = parseInt(val)
      }
    }

    return {
      isTrackingCurrentTask,
      hours,
      minutes,
      toggleTimer,
      leadingZero,
      overrideTimeblocks,
      cc
    }
  }
})
