import ApiService from '@/services/ApiService'
import TeamJiraSettings from '@/types/jiraSettings'
import SlackSettings from '@/types/slackSettings'
import { IntegrationsState, RootState } from '@/types/store'
import UpdateTeamSlackSettingsRequest from '@root/backend/src/functions/integrations/slack/update-team-slack-settings/UpdateTeamSlackSettingsRequest'
import { Module } from 'vuex'

const integrations: Module<IntegrationsState, RootState> = {
  namespaced: true,
  state: {
    slackSettings: undefined,
    jiraSettings: undefined,
    integrationSettingsLoaded: false
  },
  getters: {
    getSlackSettings: (state) => state.slackSettings,
    getJiraSettings: (state) => state.jiraSettings,
    isSocialConnectEnabled: (state) => state.slackSettings?.socialConnectEnabled === true,
    isIntegrationSettingsLoaded: (state) => state.integrationSettingsLoaded
  },
  mutations: {
    setSlackSettings(state, slackSettings?: SlackSettings) {
      state.slackSettings = slackSettings
    },
    setJiraSettings(state, jiraSettings?: TeamJiraSettings) {
      state.jiraSettings = jiraSettings
    },
    setIntegrationSettingsLoaded(state, integrationSettingsLoaded: boolean) {
      state.integrationSettingsLoaded = integrationSettingsLoaded
    }
  },
  actions: {
    clearActiveTeamData({ commit }) {
      commit('setSlackSettings', undefined)
      commit('setJiraSettings', undefined)
      commit('setIntegrationSettingsLoaded', false)
    },
    async loadActiveTeamData({ commit }, params: { teamId: string }): Promise<void> {
      commit('setIntegrationSettingsLoaded', false)
      const response = await ApiService.sendRequest(`team-integration-settings/${params.teamId}`, {
        method: 'GET',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Could not retrieve team integration settings')
      } else {
        const responseJson = await response.json()
        commit('setSlackSettings', responseJson.slackSettings)
        commit('setJiraSettings', responseJson.jiraSettings)
        commit('setIntegrationSettingsLoaded', true)
      }
    },
    async associateTeamSlackToken({ commit }, params: { code: string, teamId: string }): Promise<void> {
      const response = await ApiService.sendRequest('associate-team-slack-token', {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify({
          code: params.code,
          redirectUri: process.env.VUE_APP_SLACK_OAUTH_REDIRECT_URL,
          teamId: params.teamId
        })
      })
      if (!response.ok) {
        throw new Error('Failed to add Slack')
      } else {
        commit('setSlackSettings', await response.json())
      }
    },
    async associateTeamJiraToken({ commit }, params: { code: string, teamId: string }): Promise<void> {
      const response = await ApiService.sendRequest('associate-team-jira-token', {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify({
          code: params.code,
          redirectUri: process.env.VUE_APP_JIRA_OAUTH_REDIRECT_URL,
          teamId: params.teamId
        })
      })
      if (!response.ok) {
        throw new Error('Failed to add Jira')
      } else {
        commit('setJiraSettings', await response.json())
      }
    },
    async updateSlackSettings({ state, commit }, params: { teamId: string, request: UpdateTeamSlackSettingsRequest }): Promise<void> {
      const response = await ApiService.sendRequest(`team-slack-settings/${params.teamId}`, {
        method: 'PATCH',
        requiresAuth: true,
        body: JSON.stringify(params.request)
      })

      if (!response.ok) {
        throw new Error('Slack settings could not be updated.')
      } else {
        commit('setSlackSettings', { ...state.slackSettings, ...params.request })
      }
    },
    async disconnectSlack({ commit }, params: { teamId: string }): Promise<void> {
      const response = await ApiService.sendRequest(`team-slack-settings/${params.teamId}`, {
        method: 'DELETE',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Slack settings could not be updated.')
      } else {
        commit('setSlackSettings', undefined)
      }
    },
    async disconnectJira({ commit }, params: { teamId: string }): Promise<void> {
      const response = await ApiService.sendRequest(`team-jira-settings/${params.teamId}`, {
        method: 'DELETE',
        requiresAuth: true
      })

      if (!response.ok) {
        throw new Error('Jira settings could not be updated.')
      } else {
        commit('setJiraSettings', undefined)
      }
    }
  }
}

export default integrations
