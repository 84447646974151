import truncate from '@/filters/truncate'
import capitalize from '@/filters/capitalize'

export interface Filters {
  truncate: (value: string, threshold?: number) => string,
  capitalize: (value: string | undefined) => string | undefined
}

export default <Filters>{
  truncate,
  capitalize
}
