import { PushSubscription } from 'web-push'

export default class NotificationSettings {
  constructor(
        public webPushSubscriptions: PushSubscription[],
        public webSocketConnectionId?: string,
        public enablePushNotifications = true,
        public enableNotificationEmails = true,
        public enableProductEmails = true
  ) {}
}

export class UpdateNotificationSettingsRequest {
  constructor(
      public webPushSubscriptions?: PushSubscription[],
      public enablePushNotifications?: boolean,
      public enableNotificationEmails?: boolean,
      public enableProductEmails?: boolean
  ) {}
}
