import { DailySyncMood } from '@/types/dailySync'

export const defaultMood: DailySyncMood = {
  emoji: '🤔',
  title: 'Mood'
}

export const MOOD_OPTIONS: DailySyncMood[] = [
  { emoji: '😀', title: 'Happy' },
  { emoji: '😁', title: 'Energetic' },
  { emoji: '🤩', title: 'Confident' },
  { emoji: '😎', title: 'Chill' },
  { emoji: '🤔', title: 'Thoughtful' },
  { emoji: '🤪', title: 'Crazy' },
  { emoji: '😠', title: 'Annoyed' },
  { emoji: '🥱', title: 'Tired' },
  { emoji: '🤬', title: 'Angry' },
  { emoji: '🤯', title: 'Problem focused' },
  { emoji: '🙁', title: 'Sad' },
  { emoji: '🤒', title: 'Sick' },
  { emoji: '😵', title: 'Stuck' },
  { emoji: '😮', title: 'Surprised' },
  { emoji: '😖', title: 'Confused' },
  { emoji: '😨', title: 'Worried' },
  { emoji: '🥳', title: 'Party mood' },
  { emoji: '🤫', title: 'Focused' },
  { emoji: '😏', title: 'Proud' },
  { emoji: '🥴', title: 'Under pressure' },
  { emoji: '👻', title: 'Unavailable' }
]
