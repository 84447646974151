export enum SuccessMessages {
  ACCOUNT_DETAILS_UPDATED = 'Account details have been successfully updated!',
  ACCOUNT_PICTURE_UPDATED = 'Account\'s profile picture has been updated!',
  ACCOUNT_PICTURE_REMOVED = 'Account\'s profile picture has been removed!',
  ACCOUNT_PASSWORD_CHANGED = 'Account\'s password has been successfully changed!',
  TEAM_DETAILS_UPDATED = 'Team details have been successfully updated',
  TEAM_INVITATION_ACCEPTED = 'Congratulations, you joined',
  TEAM_INVITATION_ACCEPTED_TITLE = 'Invitation accepted',
  TEAM_MEMBER_RE_INVITED = 'User re-invited successfully',
  TEAM_MEMBER_INVITED = 'User invited successfully',
  TEAM_MEMBERS_INVITED = 'Users invited successfully',
  TEAM_MEMBER_REMOVED = 'User removed successfully',
  TEAM_MEMBER_ROLE_UPDATED = 'User role updated successfully',
  TEAM_DELETED = 'Team has been successfully deleted',
  NOTIFICATIONS_EMPTY_LIST = 'You don\'t have any new notifications',
  DAILY_ACCEPTED = 'Daily sync updated',
  URL_COPIED_TO_CLIPBOARD = 'URL copied to clipboard',
  // add organisation messages here
  ORGANISATION_DETAILS_UPDATED = 'Organisation details have been successfully updated',
  ORGANISATION_PICTURE_UPDATED = 'Organisation\'s profile picture has been updated!',
  ORGANISATION_VALUES_UPDATED = 'Organisation values successfully updated',
  ORGANISATION_VALUE_DELETED = 'Organisation value successfully deleted',
}

export enum ErrorMessages {
  FILE_SIZE_EXCEEDS_MAXIMUM = 'This file\'s size exceeds maximum of 20 MB.',
  UNSUCCESSFUL_PICTURE_UPDATE = 'There\'s been an error while trying to remove the profile picture, please try again.',
  UNSUCCESSFUL_PICTURE_PROCESSING = 'There\'s been an error processing the image, please try again.',
  UNSUCCESSFUL_PICTURE_UPLOAD = 'There\'s been an error uploading the image, please try again.',
  UNSUCCESSFUL_CROP = 'Something went wrong with retrieving the cropped image. Please try again.',
  FAILED_GIPHY_GENERIC = 'Something went wrong with fetching the gifs',
  UNSUCCESSFUL_REMOVE_FROM_TEAM = 'Something went wrong with removing the user from team.',
  UNSUCCESSFUL_DELETE_TEAM = 'Something went wrong with deleting the team, please try again',
  // add organisation messages here
  UNSUCCESSFUL_ORGANISATION_DETAILS_UPDATE = 'Something went wrong with updating the organisation details, please try again',
  UNSUCCESSFUL_ADD_TO_ORGANISATION = 'Something went wrong with adding the user to the organisation.',
  UNSUCCESSFUL_REMOVE_FROM_ORGANISATION = 'Something went wrong with removing the user from organisation.',
}

/* New strucutre of messages, will update the others later */
export enum ERRORS {
  GIPHY_GENERIC = 'Something went wrong while trying to retrieve the gifs, please try again.'
}
