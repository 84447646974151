import { createApp } from 'vue'
import Amplify from 'aws-amplify'
import VueGtag from 'vue-gtag'
import VueSelect from 'vue-next-select'
import FloatingVue from 'floating-vue'

import App from '@/App.vue'
import bootstrap from '@/bootstrap'
import router from '@/router'
import { key, store } from '@/store'

import '@/registerServiceWorker'

import 'normalize.css'
import 'vue-next-select/dist/index.css'
import '@/assets/scss/bootstrap.scss'
import 'floating-vue/dist/style.css'

import filters from '@/filters'
import helpers from '@/helpers'

Amplify.configure({
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    clientMetadata: { redirectBaseUrl: `${process.env.VUE_APP_BASE_URL}/sign-up` }, // This gets passed to the Cognito PreSignUp Lambda Triggers so we can customise the email verification link
    oauth: {
      domain: process.env.VUE_APP_OAUTH_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: process.env.VUE_APP_OAUTH_REDIRECT_URI,
      redirectSignOut: `${process.env.VUE_APP_OAUTH_REDIRECT_URI}/sign-in`,
      responseType: 'code'
    }
  }
})

const app = createApp(App)
bootstrap.registerGlobalComponents(app)
bootstrap.registerCustomDirectives(app)
app.component('AppSelect', VueSelect)

app.config.globalProperties.$filters = filters
app.config.globalProperties.$helpers = helpers

store.dispatch('login')
  .catch(() => {
    console.info('No current user found - redirecting to sign-in screen')
  })
  .finally(async () => {
    app.use(store, key)
    app.use(router)
    app.use(FloatingVue)
    app.use(VueGtag, {
      config: {
        id: 'G-M06B0HVW37'
      }
    }, router)
    // Register a global custom directive called `v-focus`
    app.directive('focus', {
      // When the bound element is mounted into the DOM...
      mounted(el, binding) {
        if (binding.value) {
          // Focus the element
          el.focus()
        }
      }
    })
    app.mount('#app')
  })
