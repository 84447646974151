import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "quiz-card__navigation" }
const _hoisted_2 = { class: "quiz-card__content-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "quiz-card-header"
}
const _hoisted_4 = {
  key: 0,
  class: "quiz-card-header__title"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "quiz-card-header__description" }
const _hoisted_7 = { class: "quiz-card__content" }
const _hoisted_8 = { class: "quiz-card__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppPanel = _resolveComponent("AppPanel")!

  return (_openBlock(), _createBlock(_component_AppPanel, {
    class: _normalizeClass(["quiz-card", { 'quiz-card--content-vertically-aligned' : $props.isVerticallyAligned }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        ($props.prev)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('quiz-navigation:back')))
            }, [
              _createVNode(_component_AppIcon, {
                name: "arrow",
                size: "16"
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('quiz-navigation:close')))
        }, [
          _createVNode(_component_AppIcon, {
            name: "close",
            size: "16"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        ($props.title !== '' || $props.description)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              ($props.title !== '')
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                    ($props.emoji)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($props.emoji), 1))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString($props.title), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_6, _toDisplayString($props.description), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "actions")
        ])
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}