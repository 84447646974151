import { UserProfile } from './user'

export enum NotificationStatus {
  New = 'New', // The user has not yet viewed the notification in the app
  ActionRequired = 'ActionRequired', // The user has viewed the notification but still has a related action to complete
  Completed = 'Completed', // The user has viewed the notification and no further action is required
  Dismissed = 'Dismissed' // The user has dismissed the notification
}

export class Notification {
  constructor(
    public id: string,
    public recipientId: string,
    public teamId: string,
    public description: string,
    public context: Record<string, unknown>,
    public senderId: string,
    public priority: number,
    public type: string,
    public createdAt: string,
    public title: string,
    public updatedAt: string,
    public status: NotificationStatus,
    public appLocation: string,
    public profile?: UserProfile
  ) {}
}
