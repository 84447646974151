
import { defineComponent, ref } from 'vue'

import QuizInterestPicker from '@feature/quiz/quiz-personal-stage/QuizInterestsPicker.vue'

import QuizService from '@/services/QuizService'

import QuizCard from '@feature/quiz/QuizCard.vue'

export default defineComponent({
  name: 'QuizPersonalStageInterests',
  components: {
    QuizCard,
    QuizInterestPicker
  },
  setup(props, { emit }) {
    const { interests } = QuizService.getPersonalStageData()
    const selectedInterests = ref([...interests])

    function navigateNext() {
      emit('quiz-navigation:next')

      QuizService.save({
        interests: selectedInterests.value
      })
      QuizService.next()
    }

    return {
      QuizService,
      navigateNext,
      selectedInterests
    }
  }
})
