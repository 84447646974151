import { Callback } from '@/types/global'
import { Ref, ref } from 'vue'
import breakpoints from '@/assets/scss/exports.scss'

export enum WINDOW_EVENTS {
  RESIZE = 'resize',
  SCROLL = 'scroll',
  LOAD = 'load',
  BEFORE_UNLOAD = 'beforeunload',
  UNLOAD = 'unload',
  OFFLINE = 'offline',
  STORAGE = 'storage'
}

export const CSS_BREAKPOINTS = breakpoints

interface WindowEventServiceEventObject {
  callback: Callback,
  once?: boolean,
  unique?: boolean,
  prepend?: boolean
}

interface WindowEventHandler {
  [key: string]: WindowEventServiceEventObject[]
}

class WindowEventsService {
  private static instance: WindowEventsService
  private handlers: WindowEventHandler
  private currentBreakpoint: Ref<string>

  private updateCurrentBreakpoint (breakpoint: string): void {
    this.currentBreakpoint.value = breakpoint
  }

  private handleBindEvent(event: WINDOW_EVENTS) {
    if (event === WINDOW_EVENTS.LOAD || event === WINDOW_EVENTS.RESIZE) {
      this.addListenersForBreakpointChange()
    }
    this.processEventQueue(event)
  }

  private addListenersForBreakpointChange() {
    for (const value of Object.values(breakpoints).reverse()) {
      if (window.matchMedia(`(min-width: ${value})`).matches) {
        this.updateCurrentBreakpoint(value)
        break
      }
    }
  }

  private constructor() {
    this.handlers = {}
    this.currentBreakpoint = ref('')

    Object.values(WINDOW_EVENTS).forEach((event: WINDOW_EVENTS) => {
      this.handlers[event] = []
      window.addEventListener(event, () => {
        this.handleBindEvent(event)
      })
    })
  }

  public static init(): WindowEventsService {
    if (!WindowEventsService.instance) {
      WindowEventsService.instance = new WindowEventsService()
    }

    return WindowEventsService.instance
  }

  private processEventQueue(event: WINDOW_EVENTS) {
    this.handlers[event] = this.handlers[event].reduce((newHandlers: Array<WindowEventServiceEventObject>, handler: WindowEventServiceEventObject) => {
      handler.callback()
      return !handler.once ? [...newHandlers, handler] : newHandlers
    }, [])
  }

  public on(event: WINDOW_EVENTS, callback: Callback, { once = false, unique = false, prepend = false } = {}) {
    if (typeof callback === 'function') {
      const isCallbackUnique = !this.handlers[event].some((handler: { callback: Callback }) => `${handler.callback}` === `${callback}`)
      if (!unique || (unique && isCallbackUnique)) {
        if (prepend) {
          this.handlers[event].unshift({ once, callback })
        } else {
          this.handlers[event].push({ once, callback })
        }
      }
    }
  }

  public matches(breakpoint: string) {
    return this.currentBreakpoint.value === breakpoint
  }
}

const instance = WindowEventsService.init()
Object.freeze(instance)

export default instance
