
import { defineComponent } from 'vue'
import ToastNotificationService from '@/services/ToastNotificationService'
import SlideFadeTransition from '@transition/SlideFadeTransition.vue'

export default defineComponent({
  name: 'ToastNotificationFacility',
  components: {
    SlideFadeTransition
  },
  setup() {
    return {
      toastNotifications: ToastNotificationService.getAvailableNotifications()
    }
  }
})
