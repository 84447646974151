import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "app-text-editor-image",
  "data-drag-handle": ""
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "floating-menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLoadingIcon = _resolveComponent("AppLoadingIcon")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_FadeTransition = _resolveComponent("FadeTransition")!
  const _component_NodeViewWrapper = _resolveComponent("NodeViewWrapper")!

  return (_openBlock(), _createBlock(_component_NodeViewWrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isPreview)
          ? (_openBlock(), _createBlock(_component_AppLoadingIcon, {
              key: 0,
              class: "preview-loader",
              width: "50",
              height: "50"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("img", {
          class: _normalizeClass(["custom-image", {
          'custom-image__is-preview': _ctx.isPreview,
          'custom-image__is-selected': _ctx.imageIsSelected,
          [`custom-image__size-${_ctx.node.attrs.size}`]: true,
        }]),
          src: _ctx.node.attrs.src
        }, null, 10, _hoisted_2),
        _createVNode(_component_FadeTransition, { isGroup: true }, {
          default: _withCtx(() => [
            (_ctx.imageIsSelected)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "delete-image",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteNode()))
                }, [
                  _createVNode(_component_AppIcon, {
                    size: "16",
                    name: "close"
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.imageIsSelected)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("button", {
                    class: _normalizeClass(["menu-button", { 'is-active': _ctx.node.attrs.size === 'small' }]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setSize('small')))
                  }, [
                    _createVNode(_component_AppIcon, {
                      size: "16",
                      name: "minimize-solid"
                    })
                  ], 2),
                  _createElementVNode("button", {
                    class: _normalizeClass(["menu-button", { 'is-active': _ctx.node.attrs.size === 'medium' }]),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setSize('medium')))
                  }, [
                    _createVNode(_component_AppIcon, {
                      size: "16",
                      name: "compress-solid"
                    })
                  ], 2),
                  _createElementVNode("button", {
                    class: _normalizeClass(["menu-button", { 'is-active': _ctx.node.attrs.size === 'large' }]),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setSize('large')))
                  }, [
                    _createVNode(_component_AppIcon, {
                      size: "16",
                      name: "maximize-solid"
                    })
                  ], 2)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}