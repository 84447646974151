
import { defineComponent, ref, PropType } from 'vue'

import { IQuizGreatDebate } from '@/types/quiz'
import { GreatDebateCategory, GreatDebateItem } from '@/types/user'

import QuizService from '@/services/QuizService'

import QuizCard from '@feature/quiz/QuizCard.vue'
import QuizSelectableButton from '@feature/quiz/QuizSelectableButton.vue'

export default defineComponent({
  name: 'QuizLightningRound',
  emits: ['quiz-navigation:next', 'quiz-navigation:prev'],
  components: {
    QuizCard,
    QuizSelectableButton
  },
  props: {
    data: {
      type: Object as PropType<IQuizGreatDebate>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { greatDebate } = QuizService.getLightningRoundStageData()

    const entry: GreatDebateItem | undefined = greatDebate.find((g: GreatDebateItem) => g.category === props.data.value)
    const choice = ref<GreatDebateItem | undefined>(undefined)

    if (entry) {
      choice.value = entry
    }

    function navigateNext() {
      emit('quiz-navigation:next')
      const isGreatDebateEmpty = !greatDebate.length
      const categoryAlreadyExists = greatDebate.find((g: GreatDebateItem) => g.category === choice.value?.category)
      let data = []

      if (isGreatDebateEmpty) {
        data.push(choice.value)
      } else if (categoryAlreadyExists) {
        data = greatDebate.map((g) => {
          return g.category === choice.value?.category
            ? choice.value
            : g
        })
      } else {
        data = [...greatDebate, choice.value]
      }

      QuizService.save({
        greatDebate: data
      })
      QuizService.next()
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')
      QuizService.prev()
    }

    function setGreatDebateCategory(category: GreatDebateCategory, firstOption: boolean) {
      choice.value = {
        category,
        firstOption
      }
    }

    return {
      choice,
      QuizService,
      setGreatDebateCategory,
      navigateNext,
      navigatePrev
    }
  }
})
