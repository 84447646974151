import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAlert = _resolveComponent("AppAlert")!
  const _component_SlideFadeTransition = _resolveComponent("SlideFadeTransition")!

  return (_openBlock(), _createBlock(_component_SlideFadeTransition, {
    class: "toast-notification-facility",
    tag: "div",
    isGroup: true
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toastNotifications, (notification) => {
        return (_openBlock(), _createBlock(_component_AppAlert, {
          key: notification.id,
          theme: notification.type === 'error' && 'danger',
          borderless: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(notification.message), 1)
          ]),
          _: 2
        }, 1032, ["theme"]))
      }), 128))
    ]),
    _: 1
  }))
}