
import { defineComponent, PropType } from 'vue'

enum APP_SPACER_VARIATIONS {
  SIMPLE = 'simple',
  BORDERED_CONTENT = 'bordered-content'
}

export default defineComponent({
  name: 'AppSpacer',
  props: {
    type: {
      type: String as PropType<APP_SPACER_VARIATIONS>,
      required: false,
      validator: (value: APP_SPACER_VARIATIONS): boolean => Object.values(APP_SPACER_VARIATIONS).includes(value)
    }
  }
})
