import { DailySyncTask } from '@/types/dailySync'
import { store } from '@/store'

export interface BacklogEventListener {
    id: string,
    onSendToBacklog(task: DailySyncTask): void
    onSendToTaskList(task: DailySyncTask): void
}

export class BacklogService {
    public static instance = new BacklogService()
    private constructor(
      public taskListListener?: BacklogEventListener,
      public backlogListener?: BacklogEventListener
    ) {}

    public sendToBacklog(task: DailySyncTask): void {
      if (this.taskListListener) {
        this.taskListListener.onSendToBacklog(task)
      }
      if (this.backlogListener) {
        this.backlogListener.onSendToBacklog(task)
      } else {
        // if the backlog component has not registered a listener yet then update the backlog directly in the backend
        const teamId = store.getters['user/getActiveMembership']?.teamId
        const currentBacklog = store.getters['dailySync/getBacklog']
        currentBacklog.push(task)
        store.dispatch('dailySync/upsertBacklog', { teamId, tasks: currentBacklog })
      }
    }

    public sendToTaskList(task: DailySyncTask): void {
      if (this.taskListListener) {
        this.taskListListener.onSendToTaskList(task)
      }
      if (this.backlogListener) {
        this.backlogListener.onSendToTaskList(task)
      }
    }
}
