import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "deleted-comment hide"
}
const _hoisted_2 = { class: "comment-row__content" }
const _hoisted_3 = { class: "comment-row__bubble" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "comment-row__meta" }
const _hoisted_7 = { class: "timestamp" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 3,
  class: "comment-reply-editor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAvatar = _resolveComponent("AppAvatar")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppBasicDropdown = _resolveComponent("AppBasicDropdown")!
  const _component_AppDropdown = _resolveComponent("AppDropdown")!
  const _component_AppTextEditor = _resolveComponent("AppTextEditor")!
  const _component_ReactionDisplay = _resolveComponent("ReactionDisplay")!
  const _component_ReactionInput = _resolveComponent("ReactionInput")!
  const _component_Comment = _resolveComponent("Comment", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.comment.deleted && _ctx.comment.replies.length === 0 && !_ctx.parentCommentId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "We can hide this deleted top level comment that has no replies"))
      : (_ctx.comment.deleted)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["deleted-comment", { 'indented': _ctx.parentCommentId }])
          }, "Comment deleted ", 2))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["comment-row", { 'indented': _ctx.parentCommentId, 'maximized': _ctx.isEditing }])
          }, [
            _createVNode(_component_AppAvatar, {
              user: _ctx.author.userProfile,
              size: "small"
            }, null, 8, ["user"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.isCurrentUserAuthor)
                  ? (_openBlock(), _createBlock(_component_AppDropdown, {
                      key: 0,
                      class: "comment-menu"
                    }, {
                      handler: _withCtx((props) => [
                        _createElementVNode("button", {
                          onClick: props.visibility.toggle
                        }, [
                          _createVNode(_component_AppIcon, {
                            name: "expand",
                            size: "13"
                          })
                        ], 8, _hoisted_4)
                      ]),
                      default: _withCtx((props) => [
                        _createVNode(_component_AppBasicDropdown, null, {
                          items: _withCtx(() => [
                            _createElementVNode("div", {
                              onClick: ($event: any) => (props.visibility.toggle(), _ctx.isEditing = !_ctx.isEditing)
                            }, _toDisplayString(_ctx.isEditing ? 'Cancel' : 'Edit'), 9, _hoisted_5),
                            _createElementVNode("div", {
                              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteComment && _ctx.deleteComment(...args)))
                            }, "Delete")
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("h6", null, _toDisplayString(_ctx.author.userProfile.firstName) + " " + _toDisplayString(_ctx.author.userProfile.lastName), 1),
                (_openBlock(), _createBlock(_component_AppTextEditor, {
                  class: _normalizeClass(["comment-row__bubble__editor", { 'isEditing' : _ctx.isEditing }]),
                  key: _ctx.isEditing,
                  modelValue: _ctx.commentText,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.commentText) = $event)),
                  borderless: !_ctx.isEditing,
                  emojiPickerExpandsUp: true,
                  editable: _ctx.isEditing,
                  autofocus: _ctx.isEditing,
                  hasIcons: _ctx.isEditing,
                  allowCancel: true,
                  "button-text": "Save",
                  "onUserAction:post": _ctx.editComment,
                  "onUserAction:cancel": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isEditing = false))
                }, null, 8, ["class", "modelValue", "borderless", "editable", "autofocus", "hasIcons", "onUserAction:post"])),
                _createVNode(_component_ReactionDisplay, {
                  reactions: _ctx.comment.reactions
                }, null, 8, ["reactions"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_ReactionInput, {
                  modelValue: _ctx.currentUserReaction,
                  "onUpdate:modelValue": [
                    _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentUserReaction) = $event)),
                    _cache[4] || (_cache[4] = ($event: any) => (_ctx.submitReaction()))
                  ],
                  theme: "comment"
                }, null, 8, ["modelValue"]),
                (_ctx.replyEditorOpen && _ctx.replyTriggeredFromThisComment)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "reply",
                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.cancelReply && _ctx.cancelReply(...args)))
                    }, "Cancel reply"))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "reply",
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.startReply && _ctx.startReply(...args)))
                    }, "Reply")),
                _createElementVNode("span", _hoisted_7, [
                  _createTextVNode(_toDisplayString(`${_ctx.timeDiffCalc(_ctx.comment.created, true)} `), 1),
                  (_ctx.comment.updated)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, "(edited)"))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ], 2)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comment.replies, (reply) => {
      return (_openBlock(), _createBlock(_component_Comment, {
        key: reply.id,
        comment: reply,
        itemId: _ctx.itemId,
        itemType: _ctx.itemType,
        parentCommentId: _ctx.comment.id,
        replyEditorOpen: _ctx.replyEditorOpen,
        "onUserAction:reply": _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('user-action:reply', _ctx.comment.id))),
        "onUserAction:cancelReply": _ctx.cancelReply
      }, null, 8, ["comment", "itemId", "itemType", "parentCommentId", "replyEditorOpen", "onUserAction:cancelReply"]))
    }), 128)),
    (_ctx.replyEditorOpen && !_ctx.parentCommentId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_AppTextEditor, {
            ref: "replyEditor",
            modelValue: _ctx.replyText,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.replyText) = $event)),
            "has-icons": true,
            allowCancel: true,
            autofocus: true,
            placeholder: "Write a reply",
            "button-text": "Reply",
            "onUserAction:post": _ctx.postReply,
            loading: _ctx.isSaving,
            "onUserAction:cancel": _ctx.cancelReply
          }, null, 8, ["modelValue", "onUserAction:post", "loading", "onUserAction:cancel"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}