<template>
  <div class="tag-input">
    <label v-if="label" for="tag-list" class="tag-input__label">{{ label }}</label>

    <ul id="tag-list" ref="tagsList" @click.self="focusInput($event)">
      <li
        v-for="(tag, index) in tags"
        :key="tag"
        :class="{ 'tag-input__duplicate': tag === duplicate }"
      >
        <AppChip :text="tag" theme="secondary" @on-remove="removeTag(index)" />
      </li>
      <AppInput
        v-model="inputValue"
        :type="type"
        autocomplete="off"
        :id="id"
        :placeholder="placeholder"
        @blur="addTag(inputValue)"
        @keydown="handleKeypress"
        @keydown.delete="inputValue.length || removeTag(tags.length - 1)"
      />
    </ul>
  </div>
</template>

<script>
import { ref, watch, nextTick, defineComponent } from 'vue'
import inputValidationRegex from '@/data/input-validation-regex'

import AppChip from '@common/AppChip.vue'

export default defineComponent({
  name: 'AppTagInput',
  components: {
    AppChip
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'email'
    },
    placeholder: {
      type: String,
      default: 'Enter email address'
    },
    label: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const tags = ref(props.modelValue)
    const inputValue = ref('')

    if (props.type === 'email') {
      tags.value = tags.value.filter((email) => {
        return email && new RegExp(inputValidationRegex.email.format).test(email)
      })
    }

    const addTag = (tag) => {
      if (!tag) return // prevent empty tag
      // return early if duplicate
      if (tags.value.includes(tag)) {
        handleDuplicate(tag)
        return
      }

      if (props.type === 'email' &&
       !(new RegExp(inputValidationRegex.email.format).test(tag))) return

      tags.value.push(tag)
      inputValue.value = '' // reset inputValue
    }

    const removeTag = (index) => {
      tags.value.splice(index, 1)
    }

    // handling duplicates
    const duplicate = ref(null)
    const handleDuplicate = (tag) => {
      duplicate.value = tag
      setTimeout(() => (duplicate.value = null), 1000)
      inputValue.value = ''
    }

    const focusInput = (event) => {
      nextTick(() => {
        event.target.querySelector('input').focus()
      })
    }

    // handling tag change
    const tagsList = ref(null)
    const onTagsChange = () => {
      emit('update:modelValue', tags.value)
    }

    const handleKeypress = (event) => {
      if (
        event.key === ',' ||
        event.key === 'Enter' ||
        event.code === 'Tab' ||
        event.code === 'Space'
      ) {
        event.preventDefault()
        addTag(inputValue.value)
      }
    }

    watch(tags, () => {
      nextTick(onTagsChange)
    }, { deep: true })

    return {
      tags,
      inputValue,
      addTag,
      removeTag,
      focusInput,
      tagsList,
      duplicate,
      handleKeypress
    }
  }
})
</script>

<style lang="scss">
.tag-input {
  $self: &;
  position: relative;

  &__label {
    display: inline-block;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-augite);
  }

  &__duplicate {
    transition: all 200ms ease-in;

     .app-chip {
      &.app-chip--secondary {
        background: var(--color-indigo);
        animation: shake 1s;

        .app-chip__text {
          color: var(--color-white);
        }

        .app-chip__remove {
          color: var(--color-white);
        }
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .app-input {
    padding-left: 12px;
    outline: none;

    input {
      font-size: 15px;
      line-height: 27px;
      color: var(--color-augite);
      border: none;
      padding: 0;
      outline: none;

      &:active,
      &:focus {
        outline: none;
      }
    }
  }

  ul {
    --gap: 12px;

    margin: 0;
    max-height: inherit;
    font-size: 15px;
    line-height: 21px;
    background: var(--color-white);
    border: 2px solid var(--color-grey-200);
    box-sizing: border-box;
    padding: 0 14px 10px;
    cursor: text;
    overflow: auto;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));

    & > * {
      margin: var(--gap) var(--gap) 0 0;
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: scale(0.9) translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: scale(0.9) translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: scale(0.9) translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: scale(0.9) translate3d(4px, 0, 0);
  }
}
</style>
