
import { defineComponent } from 'vue'
import AppAvatar from '@common/AppAvatar.vue'

export default defineComponent({
  name: 'AppLogo',
  components: {
    AppAvatar
  },
  props: {
    theme: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    }
  }
})
