
import { defineComponent, ref } from 'vue'

import QUIZ_PROFESSIONAL_SKILLS from '@/data/json/skills.json'

import QuizService from '@/services/QuizService'

import AppDropdown from '@common/AppDropdown.vue'
import AppChip from '@common/AppChip.vue'
import QuizCard from '@feature/quiz/QuizCard.vue'

export default defineComponent({
  name: 'QuizBasicStageSkills',
  emits: ['quiz-navigation:next', 'quiz-navigation:prev'],
  components: {
    AppDropdown,
    AppChip,
    QuizCard
  },
  setup(_, { emit }) {
    const { professionalSkills } = QuizService.getBasicStageData()
    const skills = ref<string[]>([])
    const visibleSkills = ref(QUIZ_PROFESSIONAL_SKILLS.slice(0, 100))
    const selectedSkills = ref(professionalSkills.slice())
    const hideSelectedSkill = ref(false)

    function navigateNext() {
      emit('quiz-navigation:next')
      QuizService.save({
        professionalSkills: selectedSkills.value
      })
      QuizService.next()
    }

    function navigatePrev() {
      emit('quiz-navigation:prev')
      QuizService.prev()
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleSkillSearch(event: any) {
      if (event?.target?.value === '') {
        visibleSkills.value = QUIZ_PROFESSIONAL_SKILLS.slice(0, 100)
      } else {
        visibleSkills.value = QUIZ_PROFESSIONAL_SKILLS.filter((e) => e.toLowerCase().startsWith(event.target?.value?.toLowerCase())).slice(0, 100)
      }
    }

    function saveSkill(skillToAdd: string) {
      hideSelectedSkill.value = true

      if (selectedSkills.value.includes(skillToAdd)) {
        selectedSkills.value.splice(selectedSkills.value.indexOf(skillToAdd), 1)
        skills.value.splice(skills.value.indexOf(skillToAdd), 1)
      } else {
        selectedSkills.value.push(skillToAdd)
      }
    }

    function removeSkill(skillToRemove: string) {
      selectedSkills.value = selectedSkills.value.filter((skill) => skill !== skillToRemove)
      skills.value = skills.value.filter((skill) => skill !== skillToRemove)
    }

    return {
      QuizService,
      navigateNext,
      navigatePrev,
      skills,
      visibleSkills,
      selectedSkills,
      handleSkillSearch,
      saveSkill,
      removeSkill,
      hideSelectedSkill
    }
  }
})
