
import AppTextEditor from '@/components/common/AppTextEditor.vue'
import { extractMentionedUsers } from '@/helpers/mentionUtils'
import ToastNotificationService from '@/services/ToastNotificationService'
import { useStore } from '@/store'
import DailySync, { DailySyncTask, UpsertDailySyncRequest } from '@/types/dailySync'
import { CommentType } from '@/types/comments'
import { User, WorkDaySchedule } from '@/types/user'
import { deepCopyDailySyncTaskList, getDailySyncForUser, getUserScheduleForDay, isNonEmptyTask, updateTaskHistoryForDate } from '@/utils/dailySyncUtils'
import AppAvatar from '@common/AppAvatar.vue'
import DailySyncMoodSelector from '@feature/daily-sync/DailySyncMoodSelector.vue'
import DailySyncAvailabilitySelector from '@/components/feature/daily-sync/DailySyncAvailabilitySelector.vue'
import DailySyncTaskList from '@feature/daily-sync/DailySyncTaskList.vue'
import ReactionDisplay from '@common/ReactionDisplay.vue'
import Conversation from '@common/Conversation.vue'
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { timeDiffCalc } from '@/utils/calculateTimeDiff'
import { DateTime } from 'luxon'
import { BacklogEventListener, BacklogService } from '@/services/BacklogService'
import { v4 } from 'uuid'

export default defineComponent({
  name: 'DailySyncsubmission',
  emits: ['update:modelValue'],
  components: {
    AppTextEditor,
    DailySyncMoodSelector,
    DailySyncAvailabilitySelector,
    AppAvatar,
    DailySyncTaskList,
    ReactionDisplay,
    Conversation
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    userDailySync: {
      type: Object as PropType<DailySync>,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const dailySync = computed<DailySync>(() => props.userDailySync)
    const tasks = ref<DailySyncTask[]>(deepCopyDailySyncTaskList(dailySync.value?.tasks ?? []))
    const previouslyCompletedTasks = (getDailySyncForUser(DateTime.fromISO(props.userDailySync.date).minus({ day: 1 }), props.user.userId)?.tasks ?? []).filter(e => e.completed)
    const userSchedule = ref<WorkDaySchedule | undefined>(getUserScheduleForDay(props.user.userId, props.userDailySync.date))
    const expandCompletedTaskList = ref(false)
    const editingTeamTalk = ref(false)
    const isSaving = ref(false)
    let queueNextUpdate = false // this flag is used to queue updates so that only one update request is being sent at a time
    let savedTeamTalk = dailySync.value.message
    let saveTeamTalkTimer: ReturnType<typeof setTimeout> | null = null

    async function updateDailySync() {
      if (isSaving.value === true) {
        queueNextUpdate = true
      } else {
        try {
          isSaving.value = true
          const request = new UpsertDailySyncRequest(
            getNonEmptyTasksWithUpdatedHistory(),
            getMentionedUsers(),
            dailySync.value.message,
            dailySync.value.feelingEmoji,
            userSchedule.value?.works ? userSchedule.value : undefined,
            dailySync.value.reactions,
            undefined,
            dailySync.value.lastCommentTime,
            false
          )
          const teamId = store.getters['user/getActiveMembership']?.teamId
          await store.dispatch('dailySync/upsertDailySync', { teamId, userId: props.user.userId, date: dailySync.value.date, request })
          dailySync.value.lastUpdatedAt = DateTime.utc().toISO()
        } catch (error) {
          if (error instanceof Error) {
            ToastNotificationService.push({ type: 'error', message: error.message })
          }
        } finally {
          isSaving.value = false
          if (queueNextUpdate) {
            queueNextUpdate = false
            await updateDailySync()
          }
        }
      }
    }

    function getNonEmptyTasksWithUpdatedHistory(): DailySyncTask[] {
      const nonEmptyTasks = tasks.value.filter(isNonEmptyTask).map(e => ({ ...e, assignee: props.user.userId }))
      nonEmptyTasks.forEach(e => updateTaskHistoryForDate(e, dailySync.value.date))
      return nonEmptyTasks
    }

    function getMentionedUsers(): string[] {
      return [...extractMentionedUsers(dailySync.value.message), ...tasks.value.flatMap(e => extractMentionedUsers(e.text))]
    }

    async function deleteDailySync() {
      try {
        isSaving.value = true
        const teamId = store.getters['user/getActiveMembership']?.teamId
        await store.dispatch('dailySync/deleteDailySync', { teamId, userId: props.user.userId, date: dailySync.value.date })
        ToastNotificationService.push({ type: 'info', message: 'Daily sync deleted' })
      } catch (error) {
        if (error instanceof Error) {
          ToastNotificationService.push({ type: 'error', message: error.message })
        }
      } finally {
        isSaving.value = false
      }
    }

    async function onTeamTalkUpdated() {
      if (saveTeamTalkTimer) {
        clearTimeout(saveTeamTalkTimer)
      }
      saveTeamTalkTimer = setTimeout(() => {
        saveChangesToTeamTalk()
      }, 2000)
    }

    async function saveChangesToTeamTalk() {
      if (saveTeamTalkTimer) {
        clearTimeout(saveTeamTalkTimer)
      }
      if (savedTeamTalk !== dailySync.value.message) {
        savedTeamTalk = dailySync.value.message
        await updateDailySync()
      }
    }

    watch(() => store.getters['teams/getUserSchedules'], () => { userSchedule.value = getUserScheduleForDay(props.user.userId, props.userDailySync.date) })

    const backlogListener: BacklogEventListener = {
      id: v4(),
      onSendToBacklog: (task: DailySyncTask) => {
        const deleteIndex = tasks.value.findIndex(e => e.id === task.id)
        if (deleteIndex !== -1) {
          tasks.value.splice(deleteIndex, 1)
          updateDailySync()
        }
      },
      onSendToTaskList: (task: DailySyncTask) => {
        task.assignee = props.user.userId
        const insertIndex = tasks.value.filter(isNonEmptyTask).length
        tasks.value.splice(insertIndex, 0, task)
        updateDailySync()
      }
    }

    onMounted(() => {
      BacklogService.instance.taskListListener = backlogListener
    })

    return {
      dailySync,
      tasks,
      previouslyCompletedTasks,
      userSchedule,
      expandCompletedTaskList,
      editingTeamTalk,
      isSaving,
      updateDailySync,
      onTeamTalkUpdated,
      saveChangesToTeamTalk,
      deleteDailySync,
      timeDiffCalc,
      CommentType
    }
  }
})
