import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/svg/logo-text.svg?data'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: "cadrelo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAvatar = _resolveComponent("AppAvatar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-logo", [_ctx.theme && `app-logo--${_ctx.theme}`]])
  }, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        (_ctx.type)
          ? (_openBlock(), _createElementBlock("img", _hoisted_1))
          : (_openBlock(), _createBlock(_component_AppAvatar, {
              key: 1,
              user: { displayName: 'Cadrelo' },
              rounded: true,
              size: "small"
            }))
      ]),
      _: 1
    })
  ], 2))
}