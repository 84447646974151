/* eslint-disable @typescript-eslint/no-explicit-any */
import MentionList from '@/components/common/MentionList.vue'
import { Membership } from '@/types/membership'
import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion'
import { VueRenderer } from '@tiptap/vue-3'
import tippy, { GetReferenceClientRect } from 'tippy.js'

// Gets a list of up to 5 mention suggestsions by applying the supplied query to the names in the member array
export function getMentionSuggestions(query: string, members: Membership[]): string[] {
  return members
    .map((e) => (JSON.stringify({ id: e.userId, profile: { firstName: e.userProfile.firstName, lastName: e.userProfile.lastName, pictureId: e.userProfile.pictureId } })))
    .filter(item => JSON.parse(item).profile.firstName?.toLowerCase().startsWith(query.toLowerCase()))
    .slice(0, 5)
}

export function mentionRenderer(): any {
  let component: VueRenderer
  let popup: any

  return {
    onStart: (props: SuggestionProps) => {
      component = new VueRenderer(MentionList, {
        props,
        editor: props.editor
      })

      popup = tippy('body', {
        getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: 'manual',
        placement: 'bottom-start'
      })
    },

    onUpdate(props: SuggestionProps) {
      component.updateProps(props)

      popup[0].setProps({
        getReferenceClientRect: props?.clientRect
      })
    },

    onKeyDown(props: SuggestionKeyDownProps) {
      if (props.event.key === 'Escape') {
        popup[0].hide()

        return true
      }

      return component.ref?.onKeyDown(props)
    },

    onExit() {
      popup[0].destroy()
      component.destroy()
    }
  }
}

// Extract userIds of all mentioned users from a block of HTML content as produced by the AppTextEditor
export function extractMentionedUsers(content?: string): string[] {
  if (!content) {
    return []
  }
  const parser = new DOMParser()
  const html = parser.parseFromString(content, 'text/html')
  const mentions = html.getElementsByClassName('mention')
  const userIds: string[] = []
  for (const mention of mentions) {
    const userData = mention.getAttribute('data-id')
    if (userData) {
      const user = JSON.parse(userData)
      userIds.push(user.id)
    }
  }
  return [...new Set(userIds)]
}
