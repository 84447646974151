import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "check-in-avatar" }
const _hoisted_2 = {
  key: 0,
  class: "check-in-avatar__overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAvatar = _resolveComponent("AppAvatar")!
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppAvatar, {
      size: _ctx.size,
      user: _ctx.user
    }, null, 8, ["size", "user"]),
    (!_ctx.preventRemove)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AppIcon, {
            name: "close",
            size: _ctx.iconSize
          }, null, 8, ["size"])
        ]))
      : _createCommentVNode("", true)
  ]))
}